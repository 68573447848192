import { useTranslation } from 'react-i18next';
import { Institution } from '../../../../types/AccountDetails';
import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Colors } from '../../../../themes';
import React, { useState } from 'react';
import DropdownMenu, { MenuLink } from '../../../../components/menu/DropDownMenu';
import { Svgs } from '../../../../assets/svg';
import { Row, Column } from '../../../../components';

declare type InstitutionDelegate = (institution: Institution) => void;

export type InstitutionAction = {
  textKey: string;
  onClick: InstitutionDelegate;
};

export interface InstitutionalAccountProps {
  institution: Institution;
  onClick: InstitutionDelegate;
  actions: InstitutionAction[];
}

const InstitutionalAccountCard: React.FC<InstitutionalAccountProps> = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);
  const [menuIsClicked, setMenuIsClicked] = useState(false);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuIsClicked(true);
    event.stopPropagation();
  };

  const handleClose = (event: unknown) => {
    setAnchorEl(undefined);
    setMenuIsClicked(false);
    (event as React.MouseEvent<HTMLElement>).stopPropagation();
  };

  const handleCardClick = () => {
    console.log('Clicking', props.institution);
    props.onClick(props.institution);
  };

  return (
    <Grid alignItems='center' mb={1} flexBasis='50%'>
      <Card
        className={`institutional-account-card ${menuIsClicked ? 'clicked' : ''}`}
        sx={{
          'width': '95%',
          'height': '90%',
          '& :hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleCardClick}>
        <CardContent className='institution'>
          <Row>
            <Column>
              <Typography variant='p18Bold' color='secondary'>
                {props.institution.institutionName}
              </Typography>
              {props.institution.requiresLogin ? (
                <Typography color={Colors.accentBase}>{t('LoginExpired')}</Typography>
              ) : (
                <Typography color='primary'>
                  {t('CreatedOn', {
                    date: dayjs(props.institution.createdOn).format('MM/DD/YYYY'),
                  })}
                </Typography>
              )}
            </Column>
            <IconButton
              className={'show-on-hover'}
              sx={{ opacity: 0 }}
              onClick={handleMenu}
              disableRipple>
              {<Svgs.ActionSmallEllipsis />}
            </IconButton>
            <DropdownMenu
              onClose={handleClose}
              element={anchorEl}
              menuLinks={props.actions.map<MenuLink>((a) => {
                return {
                  key: a.textKey,
                  onClick: (event: React.MouseEvent<HTMLElement>) => {
                    a.onClick(props.institution);
                    handleClose(event);
                  },
                };
              })}
            />
          </Row>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default InstitutionalAccountCard;
