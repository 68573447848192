import { Authentication, Data } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { AppStorage, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';

export async function plaidLoader(container: IServiceContainer) {
  const authContext = container.get<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const appStorage = container.get<AppStorage>(ScriniumServices.AppStorage);
  const plaidDataCache = appStorage.retrieve<Data.Plaid.PlaidCompartments>(
    Data.Plaid.plaidStorageKey
  );
  const plaidListener = new Data.Plaid.PlaidDataListener(authContext, plaidDataCache);
  plaidListener.start();
  return json('goals listener started');
}
