import { Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { ChartPieVariableRadius } from '../../components/ChartPieVariableRadius';
import _ from 'lodash';
import { CSSProperties } from 'react';
import { SpendingSummaryBreakdownProps } from './SpendingSummaryBreakdown';
import { TransactionsSummaryChartColors } from './SpendingSummaryOverview';
import { useNavigate } from 'react-router-dom';

interface SpendingSummaryChartProps extends SpendingSummaryBreakdownProps {
  style?: CSSProperties;
}

const SpendingSummaryChart: React.FC<SpendingSummaryChartProps> = ({
  transactionsSummary,
  colors = TransactionsSummaryChartColors,
  repeatColorIndex = colors.length - 1,
  style = { minWidth: '200px' },
}) => {
  const { loadingSummary } = Hooks.useTransactionsSummary();
  const navigate = useNavigate();

  return (
    <ChartPieVariableRadius
      tooltipText={({ datum }) => {
        return [`${_.startCase(_.toLower(datum.x))}`, `\n`, `${formatWholeDollars(datum.y)}`];
      }}
      data={transactionsSummary.map((transaction, index) => ({
        x: _.startCase(_.toLower(transaction.primaryCategory?.toString())) ?? '',
        y: transaction.amount ?? 0,
        color: colors[index > repeatColorIndex ? repeatColorIndex : index],
      }))}
      innerRadius={50}
      style={style}
      showSkeleton={loadingSummary}
      onClick={() => navigate('spending-summary')}
    />
  );
};

export default SpendingSummaryChart;
