import React from 'react';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../../../components/form/TextInput';
import SelectInput from '../../../components/form/SelectInput';
import FormContent from '../../../components/form/FormContent';
import { Spacing } from '../../../themes';
import { Domain } from '@3nickels/data-modules';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Button } from '../../../components/buttons/Button';

export type DependentFormProps = {
  edit?: boolean;
  dependent?: Domain.DependentData;
  onCancel?: () => void;
  onSave: (d: Domain.DependentData, editing?: boolean) => void;
  makeSubmit?: boolean;
};

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('Name is required'),
  birthYear: Yup.number().required('Required'),
});

const BuildupYears = () => {
  const year = new Date().getFullYear();
  const collection: number[] = [];

  for (let i = 0; i < 26; i++) {
    collection.push(year - i);
  }

  return collection;
};

type DependentSchema = Yup.InferType<typeof validationSchema>;

const DependentForm: React.FC<DependentFormProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography color='primary' variant='h1' component='h1'>
        {props.edit ? t('EditDependent') : t('AddDependent')}
      </Typography>
      <DependentFields {...props} onSave={props.onSave} />
    </>
  );
};

export const DependentFields: React.FC<DependentFormProps> = (props) => {
  const { t } = useTranslation();
  const methods = useForm<DependentSchema>({
    defaultValues: {
      name: props.dependent?.name,
      birthYear: props.dependent?.birthYear,
    },
    resolver: yupResolver(validationSchema),
  });
  const { makeSubmit = true } = props;

  const onSubmit = methods.handleSubmit((data, e) => {
    props.onSave(data, props.edit);
    methods.resetField('name', undefined);
    methods.resetField('birthYear', { defaultValue: undefined });
    e?.preventDefault();
  });

  return (
    <FormContent formProviderProps={methods}>
      <Box component='form' onSubmit={onSubmit} noValidate>
        <Grid container justifyContent='center' mt={2}>
          <Grid item sm={10}>
            <Stack spacing={Spacing.xxs}>
              <TextInput<Domain.DependentData>
                error={methods.formState.errors.name !== undefined}
                helperText={methods.formState.errors.name && 'Required'}
                inputProps={{ maxLength: 32 }}
                name='name'
                label='DependentsFirstName'
                placeholder={t('FirstName') as string}
                defaultValue={props.dependent?.name}
                autoFocus
              />

              <SelectInput<Domain.DependentData>
                helperText='Required'
                showHelperText={methods.formState.errors.birthYear !== undefined}
                error={methods.formState.errors.birthYear !== undefined}
                name='birthYear'
                label='DependentsYearOfBirth'
                defaultValue={props.dependent?.birthYear as unknown as string}>
                {BuildupYears().map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </SelectInput>

              {props.dependent?.id && (
                <input type='hidden' {...methods.register('id')} value={props.dependent.id} />
              )}
            </Stack>
          </Grid>
        </Grid>
        {props.onCancel ? (
          <WizardFooter
            color='primary'
            onBack={props.onCancel}
            onDone={onSubmit}
            makeSubmit={makeSubmit}
            nextLabel='Save'
            backLabel='Cancel'
          />
        ) : (
          <Grid item display='flex' justifyContent='center' sm={12} my={3}>
            <Button type='submit' onClick={onSubmit}>
              Add
            </Button>
          </Grid>
        )}
      </Box>
    </FormContent>
  );
};

export { DependentForm };
