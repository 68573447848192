import { Data, Hooks } from '@3nickels/data-modules';
import { useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Typography, Drawer, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading } from '../../../../hooks/useLoading';
import { Spacing } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { BusinessEditViewWrapper as BusinessEditView } from './BusinessEditView';
import BusinessCard from './BusinessCard';

declare type BusinessSummaryDrawerType = keyof Data.Business.BusinessWizardRegistry;

const BusinessSummaryView: React.FC = () => {
  const data = Hooks.useBusinessWizardData();
  const { wizard } = Hooks.useBusinessWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const includeSpouse = Hooks.useIncludeSpouse();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const currentBalance = (data.basic.ownershipPercentage ?? 1) / 100 * (data.basic.valuation ?? 1);

  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    }
  }, [isStarted]);

  const openDrawer = (key?: BusinessSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };

  return (
    <Box>
      <Grid display='none'></Grid>
      <Grid container display='inline-flex' justifyContent='space-between'>
        <Typography variant='h1' component='h1' marginTop='10px' color='secondary'>
          {data.basic.name}
        </Typography>
        <Grid display='inline-flex' marginTop='10px'>
          <Typography color='primary' marginRight='20px'>
            Total Value
          </Typography>
          <Typography variant='p40Bold' color={currentBalance >= 0 ? 'secondary' : 'error'}>
            {formatWholeDollars(currentBalance)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item mt={2}>
        <Stack spacing={2}>
          <BusinessCard
            business={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(undefined as unknown as keyof Data.Business.BusinessWizardRegistry);
        }}
      />
      <WizardFooter nextLabel='Done' onDone={() => navigate('/account-details')} />
    </Box>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard } = Hooks.useBusinessWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key;
  if (key === 'basic') {
    return <BusinessEditView editing onBack={onBack} />;
  }

  return null;
};

export interface BusinessSummaryBusinessdProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(BusinessSummaryView, meta);
