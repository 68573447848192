/* tslint:disable */
import AccountDetailsLayout from '../pages/account-details/AccountDetailsLayout';
import { RouteObject } from 'react-router';
import BudgetDetailsLayout from '../pages/budget-details/BudgetDetailsLayout';
import GoalListScreen from '../pages/goals';
import GoalsLayout from '../pages/goals/GoalsLayout';
import CarGoalEditView from '../pages/goals/car/CarGoalEditView';
import OtherGoalEditView from '../pages/goals/other/OtherGoalEditView';
import VacationGoalEditView from '../pages/goals/vacation/VacationGoalEditView';
import LegacyGiving from '../pages/goals/legacy/LegacyGiving';
import GivingAssets from '../pages/goals/giving/assets/GivingAssets';
import GivingCollege from '../pages/goals/giving/college/GivingCollege';
import GivingOnBehalf from '../pages/goals/giving/on-behalf/GivingOnBehalf';
import GivingForTaxBenefits from '../pages/goals/giving/tax-benefits/GivingForTaxBenefits';
import SelectGoalType from '../pages/goals/SelectGoalType';
import SelectGivingGoalTypeView from '../pages/goals/giving/SelectGivingGoalType';
import SelectHouseGoalTypeView from '../pages/goals/house/SelectHouseGoalType';
import HouseGoalEditView from '../pages/goals/house/buy/HouseGoalEditView';
import MortgageGoalView from '../pages/goals/house/mortgage-payoff/MortgageGoalView';
import MortgageGoalSummaryView from '../pages/goals/house/mortgage-payoff/MortgageGoalSummaryView';
import DebtSelectionEditView from '../pages/goals/debt/DebtSelectionEditView';
import EmergencyGoalEditView from '../pages/goals/emergency/EmergencyGoalEditView';
import PresentsGivingGoalEditView from '../pages/goals/giving/presents/PresentsGivingGoalEditView';
import CashGivingGoalBasicEditView from '../pages/goals/giving/cash/CashGivingGoalBasicEditView';
import CashGivingGoalDetailsEditView from '../pages/goals/giving/cash/CashGivingGoalDetailsEditView';
import VolunteeringGoalsListView from '../pages/goals/giving/volunteering/VolunteeringGoals';
import CollegeGoalBasicEditView from '../pages/goals/college/CollegeGoalBasicEditView';
import CollegeGoalDetailsEditView from '../pages/goals/college/CollegeGoalDetailsEditView';
import RetirementGoalEditView from '../pages/goals/retirement/RetirementGoalEditView';
import HolisticFinancialAdviceLayout from '../pages/holistic-financial-advice/HolisticFinancialAdviceLayout';
import HolisticAdviceView from '../pages/holistic-financial-advice/home/HolisticAdviceView';
import SpendingSummaryOverview from '../pages/spending-summary/SpendingSummaryOverview';
import SpendingSummaryLayout from '../pages/spending-summary/SpendingSummaryLayout';
import SpendingSummaryTransacionsView from '../pages/spending-summary/SpendingSummaryTransacionsView';
import { AccountDetailsRoutes } from './AccountDetailsRoutes';
import { BudgetDetailsRoutes } from './BudgetDetailsRoutes';
import { HolisticFinancialAdviceRoutes } from './HolisticFinancialAdviceRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { LoaderFunction } from 'react-router-dom';
import {
  loadAdviceOverview,
  loadAdviceResults,
  savedGuidesLoader,
} from './loaders/HolisticAdviceLoaders';
import { wireUpLoaders } from './utils';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { netWorthLoader } from './loaders/NetWorthLoader';
import { profileLoader } from './loaders/PersonalDetailsLoader';
import { incomeReadyLoader } from './loaders/IncomeReadyLoader';
import {
  accountDetailsLoader,
  accountsLoader,
  carsLoader,
  creditCardsLoader,
  debtLoader,
  investmentAccountsLoader,
  linkAccountLoader,
  realEstateLoader,
} from './loaders/AccountDetailsLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { commonRoutes } from './CommonRoutes';
import {
  cashGivingGoalLoader,
  charityCategoriesLoader,
  goalsLoader,
  payoffDebtGoalLoader,
} from './loaders/GoalsLoader';
import { plaidLoader } from './loaders/PlaidLoader';
import { budgetLoader } from './loaders/BudgetLoader';
import { incomeLoader } from './loaders/IncomeLoader';
import UniversityLayout from '../pages/university/UniversityLayout';
import UniversityOverview from '../pages/university/UniversityOverview';
import { universityLoader } from './loaders/UniversityLoader';
import UniversityLessonView from '../pages/university/UniversityLessonView';
import { twoFactorLoader } from './loaders/TwoFactorLoader';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';
import LifestyleView from '../pages/lifestyle/LifestyleView';
import { lifestyleLoader } from './loaders/LifestyleLoader';
import ToolsLayout from '../pages/tools/ToolsLayout';

const adviceRoutes: RouteObject[] = [
  {
    path: '/',
    element: <HolisticAdviceView />,
  },
  {
    path: 'account-details',
    element: <AccountDetailsLayout headerVariant='addAccount' />,
    children: AccountDetailsRoutes,
  },
  {
    path: 'budget-details',
    element: <BudgetDetailsLayout />,
    children: BudgetDetailsRoutes,
  },
  {
    path: 'holistic-advice',
    element: <HolisticFinancialAdviceLayout />,
    children: HolisticFinancialAdviceRoutes,
  },
  {
    path: 'goals',
    element: <GoalsLayout />,
    children: [
      {
        index: true,
        element: <GoalListScreen />,
      },
      {
        path: 'select',
        element: <SelectGoalType />,
      },
      {
        path: 'car/:id?',
        element: <CarGoalEditView />,
      },
      {
        path: 'giving',
        children: [
          { index: true, element: <SelectGivingGoalTypeView /> },
          {
            path: 'assets',
            element: <GivingAssets />,
          },
          {
            path: 'cash',
            children: [
              {
                path: 'basics',
                element: <CashGivingGoalBasicEditView />,
              },
              {
                path: 'details',
                element: <CashGivingGoalDetailsEditView />,
              },
            ],
          },
          {
            path: 'college',
            element: <GivingCollege />,
          },
          {
            path: 'presents',
            element: <PresentsGivingGoalEditView />,
          },
          {
            path: 'on-behalf',
            element: <GivingOnBehalf />,
          },
          {
            path: 'tax-benefits',
            element: <GivingForTaxBenefits />,
          },
          {
            path: 'volunteering',
            element: <VolunteeringGoalsListView />,
          },
        ],
      },
      {
        path: 'house',
        children: [
          { index: true, element: <SelectHouseGoalTypeView /> },
          { path: 'buy/:id?', element: <HouseGoalEditView /> },
          {
            path: 'pay-off-mortgage',
            children: [
              { index: true, element: <MortgageGoalView /> },
              { path: ':id', element: <MortgageGoalSummaryView /> },
            ],
          },
        ],
      },
      {
        path: 'debt',
        element: <DebtSelectionEditView />,
      },
      {
        path: 'legacy',
        element: <LegacyGiving />,
      },
      {
        path: 'other/:id?',
        element: <OtherGoalEditView />,
      },
      {
        path: 'emergency/:id?',
        element: <EmergencyGoalEditView />,
      },
      {
        path: 'retirement/:id?',
        element: <RetirementGoalEditView />,
      },
      {
        path: 'vacation/:id?',
        element: <VacationGoalEditView />,
      },
      { path: 'college/basics/:id?', element: <CollegeGoalBasicEditView /> },
      { path: 'college/details/:id?', element: <CollegeGoalDetailsEditView /> },
    ],
  },
  {
    path: 'tools',
    element: <ToolsLayout />,
  },
  {
    path: 'spending-summary',
    element: <SpendingSummaryLayout />,
    children: [
      { index: true, element: <SpendingSummaryOverview /> },
      {
        path: 'transactions',
        element: <SpendingSummaryTransacionsView />, // why is this getting triggered when we are unauthenticated?
      },
    ],
  },
  {
    path: 'university',
    element: <UniversityLayout />,
    children: [
      { index: true, element: <UniversityOverview /> },
      {
        path: ':title',
        element: <UniversityLessonView />,
      },
    ],
  },
  {
    path: 'lifestyle',
    element: <LifestyleView />,
  },
  ...commonRoutes,
];

export function createAdviceRoutes(container: IServiceContainer) {
  const loaders = new Map<string, LoaderFunction>();
  // load advice on homescreen
  loaders.set('/', async () => {
    await Promise.all([
      appReadyLoader(container),
      netWorthLoader(container),
      loadAdviceOverview(container),
      plaidLoader(container),
      universityLoader(container),
      realEstateLoader(container),
      lifestyleLoader(container),
    ]);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });
  loaders.set('profile', () =>
    Promise.all([
      profileLoader(container),
      savedGuidesLoader(container),
      incomeReadyLoader(container),
      incomeLoader(container),
    ])
  );
  // might need to add a plaid redirect loader
  loaders.set('account-details', () =>
    Promise.all([
      incomeReadyLoader(container),
      accountDetailsLoader(container),
      incomeLoader(container),
      netWorthLoader(container),
      accountsLoader(container),
      plaidLoader(container),
    ])
  );
  loaders.set('account-details/link-account', ({ request }) =>
    plaidLoader(container).then(() => linkAccountLoader(request, container))
  );
  loaders.set('budget-details', () =>
    Promise.all([
      incomeReadyLoader(container),
      budgetLoader(container),
      incomeLoader(container),
      realEstateLoader(container),
      investmentAccountsLoader(container),
      carsLoader(container),
      creditCardsLoader(container),
      debtLoader(container),
    ])
  );
  loaders.set('holistic-advice', () => loadAdviceOverview(container));
  loaders.set('holistic-advice/investments', () => investmentAccountsLoader(container));
  loaders.set('holistic-advice/plan', () => loadAdviceResults(container));

  loaders.set('goals', () => Promise.all([goalsLoader(container), accountsLoader(container)]));
  loaders.set('goals/giving/volunteering', () => charityCategoriesLoader(container));
  loaders.set('goals/giving/cash/basics', () => cashGivingGoalLoader(container));
  loaders.set('goals/debt', () => payoffDebtGoalLoader(container));
  loaders.set('spending-summary', () => plaidLoader(container));
  loaders.set('university', () => universityLoader(container));
  loaders.set('settings/update-password', () => authTokenCookieLoader(container));
  loaders.set('settings/two-factor-authentication', () => twoFactorLoader(container));
  loaders.set('lifestyle', () => lifestyleLoader(container));

  return wireUpLoaders(adviceRoutes, loaders);
}
