import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/buttons/Button';
import { campingBanner } from '../../../assets/png';
import { useNavigate } from 'react-router-dom';
import { useNeedsOnboarding } from '../../../hooks';
import { useMemo } from 'react';

const WalkThroughAdviceCard: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onboarding = useNeedsOnboarding();

  const viewAdvice = useMemo(() => {
    if (onboarding) {
      return () => navigate('/holistic-advice/budget');
    }

    // skip start screen if not in onboarding
    return () => navigate('/holistic-advice/budget/monthly');
  }, [onboarding]);

  return (
    <Grid container className='walk-through-advice-card'>
      <Grid item my='30px' ml='40px'>
        <Typography variant='p30Bold' color='secondary' mb='20px'>
          {t('LookedAtYourAdviceLately?')}
        </Typography>
        <Button color='primary' onClick={viewAdvice}>
          {t('WalkMeThrough')}
        </Button>
      </Grid>
      <img src={campingBanner} />
    </Grid>
  );
};

export default WalkThroughAdviceCard;
