import { Domain } from '@3nickels/data-modules';
import { useSubject } from '@aesop-fables/scrinium';
import { PricebookBundle, PricebookProductTypeEnum } from '../../models/PricebookData';
import { PricebookServices } from '../../data/pricebook';

export function useAvailableProducts() {
  const availableProductsData = useSubject<PricebookBundle[]>(
    PricebookServices.AvailablePricebooks
  );

  const threeNickelsProducts = availableProductsData?.filter(
    (p) => p.pricebook.productType !== PricebookProductTypeEnum.GUIDED_CHOICE
  );

  const multipleProducts = (threeNickelsProducts?.length ?? 0) > 1;

  const orgOnlyOffersFreePricebook =
    !multipleProducts && threeNickelsProducts?.[0].prices[0].price === 0;

  const freePricebooks = threeNickelsProducts?.filter((p) => p.prices[0].price === 0);
  const orgDoesNotOfferFreePricebook = freePricebooks?.length === 0;

  const orgPaysPricebook = threeNickelsProducts?.find(
    (p) => p.pricebook.paidBy === Domain.PaidByEnum.ORG
  );

  return {
    availableProductsData,
    threeNickelsProducts,
    multipleProducts,
    orgOnlyOffersFreePricebook,
    orgDoesNotOfferFreePricebook,
    orgPaysPricebook,
  };
}
