import { Card, CardContent, Typography } from '@mui/material';
import { Row, Spacer } from '../components';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';

interface PasswordReqProps {
  hasMinChar: boolean;
  hasANumber: boolean;
  hasUpperCase: boolean;
  hasSpecialChar: boolean;
}

export const PasswordRequirements: React.FC<PasswordReqProps> = ({
  hasMinChar,
  hasANumber,
  hasUpperCase,
  hasSpecialChar,
}) => {
  const errorText = () => ({ color: 'error.main' });
  const successText = () => ({ color: 'primary' });
  const { isMobile, isTablet } = useDeviceType();

  return (
    <Card variant='innerCard'>
      <CardContent className='pad20'>
        {isMobile || isTablet ? (
          <>
            <Row>
              {hasMinChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must be between 8-32 characters.
              </Typography>
            </Row>
            <Spacer height='xxs' />
            <Row>
              {hasANumber ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must include one number.
              </Typography>
            </Row>
            <Spacer height='xxs' />
            <Row>
              {hasUpperCase ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must include one upper case letter.
              </Typography>
            </Row>
            <Spacer height='xxs' />
            <Row>
              {hasSpecialChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must include one special character.
              </Typography>
            </Row>
          </>
        ) : (
          <Row>
            <Row>
              {hasMinChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must be between 8-32 characters.
              </Typography>
            </Row>
            <Spacer width='xxs' />
            <Row>
              {hasANumber ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must include one number.
              </Typography>
            </Row>
            <Spacer width='xxs' />
            <Row>
              {hasUpperCase ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must include one upper case letter.
              </Typography>
            </Row>
            <Spacer width='xxs' />
            <Row>
              {hasSpecialChar ? <Svgs.IconCheckmark /> : <Svgs.DisplayExtraSmallCustomX />}
              <Spacer width='xxs' />
              <Typography variant='p14' color={hasMinChar ? successText() : errorText()}>
                Must include one special character.
              </Typography>
            </Row>
          </Row>
        )}
      </CardContent>
    </Card>
  );
};
