import { Grid, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { Row, Spacer } from '../../../components';
import { Svgs } from '../../../assets/svg';
import { Api, Domain } from '@3nickels/data-modules';
import { passport } from '../../../assets/png';

export interface GoalIconProps {
  goalType: Domain.GoalTypeEnum;
}

export const GoalIcon: React.FC<GoalIconProps> = ({ goalType }) => {
  switch (goalType) {
    case Domain.GoalTypeEnum.Car:
      return <Svgs.Car />;
    case Domain.GoalTypeEnum.Cash_Giving:
      return <Svgs.Cash />;
    case Domain.GoalTypeEnum.Non_Mortgage_Debt:
      return <Svgs.Debt />;
    case Domain.GoalTypeEnum.House:
    case Domain.GoalTypeEnum.Mortgage:
      return <Svgs.House />;
    case Domain.GoalTypeEnum['Emergency Fund']:
      return <Svgs.Umbrella />;
    case Domain.GoalTypeEnum.Gift:
      return <Svgs.Presents />;
    case Domain.GoalTypeEnum.Volunteer:
      return <Svgs.GivingHeart />;
    case Domain.GoalTypeEnum.Vacation:
      return <img src={passport} alt='passport' style={{ width: '100px', height: '100px' }} />;
    case Domain.GoalTypeEnum.College:
      return <Svgs.CollegeGradCap />;
    case Domain.GoalTypeEnum.Retirement:
      return <Svgs.BeachChair />;
    default:
      return <Svgs.ArtPiggyBank width='100' height='100' />;
  }
};

export interface GoalNameProps {
  goal: Api.GoalRest;
}

export const GoalName: React.FC<GoalNameProps> = ({ goal }) => {
  const name =
    goal.goalName && goal.goalType !== Domain.GoalTypeEnum.Non_Mortgage_Debt
      ? goal.goalName
      : goal.goalType === Domain.GoalTypeEnum.Non_Mortgage_Debt
      ? 'Pay Off High-Cost Debt'
      : '';
  return (
    <Typography variant='h1' color='primary'>
      {name}
    </Typography>
  );
};

export interface GoalStatusProps {
  goalStatus?: Domain.GoalStatusEnum;
}

export const StatusLabel: React.FC<GoalStatusProps & TypographyProps> = ({
  goalStatus,
  ...rest
}) => {
  switch (goalStatus) {
    case Domain.GoalStatusEnum.OnTarget:
      return (
        <Typography variant='p14Bold' color='primary' {...rest}>
          On-Target
        </Typography>
      );
    case Domain.GoalStatusEnum.Complete:
      return (
        <Typography variant='p14Bold' color='secondary' {...rest}>
          Complete
        </Typography>
      );
    default:
      return (
        <Typography variant='p14Bold' color='error.main' {...rest}>
          Off-Target
        </Typography>
      );
  }
};

export const StatusIcon: React.FC<GoalStatusProps> = ({ goalStatus }) => {
  switch (goalStatus) {
    case Domain.GoalStatusEnum.OnTarget:
      return <Svgs.DisplaySmallCustomOnTarget />;
    case Domain.GoalStatusEnum.Complete:
      return <Svgs.IconCheckmark />;
    default:
      return <Svgs.DisplaySmallCustomWarning />;
  }
};

export const StatusMessage: React.FC<GoalStatusProps> = ({ goalStatus }) => {
  switch (goalStatus) {
    case Domain.GoalStatusEnum.OnTarget:
      return (
        <Typography variant='p16' color='primary'>
          Nice! You’re on-target to reach this goal.
        </Typography>
      );
    case Domain.GoalStatusEnum.Complete:
      return (
        <Typography variant='p16' color='secondary'>
          Congratulations! You’ve reached this goal.
        </Typography>
      );
    case Domain.GoalStatusEnum.OffTarget:
      return (
        <Typography variant='p16' color='error.main'>
          Unfortunately, you’re not able to fund this goal right now.
        </Typography>
      );
    default:
      return (
        <Typography variant='p16' color='error.main'>
          Unfortunately, you’re not able to fully fund this goal right now, but we’ll help you get
          as far as you can.
        </Typography>
      );
  }
};

export interface GoalHeaderProps {
  goal: Domain.GoalSummary;
  index: number;
  totalGoals: number;
}

const GoalHeader: React.FC<GoalHeaderProps> = ({ goal, index, totalGoals }) => {
  const { goalPlan, advisedGoalPlan, goalStatus } = goal;

  return (
    <Grid container display='inline-flex' justifyContent='space-between'>
      <Grid display='inline-flex' sm={9}>
        <GoalIcon goalType={advisedGoalPlan.goalType ?? Domain.GoalTypeEnum.Car} />
        <Grid item ml='40px'>
          <GoalName goal={advisedGoalPlan ?? goalPlan} />
          <Spacer height='xxs' />
          <Row>
            <StatusLabel goalStatus={goalStatus} />
            <Spacer width='xxxs' />
            <StatusIcon goalStatus={goalStatus} />
          </Row>
          <Spacer height='xxs' />
          <StatusMessage goalStatus={goalStatus} />
        </Grid>
      </Grid>
      <Grid display='flex-end'>
        <Typography variant='p16' color='secondary'>
          <strong>Priority {index + 1}</strong>
          &nbsp;(out of {totalGoals} goal{totalGoals > 1 && 's'})
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GoalHeader;
