import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { PricebookBundle } from '../../models/PricebookData';
import { AxiosServices } from '@aesop-fables/containr-axios';
import { inject } from '@aesop-fables/containr';

export class PricebookApi {
  constructor(@inject(AxiosServices.AxiosInstance) private readonly axios: AxiosInstance) {}

  selectPricebook(id: number): Promise<AxiosResponse<void>> {
    return this.axios.post(`pricebook-selection?pricebookId=${id}`);
  }

  getAvailable(): Promise<AxiosResponse<PricebookBundle[]>> {
    return this.axios.get('available-products');
  }
}
