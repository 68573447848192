import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PalmTreeSvg: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h30v30H0z" />
          <path
            d="M26.798 9.993 25.146 8.34c-1.846-1.845-4.061-1.933-5.168-1.827l2.302-2.302a.734.734 0 0 0 .159-.773.708.708 0 0 0-.65-.439h-3.394c-1.459 0-2.69.755-3.392 1.792A4.099 4.099 0 0 0 11.61 3H8.217a.708.708 0 0 0-.492 1.212l2.303 2.302C8.903 6.39 6.705 6.496 4.86 8.34L3.207 9.993a.734.734 0 0 0-.158.773c.105.263.37.439.65.439h4.817a4.042 4.042 0 0 0-1.002 2.688v3.391c0 .281.176.545.44.65a.69.69 0 0 0 .773-.158l5.291-5.288-1.88 13.106H9.728a.705.705 0 0 0-.703.703c0 .387.316.703.703.703h10.53a.705.705 0 0 0 .703-.703.705.705 0 0 0-.703-.703H17.85l-1.88-13.106 5.29 5.288a.75.75 0 0 0 .774.158.707.707 0 0 0 .44-.65v-3.39a4.076 4.076 0 0 0-1.002-2.689h4.816a.708.708 0 0 0 .65-.44c.124-.263.054-.561-.14-.772z"
            fill="#1E2969"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
