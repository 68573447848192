import ContentView from '../../layout/Content';
import { useContentView } from '../../layout/ContentViewContext';
import React from 'react';
import { FreeWith3nuView } from './FreeWith3nu/Free3nuView';
import { FreeDefaultView } from './FreeDefaultView/FreeDefaultView';
import { useAvailableProducts } from '../../../hooks/pricebook/useAvailableProducts';
import { BetaMessage } from '../../../components/header/BetaMessage';
import { Hooks } from '@3nickels/data-modules';

const FreeView: React.FC = () => {
  const { sideNav } = useContentView();
  const { threeNickelsProducts } = useAvailableProducts();
  const universityEnabled = Hooks.useUniversityEnabled();
  const universityAvailable = threeNickelsProducts?.find(
    (p) => p.pricebook.productType === 'NICKELSU'
  );

  return (
    <ContentView sideNavMenuItems={sideNav('Home')} leftChildren={<BetaMessage />}>
      {universityAvailable && !universityEnabled ? <FreeWith3nuView /> : <FreeDefaultView />}
    </ContentView>
  );
};

export default FreeView;
