import { Typography, Card, FormGroup, Button } from '@mui/material';
import Cookies from 'js-cookie';
import { Form } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';
import { mobileMargin } from '../App';
import { logo3NSmall } from '../assets/png';
import { Column, DatePicker, Row, Spacer, VerticalFormInput } from '../components';
import { useDeviceType } from '../hooks/useDeviceType';
import { ProfileData } from '../models/ProfileData';
import { onValidateBirthdate } from './CreateAccount';
import { useMutation } from '@aesop-fables/scrinium';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { API_URL } from '../configuration/Environment';

const PersonalInfo = () => {
  const navigate = useNavigate();
  const organizationData = Hooks.useOrganizationData();
  const { person = {} } = Hooks.useCombinedSelfData();
  const updatePersonData = useMutation(new Data.People.Mutations.UpdatePersonData());
  const { isMobile, isTablet } = useDeviceType();

  const onSubmit = async (values: ProfileData) => {
    await updatePersonData.action(values);

    navigate('/');
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Column style={{ margin: isTablet || isMobile ? mobileMargin : '15px 100px' }}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
              {organizationData ? (
                organizationData.logoUri ? (
                  <img
                    src={`${API_URL}organization/logo`}
                    alt={organizationData?.name}
                    style={{ height: '60%' }}
                  />
                ) : (
                  <Typography variant='p25Bold' color='secondary'>
                    {organizationData?.name}
                  </Typography>
                )
              ) : (
                <Card variant='ghost' />
              )}
              <Link style={{ height: '70%' }} to='/'>
                <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
              </Link>
            </Row>
            <Typography variant='p30Bold' color='primary'>
              Personal Info
            </Typography>
            <Spacer height='xs' />
            <Typography variant='h2' color='secondary'>
              Just need a little more info to create your 3Nickels account!
            </Typography>
            <Spacer height='xs' />
            <FormGroup
              style={{
                alignSelf: 'center',
                width: isMobile || isTablet ? '90%' : '70%',
                maxWidth: isMobile ? '' : '650px',
              }}>
              {!(person?.firstName && person.lastName) &&
                (isMobile || isTablet ? (
                  <>
                    <VerticalFormInput<Domain.SelfData>
                      fieldName='firstName'
                      label='First Name'
                      placeholder='First Name'
                      initialValue={person?.firstName}
                      inputStyle={{ width: '100%' }}
                      maxLength={32}
                      required
                      autoFocus
                    />
                    <Spacer height='sm' />
                    <VerticalFormInput<ProfileData>
                      fieldName='lastName'
                      label='Last Name'
                      placeholder='Last Name'
                      initialValue={person?.lastName}
                      inputStyle={{ width: '100%' }}
                      maxLength={32}
                      required
                    />
                    <Spacer height='sm' />
                  </>
                ) : (
                  <>
                    <Row style={{ width: '100%' }}>
                      <Column style={{ width: '100%' }}>
                        <VerticalFormInput<Domain.SelfData>
                          fieldName='firstName'
                          label='First Name'
                          placeholder='First Name'
                          initialValue={person?.firstName}
                          inputStyle={{ width: '100%' }}
                          maxLength={32}
                          required
                          autoFocus
                        />
                      </Column>
                      <Spacer height='sm' />
                      <Column style={{ marginLeft: '15px', width: '100%' }}>
                        <VerticalFormInput<Domain.SelfData>
                          fieldName='lastName'
                          label='Last Name'
                          placeholder='Last Name'
                          initialValue={person?.lastName}
                          inputStyle={{ width: '100%' }}
                          maxLength={32}
                          required
                        />
                      </Column>
                    </Row>
                    <Spacer height='sm' />
                  </>
                ))}
              {!person?.birthDate && (
                <DatePicker<Domain.SelfData>
                  fieldName='birthDate'
                  label='Date of Birth'
                  placeholder='MM/DD/YYYY'
                  helpText={<HelpText />}
                  validate={onValidateBirthdate}
                  required
                />
              )}
            </FormGroup>
            <Spacer height='sm' />
            {isMobile || isTablet ? (
              <Column style={{ justifyContent: 'space-between' }}>
                <Button onClick={handleSubmit} color='secondary'>
                  Save & Continue
                </Button>
                <Spacer height='xs' />
                <form method='post' action={API_URL.replace('/api', '/') + 'logout'}>
                  <Button
                    type='submit'
                    variant='outlined'
                    color='secondary'
                    style={{ width: '100%' }}>
                    Cancel &amp; Log Out
                  </Button>
                  <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                </form>
              </Column>
            ) : (
              <Row style={{ justifyContent: 'space-between' }}>
                <form method='post' action={API_URL.replace('/api', '/') + 'logout'}>
                  <Button type='submit' variant='outlined' color='secondary'>
                    Cancel &amp; Log Out
                  </Button>
                  <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                </form>
                <Button onClick={handleSubmit} color='secondary'>
                  Save & Continue
                </Button>
              </Row>
            )}
          </Column>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

const HelpText = () => {
  return (
    <Column style={{ padding: '20px' }}>
      <Typography variant='p16Bold' color='secondary'>
        Date of Birth
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>Used to optimize savings and withdrawals</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>Certain ages, like 59 1/2 and 70 1/2, are important for tax reasons.</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        Sounds bizarre? Well, thank our complicated tax laws.
      </Typography>
      <Spacer height='xxl' />
    </Column>
  );
};

export default PersonalInfo;
