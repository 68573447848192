/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { exploreMountain } from '../../../assets/png';
import { useNavigate } from 'react-router-dom';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import { WizardFooter } from '../../../components/form/WizardFooter';
import HolisticPlanStepIcon from '../../../components/HolisticPlanStepIcon';
import { Data, Hooks } from '@3nickels/data-modules';
import { useBackHandler } from '../progress-indicator/useBackHandler';

const PlanOutlineView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const holisticAdvice = Hooks.useHolisticAdvice();
  const overview = Hooks.useHolisticAdviceOverview();
  const plan = Hooks.HolisticAdvicePlan.useHolisticAdvicePlan();
  const commands = Hooks.useCommands();
  const [downloaded, setDownloaded] = useState(false);

  const backHandler = useBackHandler(overview, 'plan');

  useEffect(() => {
    if (holisticAdvice?.caseId) {
      commands.execute(Data.HolisticAdvice.AcceptHolisticAdvice, holisticAdvice.caseId);
    }
  }, [commands, holisticAdvice?.caseId]);

  useEffect(() => {
    if (holisticAdvice?.caseId && !downloaded) {
      setDownloaded(true);
      commands.execute(Data.HolisticAdvice.GenerateHolisticGuide, holisticAdvice.caseId);
    }
  }, [holisticAdvice?.caseId]);

  const onDone = () => {
    navigate('/');
  };

  return (
    <Box>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t('WhatNow?')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('HeresThePlan')}
      </Typography>
      <Grid container flexWrap='nowrap' justifyContent='space-between'>
        <Grid item>
          {plan.map((goal) => (
            <Grid>
              <Typography color='primary' variant='p18Bold' mt='40px'>
                {goal.label}
              </Typography>
              {goal.steps.map((step) => (
                <Typography className='subtitle' color='secondary' variant='p16' columnGap='20px'>
                  <HolisticPlanStepIcon
                    type={step.type}
                    style={{ minWidth: '30px', minHeight: '30px' }}
                  />
                  {step.content}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid sm={5}>
          <Grid component='img' src={exploreMountain} style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
      <WizardFooter nextLabel='GoToAdviceHome' onDone={onDone} onBack={backHandler} />
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'GoToAdviceHome',
  headerVariant: 'download',
  showProgress: true,
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(PlanOutlineView, meta);
