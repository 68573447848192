import { Domain, Hooks } from '@3nickels/data-modules';
import { Grid, Typography, Box } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { GroupEdit } from '../../../components/GroupEdit';
import { formatWholeDollars } from '../../../helpers/utilityFunctions';
import { Modal, Spacer } from '../../../components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MutatorState } from '@aesop-fables/scrinium';
import { useLoading } from '../../../hooks/useLoading';
import { useTranslation } from 'react-i18next';
import { Home2Svg as HomeSvg } from '../../../components/images/HomeSvg';

interface HomeViewProps {
  homes: Domain.HousingData[];
  deleteHome: MutatorState<number>;
}

export const HomeView: React.FC<HomeViewProps> = ({ homes, deleteHome }) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { wizard } = Hooks.useHomeWizard();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.HousingData>();

  const handleEdit = (x: Domain.HousingData) => {
    wizard.start({ id: x.id, type: Domain.RealEstateTypeEnum.PERSONAL });
    navigate(`/account-details/home/summary/${x.id}`);
  };

  const handleDeleteHome = (home: Domain.HousingData) => {
    if (home.id) {
      setLoading(true);
      deleteHome.action(home.id);
    }
  };

  if (homes.length === 0) return null;

  return (
    <>
      <Grid display='none'>
        <Modal
          title='Remove Home?'
          primaryButtonText='No, Keep Home'
          secondaryButtonText='Yes, Remove Home'
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={() => {
            if (selectedAccount) {
              handleDeleteHome(selectedAccount);
            }
          }}>
          <Typography variant='p16' color='secondary'>
            This will remove all of the information for this account. Are you sure you want to do
            that?
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p16Bold' color='secondary'>
            {selectedAccount?.name}
          </Typography>
          <Typography variant='p16Bold' color='secondary'>
            {t('Home')}
          </Typography>
        </Modal>
      </Grid>
      <Grid item xs={12} className='account-view'>
        <Typography
          className='account-type-header'
          display='flex'
          alignItems='center'
          color='primary'
          variant='p18Bold'>
          {HomeSvg({ fontSize: 'large' })}
          {t('Homes', { number: homes.length })}
        </Typography>
        <Box>
          <GroupEdit<Domain.HousingData>
            className='account-list'
            items={homes}
            keyFn={(x) => `${x.id ?? 0}`}
            mainContentFn={(x) => (
              <Grid container>
                <Grid marginRight='10px'>
                  <Typography
                    className='hover-clickable'
                    color='secondary'
                    fontWeight='bold'
                    onClick={() => handleEdit(x)}>
                    {x.name}
                  </Typography>
                  <Typography marginTop='5px' color='secondary' variant='p12'>
                    {x.debt?.linkedInstitution?.institutionName ?? 'Manually Added'}
                  </Typography>
                </Grid>
                <Grid className='account-view-status-icon' height='20px'>
                  {x.isLinked && (
                    <Grid display='flex' alignItems='center'>
                      <Svgs.LinkPaperClip />
                      {x.linkedInstitution?.requiresLogin && (
                        <Typography color='accent' component='span' variant='p12'>
                          Login Expired
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            summaryFn={(x) => {
              const currentBalance = x.debt?.amountOwed
                ? (x.estimatedValue ?? 0) - x.debt.amountOwed
                : x.estimatedValue ?? 0;
              return (
                <Grid>
                  <Typography
                    color={
                      x.linkedInstitution?.requiresLogin
                        ? 'accent'
                        : currentBalance >= 0
                        ? 'secondary'
                        : 'error'
                    }>
                    {formatWholeDollars(currentBalance)}
                  </Typography>
                </Grid>
              );
            }}
            needsAttention={(x) => x.linkedInstitution?.requiresLogin}
            stackEndContent
            onDelete={(x) => {
              setSelectedAccount(x);
              setModalOpen(true);
            }}
            onEdit={(x) => {
              handleEdit(x);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};
