/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import {
  StringOrNumberOrCallback,
  VictoryLabel,
  VictoryLabelStyleObject,
  VictoryPie,
  VictoryTooltip,
} from 'victory';

interface ChartPieVariableRadiusProps {
  style?: CSSProperties;
  data: ChartPieVariableRadiusData[];
  innerRadius?: number;
  innerLabelStyle?: VictoryLabelStyleObject | VictoryLabelStyleObject[] | undefined;
  innerLabelText?: string[] | StringOrNumberOrCallback | undefined;
  tooltipText?:
    | string[]
    | number[]
    | ((data: any) => string | number | string[] | number[] | null)
    | undefined;
  onClick?: (event: React.SyntheticEvent<any>, props?: any) => void;
  showSkeleton?: boolean;
}

export interface ChartPieVariableRadiusData {
  x: number | string;
  y: number;
  color: string;
}

export const ChartPieVariableRadius: React.FunctionComponent<ChartPieVariableRadiusProps> = (
  props
) => {
  const {
    data,
    style,
    innerRadius,
    innerLabelStyle,
    innerLabelText,
    tooltipText,
    showSkeleton,
    onClick,
  } = props;
  const [viewSize] = useState({ width: 300, height: 300 });

  return (
    <svg
      viewBox={`0 0 ${viewSize.width} ${viewSize.height}`}
      style={{
        width: '90%',
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}>
      {showSkeleton && (
        <foreignObject x={15} y={15} width='90%' height='90%'>
          <Skeleton variant='circular' width='100%' height='100%' />
        </foreignObject>
      )}
      {!showSkeleton && (
        <VictoryPie
          standalone={false}
          data={data}
          events={[
            {
              target: 'data',
              eventHandlers: {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: onClick ?? (() => {}),
              },
            },
          ]}
          innerRadius={innerRadius}
          radius={({ datum }) => 80 + datum.y.toString().substring(0, 2) * 0.7}
          animate={{ easing: 'bounce', duration: 1000 }}
          width={viewSize.width}
          height={viewSize.height}
          x='x'
          y='y'
          padding={{
            top: 30,
            bottom: 30,
          }}
          style={{
            data: {
              fill: ({ datum }) => datum.color,
              cursor: onClick ? 'pointer' : undefined,
            },
            labels: {
              fill: (props: any) => props.datum.color,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
            },
          }}
          labels={tooltipText}
          labelComponent={
            <VictoryTooltip
              flyoutStyle={{
                fill: '#fff',
                stroke: 'rgba(30, 41, 105, 0.2)',
                padding: '10.5px 8.5px 10.5px 21px',
                margin: '43px 36.5px 250px 164px',
              }}
              flyoutPadding={10}
              pointerLength={5}
              constrainToVisibleArea
              // labelComponent={<VictoryLabel textComponent={<Typography />} />}
            />
          }
        />
      )}
      {innerLabelText && (
        <VictoryLabel
          textAnchor='middle'
          verticalAnchor='middle'
          x={viewSize.width / 2}
          y={viewSize.height / 2}
          style={innerLabelStyle}
          text={innerLabelText}
        />
      )}
    </svg>
  );
};
