import { IServiceContainer } from '@aesop-fables/containr';
import { ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { json, redirect } from 'react-router-dom';
import { waitFor } from '../utils';
import { AccountDetailsReadySubject } from '../../subjects/AppReadySubject';
import { firstValueFrom } from 'rxjs';
import { Data } from '@3nickels/data-modules';

export async function accountDetailsLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const accountDetailsReady$ = resolver.resolveSubject(AccountDetailsReadySubject);
  await waitFor(accountDetailsReady$);

  return json('account details ready');
}

export async function linkAccountLoader(request: Request, container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const selfData$ = resolver.resolveSubject(Data.People.Projections.SelfDataSubject);
  const { includeSpouse } = await firstValueFrom(selfData$);

  const redirectTo =
    includeSpouse === true
      ? '/account-details/link-account/choose-owner'
      : '/account-details/link-account/account-type';

  if (request.url.endsWith('link-account')) {
    return redirect(redirectTo);
  }

  return json('link account - no redirect');
}

export async function accountsLoader(container: IServiceContainer) {
  const otherAssetDataListener = container.resolve(Data.OtherAssets.OtherAssetDataListener);
  const cashAccountListener = container.resolve(Data.CashAccounts.CashAccountDataListener);
  const collegeSavingsPlanListener = container.resolve(
    Data.CollegeSavingsPlans.CollegeSavingsPlanDataListener
  );
  const carsListener = container.resolve(Data.Cars.CarDataListener);
  const realEstateListener = container.resolve(Data.RealEstate.HousingEntityDataListener);
  const debtListener = container.resolve(Data.Debt.DebtDataListener);
  const creditCardListener = container.resolve(Data.CreditCards.CreditCardDataListener);
  const businessListener = container.resolve(Data.Business.BusinessDataListener);
  const pensionsListener = container.resolve(Data.Pensions.PensionDataListener);
  const investmentAccountListener = container.resolve(
    Data.InvestmentAccounts.InvestmentAccountDataListener
  );
  const editEligibilityListener = container.resolve(
    Data.InvestmentAccounts.EditEligibilityDataListener
  );
  const spousseAccountEligibilityListener = container.resolve(
    Data.InvestmentAccounts.SpouseAccountEligibilityDataListener
  );
  const spousseAccountEditEligibilityListener = container.resolve(
    Data.InvestmentAccounts.SpouseEditEligibilityDataListener
  );

  otherAssetDataListener.start();
  cashAccountListener.start();
  collegeSavingsPlanListener.start();
  carsListener.start();
  realEstateListener.start();
  debtListener.start();
  creditCardListener.start();
  businessListener.start();
  investmentAccountListener.start();
  pensionsListener.start();
  editEligibilityListener.start();
  spousseAccountEligibilityListener.start();
  spousseAccountEditEligibilityListener.start();
}

export async function otherAssetsLoader(container: IServiceContainer) {
  const otherAssetDataListener = container.resolve(Data.OtherAssets.OtherAssetDataListener);

  otherAssetDataListener.start();
}

export async function cashAccountsLoader(container: IServiceContainer) {
  const cashAccountListener = container.resolve(Data.CashAccounts.CashAccountDataListener);

  cashAccountListener.start();
}

export async function collegeSavingsPlanLoader(container: IServiceContainer) {
  const collegeSavingsPlanListener = container.resolve(
    Data.CollegeSavingsPlans.CollegeSavingsPlanDataListener
  );

  collegeSavingsPlanListener.start();
}

export async function carsLoader(container: IServiceContainer) {
  const carsListener = container.resolve(Data.Cars.CarDataListener);

  carsListener.start();
}

export async function realEstateLoader(container: IServiceContainer) {
  const realEstateListener = container.resolve(Data.RealEstate.HousingEntityDataListener);

  realEstateListener.start();
}

export async function debtLoader(container: IServiceContainer) {
  const debtListener = container.resolve(Data.Debt.DebtDataListener);

  debtListener.start();
}

export async function creditCardsLoader(container: IServiceContainer) {
  const creditCardListener = container.resolve(Data.CreditCards.CreditCardDataListener);

  creditCardListener.start();
}

export async function businessLoader(container: IServiceContainer) {
  const businessListener = container.resolve(Data.Business.BusinessDataListener);

  businessListener.start();
}

export async function investmentAccountsLoader(container: IServiceContainer) {
  const investmentAccountListener = container.resolve(
    Data.InvestmentAccounts.InvestmentAccountDataListener
  );

  investmentAccountListener.start();

  return json('Investment Accounts listener started');
}

export async function pensionsLoader(container: IServiceContainer) {
  const pensionsListener = container.resolve(Data.Pensions.PensionDataListener);
  pensionsListener.start();
}

export async function eligibilityLoader(container: IServiceContainer) {
  const editEligibilityListener = container.resolve(
    Data.InvestmentAccounts.EditEligibilityDataListener
  );
  const spousseAccountEligibilityListener = container.resolve(
    Data.InvestmentAccounts.SpouseAccountEligibilityDataListener
  );
  const spousseAccountEditEligibilityListener = container.resolve(
    Data.InvestmentAccounts.SpouseEditEligibilityDataListener
  );

  editEligibilityListener.start();
  spousseAccountEligibilityListener.start();
  spousseAccountEditEligibilityListener.start();
}
