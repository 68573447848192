/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Commands, Data, Domain, Hooks } from '@3nickels/data-modules';
import { FormLoader } from '../../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../../components/form/FormContent';
import { LayoutMeta, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../../EditViewUtils';
import { AccountDetailsLayoutMeta } from '../../../AccountDetailsLayout';
import HSAContributionsForm from './HSAContributionsForm';
import { cleanWholeNumberStr, formatWholeDollars } from '../../../../../helpers/utilityFunctions';
import { useService } from '@aesop-fables/containr-react';

const hsaContributionsSchema = (individualLimits: number, familyLimits: number) =>
  Yup.object({
    coverageType: Yup.string()
      .required('Coverage Type is required')
      .test('coverageType', 'Coverage Type is required', (type) =>
        Domain.CoverageTypeEnumList.some((option) => {
          return option.key === type;
        })
      ),
    annualContribPreTaxDollar: Yup.string().test({
      name: 'annualContributions less than limit',
      // arrow function cannot access parent
      test: function (annualContribPreTaxDollar, { createError, path, parent }) {
        const { coverageType } = parent;
        const contrib = cleanWholeNumberStr(annualContribPreTaxDollar ?? '');
        if (coverageType === Domain.CoverageTypeEnum.Single && contrib > individualLimits) {
          return createError({
            message: `Maximum ${formatWholeDollars(individualLimits)}`,
            path,
          });
        } else if (coverageType === Domain.CoverageTypeEnum.Family && contrib > familyLimits) {
          return createError({
            message: `Maximum ${formatWholeDollars(familyLimits)}`,
            path,
          });
        }

        return true;
      },
    }),
  });

export const HSAContributionsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { id } = useParams();
  // TODO -- We should probably add some higher-level support for handling errors in the url (strings instead of numbers)
  const investmentAccountId = parseInt(id ?? '');
  const { loading, values } = Hooks.useHsaContributionFormData(investmentAccountId);

  if (loading) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }
  return (
    <FormLoader loading={loading}>
      <HSAContributionsEditView
        investmentAccountId={investmentAccountId}
        values={values}
        editing={editing}
        onBack={onBack}
      />
    </FormLoader>
  );
};

interface HSAContributionsEditViewProps extends EditViewProps {
  values: Data.InvestmentAccounts.HsaContributionsFormData;
  investmentAccountId: number;
}

const HSAContributionsEditView: React.FC<HSAContributionsEditViewProps> = ({
  investmentAccountId,
  editing,
  onBack,
  values,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const federalLimits = Hooks.useFederalLimits();
  const methods = useForm<Data.InvestmentAccounts.HsaContributionsFormData>({
    defaultValues: values,
    resolver: yupResolver(
      hsaContributionsSchema(
        federalLimits?.individualHsaContributions ?? 0,
        federalLimits?.familyHsaContributions ?? 0
      )
    ),
  });
  const { setLoading } = useLoading();
  const commands = useService<Commands.ICommandExecutor>(Data.DataServices.CommandExecutor);

  const onSubmit = async (values: Data.InvestmentAccounts.HsaContributionsFormData) => {
    setLoading(true);

    try {
      await commands.execute(Data.InvestmentAccounts.Commands.UpdateHsaContributions, {
        investmentAccountId,
        coverageType: values.coverageType,
        annualContribPreTaxDollar: cleanWholeNumberStr(
          values.annualContribPreTaxDollar as unknown as string
        ),
      });

      if (editing && onBack) {
        onBack();
        return;
      }

      // const wizardParams = await firstValueFrom(wizard.params$);
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/account-details/health-savings-account/investments/${investmentAccountId}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormContent formProviderProps={methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box>
            {/* Should be about 30px */}
            <Typography className='title' color='primary' component='h1' variant='h1'>
              {t('AnnualContributions')}
            </Typography>

            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={editing ? 10 : 6}>
                <HSAContributionsForm contributions={values} onSubmit={onSubmit} />
              </Grid>
            </Grid>
          </Box>
          <WizardFooter
            color={editing ? 'primary' : undefined}
            disableBack={false}
            onBack={onBack}
            {...getWizardFooterProps(meta.nextLocaleKey, editing)}
          />
        </form>
      </FormContent>
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 3,
    currentStep: 2,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(HSAContributionsEditViewWrapper, meta);
