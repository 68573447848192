import { LicenseData } from '../../models/LicenseData';
import {
  ConfiguredDataSource,
  DataCache,
  DataCompartmentOptions,
  createDataCache,
  fromAppStorage,
} from '@aesop-fables/scrinium';
import { IServiceContainer, inject } from '@aesop-fables/containr';
import { Authentication, Data, PredicateListener } from '@3nickels/data-modules';

export interface LicenseCompartments {
  license: DataCompartmentOptions<LicenseData | undefined>;
}

export const licenseStorageKey = 'data/license';

export class LicenseDataListener extends PredicateListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    context: Authentication.IAuthenticationContext,
    @fromAppStorage(licenseStorageKey) private readonly cache: DataCache<LicenseCompartments>
  ) {
    super(context.isAuthenticated$);
  }

  onNext(): void {
    const keys: (keyof LicenseCompartments)[] = ['license'];
    Promise.all([keys.map((key) => this.cache.reload(key))]);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const withLicenseData = Data.create3NickelsDataCacheModule<LicenseCompartments>(
  licenseStorageKey,
  (authContext: Authentication.IAuthenticationContext, container: IServiceContainer) => {
    return createDataCache<LicenseCompartments>({
      license: {
        source: new ConfiguredDataSource(async () => {
          // do something
          return undefined;
        }),
        defaultValue: undefined,
      },
    });
  }
);
