import { Grid, Typography } from '@mui/material';
import ContentView from '../layout/Content';
import { useContentView } from '../layout/ContentViewContext';
import React from 'react';
import { ToolsCarousel } from './ToolsCarousel';
import { ToolsHeaderCard } from './ToolsHeaderCard';

const ToolsLayout: React.FC = () => {
  const { sideNav } = useContentView();

  return (
    <ContentView sideNavMenuItems={sideNav('Tools')}>
      <Grid>
        <Grid mb={5}>
          <ToolsHeaderCard />
        </Grid>
        <Typography mb={3} variant='p18Bold' color='primary'>
          Here’s What You Get!
        </Typography>
        <ToolsCarousel />
      </Grid>
    </ContentView>
  );
};

export default ToolsLayout;
