import { Api, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { Typography, Button, Grid } from '@mui/material';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { useAvailableProducts } from '../../../hooks/pricebook/useAvailableProducts';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { SeeLegalDocs } from '../../settings/manage-subscription/Footnote';
import { Colors } from '../../../themes';

const defaultButtonColor = 'primary';
const defaultLinkColor = Colors.primaryBase;
interface SubscriptionDetailsProps {
  style?: React.CSSProperties;
  buttonColor?:
    | 'accent'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'tertiary'
    | undefined;
  linkColor?: string;
}

export const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  style,
  buttonColor = defaultButtonColor,
  linkColor = defaultLinkColor,
}) => {
  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const legalDocs = Hooks.useTermsAndConditions();
  const { threeNickelsProducts } = useAvailableProducts();
  const recentPlan = threeNickelsProducts?.find((p) => !p.pricebook.pricebookName.includes('Free'));

  const intervalType = recentPlan?.prices[0].intervalType.toLowerCase();
  const oneTimePayment = intervalType === 'none';
  const price = recentPlan?.prices[0].price && recentPlan.prices[0].price / 100;
  const secondaryPrice = recentPlan?.prices[1]?.price && recentPlan.prices[1]?.price / 100;
  const promotionalPeriodInMonths =
    recentPlan?.prices[1]?.introductionPeriodInDays &&
    Math.round(recentPlan.prices[1]?.introductionPeriodInDays / 30);

  const buttonText = oneTimePayment
    ? `Purchase for $${price}`
    : `Subscribe for $${price}/${intervalType}`;
  const description = oneTimePayment
    ? `Initially a one-time payment. After the ${promotionalPeriodInMonths}-month promotional period, Advice subscription auto-renews at ${secondaryPrice}/month and will continue to auto-renew until you cancel.`
    : 'This subscription automatically renews every month and will continue to auto-renew until you cancel.';

  return (
    <Grid
      style={style}
      flexDirection='column'
      textAlign='center'
      justifyContent='center'
      alignContent='center'
      alignItems='center'
      alignSelf='center'>
      <Grid mb={2} mr='16%' ml='16%'>
        <Typography className='link-wrapper' variant='p12' color='secondary'>
          {description}{' '}
          <SeeLegalDocs
            termsApi={termsApi}
            legalDocs={legalDocs}
            linkStyle={{
              color: linkColor,
              textDecorationColor: linkColor,
              fontWeight: linkColor === defaultLinkColor ? 'normal' : 'bold',
            }}
          />
        </Typography>
      </Grid>
      <Button
        color={buttonColor}
        onClick={async () => {
          if (recentPlan) {
            const stripeUrl = await paymentApi.createSession(recentPlan.pricebook.id);
            window.location.replace(stripeUrl.data);
          }
        }}>
        {buttonText}
      </Button>
    </Grid>
  );
};
