import { Authentication, Data } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { AppStorage, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';

export async function netWorthLoader(container: IServiceContainer) {
  const authContext = container.get<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const appStorage = container.get<AppStorage>(ScriniumServices.AppStorage);
  const netWorthDataCache = appStorage.retrieve<Data.NetWorth.NetWorthCompartments>(
    Data.NetWorth.netWorthStorageKey
  );
  const netWorthListener = new Data.NetWorth.NetWorthListener(authContext, netWorthDataCache);
  netWorthListener.start();
  return json('net worth listener started');
}
