import { ReactComponent as ActionSmallBank } from './action-small-bank-default.svg';
import { ReactComponent as ActionSmallCalendar } from './action-small-calendar-default.svg';
import { ReactComponent as ActionSmallCloseDefault } from './action-small-close-default.svg';
import { ReactComponent as ActionSmallEdit } from './action-small-edit-default.svg';
import { ReactComponent as ActionSmallEllipsis } from './action-small-ellipsis-default.svg';
import { ReactComponent as ActionSmallEnvelopeTertiaryDefault } from './action-small-envelope-tertiary-default.svg';
import { ReactComponent as ActionSmallFilter } from './action-small-filter-default.svg';
import { ReactComponent as ActionSmallGrip } from './action-small-grip-default.svg';
import { ReactComponent as ActionSmallPhone2TertiaryDefault } from './action-small-phone-2-tertiary-default.svg';
import { ReactComponent as ActionSmallPlus } from './action-small-plus-default.svg';
import { ReactComponent as ActionAdviceIconDefault } from './action-tab-bar-advice-icon-only-default.svg';
import { ReactComponent as ActionBudgetIconDefault } from './action-tab-bar-budget-icon-only-default.svg';
import { ReactComponent as ActionGoalsIconDefault } from './action-tab-bar-goals-icon-only-default.svg';
import { ReactComponent as ActionNetWorthIconDefault } from './action-tab-bar-net-worth-icon-only-default.svg';
import { ReactComponent as ActionToolsIconDefault } from './action-tab-bar-tools-icon-only-default.svg';
import { ReactComponent as AppleLogoWhite } from './apple-logo-white.svg';
import { ReactComponent as ArtAdvice } from './art-advice.svg';
import { ReactComponent as ArtApproved } from './art-approved.svg';
import { ReactComponent as ArtBooks } from './art-books.svg';
import { ReactComponent as ArtBusiness } from './art-business.svg';
import { ReactComponent as ArtCar } from './art-car.svg';
import { ReactComponent as ArtCellPhone } from './art-cell-phone.svg';
import { ReactComponent as ArtCoffee } from './art-coffee.svg';
import { ReactComponent as ArtCollegeGradCap } from './art-college-grad-cap.svg';
import { ReactComponent as ArtConfusedMan } from './art-confused-man.svg';
import { ReactComponent as ArtDashboardChecklist } from './art-dashboard-checklist.svg';
import { ReactComponent as ArtDashboardLightbulb } from './art-dashboard-lightbulb.svg';
import { ReactComponent as ArtDashboardMagnifyingGlass } from './art-dashboard-magnifying-glass.svg';
import { ReactComponent as ArtDebtSmall } from './art-debt-small.svg';
import { ReactComponent as ArtGivingCollege } from './art-giving-college.svg';
import { ReactComponent as ArtHeart } from './art-giving-heart.svg';
import { ReactComponent as ArtHouse } from './art-house.svg';
import { ReactComponent as ArtHouseLarge } from './art-house-large.svg';
import { ReactComponent as ArtLaptop } from './art-laptop.svg';
import { ReactComponent as ArtLightbulbMedium } from './art-lightbulb-medium.svg';
import { ReactComponent as ArtLightbulbSmall } from './art-lightbulb-small.svg';
import { ReactComponent as ArtMeditation } from './art-meditation.svg';
import { ReactComponent as ArtMoneyPlant } from './art-money-plant.svg';
import { ReactComponent as ArtMoneySmall } from './art-money-small.svg';
import { ReactComponent as ArtOrganization } from './art-organization.svg';
import { ReactComponent as ArtPieChart } from './art-pie-chart.svg';
import { ReactComponent as ArtPieChartLarge } from './art-pie-chart-large.svg';
import { ReactComponent as ArtPiggyBank } from './art-piggy-bank.svg';
import { ReactComponent as ArtPlant } from './art-plant.svg';
import { ReactComponent as ArtRetirement } from './art-retirement.svg';
import { ReactComponent as ArtRetirementLarge } from './art-retirement-large.svg';
import { ReactComponent as ArtRetirementSmall } from './art-retirement-small.svg';
import { ReactComponent as ArtStudyMaterials } from './art-study-materials.svg';
import { ReactComponent as ArtTarget } from './art-target.svg';
import { ReactComponent as ArtTargetSmall } from './art-target-small.svg';
import { ReactComponent as ArtThumbsUp } from './art-thumbs-up.svg';
import { ReactComponent as ArtUmbrella } from './art-umbrella.svg';
import { ReactComponent as ArtUni3N } from './art-uni-3n.svg';
import { ReactComponent as BeachChair } from './beach-chair.svg';
import { ReactComponent as BulletBook } from './bullet-book.svg';
import { ReactComponent as BulletCalculator } from './bullet-calculator.svg';
import { ReactComponent as BulletChecklist } from './bullet-checklist.svg';
import { ReactComponent as BulletCoach } from './bullet-coach.svg';
import { ReactComponent as BulletInvestmentGC } from './bullet-investment-gc.svg';
import { ReactComponent as BulletInvestment } from './bullet-investment.svg';
import { ReactComponent as BulletLessons } from './bullet-lessons.svg';
import { ReactComponent as BulletLoans } from './bullet-loans.svg';
import { ReactComponent as BulletPiggyBank } from './bullet-piggy-bank.svg';
import { ReactComponent as BulletQa } from './bullet-qa.svg';
import { ReactComponent as BulletStatistics } from './bullet-statistics.svg';
import { ReactComponent as Car } from './car.svg';
import { ReactComponent as Cash } from './cash.svg';
import { ReactComponent as CollegeGradCap } from './college-grad-cap.svg';
import { ReactComponent as Debt } from './debt.svg';
import { ReactComponent as DisplayExtraSmallCustomX } from './display-extra-small-custom-x.svg';
import { ReactComponent as DisplayHamburgerMenuSignOut } from './display-hamburger-menu-sign-out.svg';
import { ReactComponent as DisplayHamburgerMenuHeadset } from './display-hamburger-menu-headset.svg';
import { ReactComponent as DisplayLargeCustomCheckmark } from './display-large-custom-checkmark.svg';
import { ReactComponent as DisplayLargeCustomMoneyBag } from './display-large-custom-money-bag.svg';
import { ReactComponent as DisplayLargeCustomMoneyExchange } from './display-large-custom-money-exchange.svg';
import { ReactComponent as DisplayLargeCustomScale } from './display-large-custom-scale.svg';
import { ReactComponent as DisplayLargeCustomTax } from './display-large-custom-tax.svg';
import { ReactComponent as DisplayLargeCustomWarning } from './display-large-custom-warning.svg';
import { ReactComponent as DisplayMediumCustomRibbon } from './display-medium-custom-ribbon.svg';
import { ReactComponent as DisplayMediumPrimaryAnotherBehalf } from './display-medium-primary-another-behalf.svg';
import { ReactComponent as DisplayMediumPrimaryCar } from './display-medium-primary-car.svg';
import { ReactComponent as DisplayMediumPrimaryCoins } from './display-medium-primary-coins.svg';
import { ReactComponent as DisplayMediumPrimaryCollege } from './display-medium-primary-college.svg';
import { ReactComponent as DisplayMediumPrimaryDebt } from './display-medium-primary-debt.svg';
import { ReactComponent as DisplayMediumPrimaryGifts } from './display-medium-primary-gifts.svg';
import { ReactComponent as DisplayMediumPrimaryHouse } from './display-medium-primary-house.svg';
import { ReactComponent as DisplayMediumPrimaryInvestment } from './display-medium-primary-investment.svg';
import { ReactComponent as DisplayMediumPrimaryLegacy } from './display-medium-primary-legacy.svg';
import { ReactComponent as DisplayMediumPrimaryPiggyBank } from './display-medium-primary-piggy-bank.svg';
import { ReactComponent as DisplayMediumPrimaryRetirement } from './display-medium-primary-retirement.svg';
import { ReactComponent as DisplayMediumPrimaryTax } from './display-medium-primary-tax.svg';
import { ReactComponent as DisplayMediumPrimaryTime } from './display-medium-primary-time.svg';
import { ReactComponent as DisplayMediumPrimaryUmbrella } from './display-medium-primary-umbrella.svg';
import { ReactComponent as DisplayMediumPrimaryVacation } from './display-medium-primary-vacation.svg';
import { ReactComponent as DisplaySmallCustomAdvice } from './display-small-custom-advice.svg';
import { ReactComponent as DisplaySmallCustomAlerts } from './display-small-custom-alerts.svg';
import { ReactComponent as DisplaySmallCustomBirthdayCake } from './display-small-custom-birthday-cake.svg';
import { ReactComponent as DisplaySmallCustomBook } from './display-small-custom-book.svg';
import { ReactComponent as DisplaySmallCustomBank } from './display-small-custom-bank.svg';
import { ReactComponent as DisplaySmallCustomBudget } from './display-small-custom-budget.svg';
import { ReactComponent as DisplaySmallCustomCalculator } from './display-small-custom-calculator.svg';
import { ReactComponent as DisplaySmallCustomCalendar } from './display-small-custom-calendar.svg';
import { ReactComponent as DisplaySmallCustomCar } from './display-small-custom-car.svg';
import { ReactComponent as DisplaySmallCustomClock } from './display-small-custom-clock.svg';
import { ReactComponent as DisplaySmallCustomCoins } from './display-small-custom-coins.svg';
import { ReactComponent as DisplaySmallCustomCreditCard } from './display-small-custom-credit-card.svg';
import { ReactComponent as DisplaySmallCustomCreditCard2 } from './display-small-custom-credit-card-2.svg';
import { ReactComponent as DisplaySmallCustomDeposit } from './display-small-custom-deposit.svg';
import { ReactComponent as DisplaySmallCustomDebt } from './display-small-custom-debt.svg';
import { ReactComponent as DisplaySmallCustomEnvelope } from './display-small-custom-envelope.svg';
import { ReactComponent as DisplaySmallCustomFood } from './display-small-custom-food.svg';
import { ReactComponent as DisplaySmallCustomGifts } from './display-small-custom-gifts.svg';
import { ReactComponent as DisplaySmallCustomGoals } from './display-small-custom-goals.svg';
import { ReactComponent as DisplaySmallCustomHealthInsurance } from './display-small-custom-health-insurance.svg';
import { ReactComponent as DisplaySmallCustomHouse } from './display-small-custom-house.svg';
import { ReactComponent as DisplaySmallCustomInfo } from './display-small-custom-info.svg';
import { ReactComponent as DisplaySmallCustomInvestment } from './display-small-custom-investment.svg';
import { ReactComponent as DisplaySmallCustomItemization } from './display-small-custom-itemization.svg';
import { ReactComponent as DisplaySmallCustomLegacy } from './display-small-custom-legacy.svg';
import { ReactComponent as DisplaySmallCustomLightBulb } from './display-small-custom-lightbulb.svg';
import { ReactComponent as DisplaySmallCustomLoans } from './display-small-custom-loans.svg';
import { ReactComponent as DisplaySmallCustomLock } from './display-small-custom-lock.svg';
import { ReactComponent as DisplaySmallCustomMedical } from './display-small-custom-medical.svg';
import { ReactComponent as DisplaySmallCustomMembers } from './display-small-custom-members.svg';
import { ReactComponent as DisplaySmallCustomOnTarget } from './display-small-custom-on-target.svg';
import { ReactComponent as DisplaySmallCustomOrganization } from './display-small-custom-organization.svg';
import { ReactComponent as DisplaySmallCustomPayOff } from './display-small-custom-pay-off.svg';
import { ReactComponent as DisplaySmallCustomPhone2 } from './display-small-custom-phone-2.svg';
import { ReactComponent as DisplaySmallCustomPiggyBank } from './display-small-custom-piggy-bank.svg';
import { ReactComponent as DisplaySmallCustomProfileActive } from './display-small-custom-profile-active.svg';
import { ReactComponent as DisplaySmallCustomProfileDefault } from './display-small-custom-profile-default.svg';
import { ReactComponent as DisplaySmallCustomProgressComplete } from './display-small-custom-progress-complete.svg';
import { ReactComponent as DisplaySmallCustomPromotions } from './display-small-custom-promotions.svg';
import { ReactComponent as DisplaySmallCustomRetirement } from './display-small-custom-retirement.svg';
import { ReactComponent as DisplaySmallCustomScale } from './display-small-custom-scale.svg';
import { ReactComponent as DisplaySmallCustomSearch } from './display-small-custom-search.svg';
import { ReactComponent as DisplaySmallCustomShield } from './display-small-custom-shield.svg';
import { ReactComponent as DisplaySmallCustomShoppingCart } from './display-small-custom-shopping-cart.svg';
import { ReactComponent as DisplaySmallCustomUmbrella } from './display-small-custom-umbrella.svg';
import { ReactComponent as DisplaySmallCustomVacation } from './display-small-custom-vacation.svg';
import { ReactComponent as DisplaySmallCustomVideoCamera } from './display-small-custom-video-camera.svg';
import { ReactComponent as DisplaySmallCustomWallet } from './display-small-custom-wallet.svg';
import { ReactComponent as DisplaySmallCustomWarning } from './display-small-custom-warning.svg';
import { ReactComponent as DisplaySmallWhiteBank } from './display-small-white-bank.svg';
import { ReactComponent as DisplaySmallWhiteBudget } from './display-small-white-budget.svg';
import { ReactComponent as DisplaySmallWhiteCar } from './display-small-white-car.svg';
import { ReactComponent as DisplaySmallWhiteCoins } from './display-small-white-coins.svg';
import { ReactComponent as DisplaySmallWhiteDeposit } from './display-small-white-deposit.svg';
import { ReactComponent as DisplaySmallWhiteFood } from './display-small-white-food.svg';
import { ReactComponent as DisplaySmallWhiteHouse } from './display-small-white-house.svg';
import { ReactComponent as DisplaySmallWhiteLegacy } from './display-small-white-legacy.svg';
import { ReactComponent as DisplaySmallWhiteLightBulb } from './display-small-white-lightbulb.svg';
import { ReactComponent as DisplaySmallWhiteLoans } from './display-small-white-loans.svg';
import { ReactComponent as DisplaySmallWhiteMedical } from './display-small-white-medical.svg';
import { ReactComponent as DisplaySmallWhiteMembers } from './display-small-white-members.svg';
import { ReactComponent as DisplaySmallWhiteRetirement } from './display-small-white-retirement.svg';
import { ReactComponent as DisplaySmallWhiteShoppingCart } from './display-small-white-shopping-cart.svg';
import { ReactComponent as DisplaySmallWhiteVideoCamera } from './display-small-white-video-camera.svg';
import { ReactComponent as DisplaySmallWhiteWallet } from './display-small-white-wallet.svg';
import { ReactComponent as DisplaySmallWhiteWithdraw } from './display-small-white-withdraw.svg';
import { ReactComponent as GivingHeart } from './giving-heart.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as DisplaySmallCustomWithdraw } from './display-small-custom-withdraw.svg';
import { ReactComponent as Icon3NUCoach } from './icon-3nu-coach.svg';
import { ReactComponent as Icon3NULessons } from './icon-3nu-lessons.svg';
import { ReactComponent as Icon3NUQa } from './icon-3nu-qa.svg';
import { ReactComponent as IconAdd } from './icon-add.svg';
import { ReactComponent as IconAdminsActive } from './icon-admins-active.svg';
import { ReactComponent as IconAdminsDefault } from './icon-admins-default.svg';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import { ReactComponent as IconArrowRight } from './icon-arrow-right.svg';
import { ReactComponent as IconBook } from './icon-book.svg';
import { ReactComponent as IconCalculator } from './icon-calculator.svg';
import { ReactComponent as IconChecklist } from './icon-checklist.svg';
import { ReactComponent as IconCheckmark } from './icon-checkmark.svg';
import { ReactComponent as IconClose } from './icon-close.svg';
import { ReactComponent as IconColllege } from './icon-college.svg';
import { ReactComponent as IconCommunicationsActive } from './icon-communications-active.svg';
import { ReactComponent as IconCommunicationsDefault } from './icon-communications-default.svg';
import { ReactComponent as IconContactUsActive } from './icon-contact-us-active.svg';
import { ReactComponent as IconContactUsDefault } from './icon-contact-us-default.svg';
import { ReactComponent as IconDashboardActive } from './icon-dashboard-active.svg';
import { ReactComponent as IconDashboardDefault } from './icon-dashboard-default.svg';
import { ReactComponent as IconDelete } from './icon-delete.svg';
import { ReactComponent as IconDownload } from './icon-download.svg';
import { ReactComponent as IconEdit } from './icon-edit.svg';
import { ReactComponent as IconHouseActive } from './icon-house-active.svg';
import { ReactComponent as IconHouseDefault } from './icon-house-default.svg';
import { ReactComponent as IconInvestment } from './icon-investment.svg';
import { ReactComponent as IconLicenseActive } from './icon-license-active.svg';
import { ReactComponent as IconLicenseDefault } from './icon-license-default.svg';
import { ReactComponent as IconMembersActive } from './icon-members-active.svg';
import { ReactComponent as IconMembersDefaultWhite } from './icon-members-default-white.svg';
import { ReactComponent as IconMembersDefault } from './icon-members-default.svg';
import { ReactComponent as IconMembersLarge } from './icon-members-large.svg';
import { ReactComponent as IconMinimize } from './icon-minimize.svg';
import { ReactComponent as IconNavAlertDefault } from './icon-nav-alert-default.svg';
import { ReactComponent as IconNavMenuDefault } from './icon-nav-menu-default.svg';
import { ReactComponent as IconOrganization } from './icon-organization.svg';
import { ReactComponent as IconOrgPaysLarge } from './icon-org-pays-large.svg';
import { ReactComponent as IconOrgPaysTertiary } from './icon-org-pays-tertiary.svg';
import { ReactComponent as IconPaymentActive } from './icon-payment-active.svg';
import { ReactComponent as IconPaymentDefault } from './icon-payment-default.svg';
import { ReactComponent as IconProfile } from './icon-profile.svg';
import { ReactComponent as IconSearch } from './icon-search.svg';
import { ReactComponent as IconStepper } from './icon-stepper.svg';
import { ReactComponent as LinkPaperClip } from './link-paperclip.svg';
import { ReactComponent as NickelsULogoSecondary } from './3nu-logo-secondary.svg';
import { ReactComponent as PiggyBank } from './piggy-bank.svg';
import { ReactComponent as Presents } from './presents.svg';
import { ReactComponent as Umbrella } from './umbrella.svg';
import { ReactComponent as IpmIconDelete } from './ipm-icon-delete.svg';
import { ReactComponent as WebIconGrip } from './web-icon-grip.svg';
import { ReactComponent as WebIconOrganization } from './web-icon-organization.svg';
import { ReactComponent as WebIconRefresh } from './web-icon-refresh.svg';

export const Svgs = {
  ActionSmallBank,
  ActionSmallCalendar,
  ActionSmallCloseDefault,
  ActionSmallEdit,
  ActionSmallEllipsis,
  ActionSmallEnvelopeTertiaryDefault,
  ActionSmallFilter,
  ActionSmallGrip,
  ActionSmallPhone2TertiaryDefault,
  ActionSmallPlus,
  ActionAdviceIconDefault,
  ActionBudgetIconDefault,
  ActionGoalsIconDefault,
  ActionNetWorthIconDefault,
  ActionToolsIconDefault,
  AppleLogoWhite,
  ArtAdvice,
  ArtApproved,
  ArtBooks,
  ArtBusiness,
  ArtCar,
  ArtCoffee,
  ArtCellPhone,
  ArtCollegeGradCap,
  ArtConfusedMan,
  ArtDashboardChecklist,
  ArtDashboardLightbulb,
  ArtDashboardMagnifyingGlass,
  ArtDebtSmall,
  ArtGivingCollege,
  ArtHeart,
  ArtHouse,
  ArtHouseLarge,
  ArtLaptop,
  ArtLightbulbMedium,
  ArtLightbulbSmall,
  ArtMeditation,
  ArtMoneyPlant,
  ArtMoneySmall,
  ArtOrganization,
  ArtPieChart,
  ArtPieChartLarge,
  ArtPlant,
  ArtPiggyBank,
  ArtRetirement,
  ArtRetirementLarge,
  ArtRetirementSmall,
  LinkPaperClip,
  ArtStudyMaterials,
  ArtTarget,
  ArtTargetSmall,
  ArtThumbsUp,
  ArtUmbrella,
  ArtUni3N,
  BeachChair,
  BulletBook,
  BulletCalculator,
  BulletChecklist,
  BulletCoach,
  BulletInvestmentGC,
  BulletInvestment,
  BulletLessons,
  BulletLoans,
  BulletPiggyBank,
  BulletQa,
  BulletStatistics,
  Car,
  Cash,
  CollegeGradCap,
  Debt,
  DisplayExtraSmallCustomX,
  DisplayHamburgerMenuSignOut,
  DisplayHamburgerMenuHeadset,
  DisplayLargeCustomCheckmark,
  DisplayLargeCustomMoneyBag,
  DisplayLargeCustomMoneyExchange,
  DisplayLargeCustomScale,
  DisplayLargeCustomTax,
  DisplayLargeCustomWarning,
  DisplayMediumCustomRibbon,
  DisplayMediumPrimaryAnotherBehalf,
  DisplayMediumPrimaryCar,
  DisplayMediumPrimaryCoins,
  DisplayMediumPrimaryCollege,
  DisplayMediumPrimaryDebt,
  DisplayMediumPrimaryGifts,
  DisplayMediumPrimaryHouse,
  DisplayMediumPrimaryInvestment,
  DisplayMediumPrimaryLegacy,
  DisplayMediumPrimaryPiggyBank,
  DisplayMediumPrimaryRetirement,
  DisplayMediumPrimaryTax,
  DisplayMediumPrimaryTime,
  DisplayMediumPrimaryUmbrella,
  DisplayMediumPrimaryVacation,
  DisplaySmallCustomAdvice,
  DisplaySmallCustomAlerts,
  DisplaySmallCustomBirthdayCake,
  DisplaySmallCustomBook,
  DisplaySmallCustomBudget,
  DisplaySmallCustomBank,
  DisplaySmallCustomCalculator,
  DisplaySmallCustomCalendar,
  DisplaySmallCustomCar,
  DisplaySmallCustomClock,
  DisplaySmallCustomCoins,
  DisplaySmallCustomCreditCard,
  DisplaySmallCustomCreditCard2,
  DisplaySmallCustomDeposit,
  DisplaySmallCustomDebt,
  DisplaySmallCustomEnvelope,
  DisplaySmallCustomFood,
  DisplaySmallCustomGifts,
  DisplaySmallCustomGoals,
  DisplaySmallCustomHealthInsurance,
  DisplaySmallCustomHouse,
  DisplaySmallCustomInfo,
  DisplaySmallCustomInvestment,
  DisplaySmallCustomItemization,
  DisplaySmallCustomLegacy,
  DisplaySmallCustomLightBulb,
  DisplaySmallCustomLoans,
  DisplaySmallCustomLock,
  DisplaySmallCustomMedical,
  DisplaySmallCustomMembers,
  DisplaySmallCustomOnTarget,
  DisplaySmallCustomOrganization,
  DisplaySmallCustomPayOff,
  DisplaySmallCustomPhone2,
  DisplaySmallCustomPiggyBank,
  DisplaySmallCustomProfileActive,
  DisplaySmallCustomProfileDefault,
  DisplaySmallCustomProgressComplete,
  DisplaySmallCustomPromotions,
  DisplaySmallCustomRetirement,
  DisplaySmallCustomScale,
  DisplaySmallCustomSearch,
  DisplaySmallCustomShield,
  DisplaySmallCustomShoppingCart,
  DisplaySmallCustomUmbrella,
  DisplaySmallCustomVacation,
  DisplaySmallCustomVideoCamera,
  DisplaySmallCustomWallet,
  DisplaySmallCustomWarning,
  DisplaySmallWhiteBank,
  DisplaySmallWhiteBudget,
  DisplaySmallWhiteCar,
  DisplaySmallWhiteCoins,
  DisplaySmallWhiteDeposit,
  DisplaySmallWhiteFood,
  DisplaySmallWhiteHouse,
  DisplaySmallWhiteLegacy,
  DisplaySmallWhiteLightBulb,
  DisplaySmallWhiteLoans,
  DisplaySmallWhiteMedical,
  DisplaySmallWhiteMembers,
  DisplaySmallWhiteRetirement,
  DisplaySmallWhiteShoppingCart,
  DisplaySmallWhiteVideoCamera,
  DisplaySmallWhiteWallet,
  DisplaySmallWhiteWithdraw,
  GivingHeart,
  House,
  DisplaySmallCustomWithdraw,
  Icon3NUCoach,
  Icon3NULessons,
  Icon3NUQa,
  IconAdd,
  IconAdminsActive,
  IconAdminsDefault,
  IconArrowDown,
  IconArrowRight,
  IconBook,
  IconCalculator,
  IconChecklist,
  IconCheckmark,
  IconClose,
  IconColllege,
  IconCommunicationsActive,
  IconCommunicationsDefault,
  IconContactUsActive,
  IconContactUsDefault,
  IconDashboardActive,
  IconDashboardDefault,
  IconDelete,
  IconDownload,
  IconEdit,
  IconHouseActive,
  IconHouseDefault,
  IconInvestment,
  IconLicenseActive,
  IconLicenseDefault,
  IconMembersActive,
  IconMembersDefaultWhite,
  IconMembersDefault,
  IconMembersLarge,
  IconMinimize,
  IconNavAlertDefault,
  IconNavMenuDefault,
  IconOrganization,
  IconOrgPaysLarge,
  IconOrgPaysTertiary,
  IconPaymentActive,
  IconPaymentDefault,
  IconProfile,
  IconSearch,
  IconStepper,
  IpmIconDelete,
  NickelsULogoSecondary,
  PiggyBank,
  Presents,
  Umbrella,
  WebIconGrip,
  WebIconOrganization,
  WebIconRefresh,
};
