import BudgetDetailsAverages from '../pages/budget-details/BudgetDetailsAverages';
import BudgetDetailsHome from '../pages/budget-details/BudgetDetailsHome';

export const BudgetDetailsRoutes = [
  {
    index: true,
    element: <BudgetDetailsHome />,
  },
  {
    path: 'averages',
    element: <BudgetDetailsAverages />,
  },
];
