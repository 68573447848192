import { IServiceContainer, inject } from '@aesop-fables/containr';
import {
  DataCompartmentOptions,
  createDataCache,
  ConfiguredDataSource,
  DataCache,
  fromAppStorage,
} from '@aesop-fables/scrinium';
import { Api, Authentication, Data, PredicateListener } from '@3nickels/data-modules';

export interface TermsCompartments {
  docs: DataCompartmentOptions<Api.TermsDoc[] | undefined>;
}

export const termsStorageKey = '@3nickels/web/terms';

export class TermsDataListener extends PredicateListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    context: Authentication.IAuthenticationContext,
    @fromAppStorage(termsStorageKey) private readonly cache: DataCache<TermsCompartments>
  ) {
    super(context.isAuthenticated$);
  }

  onNext(): void {
    const keys: (keyof TermsCompartments)[] = ['docs'];
    Promise.all([keys.map((key) => this.cache.reload(key))]);
  }
}

export const withTermsData = Data.create3NickelsDataCacheModule<TermsCompartments>(
  termsStorageKey,
  (authContext: Authentication.IAuthenticationContext, container: IServiceContainer) => {
    const termsApi = container.get<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
    return createDataCache<TermsCompartments>({
      docs: {
        source: new ConfiguredDataSource(async () => {
          const { data } = await termsApi.getAll();
          return data;
        }),
        defaultValue: undefined,
      },
    });
  }
);
