import { Box, Card, Collapse, Drawer, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ToggleRadioButtonGroup from './ToggleRadioButtonGroup';
import { Spacing } from '../../themes';
import { useFormContext } from 'react-hook-form';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useSubject } from '@aesop-fables/scrinium';
import {
  AddDependentRow,
  DependentDrawerProps,
} from '../../pages/personal-details/dependent/DependentView';
import CheckSelect from '../CheckSelect';
import {
  DependentFields,
  DependentForm,
} from '../../pages/personal-details/dependent/DependentForm';
import { useSaveDependent } from '../../hooks/useMutations/useDependentMutations';
import { useLoading } from '../../hooks/useLoading';
import { BeneficiaryTypeOptions } from '../../pages/account-details/investment-account/college-savings-plan/Types';

export type BeneficiaryModel = {
  beneficiaryType?: Domain.BeneficiaryTypeEnum;
  beneficiaryId?: number;
};

export type BeneficiaryPickerProps = {
  defaultValues?: BeneficiaryModel;
  onSubmit: (values: BeneficiaryModel) => Promise<void>;
  addDependentOpen: boolean;
  setAddDependentOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editing?: boolean;
  label: string;
};

const BeneficiaryPicker: React.FC<BeneficiaryPickerProps> = (props) => {
  const { formState, setValue } = useFormContext();
  const { setLoading } = useLoading();
  const { person, includeSpouse } = Hooks.useCombinedSelfData();
  const spouse = Hooks.useSpouseData();
  const [beneficiaryType, setBeneficiaryType] = useState<Domain.BeneficiaryTypeEnum | undefined>();
  const dependents =
    useSubject<Domain.DependentData[]>(Data.People.PersonServices.DependentData) ?? [];
  const [activeDependent, setActiveDependent] = useState<Domain.DependentData | undefined>(
    undefined
  );
  const [drawerOpen, setDrawerOpen] = React.useState<DependentDrawerProps>({
    open: false,
  });

  const setDependent = (d?: Domain.DependentData) => {
    setActiveDependent(d);
    setValue('beneficiaryId', d?.id);
  };

  const saveDependent = useSaveDependent(false, (dependents?: Domain.DependentData[]) => {
    if (dependents && dependents.length > 0) {
      const mostRecentDependent = dependents.pop();
      setDependent(mostRecentDependent);
    }
    if (props.editing) {
      props.setAddDependentOpen(false);
    } else {
      setDrawerOpen({ open: false });
    }
  });

  const setBeneficiaryTypeHandler = (value: Domain.BeneficiaryTypeEnum) => {
    setBeneficiaryType(value);
    setValue('beneficiaryType', value);
    switch (value) {
      case Domain.BeneficiaryTypeEnum.USER:
        setValue('beneficiaryId', person?.personId);
        break;
      case Domain.BeneficiaryTypeEnum.SPOUSE:
        setValue('beneficiaryId', spouse?.spouseId);
        break;
      case Domain.BeneficiaryTypeEnum.DEPENDENT:
        setValue('beneficiaryId', activeDependent?.id);
        break;
    }
  };

  useEffect(() => {
    if (props.defaultValues?.beneficiaryType) {
      setBeneficiaryTypeHandler(props.defaultValues.beneficiaryType);
      if (props.defaultValues.beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT) {
        setValue('beneficiaryId', props.defaultValues.beneficiaryId);
      }
    }
  }, []);

  const handleSaveDependent = (values: Domain.DependentData) => {
    setLoading(true);
    saveDependent.action(values);
  };

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <ToggleRadioButtonGroup<BeneficiaryModel>
          error={formState.errors.beneficiaryType !== undefined}
          helperText={formState.errors.beneficiaryType?.message?.toString()}
          label={props.label}
          name='beneficiaryType'
          autoFocus
          row
          onChange={(target) => {
            setBeneficiaryTypeHandler(target.value as Domain.BeneficiaryTypeEnum);
            if (props.addDependentOpen) {
              props.setAddDependentOpen(false);
            }
          }}
          defaultValue={props.defaultValues?.beneficiaryType}
          items={
            includeSpouse
              ? BeneficiaryTypeOptions
              : BeneficiaryTypeOptions.filter(
                  (option) => option.value !== Domain.BeneficiaryTypeEnum.SPOUSE
                )
          }
        />
      </Stack>
      {beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT && (
        <Stack spacing={Spacing.xxxs}>
          <Grid className='dependent-select'>
            <CheckSelect<Domain.DependentData>
              items={dependents}
              keyFn={(d: Domain.DependentData) => d.id?.toString() ?? ''}
              titleFn={(d: Domain.DependentData) => d.name}
              subTitleFn={(d: Domain.DependentData) => d.birthYear}
              valueFn={(d: Domain.DependentData) =>
                d.id === (activeDependent?.id ?? props.defaultValues?.beneficiaryId)
              }
              onChange={(d: Domain.DependentData, checked: boolean) => {
                if (checked) {
                  setDependent(d);
                } else {
                  setDependent(undefined);
                }
                if (props.addDependentOpen) {
                  props.setAddDependentOpen(false);
                }
              }}
            />
          </Grid>
          {props.editing ? (
            <Card className='dropdown-card'>
              <AddDependentRow
                onAdd={() => props.setAddDependentOpen(true)}
                onClose={() => props.setAddDependentOpen(false)}
                open={props.addDependentOpen}
              />
              <Collapse in={props.addDependentOpen}>
                <DependentFields onSave={handleSaveDependent} />
              </Collapse>
            </Card>
          ) : (
            <>
              <AddDependentRow
                onAdd={() => setDrawerOpen({ open: true })}
                onClose={() => setDrawerOpen({ open: false })}
                open={drawerOpen.open}
              />
              <Drawer
                anchor='right'
                open={drawerOpen.open}
                onClose={() => {
                  setDrawerOpen({ open: false });
                }}>
                <Box p={Spacing.xxs}>
                  <DependentForm
                    dependent={drawerOpen.dependent}
                    edit={false}
                    onCancel={() => {
                      setDrawerOpen({ open: false });
                    }}
                    onSave={handleSaveDependent}
                    makeSubmit={false}
                  />
                </Box>
              </Drawer>
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default BeneficiaryPicker;
