import { Typography, Button, IconButton } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../components';
import { logo3NSmall } from '../assets/png';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { PricebookBundle } from '../models/PricebookData';
import { PackageCard } from './LearnMoreAdviceAndThreeNickelsUDrawer';

export interface LearnMoreDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pricebookBundle: PricebookBundle;
}

const LearnMoreAdviceDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
}) => {
  const { isMobile } = useDeviceType();

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: isMobile ? '100%' : '90%' }}>
          {!isMobile && (
            <Typography color='secondary' variant='p14'>
              3Nickels packages
            </Typography>
          )}
          <Spacer height='xxs' />

          <Typography color='primary' variant={isMobile ? 'p24Bold' : 'p30Bold'}>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        {isMobile ? (
          <IconButton onClick={() => setDrawerOpen(false)}>
            <Svgs.IconNavAlertDefault />
          </IconButton>
        ) : (
          <img src={logo3NSmall} alt='logo' style={{ height: '40px' }} />
        )}
      </Row>
      <PackageCard pricebookBundle={pricebookBundle} />
      {isMobile && (
        <>
          <Spacer height='xs' />
          <Column style={{ alignItems: 'center' }}>
            <Svgs.ArtPieChart />
          </Column>
        </>
      )}
      <Spacer height='xs' />
      <Typography color='secondary' variant='p18Bold'>
        Description
      </Typography>
      <Spacer height='xxs' />
      <Row>
        <Column style={{ width: '100%' }}>
          <Typography color='secondary' variant='p16'>
            Experience The Financial Advisor In Your Pocket&#0174;!
          </Typography>
          <Spacer height='xxs' />
          <Typography color='secondary' variant='p16'>
            Connect all of your finances in one place and let us do the heavy lifting. We’ve been
            advising people on their finances for over 20 years, backed by our sophisticated,
            award-winning technology. Now it’s in your hands!
          </Typography>
        </Column>
        {!isMobile && <Svgs.ArtPieChart style={{ marginLeft: '20px', marginRight: '20px' }} />}
      </Row>
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        At a glance...
      </Typography>
      <Column style={{ width: '85%' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletBook
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <strong>Accessible education</strong>&nbsp;at every level of financial literacy.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletCalculator
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <strong>Customizable tools</strong> to help you with budgeting, retirement, debt payoff
            and much more.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletChecklist
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <strong>Actionable financial planning</strong> to help you reach your goals.
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletInvestment
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <strong>Objective investment advice</strong> for each of your personal accounts.
          </Typography>
        </Row>
      </Column>

      {!isMobile && (
        <>
          <Spacer height='xs' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => setDrawerOpen(false)}
              className='xs'
              variant='outlined'
              color='primary'>
              Close
            </Button>
          </Row>
        </>
      )}
      <Spacer height='md' />
    </RightDrawer>
  );
};

export default LearnMoreAdviceDrawer;
