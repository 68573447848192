import { Typography } from '@mui/material';
import { Free3nuCarousel } from './Free3nuCarousel';
import { Free3nuHeaderCard } from './Free3nuHeaderCard';
import { Free3nuFooterCard } from './Free3nuFooterCard';
import { SubscriptionDetails } from '../SubscriptionDetails';

export const FreeWith3nuView: React.FC = () => {
  return (
    <>
      <Free3nuHeaderCard />

      <Typography mt={5} mb={3} variant='p18Bold' color='primary'>
        Here’s What You Get!
      </Typography>

      <Free3nuCarousel />

      <SubscriptionDetails
        style={{ padding: '38px', paddingBottom: '45px' }}
        buttonColor='secondary'
      />

      <Free3nuFooterCard />
    </>
  );
};
