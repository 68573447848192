import * as Yup from 'yup';
import { cleanWholeNumberStr } from '../helpers/utilityFunctions';
import { Domain } from '@3nickels/data-modules';
import dayjs from 'dayjs';

function useBasicGoalValidation() {
  const formSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    amount: Yup.string()
      .required('Amount is required')
      .test('amount', 'Amount must be greater than $0', (amount) => {
        const cleanNumber = cleanWholeNumberStr(amount);
        return cleanNumber > 0;
      }),
    targetDateType: Yup.string().required('Target Date Type is required'),
    targetDate: Yup.string()
      .notRequired()
      .when('targetDateType', {
        is: Domain.GoalDateTypeEnum.ChooseDate.toString(),
        then: (schema) =>
          schema
            .required('Target Date is required')
            .test('targetDate', 'Date must be in the future', (targetDate) =>
              dayjs(targetDate).isAfter(dayjs(), 'day')
            ),
      }),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatInputs = (values: any) => {
    const amount = cleanWholeNumberStr(values.amount);
    let targetDate;
    if (values.targetDateType === Domain.GoalDateTypeEnum.ChooseDate) {
      targetDate = dayjs(values.targetDate).format('YYYY-MM-DD');
    } else {
      targetDate = dayjs().format('YYYY-MM-DD');
    }

    return { amount, targetDate };
  };

  return { formSchema, formatInputs };
}

export default useBasicGoalValidation;
