import { IServiceContainer } from '@aesop-fables/containr';
import { AppStorage, ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';
import { Data } from '@3nickels/data-modules';

export async function incomeLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const shouldLoad$ = resolver.resolveSubject(Data.Income.LoadIncomeCondition);
  const appStorage = container.get<AppStorage>(ScriniumServices.AppStorage);
  const incomeDataCache = appStorage.retrieve<Data.Income.IncomeCompartments>(
    Data.Income.incomeStorageKey
  );
  const incomeListener = new Data.Income.IncomeDataListener(shouldLoad$, incomeDataCache);
  incomeListener.start();
  return json('income listener started');
}
