import { Data, Domain } from '@3nickels/data-modules';
import { useMutation, MutationStatus, useSubject } from '@aesop-fables/scrinium';
import { useEffect } from 'react';
import { useLoading } from '../useLoading';
import { useMessage } from '../useMessage';

export function useSaveDependent(
  editing?: boolean,
  cleanUp?: (dependents?: Domain.DependentData[]) => void
) {
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const dependents =
    useSubject<Domain.DependentData[]>(Data.People.PersonServices.DependentData) ?? [];

  const saveDependent = useMutation(new Data.People.Mutations.SaveDependent());

  useEffect(() => {
    if (saveDependent.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(`Dependent ${editing ? 'updated' : 'added'}!`, 'success');
      if (cleanUp) {
        cleanUp(dependents);
      }
    }
  }, [saveDependent.status]);

  return saveDependent;
}

export function useDeleteDependent(editing?: boolean, cleanUp?: () => void) {
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const deleteDependent = useMutation(new Data.People.Mutations.DeleteDependent());

  useEffect(() => {
    if (deleteDependent.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(`Dependent removed!`);
      if (cleanUp) {
        cleanUp();
      }
    }
  }, [deleteDependent.status]);

  return deleteDependent;
}
