/* eslint-disable @typescript-eslint/no-empty-function */
import { AlertColor } from '@mui/material';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface IMessage {
  content?: string;
  severity?: AlertColor;
  show: boolean;
}

export interface IMessageContext {
  message: IMessage;
  showMessage: (content?: string, severity?: AlertColor, timeout?: number) => void;
  hideMessage: () => void;
}

const MessageContext = createContext<IMessageContext>({
  message: { show: false },
  showMessage: () => {},
  hideMessage: () => {},
});

export const MessageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [message, setMessage] = useState<IMessage>({ show: false });
  const [scheduled, setScheduled] = useState<NodeJS.Timeout | undefined>();

  const hideMessage = () => {
    setMessage((previous) => {
      return { ...previous, show: false };
    });
    if (scheduled) {
      clearTimeout(scheduled);
      setScheduled(undefined);
    }
  };

  const showMessage = (content?: string, severity: AlertColor = 'success', timeout = 5000) => {
    if (scheduled) {
      clearTimeout(scheduled);
      setScheduled(undefined);
    }
    setMessage({ content, severity, show: true });
    if (timeout > 0) {
      const t = setTimeout(hideMessage, timeout);
      setScheduled(t);
    }
  };

  return (
    <MessageContext.Provider value={{ message, showMessage, hideMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export function useMessage() {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessage must be used within MessageProvider');
  }
  return context;
}
