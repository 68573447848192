import React from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { TextInputProps } from './TextInput';
import { cleanWholeNumberStr } from '../../helpers/utilityFunctions';
import { Typography } from '@mui/material';

export default function NumberInput<T>(props: TextInputProps<T>) {
  const [formattedValue, setFormattedValue] = React.useState<string>('');
  const methods = useFormContext();
  const enableDecimal = props.type === 'number' || props.type === 'percent';
  const endAdornment =
    props.type === 'percent' ? <Typography>%</Typography> : props.InputProps?.endAdornment;
  const max = props.max ?? (props.type === 'percent' ? 100 : Infinity);
  const min = props.min ?? 0;

  const numberHandler = (value: string) => {
    let returnNumber = '0';
    if (value.length === 0) {
      setFormattedValue('0');
      return returnNumber;
    }
    const number = cleanWholeNumberStr(value, {
      float: enableDecimal,
      precision: props.precision,
      max,
    });

    if (number === 0) {
      setFormattedValue('0');
    } else if (enableDecimal && value.endsWith('.') && number < max) {
      returnNumber = value;
      setFormattedValue(value);
    } else {
      returnNumber = number.toString();
      setFormattedValue(returnNumber);
    }

    return returnNumber;
  };

  const handleBlur = () => {
    const number = cleanWholeNumberStr(formattedValue, {
      float: enableDecimal,
      precision: props.precision,
      max,
    });
    let correctedValue = undefined;

    if (number < min) {
      correctedValue = min.toString();
      setFormattedValue(correctedValue);
    } else if (number > max) {
      correctedValue = max.toString();
      setFormattedValue(correctedValue);
    } else {
      correctedValue = formattedValue;
    }

    methods.setValue(props.name as string, correctedValue);
  };

  React.useEffect(() => {
    const number = numberHandler(props.defaultValue?.toString() ?? '');
    methods.setValue(props.name as string, number);
  }, [props.defaultValue]);

  React.useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === props.name) {
        // allow custom function to handle inputs with unconventional names
        // i.e. fieldArrays where convention is fieldArrayName.index.property
        const newVal = props.resolver ? props.resolver(values) : values[name].toString();
        numberHandler(newVal);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, props.name, numberHandler]);

  return (
    <TextField
      {...(props as TextFieldProps)}
      type={undefined}
      fullWidth
      size='small'
      variant='outlined'
      inputProps={{
        ...props.inputProps,
        ...{ color: 'secondary' },
        ...{
          style: {
            width: props.type === 'percent' ? formattedValue.length.toString() + 'ch' : undefined,
            color: props.type === 'percent' ? 'red !important' : undefined,
          },
        },
        ...(methods && methods.register ? { ...methods.register(props.name as string) } : {}),
      }}
      InputProps={{ ...props.InputProps, endAdornment }}
      defaultValue={undefined}
      value={formattedValue}
      onBlur={handleBlur}
    />
  );
}
