import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Svgs } from '../../../../assets/svg';
import { Spacer } from '../../../../components';
import { Carousel } from '../../../../components/Carousel';

export const FreeDefaultCarousel: React.FC = () => {
  return <Carousel items={items} itemWidth={135} />;
};

export const items = [
  <Card className='tools-carousel-card'>
    <Grid mt={1}>
      <Svgs.ArtRetirementSmall />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Plan for an early retirement
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid mt={-1.5}>
      <Svgs.ArtDebtSmall />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Pay off debt
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid mt={1}>
      <Svgs.ArtUmbrella />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Start a rainy day fund
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid
      container
      mb={-3}
      mt={2}
      style={{
        alignItems: 'flex-start',
      }}>
      <Svgs.ArtPieChart height='65%' />
    </Grid>

    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center' width='50%'>
      Learn investing
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid>
      <Svgs.ArtMoneySmall />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Analyze your spending
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid mt={1.5}>
      <Svgs.ArtBusiness />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Link your accounts
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Spacer height='xxs' />
    <Grid>
      <Svgs.ArtLightbulbMedium />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Learn at your own pace
    </Typography>
  </Card>,
];
