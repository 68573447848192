import { Button, FormGroup, Typography, Link, Divider, ButtonBase, Backdrop } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Column, VerticalFormInput, Row, Spacer } from '../components';
import { Svgs } from '../assets/svg';
import { logo3NSmall } from '../assets/png';
import { Form } from 'react-final-form';
import { SignUpLoginWizard, signUpLoginWizardKey } from '../services/signUpLogin';
import { useDeviceType } from '../hooks/useDeviceType';
import { mobileMargin } from '../App';
import { Toast } from '../models/Toast';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useService } from '@aesop-fables/containr-react';
import { useAuthenticationStatus } from '../hooks';
import { Loading } from '../hooks/useLoading';
import { useAppToasts } from '../AppToasts';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { Api } from '@3nickels/data-modules';
import { ADMIN_URL, AUTH_URL } from '../configuration/Environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const AppleID: any;

export const AppleLogin = () => {
  const authApi = useService<Api.AuthApi>(Api.ApiKeys.AuthApi);
  const [appleLoaded, setAppleLoaded] = useState(false);
  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const { isMobile, isTablet } = useDeviceType();

  // load apple auth
  useEffect(() => {
    const loadClientId = async () => {
      const { data } = await authApi.getAppleClientId();
      setClientId(data.webClientId);
    };
    loadClientId();
    const loadScript = document.createElement('script');

    loadScript.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    loadScript.addEventListener('load', () => setAppleLoaded(true));
    document.body.appendChild(loadScript);

    return () => {
      document.body.removeChild(loadScript);
    };
  }, [authApi]);

  useEffect(() => {
    if (appleLoaded && clientId) {
      const initScript = document.createElement('script');

      initScript.type = 'text/javascript';
      initScript.innerHTML = AppleID.auth.init({
        clientId,
        redirectURI: AUTH_URL + 'apple/web',
        scope: 'name email',
        state: Cookies.get('XSRF-TOKEN'),
        usePopup: false,
      });
    }
  }, [appleLoaded, clientId]);

  return isMobile || isTablet ? (
    <ButtonBase
      style={{ borderRadius: '50px', height: '40px', paddingTop: '5px', width: 'fit-content' }}>
      <div
        id='appleid-signin'
        data-color='black'
        data-border='true'
        data-border-radius='50'
        data-height='40'
        data-type='sign in'
      />
    </ButtonBase>
  ) : (
    <ButtonBase style={{ borderRadius: '50px', height: '40px', paddingTop: '5px' }}>
      <div
        id='appleid-signin'
        data-color='black'
        data-border='true'
        data-border-radius='50'
        data-width='250'
        data-height='40'
        data-mode='left-align'
        data-logo-size='large'
        data-label-position='50'
        data-type='sign in'
      />
    </ButtonBase>
  );
};

interface LoginData {
  username?: string;
  password?: string;
}

const MemberLogin = () => {
  const navigate = useNavigate();
  const { setToast } = useAppToasts();
  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);
  const { isMobile, isTablet } = useDeviceType();

  const onSubmit = async (values: LoginData) => {
    const { username, password } = values;
    if (username && password) {
      try {
        await signUpLoginWizard.saveLoginDataAndAttemptLogin(username ?? '', password ?? '');
        navigate('/two-factor-auth');
      } catch (err) {
        console.log(err);
        setToast(
          new Toast({
            message: 'We weren’t banking on that happening...please try again later.',
            open: true,
            severity: 'error',
          })
        );
      }
    }
  };

  const onValidateEmail = (value: string) => {
    return value ? undefined : 'Please enter an email';
  };

  const onValidatePassword = (value: string) => {
    return value ? undefined : 'Please enter a password';
  };

  const onForgotPassword = () => {
    navigate('/forgot-password');
  };

  const onCancel = () => {
    window.location.replace('https://3nickels.com');
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Column style={{ margin: isMobile ? mobileMargin : '15px 100px' }}>
            <img
              src={logo3NSmall}
              alt='logo'
              style={{ height: '55px', alignSelf: isMobile ? 'center' : 'flex-end' }}
            />
            <Row style={{ alignItems: 'center' }}>
              <Svgs.ArtLaptop height='75px' />
              <Spacer width='sm' />
              <Typography variant='p30Bold' color='primary'>
                Login
              </Typography>
            </Row>
            <Spacer height='xs' />
            {isMobile || isTablet ? (
              <Column>
                <Column>
                  <Typography variant='p20' color='secondary'>
                    Welcome back! Log in to your 3Nickels account.
                  </Typography>
                  <Spacer height='xs' />
                  <Typography className='link-wrapper' variant='h3' color='secondary'>
                    Are you an admin for your organization?&nbsp;
                    <Link href={ADMIN_URL}>Log in as an admin</Link>
                  </Typography>
                  <Spacer height='xs' />
                  <Row style={{ justifyContent: 'center' }}>
                    <AppleLogin />
                  </Row>
                  <Spacer height='xs' />
                  <Divider color='secondary' flexItem>
                    <Typography variant='p18Bold' color='secondary'>
                      OR
                    </Typography>
                  </Divider>
                  <Spacer height='xs' />
                  <FormGroup
                    style={{
                      alignSelf: 'center',
                      width: '100%',
                    }}>
                    <VerticalFormInput<LoginData>
                      fieldName='username'
                      name='username'
                      label='Email'
                      validate={onValidateEmail}
                    />
                    <Spacer height='md' />
                    <VerticalFormInput<LoginData>
                      fieldName='password'
                      name='password'
                      label='Password'
                      validate={onValidatePassword}
                    />
                  </FormGroup>
                  <Spacer height='sm' />
                  <Button onClick={onForgotPassword} className='ghostAlt'>
                    Forgot Password?
                  </Button>
                  <Spacer height='sm' />
                  <Typography className='link-wrapper' variant='h3' color='secondary'>
                    Don’t have an account?&nbsp;<Link href='/welcome'>Sign up</Link>
                  </Typography>
                  <Spacer height='sm' />
                  <Column style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                      type='submit'
                      onClick={handleSubmit}
                      style={{ width: '100%' }}
                      color='secondary'>
                      Log In
                    </Button>
                    <Spacer height='xs' />
                    <Button
                      onClick={onCancel}
                      style={{ width: '100%' }}
                      variant='outlined'
                      color='secondary'>
                      Cancel
                    </Button>
                    <Spacer height='lg' />
                  </Column>
                </Column>
              </Column>
            ) : (
              <Row>
                <Column>
                  <Typography variant='p20' color='secondary'>
                    Welcome back! Log in to your 3Nickels account.
                  </Typography>
                  <Spacer height='xs' />
                  <Typography className='link-wrapper' variant='h3' color='secondary'>
                    Are you an admin for your organization?{' '}
                    <Link href={ADMIN_URL}>Log in as an admin</Link>
                  </Typography>
                  <Spacer height='sm' />

                  <FormGroup
                    style={{
                      alignSelf: 'center',
                      width: '100%',
                    }}>
                    <VerticalFormInput<LoginData>
                      fieldName='username'
                      label='Email'
                      autoFocus
                      validate={onValidateEmail}
                    />
                    <Spacer height='md' />
                    <VerticalFormInput<LoginData>
                      fieldName='password'
                      label='Password'
                      validate={onValidatePassword}
                    />
                  </FormGroup>
                  <Spacer height='xs' />
                  <Button onClick={onForgotPassword} className='ghostAlt'>
                    Forgot Password?
                  </Button>
                  <Spacer height='xs' />
                  <Typography className='link-wrapper' variant='h3' color='secondary'>
                    Don’t have an account?&nbsp;<Link href='/welcome'>Sign up</Link>
                  </Typography>
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Button onClick={onCancel} variant='outlined' color='secondary'>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      color='secondary'
                      type='submit'
                      style={{ alignSelf: 'flex-end' }}>
                      Log In
                    </Button>
                  </Row>
                </Column>
                <Divider
                  style={{ margin: '0px 75px' }}
                  color='secondary'
                  orientation='vertical'
                  flexItem>
                  <Typography variant='p18Bold' color='secondary'>
                    OR
                  </Typography>
                </Divider>
                <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='p18Bold' color='secondary'>
                    Log in another way
                  </Typography>
                  <Spacer height='sm' />
                  <AppleLogin />
                </Column>
              </Row>
            )}
          </Column>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

const MemberLoginGuard: React.FC = () => {
  const { isReady } = useAuthenticationStatus();
  if (!isReady) {
    return (
      <Backdrop open={true}>
        <Loading />
      </Backdrop>
    );
  }

  return <MemberLogin />;
};

export default MemberLoginGuard;
