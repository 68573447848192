import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Svgs } from '../../assets/svg';
import { Spacer } from '../../components';
import { magnifyingGlass } from '../../assets/png';
import { Carousel } from '../../components/Carousel';

export const ToolsCarousel: React.FC = () => {
  return <Carousel items={items} itemWidth={135} />;
};

export const items = [
  <Card className='tools-carousel-card'>
    <Grid>
      <Svgs.ArtRetirementSmall />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Check your IRA for fees
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid>
      <Svgs.ArtDebtSmall />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Know the real cost of debt
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid>
      <Svgs.ArtUmbrella />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Decipher insurance
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid
      container
      mb={-2}
      mt={1}
      style={{
        alignItems: 'flex-start',
      }}>
      <Svgs.ArtPieChart height='65%' />
    </Grid>

    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center' width='50%'>
      Learn investing
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid>
      <Svgs.ArtCollegeGradCap />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Plan for college costs
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Grid>
      <Svgs.ArtCar />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Learn the car-buying process
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Spacer height='xxs' />
    <Grid>
      <Svgs.ArtHouse />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Find mortgage rates
    </Typography>
  </Card>,

  <Card className='tools-carousel-card'>
    <Spacer height='xxs' />
    <Grid>
      <img src={magnifyingGlass} />
    </Grid>
    <Spacer height='xxxs' />
    <Typography variant='p14' color='secondary' textAlign='center'>
      Search for a charity
    </Typography>
  </Card>,
];
