import { type SystemStyleObject, type Theme } from '@mui/system';
import { Full } from './percentages';
import { CSSProperties } from '@mui/material/styles/createMixins';
// eslint-disable-next-line no-restricted-imports
import { Colors } from '.';

export const Fullscreen: CSSProperties = {
  height: Full,
  width: Full,
};

export const FullscreenView: CSSProperties = {
  height: '100vh',
  width: '100vw',
  minHeight: Full,
  overflowY: 'auto',
};

export const BackgroundImage: CSSProperties = {
  backgroundImage: 'url("/images/hexagonBackgroundFaded.png")',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
};

export const EmbeddedListItem: CSSProperties = {
  padding: 2,
  borderRadius: 2,
  border: `1px solid ${Colors.primaryLightest}`,
  height: 47,
  alignContent: 'center',
};

export const TextFieldStyles: Record<string, SystemStyleObject<Theme>> = {
  centeredTextAndPlaceholder: {
    'textAlign': 'center',
    '& input::placeholder': {
      textAlign: 'center',
    },
    '& input': {
      textAlign: 'center',
    },
  },
  borderRadius_10: {
    '& fieldset': {
      borderRadius: 10,
    },
  },
};

export const DrawerWidth = '40%';

export const LightBackgroundCardRoundedCorners: CSSProperties = {
  borderRadius: '12px',
  backgroundColor: Colors.primaryLightest,
  padding: '10px 15px',
};

export const HexGridContainer: CSSProperties = {
  'flexWrap': 'wrap',
  'display': 'flex',
  'width': '90%',
  'margin': '0 auto',
  'paddingLeft': '25px',
  'overflow': 'hidden',
  'listStyleType': 'none',
  '&.nested': {
    marginTop: '-140px',
  },
  '& .content-wrapper': {
    'filter': 'drop-shadow(-1px 6px 8px rgba(30, 41, 105, 0.5))',
    '&:nth-child(odd)': {
      marginTop: '133px',
    },
  },
};

// #1b7690
export const HexGridItemContainer: CSSProperties = {
  'position': 'relative',
  'background': '#1b7690',
  'height': '146px',
  'width': '127px',
  'boxShadow': '6px 6px 17px 0 #000',
  //'overflow': 'hidden',
  'transform': 'rotate(90deg)',
  'clipPath': 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
  '&::after': {
    content: '""',
    display: 'block',
    paddingBottom: '90%',
  },
  '& .content': {
    cursor: 'pointer',
    width: '121px',
    height: '140px',
    background: '#fff',
    position: 'absolute',
    top: '3.2px',
    left: '2.7px',
    clipPath: 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
  },
  '& .content:hover': {
    background: Colors.disabledLight,
  },
  '& .icon, .text': {
    position: 'absolute',
    transform: 'rotate(-90deg)',
  },
  '& .icon': {
    top: '32%',
    left: '15%',
  },
  '& .text': {
    top: '36%',
    left: '34%',
    width: '87px',
    height: '39px',
    textAlign: 'center',
  },
};

export const ShowGroupDeleteOnHover: CSSProperties = {
  '& .group-edit-delete': { 'opacity': 0, 'transition': 'opacity 1s', '& button': { padding: 0 } },
  '&:hover': {
    '& .group-edit-delete': { opacity: 1 },
  },
};

export const ShowOnHover: CSSProperties = {
  '& .show-on-hover': { 'opacity': 0, 'transition': 'opacity 1s', '& button': { padding: 0 } },
  '&:hover': {
    '& .show-on-hover': { opacity: 1 },
  },
};
