import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hooks } from '@3nickels/data-modules';
import { Box, Typography } from '@mui/material';
import ContentView from '../layout/Content';
import ContactOptions from './ContactOptions';
import MessageDrawer from './MessageDrawer';
import { useContentView } from '../layout/ContentViewContext';

const ContactUsView: React.FC = () => {
  const { t } = useTranslation();
  const { featureMap } = Hooks.usePrincipalUser();
  const [open, setOpen] = useState(false);
  const { sideNav } = useContentView();

  return (
    <ContentView sideNavMenuItems={sideNav('ContactUs')}>
      <Box>
        <Typography variant='h1' color='primary'>
          {t('ContactUs')}
        </Typography>
        <MessageDrawer open={open} onClose={() => setOpen(false)} />
        <ContactOptions featureMap={featureMap} setOpen={setOpen} />
      </Box>
    </ContentView>
  );
};

export default ContactUsView;
