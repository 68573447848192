/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, forwardRef, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Backdrop, Card, Grid, IconButton, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { GoalsLayoutMeta } from './GoalsLayout';
import { useDeleteGoal } from '../../hooks/useMutations/useGoalsMutations';
import { Svgs } from '../../assets/svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MutationStatus, useMutation } from '@aesop-fables/scrinium';
import { throttle } from 'lodash';
import { Loading } from '../../hooks/useLoading';
import Modal from '../../components/Modal';
import Spacer from '../../components/Spacer';
import { HolisticGoalDisplay } from '@3nickels/data-modules/lib/domain';
import { GoalManager, useGoalManager } from './GoalDrawerManager';
import { useGoalDrawerRoutes } from './GoalDrawerRoutes';

export const GoalBannerIcon: React.FC<Domain.HolisticGoalDisplay> = (item) => {
  if (!item) {
    return null;
  }

  if (item.type === Domain.GoalTypeEnum.Car) {
    return <Svgs.DisplaySmallCustomCar />;
  }

  if (item.type === Domain.GoalTypeEnum.Cash_Giving) {
    return <Svgs.DisplaySmallCustomCoins />;
  }

  if (item.type === Domain.GoalTypeEnum.College) {
    return <Svgs.IconColllege />;
  }

  if (item.type === Domain.GoalTypeEnum['Emergency Fund']) {
    return <Svgs.DisplaySmallCustomUmbrella />;
  }

  if (item.type === Domain.GoalTypeEnum.Gift || item.type === Domain.GoalTypeEnum.Volunteer) {
    return <Svgs.DisplaySmallCustomGifts />;
  }

  if (item.type === Domain.GoalTypeEnum.House || item.type === Domain.GoalTypeEnum.Mortgage) {
    return <Svgs.DisplaySmallCustomHouse />;
  }

  if (item.type === Domain.GoalTypeEnum.Non_Mortgage_Debt) {
    return <Svgs.DisplaySmallCustomDebt />;
  }

  if (item.type === Domain.GoalTypeEnum.Vacation) {
    return <Svgs.DisplaySmallCustomVacation />;
  }

  if (item.type === Domain.GoalTypeEnum.Other) {
    return <Svgs.DisplaySmallCustomPiggyBank />;
  }

  if (item.type === Domain.GoalTypeEnum.Retirement) {
    return <Svgs.DisplaySmallCustomRetirement />;
  }

  return null;
};

declare type SortableGoalsProps = {
  goals: HolisticGoalDisplay[];
};

const SortableGoals = forwardRef<HTMLUListElement, PropsWithChildren>(({ children }, ref) => {
  return (
    <Box ref={ref}>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant='p16Bold' color='primary'>
            Priority
          </Typography>
        </Grid>
        <Grid item xs={5} alignSelf='flex-start'>
          <Typography variant='p16Bold' color='primary'>
            Name
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='p16Bold' color='primary'>
            Type
          </Typography>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
      </Grid>
      <Grid container justifyContent='center' flexDirection='column' mt={2}>
        {children}
      </Grid>
    </Box>
  );
});

const GoalListItem = forwardRef<HTMLElement, any>(
  ({ goal, dragHandleProps, snapshot, onDelete, ...props }, ref) => {
    const [menuIsClicked, setMenuIsClicked] = useState(false);
    // const editGoal = useGoalEditor();
    const { selectGoal } = useGoalManager();

    const editGoal = useCallback(() => {
      selectGoal(goal);
    }, [goal, selectGoal]);

    const handleClose = (event: unknown) => {
      setMenuIsClicked(false);
      (event as React.MouseEvent<HTMLElement>).stopPropagation();
    };

    return (
      <Grid item ref={ref} {...props} sx={{ marginTop: '5px' }}>
        <Card
          className={`goal-list-item${menuIsClicked || snapshot?.isDragging ? ' clicked' : ''}`}
          sx={{ padding: '10px' }}>
          <Grid container>
            <Grid item xs={1} alignSelf='center' paddingLeft={1}>
              <Typography variant='p16' color='secondary'>
                <span className='hide-on-hover'>{goal.priority}</span>
                <IconButton
                  className={'show-on-hover'}
                  sx={{ opacity: 0 }}
                  {...dragHandleProps}
                  disableRipple>
                  {<Svgs.WebIconGrip />}
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={1} alignSelf='center'>
              <GoalBannerIcon {...goal} />
            </Grid>
            <Grid item xs={5} alignSelf='center'>
              <Typography
                className='hover-clickable'
                variant='p16Bold'
                color='secondary'
                onClick={() => editGoal()}
                style={{
                  width: '90%',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {goal.name}
              </Typography>
            </Grid>
            <Grid item xs={4} alignSelf='center'>
              <Typography variant='p16' color='secondary'>
                {Domain.GoalTypeLabels[goal.type as Domain.GoalTypeEnum]}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {/* TODO -- Come back to this */}
              {goal.canRemove && goal.type !== Domain.GoalTypeEnum.Volunteer && (
                <IconButton
                  className={'show-on-hover'}
                  sx={{ opacity: 0 }}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    onDelete(goal);
                    handleClose(event);
                  }}
                  disableRipple>
                  {<Svgs.IpmIconDelete />}
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
);

const SortableGoalList: React.FC<SortableGoalsProps> = ({ goals }) => {
  const [sortedGoals, setSortedGoals] = useState<Domain.HolisticGoalDisplay[] | undefined>(
    undefined
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState<Domain.HolisticGoalDisplay | undefined>(undefined);
  const deleteGoal = useDeleteGoal();

  useEffect(() => {
    setSortedGoals(
      goals.map((goal, index) => {
        return {
          ...goal,
          priority: index + 1,
        };
      })
    );
  }, [goals]);

  const mutation = useMutation(new Data.Goals.UpdateGoalPriorities());
  const throttledHandler = React.useMemo(() => throttle(mutation.action, 250), [mutation.action]);

  const confirmDelete = (goal: HolisticGoalDisplay) => {
    setSelected(goal);
    setModalOpen(true);
  };

  const dragEnded = (param: any) => {
    const { source, destination } = param;

    const newGoals = [...(sortedGoals ?? [])];
    //extracting the source item from the list
    const item = newGoals.splice(source.index, 1)[0];
    //inserting it at the destination index.
    newGoals.splice(destination.index, 0, item);

    setSortedGoals(
      newGoals.map((goal, index) => ({
        ...goal,
        priority: index + 1,
      }))
    );

    // queue up the throttled save
    throttledHandler(
      newGoals.map((x, index) => ({
        id: x.id,
        priority: index + 1,
      }))
    );
  };

  const goalRoutes = useGoalDrawerRoutes();
  return (
    <DragDropContext onDragEnd={dragEnded}>
      <Backdrop open={mutation.status === MutationStatus.Executing}>
        <Loading />
      </Backdrop>
      <Modal
        title='Remove Goal?'
        primaryButtonText='No, Keep Goal'
        secondaryButtonText='Yes, Remove Goal'
        swapButtonFunctionality
        open={modalOpen}
        setOpen={setModalOpen}
        handleSave={() => {
          if (selected) {
            deleteGoal.action({ id: selected?.id, type: selected?.type });
          }
        }}>
        <Typography variant='p16' color='secondary'>
          This will remove all of the information for this goal. Are you sure you want to do that?
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p16Bold' color='secondary'>
          {selected?.name}
        </Typography>
        <Typography variant='p16Bold' color='secondary'>
          {Domain.GoalTypeLabels[selected?.type as Domain.GoalTypeEnum]}
        </Typography>
      </Modal>
      <GoalManager routes={goalRoutes}>
        <Droppable droppableId='goals-wrapper'>
          {(provided) => {
            return (
              <SortableGoals ref={provided.innerRef} {...provided.droppableProps}>
                {sortedGoals &&
                  sortedGoals.map((goal, i) => {
                    return (
                      <Draggable
                        draggableId={goal.id?.toString() as string}
                        index={i}
                        key={goal.id}>
                        {(_provided, _snapshot) => (
                          <GoalListItem
                            goal={goal}
                            style={{
                              padding: '10px',
                              border: '1px solid black',
                              marginTop: '5px',
                            }}
                            ref={_provided.innerRef}
                            onDelete={confirmDelete}
                            dragHandleProps={_provided.dragHandleProps}
                            {..._provided.draggableProps}
                            snapshot={_snapshot}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </SortableGoals>
            );
          }}
        </Droppable>
      </GoalManager>
    </DragDropContext>
  );
};

export const GoalListScreen: React.FC = () => {
  const goals = Hooks.useFlattenedGoalData();

  return (
    <Box>
      <Box>
        <Typography className='title' variant='h1' component='h1' color='secondary'>
          Goals
        </Typography>
      </Box>
      <Box mt={2}>
        <Grid item>
          {typeof goals !== 'undefined' && goals.length !== 0 && <SortableGoalList goals={goals} />}
        </Grid>
      </Box>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  headerVariant: 'addGoal',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(GoalListScreen, meta);
