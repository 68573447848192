import * as React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { type AlertColor, type AlertProps } from '@mui/material/Alert';
import { createContext, useState } from 'react';

export type ToastContext = {
  current: Toast;
  displayToast: (data: ToastData) => void;
};

// ToastData update these once we have designs for them
const defaultMessages = {
  success: 'Success',
  error: 'Error',
  warning: 'Warning',
  info: 'Info',
  empty: undefined,
};

export type ToastData = {
  open: boolean;
  severity?: AlertColor;
  message?: string;
  autoHideDuration?: number;
};

export class Toast implements ToastData {
  open: boolean;

  severity?: AlertColor;

  message?: string;

  autoHideDuration?: number;

  constructor(data: ToastData) {
    this.open = data.open;
    this.severity = data.severity;
    this.message = data.message ?? defaultMessages[this.severity ?? 'empty'];
    this.autoHideDuration = data.autoHideDuration;
  }
}

// When you want to call a toast you must call:
// const { setToast } = useAppToasts();
// setToast(new Toast({ message: '<your message here>', open: true, severity: '<yourSeverity>', autoHideDuration: <someNumber> }));

// Tips:
// autoHideDuration will be 6000 milliseconds by default
// message will have a default message based on supplied severity type
// if you dont specify severity, no toast will appear.

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
));

type ToastBarProps = {
  open: boolean;
  severity?: AlertColor;
  message?: string;
  autoHideDuration?: number;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

const ToastBar: React.FC<ToastBarProps> = ({
  open,
  severity,
  message,
  autoHideDuration,
  handleClose,
}) => {
  if (!severity) {
    // To prevent it flashing green when the toast goes away. Mui sets it to success by default
    return null;
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration || 6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert className='toast-bar' onClose={handleClose} severity={severity}>
        {message || ''}
      </Alert>
    </Snackbar>
  );
};

export const ToastbarContext = createContext<ToastContext>({
  current: new Toast({ open: false }),
  displayToast() {
    // No-op
  },
});

export const ToastbarProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [toast, setToast] = useState(new Toast({ open: false }));
  const displayToast = (data: ToastData) => {
    setToast(new Toast(data));
  };

  return (
    <ToastbarContext.Provider value={{ displayToast, current: toast }}>
      {children}
    </ToastbarContext.Provider>
  );
};

export default ToastBar;
