import { useTranslation } from 'react-i18next';
import { LayoutContext } from '../../../../types/LayoutMeta';
import { Backdrop, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spacer } from '../../../../components';
import ButtonHeader from '../../headers/ButtonHeader';
import InstitutionalAccountCard from './InstitutionalAccountCard';
import { useObservable } from '@aesop-fables/scrinium';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { usePlaidLink } from 'react-plaid-link';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PlaidWaitScreen } from '../PlaidWaitScreen';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { firstValueFrom } from 'rxjs';
import { usePlaidCredentialRefresh } from '../../../../hooks/usePlaidCredentialRefresh';

const ChooseInstitutionViewWrapper: React.FC = () => {
  const wizard = Hooks.usePlaidWizard();
  const navigate = useNavigate();
  const location = useLocation();
  const summaryUrl = location.state?.summaryUrl;
  const loading = useObservable(wizard.loading$) ?? true;
  const institutions = useObservable(wizard.institutions$);
  const context = Hooks.usePlaidContext();

  useEffect(() => {
    if (!loading && typeof context.token === 'undefined') {
      wizard.generateLinkToken();
    }
  }, [loading, context.token]);

  useEffect(() => {
    if (!loading && context.accountTypes.length === 0 && !context.account) {
      navigate(`/account-details/link-account/choose-owner`);
    }
  }, [loading, context.accountTypes]);

  if (loading || !context.token || typeof institutions === 'undefined') {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  const plaidCompleteHandler = async () => {
    const ctx = await firstValueFrom(wizard.context$);
    if (ctx.account) {
      navigate(`/account-details/link-account/manual-import`, {
        state: { summaryUrl: summaryUrl, hideBack: true },
      });
    } else {
      navigate(`/account-details/link-account/import-account`);
    }
  };

  const autoLinkJob = context.job;
  if (typeof autoLinkJob !== 'undefined') {
    return <PlaidWaitScreen autoLinkJob={autoLinkJob} onComplete={plaidCompleteHandler} />;
  }

  return <ChooseInstitutionView institutions={institutions} linkToken={context.token} />;
};

declare type PlaidInstitutionCardProps = {
  institution: Domain.PlaidInstitution;
  onClick: (institution: Domain.PlaidInstitution) => void;
};

const PlaidInstitutionCard: React.FC<PlaidInstitutionCardProps> = ({ institution, onClick }) => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const unlinkHandler = async (institution: Domain.PlaidInstitution) => {
    try {
      setLoading(true);
      await commands.execute(Data.Plaid.Commands.UnlinkPlaidInstitution, {
        accessId: institution.accessId,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateLoginHandler = usePlaidCredentialRefresh(institution.accessId);

  const actions = useMemo(() => {
    const list = [];
    if (institution.requiresLogin === true) {
      list.push({
        textKey: 'UpdateLogin',
        onClick: updateLoginHandler,
      });
    }

    list.push({
      textKey: 'UnlinkInstitution',
      onClick: () => {
        unlinkHandler(institution);
      },
    });

    return list;
  }, [institution]);

  return (
    <InstitutionalAccountCard
      key={`${institution.accessId}:${institution.institutionId}`}
      onClick={onClick}
      institution={institution}
      actions={actions}
    />
  );
};

export interface ChooseInstitutionViewProps {
  linkToken: string;
  institutions: Domain.PlaidInstitution[];
}

const ChooseInstitutionView: React.FC<ChooseInstitutionViewProps> = ({
  institutions,
  linkToken,
}) => {
  const { t } = useTranslation();
  const wizard = Hooks.usePlaidWizard();
  const context = Hooks.usePlaidContext();
  const navigate = useNavigate();
  const location = useLocation();
  const summaryUrl = location.state?.summaryUrl;

  const { open, ready } = usePlaidLink({
    onSuccess: async (public_token: string) => {
      const ctx = await firstValueFrom(wizard.context$);
      if (ctx.account) {
        wizard.startManualLinkJob(
          public_token,
          context.filter?.filter ?? Domain.FinancialAccountTypeEnum.InvestmentAccount
        );
      } else {
        wizard.startAutoLinkJob(public_token);
      }
    },
    onExit: (err) => {
      if (err) {
        console.error(err);
      }
    },
    token: linkToken,
  });

  const [opened, setOpened] = useState(false);
  useEffect(() => {
    if (ready && !opened && institutions.length === 0) {
      open();
      setOpened(true);
    }
  }, [opened, setOpened, open, ready, institutions]);

  const linkInstitutionHandler = useCallback(() => {
    if (ready) {
      open();
    }
  }, [open, ready, context]);

  const { setMeta } = useOutletContext<LayoutContext>();
  useEffect(() => {
    setMeta({
      showBack: true,
      showNext: false,
      header: (
        <ButtonHeader
          disabled={!ready}
          text={t('LinkNewInstitution')}
          onClick={linkInstitutionHandler}
        />
      ),
    });
  }, [ready]);

  const institutionClickedHandler = async (institution: Domain.PlaidInstitution) => {
    // setLoading(true);
    try {
      await wizard.selectInstitution(institution.accessId);

      if (!context.account) {
        navigate(`/account-details/link-account/import-account`);
      } else {
        navigate(`/account-details/link-account/manual-import`, {
          state: { summaryUrl: summaryUrl },
        });
      }
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Grid container flexDirection='column'>
      <Typography className='title' variant='h1' component='h1' color='primary'>
        {t('ChooseAnInstitution')}
      </Typography>
      <Typography className='subtitle' color='secondary'>
        {t('ChooseSavedFinancialInstitution')}
      </Typography>
      <Spacer height='xs' />
      <Grid flexDirection='row' container justifyContent='space-around' sm={9} marginLeft={9}>
        {institutions.map((institution) => {
          return (
            <PlaidInstitutionCard
              key={`${institution.accessId}:${institution.institutionId}`}
              onClick={institutionClickedHandler}
              institution={institution}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ChooseInstitutionViewWrapper;
