import {
  ConfiguredDataSource,
  DataCache,
  DataCompartmentOptions,
  IAppStorage,
  ISubject,
  ScriniumServices,
  createDataCache,
} from '@aesop-fables/scrinium';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { PricebookApi } from '../../api/apis/PricebookApi';
import { PricebookBundle } from '../../models/PricebookData';
import { IServiceContainer, inject } from '@aesop-fables/containr';
import { Authentication, Data } from '@3nickels/data-modules';
import { Observable } from 'rxjs';
import { namespacedService } from '../../utils';

export const PricebookServices = {
  AvailablePricebooks: namespacedService('pricebook/subjects/AvailablePricebooks'),
};

export interface PricebookCompartments {
  pricebookOptions: DataCompartmentOptions<PricebookBundle[] | undefined>;
}

export const pricebookStorageKey = 'data/pricebook';

export const withPricebookData = Data.create3NickelsDataCacheModule<PricebookCompartments>(
  pricebookStorageKey,
  (authContext: Authentication.IAuthenticationContext, container: IServiceContainer) => {
    const pricebookApi = container.get<PricebookApi>(ApiKeys.Pricebook);
    return createDataCache<PricebookCompartments>({
      pricebookOptions: {
        source: new ConfiguredDataSource(async () => {
          const { data } = await pricebookApi.getAvailable();
          return data;
        }),
        defaultValue: undefined,
      },
    });
  }
);

export class AvailablePricebooks implements ISubject<PricebookBundle[]> {
  private readonly cache: DataCache<PricebookCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly appStorage: IAppStorage) {
    this.cache = this.appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  }
  createObservable(): Observable<PricebookBundle[]> {
    return this.cache.observe$<PricebookBundle[]>('pricebookOptions');
  }
}
