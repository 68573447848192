import { useCallback } from 'react';
import { Domain, Hooks } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { useAppToasts } from '../../AppToasts';

export function useGoalEditor() {
  const navigateFn = useNavigate();
  const { showError } = useAppToasts();
  const { wizard: basicGoalWizard } = Hooks.useBasicGoalWizard();
  const { wizard: collegeGoalWizard } = Hooks.useCollegeGoalWizard();
  const { wizard: retirementGoalWizard } = Hooks.useRetirementGoalWizard();
  const emergencyGoal = Hooks.useEmergencyFundGoal();
  const retirementGoal = Hooks.useRetirementGoal();

  return useCallback(
    async (type: Domain.GoalTypeEnum) => {
      const navigate = (url: string) => {
        navigateFn(`${url}`);
      };

      try {
        switch (type) {
          case Domain.GoalTypeEnum.Car:
            await basicGoalWizard.start({ type: Domain.GoalTypeEnum.Car });
            navigate('/goals/car');
            break;
          case Domain.GoalTypeEnum.College:
            await collegeGoalWizard.start({});
            navigate('/goals/college/basics');
            break;
          case Domain.GoalTypeEnum.Gift:
            navigate('/goals/giving');
            break;
          case Domain.GoalTypeEnum.House:
            navigate('/goals/house');
            break;
          case Domain.GoalTypeEnum.Other:
            await basicGoalWizard.start({ type: Domain.GoalTypeEnum.Other });
            navigate('/goals/other');
            break;
          case Domain.GoalTypeEnum.Vacation:
            await basicGoalWizard.start({ type: Domain.GoalTypeEnum.Vacation });
            navigate('/goals/vacation');
            break;
          case Domain.GoalTypeEnum.Volunteer:
            navigate('/goals/giving/volunteering');
            break;
          case Domain.GoalTypeEnum.Non_Mortgage_Debt:
            navigate('/goals/debt');
            break;
          case Domain.GoalTypeEnum['Emergency Fund']:
            await basicGoalWizard.start({
              type: Domain.GoalTypeEnum['Emergency Fund'],
            });
            navigate('/goals/emergency');
            break;
          case Domain.GoalTypeEnum.Retirement:
            await retirementGoalWizard.start({ id: retirementGoal.value?.id });
            navigate('/goals/retirement');
            break;
        }
      } catch (err) {
        console.log(err);
        // This is just here temporarily
        showError('Could not load goal');
      }
    },
    [emergencyGoal, retirementGoal, retirementGoalWizard, basicGoalWizard, collegeGoalWizard]
  );
}
