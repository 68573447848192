import { useNavigate } from 'react-router-dom';
import { getWizardFooterProps } from '../../../EditViewUtils';
import { Loading } from '../../../../hooks/useLoading';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import LinkAccountHeader from '../../headers/LinkAccountHeader';
import { Backdrop, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Spacing } from '../../../../themes';
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LinkAccountFormData } from '../Types';
import AccountOwnerForm from './AccountOwnerForm';
import { useForm } from 'react-hook-form';
import FormContent from '../../../../components/form/FormContent';
import { Domain, Hooks } from '@3nickels/data-modules';
import { useObservable } from '@aesop-fables/scrinium';

const ownerSchema = Yup.object({
  owner: Yup.string().required('Owner is required'),
});

declare type AccountOwnerEditViewProps = {
  owner: Domain.Owner;
};

const AccountOwnerEditViewWrapper: React.FC = () => {
  const wizard = Hooks.usePlaidWizard();
  const context = useObservable(wizard.context$);
  const { includeSpouse } = Hooks.useCombinedSelfData();
  const navigate = useNavigate();

  useEffect(() => {
    if (includeSpouse === false) {
      navigate(`/account-details`);
    }
  }, [includeSpouse]);

  if (typeof includeSpouse === 'undefined' || typeof context?.owner === 'undefined') {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  return <AccountOwnerEditView owner={context?.owner} />;
};

const AccountOwnerEditView: React.FC<AccountOwnerEditViewProps> = ({ owner }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const wizard = Hooks.usePlaidWizard();

  const onSubmit = async (values: LinkAccountFormData) => {
    wizard.chooseOwner(values.owner as Domain.Owner);
    navigate(`/account-details/link-account/account-type`);
  };

  const methods = useForm<LinkAccountFormData>({
    defaultValues: { owner },
    resolver: yupResolver(ownerSchema),
  });

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container flexDirection='column'>
          <Typography className='title' variant='h1' component='h1' color='primary'>
            {t('ChooseAccountOwner')}
          </Typography>
          <Typography className='subtitle' color='secondary'>
            {t('ToGiveBestAdviceWhoOwnsAccount')}
          </Typography>
          <Grid container justifyContent='center'>
            <Grid item pt={Spacing.xxxs + 1} sm={6}>
              <AccountOwnerForm onSubmit={onSubmit} owner={owner} />
            </Grid>
            <WizardFooter
              // disableNext={typeof needsAttention !== 'undefined'}
              {...getWizardFooterProps(meta.nextLocaleKey)}
              // onDone={() => navigate('/account-details/link-account/institutions')}
            />
          </Grid>
        </Grid>
      </form>
    </FormContent>
  );
};

const meta = {
  nextLocaleKey: 'Continue',
  header: <LinkAccountHeader />,
} satisfies LayoutMeta;

export default withLayoutMeta(AccountOwnerEditViewWrapper, meta);
