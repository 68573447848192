/* tslint:disable */
import { LoaderFunction } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { commonRoutes } from './CommonRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { wireUpLoaders } from './utils';
import FreeView from '../pages/free/home/FreeView';
import { twoFactorLoader } from './loaders/TwoFactorLoader';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';

export const freeRoutes: RouteObject[] = [
  {
    path: '/',
    element: <FreeView />,
  },
  ...commonRoutes,
];

export function createFreeRoutes(container: IServiceContainer) {
  const loaders = new Map<string, LoaderFunction>();
  loaders.set('/', async () => {
    await appReadyLoader(container);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });
  loaders.set('settings/update-password', () => authTokenCookieLoader(container));
  loaders.set('settings/two-factor-authentication', () => twoFactorLoader(container));

  return wireUpLoaders(freeRoutes, loaders);
}
