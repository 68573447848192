/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { Svgs } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import InfoCard from '../../../components/InfoCard';
import { WizardFooter } from '../../../components/form/WizardFooter';
import useAdviceResultsNextInvestmentAccount from '../../../hooks/useAdviceResultsNextInvestmentAccount';
import { Hooks } from '@3nickels/data-modules';
import { useBackHandler } from '../progress-indicator/useBackHandler';

const OpenBrokerageAccountView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToNext = useAdviceResultsNextInvestmentAccount();
  const holisticAdvice = Hooks.useHolisticAdviceOverview();

  const backHandler = useBackHandler(holisticAdvice, 'investment', 2);

  const nextId = useMemo(() => {
    // id === 2 means open a brokerage account
    const index = holisticAdvice?.investmentMixes.findIndex((x) => x.id === 2);

    if (typeof index === 'undefined' || index < 0) return undefined;

    const next = holisticAdvice?.investmentMixes[index + 1];

    return next?.id;
  }, [holisticAdvice?.investmentMixes]);
  const nextLabel = nextId ? 'Next Account' : 'Next: My Plan';

  const openAnAccount = () => {
    navigate('/holistic-advice/investments/open-an-account');
  };

  const shopAccountOptions = () => {
    navigate('/holistic-advice/investments/shop-accounts');
  };

  const onDone = () => {
    navigateToNext(nextId);
  };

  return (
    <Box>
      <Grid container flexWrap='nowrap' justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography className='title' color='primary' component='h1' variant='h1'>
            {t('OpenBrokerageAccount')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('GetTheMoneyYoureSavingWorking')}
          </Typography>
          <Typography color='secondary' variant='p18Bold' mt='40px'>
            {t('WhatsABrokerageAccount?')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('ABrokerageAccountIsAFinancialAccount')}
          </Typography>
        </Grid>
        <Grid sm={6} md={10} display='flex' justifyContent='center'>
          <Svgs.ArtPiggyBank />
        </Grid>
      </Grid>
      <Typography color='secondary' variant='p18Bold' mt='40px'>
        {t('WhyAreWeRecommendingOne?')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('CheckingAndSavingsAccountsRarelyKeepUp')}
      </Typography>
      <Grid container className='brokerage-account-info-cards'>
        <InfoCard onClick={openAnAccount}>
          <Typography variant='p16Bold' color='primary'>
            {t('HowToOpenAnAccount')}
          </Typography>
        </InfoCard>
        <InfoCard onClick={shopAccountOptions}>
          <Typography variant='p16Bold' color='primary'>
            {t('ShopBrokerageAccounts')}
          </Typography>
        </InfoCard>
      </Grid>
      <WizardFooter nextLabel={nextLabel} onDone={onDone} onBack={backHandler} />
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'NextAccount',
  showProgress: true,
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(OpenBrokerageAccountView, meta);
