import { Card, Grid } from '@mui/material';
import { Svgs } from '../assets/svg';
import { PropsWithChildren } from 'react';

interface InfoCardProps extends PropsWithChildren {
  renderIcon?: boolean;
  onClick?: () => void;
}

const InfoCard: React.FC<InfoCardProps> = ({ onClick, children, ...props }) => {
  const renderIcon = props.renderIcon ?? true;
  return (
    <Grid className='info-card-border info-card' onClick={onClick}>
      <Card>
        {renderIcon && <Svgs.ArtLightbulbSmall />}
        {children}
        {typeof onClick !== 'undefined' && <Svgs.IconArrowRight />}
      </Card>
    </Grid>
  );
};

export default InfoCard;
