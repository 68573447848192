import { AcceptTermsHandler } from './AcceptTermsHandler';
import { MissingPersonalInfoHandler } from './MissingPersonalInfoHandler';
import { RedirectContext, RedirectHandler } from './RedirectHandler';
import {
  IServiceContainer,
  Scopes,
  createServiceModule,
  injectArray,
  injectContainer,
} from '@aesop-fables/containr';
import { RedirectServices } from './Types';
import { Data, Domain } from '@3nickels/data-modules';
import { Observable, firstValueFrom } from 'rxjs';
import { ISubjectResolver, ScriniumServices, injectSubject } from '@aesop-fables/scrinium';
import { namespacedService } from '../utils';
import { logger } from '../Logging';
import { PlaidIncompleteHandler } from './PlaidIncompleteHandler';

export const useRedirectHandlers = createServiceModule(
  namespacedService('redirects'),
  (services) => {
    services.autoResolve(RedirectServices.RedirectService, RedirectService, Scopes.Transient);
    services.arrayAutoResolve(RedirectServices.AdviceHandlers, MissingPersonalInfoHandler);
    services.arrayAutoResolve(RedirectServices.AdviceHandlers, AcceptTermsHandler);
    services.arrayAutoResolve(RedirectServices.AdviceHandlers, PlaidIncompleteHandler);
    services.arrayAutoResolve(RedirectServices.FreeHandlers, MissingPersonalInfoHandler);
    services.arrayAutoResolve(RedirectServices.FreeHandlers, AcceptTermsHandler);
  }
);

export interface IRedirectService {
  redirect(): Promise<Response>;
}

export class RedirectService implements IRedirectService {
  constructor(
    @injectArray(RedirectServices.AdviceHandlers)
    private readonly adviceHandlers: RedirectHandler[],
    @injectArray(RedirectServices.FreeHandlers) private readonly freeHandlers: RedirectHandler[],
    @injectSubject(Data.People.PersonServices.SelfData)
    private readonly selfData: Observable<Domain.SelfData>,
    @injectSubject(Data.People.PersonServices.SpouseData)
    private readonly spouse: Observable<Domain.SpouseData>,
    @injectContainer() private readonly container: IServiceContainer
  ) {}

  async redirect(): Promise<Response> {
    const resolver = this.container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
    const user$ = resolver.resolveSubject(Data.Users.PrincipalUserSubject);
    const [user, person, spouse] = await Promise.all([
      firstValueFrom(user$),
      firstValueFrom(this.selfData),
      firstValueFrom(this.spouse),
    ]);

    const context: RedirectContext = {
      user,
      person,
      spouse,
      userConfig: user?.userConfig as Domain.UserConfig,
    };

    const handlers =
      user.productType === Domain.ProductTypeEnum.advice ? this.adviceHandlers : this.freeHandlers;

    logger.debug('[redirect] searching for handler', context);
    let handler = undefined;
    for (let i = 0; i < handlers.length; i++) {
      const h = handlers[i];
      const matches = await h.matches();
      if (matches) {
        handler = h;
        break;
      }
    }
    let response: Response | undefined;
    if (handler) {
      logger.debug('[redirect] handler found', handler);
      response = handler.handle(context);
    } else {
      logger.debug('[redirect]', handler);
    }

    return (
      response ??
      new Response(JSON.stringify({}), {
        status: 200,
        headers: {},
      })
    );
  }
}
