import { createServiceModule, Scopes } from '@aesop-fables/containr';
import { AvailablePricebooks, PricebookServices } from '../../data/pricebook';

export const usePricebookServices = createServiceModule(
  '@3nickels/web/pricebook/services',
  (services) => {
    services.autoResolve(
      PricebookServices.AvailablePricebooks,
      AvailablePricebooks,
      Scopes.Transient
    );
  }
);
