import { Grid, TypographyProps } from '@mui/material';
import BulletedText from './BulletedText';
import { Children } from 'react';

const BulletedList: React.FC<TypographyProps> = ({ children, style, color }) => {
  return (
    <Grid>
      {Children.map(children, (child, index) => (
        <BulletedText key={index} color={color} style={{ ...style }}>
          {child}
        </BulletedText>
      ))}
    </Grid>
  );
};

export default BulletedList;
