/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Api, Commands, Data, Domain, Hooks } from '@3nickels/data-modules';
import { FormLoader } from '../../../../../components/FormLoader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../../components/form/FormContent';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { useLoading } from '../../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../../EditViewUtils';
import { InvestmentData } from '../../Types';
import InvestmentDetailsForm, { InvestmentDetailsFormData } from './InvestmentDetailsForm';
import { cleanWholeNumberStr } from '../../../../../helpers/utilityFunctions';
import { useService } from '@aesop-fables/containr-react';
import { Spacer } from '../../../../../components';
import BulletedList from '../../../../../components/BulletedList';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import HelpTitle from '../../../../../components/HelpTitle';
import { Margins } from '../../../../../themes';
import { showBalanceTypeToggle } from '../../../helpers/functions';

const investmentSchema = (type?: Domain.InvestmentAccountTypeEnum, showBalanceType?: boolean) =>
  Yup.object({
    selected: Yup.string().test({
      name: 'minimumPayment greater than interest',
      // arrow function cannot access parent
      test: function (selected, { createError, path, parent }) {
        const { ticker, securityId, securityName } = parent;

        if (ticker && securityId && securityName) return true;

        return createError({
          message: 'Investment is required',
          path,
        });
      },
    }),
    valueType:
      type !== Domain.InvestmentAccountTypeEnum.BrokerageAccount
        ? Yup.string()
            .required('Required')
            .test('valueType', 'Required', (valueType) => {
              return Domain.InvestmentValueTypeEnumList.some((t) => {
                return t.key.toString() === valueType;
              });
            })
        : Yup.string().notRequired(),
    balanceType: Yup.string().when('valueType', {
      is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
      then: (schema) =>
        schema.required('Required').test('balanceType', 'Required', (balanceType) => {
          return Domain.InvestmentBalanceTypeEnumList.some((t) => {
            return t.key.toString() === balanceType;
          });
        }),
    }),
    taxType:
      showBalanceType &&
      type !== Domain.InvestmentAccountTypeEnum.HSA &&
      type !== Domain.InvestmentAccountTypeEnum.BrokerageAccount
        ? Yup.string().when('valueType', {
            is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
            then: (schema) =>
              schema.required('Required').test('taxType', 'Required', (taxType) => {
                return Domain.TaxTypeEnumList.some((t) => {
                  return t.key.toString() === taxType;
                });
              }),
          })
        : Yup.string().notRequired(),
    balance: Yup.string().when('valueType', {
      is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
      then: (schema) =>
        schema.when('balanceType', {
          is: Domain.InvestmentBalanceTypeEnum['Total Balance'].toString(),
          then: (schema) =>
            schema
              .required('Required')
              .test('balance', 'Balance must be greater than $0', (balance) => {
                const cleanNumber = cleanWholeNumberStr(balance);
                return cleanNumber > 0;
              }),
        }),
    }),
    nrShares: Yup.string().when('valueType', {
      is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
      then: (schema) =>
        schema.when('balanceType', {
          is: Domain.InvestmentBalanceTypeEnum['# of Shares'].toString(),
          then: (schema) =>
            schema
              .required('Required')
              .test('nrShares', 'Number of Shares must be greater than 0', (nrShares) => {
                const cleanNumber = cleanWholeNumberStr(nrShares);
                return cleanNumber > 0;
              }),
        }),
    }),
  });

export const InvestmentDetailsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { account } = Hooks.useInvestmentContext();
  return (
    <FormLoader loading={account?.id === 0}>
      <InvestmentDetailsEditView editing={editing} onBack={onBack} />
    </FormLoader>
  );
};

declare type InvestmentDetailsEditViewProps = EditViewProps;

const InvestmentDetailsEditView: React.FC<InvestmentDetailsEditViewProps> = ({
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const { account, institutionalAccount, selectedInvestment } = Hooks.useInvestmentContext();
  const { params } =
    Hooks.useRetirementWizard<Data.InvestmentAccounts.AccountContributionsFormData>();
  const { basic, contributions } = Hooks.useRetirementWizardData();
  const { setLoading } = useLoading();
  const [selectedSecurity, setSelectedSecurity] = useState<Api.SecurityRest | undefined>(undefined);
  const commands = useService<Commands.ICommandExecutor>(Data.DataServices.CommandExecutor);
  const planTypes =
    basic?.owner === 'spouse'
      ? Hooks.useSpouseAvailablePlanTypes()
      : Hooks.useSelfAvailablePlanTypes();
  const planEligibility = planTypes.find((plan) => plan.planType === params?.type);
  const showBalanceType = showBalanceTypeToggle(params?.type ?? Domain.PlanTypeEnum['#457']);
  const methods = useForm<InvestmentData>({
    defaultValues: selectedInvestment,
    resolver: yupResolver(investmentSchema(account?.accountType, showBalanceType)),
  });
  const planData = account?.account as Domain.PlanData;

  const formatInputs = (values: any) => {
    const valueType = parseInt(values.valueType);
    const balanceType = parseInt(values.balanceType);
    const taxType = parseInt(values.taxType);
    const balance = cleanWholeNumberStr(values.balance);
    const nrShares = cleanWholeNumberStr(values.nrShares);
    const rest = Domain.mapInvestmentDataToInvestmentRest(
      {
        ...values,
        valueType,
        balanceType,
        taxType,
        balance,
        nrShares,
      },
      selectedSecurity
    );
    const totalValue = rest.totalValue;
    return { ...rest, totalValue };
  };

  const onSubmit = async (values: InvestmentDetailsFormData) => {
    setLoading(true);

    try {
      if (account) {
        const cleanedValues = formatInputs(values);
        await commands.execute(Data.InvestmentAccounts.Commands.SaveInvestment, {
          investmentAccountId: account.id ?? 0,
          investmentAccountType: account.accountType,
          institutionalAccount,
          ...cleanedValues,
        });

        if (onBack) {
          onBack();
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormContent formProviderProps={methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box>
            {/* Should be about 30px */}
            <HelpTitle
              text={editing ? t('EditAnInvestment') : t('AddAnInvestment')}
              helpContext={AddInvestmentHelpContent(
                editing ? t('EditAnInvestment') : t('AddAnInvestment'),
                planData.planType
              )}
            />
            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={10}>
                <InvestmentDetailsForm
                  investment={selectedInvestment}
                  account={account}
                  institutionalAccount={institutionalAccount}
                  contributions={contributions}
                  planEligibility={planEligibility}
                  params={params}
                  onSubmit={onSubmit}
                  selectSecurity={(security?: Api.SecurityRest) => setSelectedSecurity(security)}
                />
              </Grid>
            </Grid>
          </Box>
          <WizardFooter
            color={'primary'}
            disableBack={false}
            backLabel='Cancel'
            onBack={onBack}
            {...getWizardFooterProps('Save', editing)}
          />
        </form>
      </FormContent>
    </>
  );
};

export const AddInvestmentHelpContent = (
  title: string,
  planType: Domain.PlanTypeEnum | undefined
) => {
  const isIraOrBrokerage =
    Domain.isIRA(planType) || planType === Domain.PlanTypeEnum['Brokerage Account'];

  return (
    <HelpPopover title={title}>
      <HelpPopoverText>We need information about how this account is invested.</HelpPopoverText>
      {!isIraOrBrokerage && (
        <>
          <Spacer height='xxs' />
          <HelpPopoverText>
            <strong>
              Important: We can provide more personalized investment advice if you include all the
              investment options available– even those without a balance. These investment options
              must be entered manually.
            </strong>
          </HelpPopoverText>
        </>
      )}
      <Spacer height='xxs' />
      <HelpPopoverText>Also note:</HelpPopoverText>
      <BulletedList style={Margins.mt_xxs}>
        <>
          Some publicly traded investments such as mutual funds, ETFs, and stocks, have ticker
          symbols.
        </>
        <>Using tickers speeds up data entry, but you can also search by investment name.</>
      </BulletedList>
    </HelpPopover>
  );
};

export default InvestmentDetailsEditViewWrapper;
