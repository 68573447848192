import { redirect } from 'react-router-dom';
import { RedirectHandler } from './RedirectHandler';
import { Data, Domain } from '@3nickels/data-modules';
import { combineLatest, firstValueFrom } from 'rxjs';
import { inject } from '@aesop-fables/containr';

export class PlaidIncompleteHandler implements RedirectHandler {
  constructor(
    @inject(Data.Plaid.PlaidServices.Wizard) private readonly wizard: Data.Plaid.PlaidWizard
  ) {}
  async matches() {
    const [status, accessId] = await firstValueFrom(
      combineLatest([this.wizard.currentStatus$, this.wizard.targetInstitution$])
    );

    if (status === Domain.PlaidStatusEnum.RequiresAttention && accessId) {
      try {
        await this.wizard.selectInstitution(accessId);
        return true;
      } catch (e) {
        console.log(e);
      }
    }

    return false;
  }

  handle(): Response {
    return redirect('/account-details/link-account/import-account');
  }
}
