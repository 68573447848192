import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { combineLatest, firstValueFrom } from 'rxjs';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading } from '../../../../hooks/useLoading';
import { Spacing } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { InvestmentAccountBasicsEditViewWrapper as RetirementPlanBasicsEditView } from '../../investment-account/InvestmentAccountBasicsEditView';
import RetirementPlanBasicsCard from '../basics/RetirementPlanBasicsCard';
import RetirementPlanContributionsCard from '../contributions/RetirementPlanContributionsCard';
import RetirementPlanEmployerContributionsCard from '../employer-contributions/RetirementPlanEmployerContributionsCard';
import InvestmentsCard from '../../investment-account/investments/InvestmentsCard';
import { RetirementPlanContributionsEditViewWrapper as RetirementPlanContributionsEditView } from '../contributions/RetirementPlanContributionsEditView';
import { RetirementPlanEmployerContributionsEditViewWrapper as RetirementPlanEmployerContributionsEditView } from '../employer-contributions/RetirementPlanEmployerContributionsEditView';
import { PlaidWaitScreen } from '../../link-account/PlaidWaitScreen';
import { SummaryView } from '../../../../components/SummaryHeader';
import IraContributionsCard from '../contributions/ira/IraContributionsCard';
import { useMessage } from '../../../../hooks/useMessage';
import { useSummaryOnDoneNavigation } from '../../link-account/PlaidHooks';

declare type RetirementPlanSummaryDrawerType =
  keyof Data.InvestmentAccounts.RetirementWizardRegistry;

const RetirementPlanSummaryViewWrapper: React.FC = () => {
  const { wizard, params } = Hooks.useRetirementWizard();
  const plaidWizard = Hooks.usePlaidWizard();
  const navigate = useNavigate();
  const loading = useObservable(plaidWizard.loading$) ?? true;
  const { id, type: planType } = useParams();
  const isStarted = useObservable(wizard.isStarted$) ?? false;
  const type =
    planType && Object.keys(Domain.accountTypeKeyToPlanTypeId).includes(planType)
      ? Domain.accountTypeKeyToPlanTypeId[
          planType as keyof typeof Domain.accountTypeKeyToPlanTypeId
        ]
      : params?.type ?? Domain.PlanTypeEnum['#457'];

  const investmentAccountId = parseInt(id ?? '');
  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: investmentAccountId, type });
    }
  }, [isStarted]);

  const context = Hooks.usePlaidContext();

  useEffect(() => {
    if (!loading && typeof context.token === 'undefined') {
      plaidWizard.generateLinkToken();
    }
  }, [loading, context.token]);

  const plaidCompleteHandler = async () => {
    const ctx = await firstValueFrom(plaidWizard.context$);
    if (ctx.account) {
      navigate(`/account-details/link-account/manual-import`);
      return;
    }

    navigate(`/account-details/link-account/import-account`);
  };

  if (!isStarted || !params || !context.token || params.id !== investmentAccountId) {
    return <Loading />;
  }

  const autoLinkJob = context.job;
  if (typeof autoLinkJob !== 'undefined') {
    return <PlaidWaitScreen autoLinkJob={autoLinkJob} onComplete={plaidCompleteHandler} />;
  }

  return <RetirementPlanSummaryView wizard={wizard} params={params} />;
};

export interface InvestmentAccountSummaryViewProps {
  wizard: Data.InvestmentAccounts.RetirementWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

// We need guards because the hooks are getting tripped up
const RetirementPlanSummaryView: React.FC<InvestmentAccountSummaryViewProps> = ({
  wizard,
  params,
}) => {
  const location = useLocation();
  const data = Hooks.useRetirementWizardData();
  const includeSpouse = Hooks.useIncludeSpouse();
  const plaidWizard = Hooks.usePlaidWizard();
  const [isOpen, setOpen] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const { showMessage, hideMessage } = useMessage();
  const [needsAttention, setNeedsAttention] = React.useState<string | undefined>();
  const navigate = useNavigate();
  const accounts = Hooks.useCombinedInvestmentAccountData();
  const organization = Hooks.useOrganizationData();
  const { institutionalAccount } = Hooks.useInstitutionalAccount();
  const onDone = useSummaryOnDoneNavigation();
  const deleteMutation = useMutation(
    new Data.InvestmentAccounts.Mutations.DeleteRetirementAccount()
  );
  const account = useMemo(
    () => accounts.find((x) => x.id === params?.id) as Domain.PlanData,
    [accounts, params]
  );
  const isInstitutional = useMemo(
    () => account?.id === institutionalAccount?.id,
    [account, institutionalAccount]
  );
  const isIra = useMemo(() => Domain.isIRA(params.type), [params]);

  useEffect(() => {
    if (
      typeof data.account?.id !== 'undefined' &&
      typeof data.contributions.eligibleForContributions !== 'boolean'
    ) {
      showMessage(
        "We're missing some info for your account. That's ok– fill it out when you can.",
        'info'
      );
      setNeedsAttention('contributions');
    }
  }, [data.contributions.eligibleForContributions]);

  useEffect(() => {
    if (
      typeof data.contributions.eligibleForContributions === 'boolean' &&
      needsAttention === 'contributions'
    ) {
      hideMessage();
      setNeedsAttention(undefined);
    }
  }, [data.contributions.eligibleForContributions, needsAttention]);

  useEffect(() => {
    if (data.investments?.length > 0) {
      let balance = 0;
      data.investments.forEach((i) => (balance += i.balance ?? 0));
      setCurrentBalance(balance);
    }
  }, [data]);

  const openDrawer = (key?: RetirementPlanSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };

  const linkAccountHandler = useCallback(() => {
    plaidWizard.prepareAccountForManualLink(
      account,
      Domain.FinancialAccountTypeEnum.InvestmentAccount
    );
  }, [plaidWizard, account]);

  const linkInvestmentsHandler = () => {
    linkAccountHandler();
    navigate('/account-details/link-account/choose-institution', {
      state: { summaryUrl: location.pathname },
    });
  };

  const removeAccountHandler = useCallback(() => {
    deleteMutation.action({ id: account.id ?? 0, participantId: account?.participantId ?? 0 }); // no need to actually wait since the datacache will still be updated
    navigate('/account-details');
    showMessage('Retirement Plan removed!');
  }, [account]);

  if (data.basic?.id !== params.id) return null;

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={currentBalance}
      onLinkAccount={linkAccountHandler}
      linkableAccount={account}
      accountType={Domain.FinancialAccountTypeEnum.InvestmentAccount}
      accountSubType={Domain.planTypeToAccountLabel[params.type as Domain.PlanTypeEnum]}
      organizationName={isInstitutional ? organization?.name : undefined}
      onRemoveAccount={isInstitutional ? undefined : removeAccountHandler}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <RetirementPlanBasicsCard
            accountBasics={data.basic}
            type={params.type}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      {Data.InvestmentAccounts.showPaycheckContrib(params.type) && (
        <Grid item mt={2}>
          <Stack spacing={2}>
            {isIra ? (
              <IraContributionsCard
                accountContributions={data.contributions}
                planType={params.type as unknown as Domain.IraPlanTypeEnum}
                onEdit={() => openDrawer('contributions')}
              />
            ) : (
              <RetirementPlanContributionsCard
                accountContributions={data.contributions}
                onEdit={() => openDrawer('contributions')}
              />
            )}
          </Stack>
        </Grid>
      )}
      {Data.InvestmentAccounts.showEmployerContrib(
        params.type,
        data.eligibility,
        data.contributions
      ) && (
        <Grid item mt={2}>
          <Stack spacing={2}>
            <RetirementPlanEmployerContributionsCard
              params={params}
              employerContributions={data.employerContributions}
              onEdit={() => openDrawer('employerContributions')}
              disableEdit={isInstitutional}
            />
          </Stack>
        </Grid>
      )}
      <Grid item mt={2}>
        <Stack spacing={2}>
          <InvestmentsCard
            investmentAccountId={params.id ?? 0}
            onEdit={() => openDrawer('basic')}
            linkAccount={linkInvestmentsHandler}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        open={isOpen}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(
            undefined as unknown as keyof Data.InvestmentAccounts.InvestmentAccountWizardRegistry
          );
        }}
      />
      <WizardFooter nextLabel='Done' onDone={onDone} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard, params } = Hooks.useRetirementWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key as keyof Data.InvestmentAccounts.RetirementWizardRegistry;

  if (!params) return null;

  if (key === 'basic') {
    return <RetirementPlanBasicsEditView type={params.type} editing onBack={onBack} />;
  } else if (key === 'contributions') {
    return <RetirementPlanContributionsEditView editing onBack={onBack} />;
  } else if (key === 'employerContributions') {
    return <RetirementPlanEmployerContributionsEditView editing onBack={onBack} />;
  }

  return null;
};

export interface RetirementPlanSummaryProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(RetirementPlanSummaryViewWrapper, meta);
