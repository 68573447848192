/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Api,
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { GoalsLayoutMeta } from '../GoalsLayout';
import CollegeGoalDetailsForm from './CollegeGoalDetailsForm';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import { GoalFormTitle } from '../../../components/FormTitle';
import dayjs from 'dayjs';
import { Toast } from '../../../models/Toast';
import { useAppToasts } from '../../../AppToasts';

const formSchema = Yup.object({
  targetDate: Yup.string().when('beneficiaryType', {
    is: (beneficiaryType: string) =>
      beneficiaryType !== Domain.BeneficiaryTypeEnum.DEPENDENT.toString(),
    then: (schema) =>
      schema
        .required('Required')
        .test('targetDate', 'Date must be in the future', (targetDate) =>
          dayjs(targetDate).isAfter(dayjs(), 'day')
        ),
  }),
  tuitionType: Yup.string().required('Required'),
  annualTuitionCost: Yup.string().required('Required'),
});

export const CollegeGoalDetailsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const navigate = useNavigate();
  const { loading, currentStep, wizard } = Hooks.useCollegeGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();
  const averageCosts = Hooks.useAverageCollegeCosts();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      if (!id) {
        navigate('/goals/college/basics');
        return;
      }
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params &&
        typeof averageCosts !== 'undefined' && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <CollegeGoalDetailsEditView
              averageCosts={averageCosts}
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface CollegeGoalDetailsEditViewProps extends EditViewProps {
  averageCosts: Api.CollegeFundSelfAverageCostsResultRest;
  currentStep: WizardStep<Data.Goals.CollegeGoalFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.CollegeGoalWizard;
  params: Data.Goals.WizardParamsWithId;
}

const CollegeGoalDetailsEditView: React.FC<CollegeGoalDetailsEditViewProps> = ({
  averageCosts,
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.Goals.CollegeGoalFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema),
  });
  const { setLoading } = useLoading();
  const { setToast } = useAppToasts();

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      currentStep.save({
        ...values,
        annualTuitionCost: cleanWholeNumberStr(values.annualTuitionCost),
        annualTuitionCostCoveragePercent: parseInt(values.annualTuitionCostCoveragePercent ?? '0'),
      });
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/goals');
    } catch (err) {
      setToast(
        new Toast({
          message: 'Please enter a unique goal name.',
          open: true,
          severity: 'error',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <GoalFormTitle type={Domain.GoalTypeEnum.College} />

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <CollegeGoalDetailsForm
                    averageCosts={averageCosts}
                    model={currentStep.model}
                    onSubmit={onSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Save & Continue',
  title: 'College',
  headerVariant: 'hex',
  hexSteps: {
    steps: 2,
    currentStep: 2,
  },
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(CollegeGoalDetailsEditViewWrapper, meta);
