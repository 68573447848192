/* eslint-disable @typescript-eslint/no-explicit-any */
import { Domain } from '@3nickels/data-modules';

export enum PricebookProductTypeEnum {
  GUIDED_CHOICE = 'GUIDED_CHOICE',
  NICKELS = 'NICKELS',
  NICKELSU = 'NICKELSU',
}

export enum PaymentTypeEnum {
  PRE_PAID = 'PRE_PAID',
  METERED_PAYMENT = 'METERED_PAYMENT',
}

export enum ProcessorNameEnum {
  STRIPE = 'STRIPE',
  ADP = 'ADP',
}

export interface Pricebook {
  id: number;
  pricebookName: string;
  defaultPricebook: boolean;
  groupId: number;
  paidBy: Domain.PaidByEnum;
  productType: PricebookProductTypeEnum;
  description: string[];
  logoUrl: string;
  artUrl: string;
  metadata: any;
  skipPayment: boolean;
  allowCoupon: boolean;
  skipStripePayment: boolean;
  paymentType: PaymentTypeEnum;
}

export interface Product {
  id: number;
  productName: string;
  productId: number;
  threeNickelsU: boolean;
}

export interface Processor {
  id: number;
  name: string;
}

export interface Price {
  id: number;
  productName: string;
  price: number;
  priceId: string;
  description: string;
  introductionPeriodInDays: number;
  subscriptionLengthInDays: number;
  intervalType: Domain.PaymentIntervalEnum;
  product: Product;
  processor: Processor;
}

export interface PricebookBundle {
  pricebook: Pricebook;
  prices: Price[];
}

export const DefaultPricebook: PricebookBundle = {
  pricebook: {
    id: 12,
    pricebookName: '3Nickels Free',
    defaultPricebook: true,
    groupId: 6,
    paidBy: Domain.PaidByEnum.USER,
    productType: PricebookProductTypeEnum.NICKELS,
    description: [
      'A suite of DIY tools to help you get answers to your questions',
      'Save your data while you work through your options',
      'Free means free',
    ],
    logoUrl: 'https://3nickels.azureedge.net/media/images/general/3n_logo_small.png',
    artUrl: 'https://3nickels.azureedge.net/media/images/general/3n_free_product.png',
    metadata: '',
    skipPayment: false,
    allowCoupon: true,
    skipStripePayment: true,
    paymentType: PaymentTypeEnum.PRE_PAID,
  },
  prices: [
    {
      id: 8,
      productName: '3Nickels Free',
      price: 0,
      priceId: 'DEFAULT',
      description: '3Nickels Free',
      introductionPeriodInDays: 0,
      subscriptionLengthInDays: 0,
      intervalType: Domain.PaymentIntervalEnum.NONE,
      product: {
        id: 21,
        productName: '3Nickels Free',
        productId: 7,
        threeNickelsU: false,
      },
      processor: {
        id: 2,
        name: 'STRIPE',
      },
    },
  ],
};
