import { Data, Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { SummaryCardProps } from '../../Types';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import { useMemo } from 'react';

export interface RetirementPlanContributionsCardProps extends SummaryCardProps {
  accountContributions: Data.InvestmentAccounts.AccountContributionsFormData;
}

const RetirementPlanContributionsCard: React.FC<RetirementPlanContributionsCardProps> = ({
  accountContributions,
  onEdit,
}) => {
  const { t } = useTranslation();
  const missingEligibility = useMemo(
    () => typeof accountContributions.eligibleForContributions !== 'boolean',
    [accountContributions.eligibleForContributions]
  );

  return (
    <CardDisplay headerKey='AnnualContributions' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('Eligible?')}
          </Typography>
          <Typography color={missingEligibility ? 'error' : 'secondary'}>
            {missingEligibility
              ? 'Answer Needed'
              : t(accountContributions.eligibleForContributions ? 'Yes' : 'No')}
          </Typography>
        </Grid>
        {accountContributions.eligibleForContributions && (
          <>
            <Grid item xs>
              <Typography variant='h3' component='h3' color='primary'>
                {t('ContributingNow')}
              </Typography>
              <Typography color='secondary'>
                {t(accountContributions.paycheckContributions ? 'Yes' : 'No')}
              </Typography>
            </Grid>
            {accountContributions.paycheckContributions ? (
              <ContributionsGrid accountContributions={accountContributions} />
            ) : (
              <>
                <Grid item xs />
                <Grid item xs />
                <Grid item xs />
              </>
            )}
          </>
        )}
      </Grid>
    </CardDisplay>
  );
};

interface ContributionsGridProps {
  accountContributions: Data.InvestmentAccounts.AccountContributionsFormData;
}

const ContributionsGrid: React.FC<ContributionsGridProps> = ({ accountContributions }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs>
        <Typography variant='h3' component='h3' color='primary'>
          {t('AnnualPreTaxSavings')}
        </Typography>
        {accountContributions.contributionType === Domain.EligibleContributionTypeEnum.Dollar && (
          <Typography color='secondary'>
            {formatWholeDollars(accountContributions.annualContribPreTaxDollar ?? 0)}
          </Typography>
        )}
        {accountContributions.contributionType === Domain.EligibleContributionTypeEnum.Percent && (
          <Typography color='secondary'>
            {formatPercent(accountContributions.annualContribPreTaxPercent ?? 0)}
          </Typography>
        )}
      </Grid>
      {accountContributions.rothContributionsAllowed && (
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AnnualRothSavings')}
          </Typography>
          {accountContributions.contributionType === Domain.EligibleContributionTypeEnum.Dollar && (
            <Typography color='secondary'>
              {formatWholeDollars(accountContributions.annualContribRothDollar ?? 0)}
            </Typography>
          )}
          {accountContributions.contributionType ===
            Domain.EligibleContributionTypeEnum.Percent && (
            <Typography color='secondary'>
              {formatPercent(accountContributions.annualContribRothPercent ?? 0)}
            </Typography>
          )}
        </Grid>
      )}
      {accountContributions.afterTaxContributionsAllowed && (
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AnnualAfterTaxSavings')}
          </Typography>
          {accountContributions.contributionType === Domain.EligibleContributionTypeEnum.Dollar && (
            <Typography color='secondary'>
              {formatWholeDollars(accountContributions.annualContribAfterTaxDollar ?? 0)}
            </Typography>
          )}
          {accountContributions.contributionType ===
            Domain.EligibleContributionTypeEnum.Percent && (
            <Typography color='secondary'>
              {formatPercent(accountContributions.annualContribAfterTaxPercent ?? 0)}
            </Typography>
          )}
        </Grid>
      )}
      {!accountContributions.rothContributionsAllowed && <Grid item xs />}
      {!accountContributions.afterTaxContributionsAllowed && <Grid item xs />}
    </>
  );
};

export default RetirementPlanContributionsCard;
