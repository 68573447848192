import { useMemo } from 'react';
import { useSubject } from '@aesop-fables/scrinium';
import { PlaidAccount } from './import-account/PlaidAccountCard';
import { IAutoBindableAccount, PlanData } from '@3nickels/data-modules/lib/domain';
import { PalmTreeSvg } from '../../../components/images/PalmTreeSvg';
import { PlaidAccountStatusEnum } from './Types';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { DebtSvg } from '../../../components/images/DebtSvg';
import { InvestmentSvg } from '../../../components/images/InvestmentSvg';
import { PiggyBankSvg } from '../../../components/images/PiggyBankSvg';

interface AccountBannerIconProps {
  financialAccountTypes: string;
  subType?: string;
}

const AccountBannerIcon: React.FC<AccountBannerIconProps> = ({
  financialAccountTypes,
  subType,
}) => {
  if (financialAccountTypes === 'DEBT' || financialAccountTypes === 'CREDIT_CARD') {
    return <DebtSvg fontSize='large' />;
  } else if (financialAccountTypes === 'CASH_ACCOUNT') {
    return <PiggyBankSvg fontSize='large' />;
  } else if (Domain.retirementPlanTypes.find((x) => x.toString() === subType)) {
    return <PalmTreeSvg fontSize='large' />;
  }
  return <InvestmentSvg fontSize='large' />;
};

function getAccountStatus(
  account: IAutoBindableAccount,
  combinedHousingAccounts: Domain.HousingData[]
): PlaidAccountStatusEnum {
  const existingHousing = combinedHousingAccounts.find(
    (x) => x.debt?.id === account.financialAccountId
  );
  if (existingHousing) {
    return PlaidAccountStatusEnum.Complete;
  }

  if (
    account.autoLinkStatus === 'notLinked' &&
    account.aggType !== 'INVESTMENT_ACCOUNT' &&
    account.aggSubType !== 'mortgage'
  ) {
    return PlaidAccountStatusEnum.Unlinked;
  }

  if (account.autoLinkStatus === 'notLinked') {
    return PlaidAccountStatusEnum.NotImported;
  }

  if (account.aggSubType === 'mortgage') {
    return PlaidAccountStatusEnum.Incomplete;
  }

  if (account.autoLinkStatus !== 'inComplete') {
    return PlaidAccountStatusEnum.Complete;
  }

  return PlaidAccountStatusEnum.NotImported;
}

// function isAccountComplete(account: IAutoBindableAccount, realEstateAccounts: Domain.RealEstateData[]): boolean {
//   return getAccountStatus(account, realEstateAccounts) === PlaidAccountStatusEnum.Complete;
// }

function createPlaidAccount(
  account: IAutoBindableAccount,
  combinedHousingAccounts: Domain.HousingData[]
): PlaidAccount {
  return {
    label: account.name,
    icon: (
      <AccountBannerIcon
        financialAccountTypes={account.financialAccountTypes}
        subType={account.subType}
      />
    ),
    status: getAccountStatus(account, combinedHousingAccounts),
    editable: true,
    disabled: false,
    account,
  };
}

export function isIRA(type: Domain.PlanTypeEnum) {
  const iraTypes = [
    Domain.PlanTypeEnum['IRA | Deductible'],
    Domain.PlanTypeEnum['IRA | Rollover'],
    Domain.PlanTypeEnum['IRA | Roth'],
  ];
  return iraTypes.indexOf(type) !== -1;
}

export function usePlaidAccountWizard() {
  const navigate = useNavigate();
  const { wizard: retirementWizard } = Hooks.useRetirementWizard();
  const { wizard: investmentAccountWizard } = Hooks.useInvestmentAccountWizard();
  const start = async (plaid: PlaidAccount) => {
    console.log('Starting', plaid);
    const aggInvestment = plaid.account as Domain.AggInvestment;
    const planData: Domain.PlanData = aggInvestment?.account as PlanData;
    if (planData?.planType) {
      if (planData.planType === Domain.PlanTypeEnum.HSA) {
        await investmentAccountWizard.start({
          bindableAccount: plaid.account,
          type: planData.planType,
        });
        navigate(`/account-details/health-savings-account/basics`);
        return;
      }
      if (planData.planType === Domain.PlanTypeEnum['Brokerage Account']) {
        await investmentAccountWizard.start({
          bindableAccount: plaid.account,
          type: planData.planType,
        });
        navigate(`/account-details/brokerage-account/basics`);
        return;
      }
      if (Domain.isRetirementPlan(planData.planType)) {
        const slug =
          Domain.planTypeIdToAccountKey[
            planData?.planType as keyof typeof Domain.planTypeIdToAccountKey
          ];
        await retirementWizard.start({ bindableAccount: plaid.account, type: planData.planType });
        navigate(`/account-details/retirement-savings-plan/basics/${slug}`);
        return;
      }
    }

    console.log(plaid.account);
    // just try it first, JOSHUA
    // THEN refactor to handlers
    if (plaid.account.aggType === 'loan' && plaid.account.aggSubType === 'mortgage') {
      navigate(`/account-details/link-account/mortgage-type`);
    }

    console.log(`Not sure what to do with this`);
  };

  return {
    start,
  };
}

export const usePlaidAccountTypes = (): PlaidAccount[] => {
  const { selectedInstitution } = Hooks.usePlaidContext();
  const accounts = selectedInstitution?.availableAccounts ?? [];
  const combinedHousingAccounts = useSubject<Domain.HousingData[]>(
    Data.RealEstate.HousingEntityServices.CombinedHousingData
  );

  return useMemo(() => {
    if (combinedHousingAccounts === undefined) {
      return [];
    }

    return accounts.map((x) => createPlaidAccount(x, combinedHousingAccounts));
  }, [accounts, combinedHousingAccounts]);
};

export const useSummaryOnDoneNavigation = () => {
  const navigate = useNavigate();
  const { selectedInstitution } = Hooks.usePlaidContext();
  const plaidWizard = Hooks.usePlaidWizard();
  if (selectedInstitution) {
    return async () => {
      await plaidWizard.reload();
      navigate(`/account-details/link-account/import-account`);
    };
  }
  return () => navigate('/account-details');
};
