/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Commands, Data, Domain, Hooks } from '@3nickels/data-modules';
import { FormLoader } from '../../../../../components/FormLoader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../../components/form/FormContent';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { useLoading } from '../../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../../EditViewUtils';
import InvestmentBalanceForm from './InvestmentBalanceForm';
import { cleanWholeNumberStr } from '../../../../../helpers/utilityFunctions';
import { useService } from '@aesop-fables/containr-react';

const balanceSchema = Yup.object({
  balance: Yup.string()
    .required('Balance is required')
    .test(
      'balance',
      'Balance must be greater than $0',
      (balance) => cleanWholeNumberStr(balance) > 0
    ),
});

export const InvestmentBalanceEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { account } = Hooks.useInvestmentContext();
  return (
    <FormLoader loading={!account?.id}>
      <InvestmentBalanceEditView editing={editing} onBack={onBack} />
    </FormLoader>
  );
};

declare type InvestmentBalanceEditViewProps = EditViewProps;

const InvestmentBalanceEditView: React.FC<InvestmentBalanceEditViewProps> = ({
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const { account, selectedInvestment } = Hooks.useInvestmentContext();
  const methods = useForm<Domain.InvestmentData>({
    defaultValues: selectedInvestment,
    resolver: yupResolver(balanceSchema),
  });
  const { setLoading } = useLoading();
  const commands = useService<Commands.ICommandExecutor>(Data.DataServices.CommandExecutor);

  const onSubmit = async (values: Domain.InvestmentData) => {
    setLoading(true);

    try {
      await commands.execute(
        Data.InvestmentAccounts.Commands.AddVanguardBalanceIndexAdmInvestment,
        {
          investmentAccountId: account?.id ?? 0,
          balance: cleanWholeNumberStr(values.balance as unknown as string),
        }
      );

      if (onBack) {
        onBack();
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormContent formProviderProps={methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {account?.accountType === Domain.InvestmentAccountTypeEnum.HSA ? (
            <HSAContent editing={editing} />
          ) : (
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t('EnterPlanBalance')}
              </Typography>
              <Typography className='subtitle' fontSize='16px' color='secondary'>
                {t('YouCanEnterAnEstimatedBalance')}
              </Typography>
              <Typography className='subtitle' fontSize='16px' color='secondary'>
                {t('WereUsingLowCostBalancedFund')}
              </Typography>
              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <InvestmentBalanceForm onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
          )}
          <WizardFooter
            color={editing ? 'primary' : undefined}
            disableBack={false}
            onBack={onBack}
            {...getWizardFooterProps('Save', editing)}
          />
        </form>
      </FormContent>
    </>
  );
};

const HSAContent: React.FC<InvestmentBalanceEditViewProps> = ({ editing }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t('EnterCashBalance')}
      </Typography>
      <Typography className='subtitle' fontSize='16px' color='secondary'>
        {t('HsaOnlyInvestedInCash')}
      </Typography>
      <Typography className='subtitle' fontSize='16px' color='secondary'>
        {t('YouCanAlwaysAddInvestmentDetails')}
      </Typography>
      <Grid container justifyContent='center' mt={2}>
        <Grid item sm={editing ? 10 : 6}>
          <InvestmentBalanceForm onSubmit={async () => {}} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestmentBalanceEditViewWrapper;
