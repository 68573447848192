import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Grid, IconButton, Link, Typography } from '@mui/material';
import { Domain } from '@3nickels/data-modules';
import {
  convertToTitleCase,
  formatDateMMDDYYYY,
  formatDecimalDollars,
} from '../../../helpers/utilityFunctions';
import { Svgs } from '../../../assets/svg';
import { PricebookBundle } from '../../../models/PricebookData';
import UnsubscribeModal from '../manage-subscription/UnsubscribeModal';

export const getSubscriptionStatus = (subscription: Domain.SubscriptionInfo) => {
  const expirationDate = new Date(subscription.expirationDate);
  const now = new Date();
  return {
    cancelled: subscription.status === 'cancelled',
    isActive: subscription.status === 'active' || subscription.status === 'trialing',
    expired: expirationDate < now,
  };
};

export const getName = (
  subscription: Domain.SubscriptionInfo,
  threeNickelsProducts: PricebookBundle[] | undefined
): string => {
  if (subscription.origin === Domain.PaymentOriginEnum.STRIPE) {
    const pricebook = threeNickelsProducts?.find(
      (p) => p.pricebook.id === subscription.pricebookId
    );
    return pricebook?.pricebook.pricebookName ?? '';
  } else {
    return subscription.name;
  }
};

interface SubscriptionSectionProps {
  subscriptionInfoData: Domain.SubscriptionInfo[] | undefined;
  threeNickelsProducts: PricebookBundle[] | undefined;
}

const SubscriptionSection: React.FC<SubscriptionSectionProps> = ({
  subscriptionInfoData,
  threeNickelsProducts,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!subscriptionInfoData || subscriptionInfoData.length === 0)
    return (
      <Card className='card-row' sx={{ padding: '10px', marginTop: '20px' }}>
        <Grid container display='flex' alignItems='center'>
          <Grid item xs={2.5} />
          <Grid
            item
            xs={7}
            style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Typography variant='p16' color='secondary'>
              {t('YouDontHaveAnySubscriptions')}
            </Typography>
          </Grid>
          <Grid item xs={0.5} />
          <Grid item xs={2} paddingRight={1} style={{ textAlign: 'end' }}>
            <Link variant='p12' onClick={() => navigate('/settings/manage-subscription')} noWrap>
              {t('ViewCurrentPlan')}
            </Link>
          </Grid>
        </Grid>
      </Card>
    );

  return (
    <>
      <Grid
        container
        mt={3}
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        padding='0px 5px'>
        <Grid item xs={0.5} paddingLeft={1} />
        <Grid item xs={4}>
          <Typography variant='p14' color='primary'>
            {t('Description')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='p14' color='primary'>
            {t('Price')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='p14' color='primary'>
            {t('Duration')}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant='p14' color='primary'>
            {t('Origin')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='p14' color='primary'>
            {t('Date')}
          </Typography>
        </Grid>
      </Grid>
      {subscriptionInfoData.map((subscription, index) => {
        return (
          <SubscriptionCard
            key={index}
            subscription={subscription}
            threeNickelsProducts={threeNickelsProducts}
          />
        );
      })}
    </>
  );
};

interface SubscriptionCardProps {
  subscription: Domain.SubscriptionInfo;
  threeNickelsProducts: PricebookBundle[] | undefined;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscription,
  threeNickelsProducts,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const name = getName(subscription, threeNickelsProducts);
  const status = getSubscriptionStatus(subscription);
  const orgPays = subscription.paidBy === Domain.PaidByEnum.ORG;
  const deletable = status.isActive && !orgPays;

  return (
    <>
      <UnsubscribeModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        subscription={subscription}
        threeNickelsProducts={threeNickelsProducts}
      />
      <Card className='card-row' sx={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
        <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={0.5} paddingLeft={1}>
            {deletable && (
              <IconButton
                className='show-on-hover'
                sx={{ opacity: 0, padding: 0 }}
                onClick={() => {
                  setModalOpen(true);
                }}
                disableRipple>
                <Svgs.IpmIconDelete />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant='p16Bold' color='secondary'>
              {name}
              <Typography
                ml={3}
                variant='p16Bold'
                color={status.isActive ? 'primary' : 'error.main'}
                sx={{ display: 'inline' }}>
                {status.isActive ? t('Active') : status.cancelled ? t('Cancelled') : t('Expired')}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='p16Bold' color='secondary'>
              {orgPays ? '$0*' : `${formatDecimalDollars(subscription.price / 100)}`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='p16' color='secondary'>
              {convertToTitleCase(subscription.interval)}-to-
              {subscription.interval.toLowerCase()}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant='p16' color='secondary'>
              {subscription.origin === Domain.PaymentOriginEnum.ANDROID
                ? 'Google'
                : convertToTitleCase(subscription.origin)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='p16' color='secondary'>
              {status.isActive ? t('Renews') : status.cancelled ? t('Expires') : t('Expired')}&nbsp;
              {formatDateMMDDYYYY(subscription.expirationDate)}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {orgPays && (
        <Typography variant='p12' color='secondary'>
          {t('YourOrganizationIsPayingForYourSubscription')}
        </Typography>
      )}
    </>
  );
};

export default SubscriptionSection;
