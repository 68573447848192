import { Box, MenuItem, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';

export type VolunteeringGoalFormProps = {
  model?: Data.Goals.VolunteeringGoalFormData;
  onSubmit: (values: Data.Goals.VolunteeringGoalFormData) => Promise<void>;
};

const VolunteeringGoalForm: React.FC<VolunteeringGoalFormProps> = (props) => {
  const { formState } = useFormContext();
  const { model } = props;
  const charityCategories = Hooks.useCharityCategoryListItems();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Goals.VolunteeringGoalFormData>
          error={formState.errors.nameOfPlace !== undefined}
          helperText={formState.errors.nameOfPlace?.message?.toString()}
          defaultValue={props.model?.nameOfPlace}
          inputProps={{ maxLength: 64 }}
          label='Name Of Place'
          name='nameOfPlace'
          autoFocus
        />

        <SelectInput<Data.Goals.VolunteeringGoalFormData>
          error={formState.errors.typeOfPlace !== undefined}
          helperText={formState.errors.typeOfPlace?.message?.toString()}
          defaultValue={props.model?.typeOfPlace}
          label='Type Of Place'
          name='typeOfPlace'
          autoFocus>
          {charityCategories.map((x) => (
            <MenuItem value={x.key}>{x.value}</MenuItem>
          ))}
        </SelectInput>

        <ToggleRadioButtonGroup<Data.Goals.VolunteeringGoalFormData>
          error={formState.errors.volunteeringFrequency !== undefined}
          helperText={formState.errors.volunteeringFrequency?.message?.toString()}
          name='volunteeringFrequency'
          label='Volunteering Frequency'
          row
          defaultValue={model?.volunteeringFrequency}
          items={[
            { label: 'Weekly', value: Domain.VolunteeringFrequencyEnum.Weekly },
            { label: 'Monthly', value: Domain.VolunteeringFrequencyEnum.Monthly },
          ]}
        />

        <TextInput<Data.Goals.VolunteeringGoalFormData>
          error={formState.errors.numberOfHours !== undefined}
          helperText={formState.errors.numberOfHours?.message?.toString()}
          defaultValue={props.model?.numberOfHours}
          label='Number Of Hours'
          name='numberOfHours'
          type='integer'
          placeholder='0'
          max={999}
          autoFocus
        />
      </Stack>
    </Box>
  );
};

export default VolunteeringGoalForm;
