import { TableContainer, Table, TableBody, Grid, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonSpendingSummaryTransactionsTable: React.FC = () => {
  return (
    <TableContainer className='spending-overview-transactions-container'>
      <Table className='spending-overview-transactions'>
        <TableBody className='spending-transactions-view'>
          <SkeletonTransaction />
          <SkeletonTransaction />
          <SkeletonTransaction />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SkeletonTransaction: React.FC = () => {
  return (
    <Grid className='transaction-row'>
      <Grid className='description-section'>
        <Skeleton variant='circular' width='30px' height='30px' />
        <Skeleton className='date' variant='rectangular' height='30px' />
        <Skeleton className='description' variant='rectangular' height='30px' />
      </Grid>
      <Grid className='amount-section' display='flex' width='50%'>
        <Skeleton
          className='category'
          variant='rectangular'
          height='30px'
          style={{ marginRight: '24px' }}
        />
        <Skeleton className='amount' variant='rectangular' height='30px' />
      </Grid>
    </Grid>
  );
};

export default SkeletonSpendingSummaryTransactionsTable;
