import { Backdrop } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { MainContent } from '../dashboard/style';
import { useDeviceType } from '../hooks/useDeviceType';
import { Loading, useLoading } from '../hooks/useLoading';
import { Toast } from '../models/Toast';
import { useService, useServiceContainer } from '@aesop-fables/containr-react';
import { Authentication, Data, Domain, Hooks } from '@3nickels/data-modules';
import { useAuthenticatedFlag, useAuthenticationStatus, useNeedsOnboarding } from '../hooks';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import SignUpRoutes from './SignUpRoutes';
import { useListener } from '@aesop-fables/scrinium';
import { LicenseDataListener } from '../data/license';
import { PaymentDataListener } from '../data/payment';
import { TermsDataListener } from '../data/terms';
import { logger } from '../Logging';
import { useAppToasts } from '../AppToasts';
import { createAdviceRoutes } from './AdviceRoutes';
import { ContentViewProvider } from '../pages/layout/ContentViewContext';
import { createAdviceOnboardingRoutes } from './AdviceOnboardingRoutes';
import { createFreeRoutes } from './FreeRoutes';
import { ApiKeys } from '../api/apis/ApiKeys';
import { PlatformSwitchApi } from '../api/apis/PlatformSwitchApi';
import { AuthTokenApi } from '../api/apis/AuthTokenApi';

function useApplicationListeners() {
  useListener(Data.Users.UserDataListener);
  useListener(Data.People.PersonDataListener);
  useListener(Data.Accounts.AccountDataListener); // is this even needed at all?
  useListener(Data.FederalLimits.FederalLimitsListener); // account deatails, maybe could stay
  useListener(Data.Keys.KeysDataListener); // maybe stay? probably only in personal details
  useListener(Data.Users.AccountDetailsCompleteListener); // should probably stay
  useListener(Data.Savings.SavingsDataListener); // do we even use this?
  useListener(Data.StandardDeduction.StandardDeductionListener); // goals - giving for tax benefits
  useListener(Data.FinancialFitness.FinancialFitnessListener); // probably should stay here since it never needs to be reloaded
  useListener(Data.Communications.CommunicationsDataListener); // alerts
  useListener(Data.TermsAndConditions.TermsAndConditionsDataListener); // maybe should stay?? used in onboarding settings
  useListener(Data.SubscriptionInfo.SubscriptionInfoDataListener); // used in settings, think should stay here
  useListener(Data.FreeTrial.FreeTrialDataListener); // settings
  useListener(Data.Media.MediaDataListener); // about us
  useListener(Data.Plan.PlanDataListener);
  useListener(LicenseDataListener); // what is this for?
  useListener(PaymentDataListener); // is this still needed?
  useListener(TermsDataListener); // is this still needed?
}

function AppGateway() {
  useApplicationListeners();

  const { loading } = useLoading();
  const authContext = useService<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const isAuthenticated = useAuthenticatedFlag();
  const needsOnboarding = useNeedsOnboarding();
  const { accountId, productType } = Hooks.usePrincipalUser();
  const { isReady, isSessionExpired } = useAuthenticationStatus();
  const { setToast } = useAppToasts();
  const { isMobile, isTablet } = useDeviceType();
  const container = useServiceContainer();
  const authTokenApi = useService<AuthTokenApi>(ApiKeys.AuthToken);
  const platformSwitchApi = useService<PlatformSwitchApi>(ApiKeys.PlatformSwitch);

  const redirectMobileUsers = useMemo(() => {
    return async () => {
      await authTokenApi.generateCookie();
      const { data } = await platformSwitchApi.webToMobile();
      // set redirect uri in session storage instead of in url
      sessionStorage.setItem('mobile-redirect-url', data.redirectUrl);
      const baseUrl = 'https://' + window.location.host;
      window.location.replace(baseUrl.concat('/mobile.html'));
    };
  }, [platformSwitchApi]);

  useEffect(() => {
    if (isAuthenticated && (isMobile || isTablet)) {
      redirectMobileUsers();
    }
  }, [isMobile, isAuthenticated]);

  useEffect(() => {
    if (isReady && isSessionExpired) {
      authContext.setIsAuthenticated(false);
      setToast(
        new Toast({
          message: 'You have been logged out due to inactivity',
          severity: 'error',
          open: true,
        })
      );
    }
  }, [isSessionExpired, isReady, setToast]);

  // Web crawlers don’t always execute JavaScript code when examining a webpage, so if you want to make sure that they read your meta tags, you need to set them up before the browser receives the page.
  const router = useMemo(() => {
    if (isAuthenticated) {
      // wait for user data to load before determining free/advice routes
      if (accountId && !isMobile && !isTablet) {
        // advice
        if (productType === Domain.ProductTypeEnum.advice) {
          if (needsOnboarding) {
            console.log('needs onboarding');
            return createBrowserRouter(createAdviceOnboardingRoutes(container));
          }
          return createBrowserRouter(createAdviceRoutes(container));
        }
        // add free routes
        return createBrowserRouter(createFreeRoutes(container));
      }
      return createBrowserRouter([
        {
          path: '*',
          element: <Loading />,
        },
      ]);
    }
    // unauthenticated
    return createBrowserRouter(createRoutesFromElements(SignUpRoutes()));
  }, [isAuthenticated, needsOnboarding, accountId, productType]);

  logger.debug('[guard] AppGateway', { loading });
  return (
    <MainContent isMobile={isMobile || isTablet}>
      <Backdrop open={loading}>
        <Loading />
      </Backdrop>
      {isReady && (
        <ContentViewProvider>
          <Hooks.PlaidWizardProvider>
            <Data.Transactions.TransactionsSummaryProvider>
              <RouterProvider router={router} />
            </Data.Transactions.TransactionsSummaryProvider>
          </Hooks.PlaidWizardProvider>
        </ContentViewProvider>
      )}
    </MainContent>
  );
}

export default AppGateway;
