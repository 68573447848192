import { SvgIcon, Tab, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Box, { type BoxProps } from '@mui/material/Box';
import React from 'react';
import { AlertMenu } from '../../components/header/AlertMenu';
import { type Profile } from '../../components/header/ProfileMenu';
import { Colors, Percentages } from '../../themes';
import { Data } from '@3nickels/data-modules';
import { ProfileData } from '@3nickels/data-modules/lib/domain';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import Images from '../../components/images';
import { useMessage } from '../../hooks/useMessage';
import CollapseMessage from '../../components/CollapseMessage';
import { Link, useNavigate } from 'react-router-dom';
import { Tabs } from '../../components/Tabs';
import { Notifications, Person } from '@mui/icons-material';
import { type BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useContentView } from './ContentViewContext';

export type HeaderProps = {
  elevation?: number;
  children?: React.ReactNode;
  leftChildren?: React.ReactNode;
} & BoxProps;

export const Header: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const { message, hideMessage } = useMessage();
  const appStorage = useAppStorage();
  const personCache = appStorage.retrieve<Data.People.PersonCompartments>(
    Data.People.personStorageKey
  );
  const personData = useObservable(personCache.observe$<ProfileData>('person'));
  const [alertAnchorEl, setAlertAnchorEl] = React.useState<undefined | HTMLElement>(undefined);

  const {
    header: { hideProfile, selectedTab },
  } = useContentView();

  const handleAlerts = (event: React.MouseEvent<HTMLElement>) => {
    setAlertAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const profile: Profile = {
    firstName: personData?.firstName ?? '',
  };

  const cascadedProps: HeaderProps = {
    elevation: 0,
    height: 'min-content',
    width: Percentages.Full,
    gridArea: 'header',
    gridColumn: 'span 2',
    ...props,
  };

  return (
    <Box {...cascadedProps}>
      <AppBar
        className='app-header'
        sx={{ backgroundColor: Colors.tertiaryBase }}
        elevation={props.elevation ?? 1}
        position='fixed'>
        <Toolbar>
          <Stack flexDirection='row' justifyContent='flex-start' p={4}>
            <Link to='/'>
              <SvgIcon inheritViewBox component={Images.NickelsLogoSvg} fontSize='large' />
            </Link>
          </Stack>
          {props.leftChildren && props.leftChildren}
          <Stack flexDirection='row' justifyContent='flex-end' marginLeft='auto' minHeight='50px'>
            {!props.children && (
              <Tabs value={selectedTab ?? false} variant='standard'>
                <Tab
                  key={'alerts'}
                  value='alerts'
                  label='Alerts'
                  icon={
                    <StyledBadge
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={[].length} // notifications length
                      color='error'>
                      <Notifications />
                    </StyledBadge>
                  }
                  iconPosition='end'
                  onClick={handleAlerts}
                />
                {!hideProfile && (
                  <Tab
                    key={'profile'}
                    value='profile'
                    label={profile.firstName}
                    icon={<Person />}
                    iconPosition='end'
                    onClick={handleProfile}
                  />
                )}
              </Tabs>
            )}
            {props.children && props.children}
          </Stack>
        </Toolbar>
        <AlertMenu notifications={[]} anchorEl={alertAnchorEl} setAnchorEl={setAlertAnchorEl} />
        <CollapseMessage
          severity={message?.severity}
          message={message?.content}
          show={message.show}
          hide={hideMessage}
        />
      </AppBar>
    </Box>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    minWidth: '14px',
    height: '14px',
    fontSize: '10px',
    fontWeight: 'bold',
    right: 2,
    top: 5,
    backgroundColor: '#9a5238',
  },
}));
