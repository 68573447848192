import { AppGateway } from './routes';
import { LoadingProvider } from './hooks/useLoading';
import { MessageProvider } from './hooks/useMessage';
import { ToastbarProvider } from './AppToasts';
import { AnimationProvider } from './hooks/useControlledAnimation';

export const mobileMargin = '15px';

function App() {
  return (
    <ToastbarProvider>
      <LoadingProvider>
        <MessageProvider>
          <AnimationProvider>
            <AppGateway />
          </AnimationProvider>
        </MessageProvider>
      </LoadingProvider>
    </ToastbarProvider>
  );
}

export default App;
