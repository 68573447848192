import { useMemo } from 'react';
import { Domain } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';

export const useBackHandler = (
  holisticAdvice: Domain.HolisticAdviceOverview | undefined,
  type: string,
  currentId?: number
) => {
  const navigate = useNavigate();

  const getLastGoalId = () => {
    const lastGoalIndex = (holisticAdvice?.goalSummaries?.length ?? 0) - 1;
    return lastGoalIndex >= 0
      ? holisticAdvice?.goalSummaries[lastGoalIndex]?.advisedGoalPlan.id
      : undefined;
  };

  const getLastInvestmentId = () => {
    const lastInvIndex = (holisticAdvice?.investmentMixes?.length ?? 0) - 1;
    return lastInvIndex >= 0 ? holisticAdvice?.investmentMixes[lastInvIndex]?.id : undefined;
  };

  const previousInvestmentAccount = (previousId: number) => {
    if (previousId === 2) {
      return '/holistic-advice/investments/brokerage';
    } else if (previousId === 4) {
      return '/holistic-advice/investments/ira';
    } else return `/holistic-advice/investments/${previousId}`;
  };

  const resolveNavigationPath = (type: string, currentId?: number) => {
    const lastInvId = getLastInvestmentId();
    const lastGoalId = getLastGoalId();

    switch (type) {
      case 'goal': {
        const index =
          holisticAdvice?.goalSummaries?.findIndex((x) => x.advisedGoalPlan.id === currentId) ?? 0;
        return index > 0
          ? `/holistic-advice/goals/${holisticAdvice?.goalSummaries[index - 1].advisedGoalPlan.id}`
          : '/holistic-advice/budget/monthly';
      }
      case 'investment': {
        const index = holisticAdvice?.investmentMixes?.findIndex((x) => x.id === currentId) ?? 0;
        if (index > 0) {
          const previousId = holisticAdvice?.investmentMixes[index - 1].id ?? 0;
          return previousInvestmentAccount(previousId);
        } else if (lastGoalId) {
          return `/holistic-advice/goals/${lastGoalId}`;
        }
        break;
      }
      case 'plan': {
        if (lastInvId) {
          return previousInvestmentAccount(lastInvId);
        } else if (lastGoalId) {
          return `/holistic-advice/goals/${lastGoalId}`;
        }
        break;
      }
      default:
        break;
    }

    return '/holistic-advice/budget/monthly';
  };

  const backHandler = useMemo(() => {
    const navigatePath = resolveNavigationPath(type, currentId);
    return () => navigate(navigatePath);
  }, [holisticAdvice, currentId, type, navigate]);

  return backHandler;
};
