/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { GoalsLayoutMeta } from '../../GoalsLayout';
import CashGivingGoalBasicForm from './CashGivingGoalBasicForm';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { GoalFormTitle } from '../../../../components/FormTitle';
import { useTranslation } from 'react-i18next';

const formSchema = Yup.object({
  dollarOrPercentSelf: Yup.string().required('Required'),
  personalGivingDream: Yup.string().when('dollarOrPercentSelf', {
    is: Domain.GivingPlanDollarPercentEnum.Dollar.toString(),
    then: (schema) =>
      schema
        .required('Dream Giving Amount is required')
        .test('amount', 'Amount must be greater than $0', (amount) => {
          const cleanNumber = cleanWholeNumberStr(amount);
          return cleanNumber > 0;
        }),
  }),
  personalGivingDreamPercent: Yup.string().when('dollarOrPercentSelf', {
    is: Domain.GivingPlanDollarPercentEnum.Percent.toString(),
    then: (schema) =>
      schema
        .required('Dream Giving Amount is required')
        .test('amount', 'Amount must be greater than $0', (amount) => {
          const cleanNumber = cleanWholeNumberStr(amount);
          return cleanNumber > 0;
        }),
  }),
});

export const CashGivingGoalBasicEditViewWrapper: React.FC<EditViewProps> = ({
  editing,
  onBack,
}) => {
  const { loading, currentStep, wizard } =
    Hooks.useCashGivingGoalWizard<Data.Goals.CashGivingGoalBasicFormData>();
  const isStarted = useObservable(wizard.isStarted$);

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({});
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && ( // don't even mount until correct step is set
        <FormLoader loading={loading}>
          <CashGivingGoalBasicEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface CashGivingGoalBasicEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Goals.CashGivingGoalBasicFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.CashGivingGoalWizard;
}

const CashGivingGoalBasicEditView: React.FC<CashGivingGoalBasicEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigateFn = useNavigate();
  const ready = useObservableGuardCondition();
  const { setLoading } = useLoading();
  const methods = useForm<Data.Goals.CashGivingGoalBasicFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema),
  });

  const goalId = useLoaderData();
  const editTitle = goalId !== null;
  const navigate = (url: string) => {
    navigateFn(`${url}`, { state: { editTitle } });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatInputs = (values: any) => {
    const personalGivingDream = cleanWholeNumberStr(values.personalGivingDream);
    const personalGivingDreamPercent = cleanWholeNumberStr(values.personalGivingDreamPercent);

    return { personalGivingDream, personalGivingDreamPercent };
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({
        ...values,
        ...cleanedValues,
      });
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/goals/giving/cash/details');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <GoalFormTitle editing={editTitle} type={Domain.GoalTypeEnum.Cash_Giving} />
              <Typography mt='7px' variant='p12' color='primary'>
                {t('NoteYourDreamGivingIsLimited')}
              </Typography>
              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <CashGivingGoalBasicForm model={currentStep.model} onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next: Current Giving',
  title: 'Giving',
  headerVariant: 'hex',
  hexSteps: {
    steps: 2,
    currentStep: 1,
  },
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(CashGivingGoalBasicEditViewWrapper, meta);
