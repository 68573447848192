import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ContentView from '../layout/Content';
import { Grid, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { useTranslation } from 'react-i18next';
import { useControlledAnimation } from '../../hooks/useControlledAnimation';
import { Data, Hooks } from '@3nickels/data-modules';
import { Svgs } from '../../assets/svg';
import { useLoading } from '../../hooks/useLoading';
import HolisticAdviceProgressIndicator from './progress-indicator/HolisticAdviceProgressIndicator';
import { useContentView } from '../layout/ContentViewContext';

export type HolisticFinancialAdviceLayout = Record<string, unknown>;

export declare type HolisticFinancialAdviceLayoutMeta = {
  headerVariant?: 'default' | 'download';
  showProgress?: boolean;
};

const HolisticFinancialAdviceHeader: React.FC = () => {
  const { t } = useTranslation();
  // TODO -- need to parameterize this
  return (
    <Typography variant='p18Bold' color='primary' alignSelf='end'>
      {t('HolisticFinancialAdvice')}
    </Typography>
  );
};

const DownloadMyPlanButton: React.FC = () => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const holisticAdvice = Hooks.useHolisticAdviceOverview();

  const downloadGuide = async () => {
    if (holisticAdvice?.caseId) {
      try {
        setLoading(true);
        const response = await commands.execute(
          Data.HolisticAdvice.GenerateHolisticGuide,
          holisticAdvice.caseId
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'guide.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Button label='DownloadMyPlan' onClick={downloadGuide}>
      <Svgs.IconDownload style={{ marginLeft: '10px' }} />
    </Button>
  );
};

const HolisticFinancialAdviceLayoutComponent: React.FC<HolisticFinancialAdviceLayout> = () => {
  const { meta, setMeta } = useLayoutMeta<HolisticFinancialAdviceLayoutMeta>();
  const navigate = useNavigate();
  const location = useLocation();
  const { controls } = useControlledAnimation();
  const { sideNav } = useContentView();

  useEffect(() => {
    controls.start('visible');
  }, [location.pathname]);

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  const headerVariableControl = {
    default: <HolisticFinancialAdviceHeader />,
    download: <DownloadMyPlanButton />,
  };

  return (
    <ContentView
      sideNavMenuItems={sideNav()}
      progressIndicator={meta.showProgress && <HolisticAdviceProgressIndicator />}
      headerChildren={headerVariableControl[meta.headerVariant ?? 'default']}>
      <Grid display='flex'>
        <Outlet key={location.pathname} context={{ setMeta }} />
      </Grid>
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default HolisticFinancialAdviceLayoutComponent;
