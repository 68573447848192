import * as Yup from 'yup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Data, Hooks } from '@3nickels/data-modules';
import { Box, Drawer, Grid, Stack, Typography } from '@mui/material';
import { Spacing } from '../../themes';
import FormContent from '../../components/form/FormContent';
import { WizardFooter } from '../../components/form/WizardFooter';
import TextInput from '../../components/form/TextInput';
import { useMessage } from '../../hooks/useMessage';

const contactFormSchema = Yup.object({
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

interface MessageDrawerProps {
  open: boolean;
  onClose: () => void;
}

const MessageDrawer: React.FC<MessageDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const { person } = Hooks.useCombinedSelfData();
  const methods = useForm<Data.ContactForm.ContactFormData>({
    resolver: yupResolver(contactFormSchema),
  });

  useEffect(() => {
    if (!open) {
      methods.reset({
        subject: '',
        message: '',
      });
    }
  }, [open, methods]);

  const sendMessage = async (values: Data.ContactForm.ContactFormData) => {
    try {
      await commands.execute(Data.ContactForm.Commands.SendMessage, {
        accountId: person?.accountId,
        ...values,
      });
      onClose();
      showMessage('Message submitted! Look out for an email from us.');
    } catch (err) {
      showMessage('Error sending message', 'info');
    }
  };

  const onSubmit = methods.handleSubmit((data, e) => {
    sendMessage(data);
    e?.preventDefault();
  });

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Grid p={Spacing.xxs}>
        <Typography variant='h1' color='primary'>
          {t('SendUsAMessage')}
        </Typography>
        <Typography mt={2} mb={2} variant='p16' color='secondary'>
          {t('BrieflyDescribeWhatTheSituationIs')}
        </Typography>
        <FormContent formProviderProps={methods}>
          <Box
            component='form'
            onSubmit={onSubmit}
            display='flex'
            justifyContent='center'
            alignItems='center'>
            <Stack spacing={3} sx={{ width: '70%' }}>
              <TextInput<Data.ContactForm.ContactFormData>
                error={methods.formState.errors.subject !== undefined}
                helperText={methods.formState.errors.subject?.message?.toString()}
                label='Subject'
                name='subject'
                placeholder={t('TypeSubjectHere') as string}
                autoFocus
              />
              <TextInput<Data.ContactForm.ContactFormData>
                error={methods.formState.errors.message !== undefined}
                helperText={methods.formState.errors.message?.message?.toString()}
                label='Message'
                name='message'
                placeholder={t('TypeMessageHere') as string}
                minRows={8}
                inputProps={{ maxLength: 1000 }}
                multiline
              />
            </Stack>
          </Box>
        </FormContent>
        <WizardFooter
          color='primary'
          onBack={onClose}
          onDone={onSubmit}
          backLabel='Cancel'
          nextLabel='Submit'
        />
      </Grid>
    </Drawer>
  );
};

export default MessageDrawer;
