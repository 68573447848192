/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import IncomeSection from './sections/IncomeSection';
import SavingsSection from './sections/SavingsSection';
import SpendingSection from './sections/SpendingSection';
import GivingSection from './sections/GivingSection';
import { BudgetSectionProps } from './Types';
import { Hooks } from '@3nickels/data-modules';

const BudgetDetailsManagerWrapper: React.FC<BudgetSectionProps> = ({ mode }) => {
  // Probably need to reload budget stuff here
  // This is just here because I know we're going to need a headless component
  const budget = Hooks.useBudgetSummary();

  return <BudgetDetailsManager mode={mode} budget={budget} />;
};

export const BudgetDetailsManager: React.FC<BudgetSectionProps> = ({ budget, mode }) => {
  return (
    <>
      <IncomeSection budget={budget} mode={mode} />
      <SavingsSection budget={budget} mode={mode} />
      <SpendingSection budget={budget} mode={mode} />
      <GivingSection budget={budget} mode={mode} />
    </>
  );
};

export default BudgetDetailsManagerWrapper;
