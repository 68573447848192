import { redirect } from 'react-router-dom';
import { RedirectHandler } from './RedirectHandler';
import { injectSubject } from '@aesop-fables/scrinium';
import { Data, Domain } from '@3nickels/data-modules';
import { Observable, firstValueFrom } from 'rxjs';

export class MissingPersonalInfoHandler implements RedirectHandler {
  constructor(
    @injectSubject(Data.People.PersonServices.SelfData)
    private readonly selfData: Observable<Domain.SelfData>
  ) {}
  async matches() {
    const person = await firstValueFrom(this.selfData);
    if (!person) {
      return false;
    }

    return !person.birthDate || !person.firstName || !person.lastName;
  }

  handle(): Response {
    return redirect('/personal-info');
  }
}
