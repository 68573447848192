/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { sortData } from '../helpers/utilityFunctions';
import { Svgs } from '../assets/svg';
import {
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import { MemberData } from '../models/OrganizationData';
import { ActivePaymentMethodData } from '../api/apis/PaymentApi';
import { Toast } from '../models/Toast';
import React from 'react';
import { useAppToasts } from '../AppToasts';
import Modal from './Modal';
import Spacer from './Spacer';
import Row from './Row';
import { Caret, CaretPlaceholder } from './Caret';

type PrimitiveType = React.ReactNode;
type DeleteModalType = 'paymentMethod' | 'member' | 'admins'; // create a type for each delete modal

function objectValues<T extends object>(obj: T) {
  return Object.keys(obj)
    .filter((objKey) => objKey !== 'id') // filter out ids
    .map((objKey) => obj[objKey as keyof T]);
}

function isPrimitive(value: any): value is PrimitiveType {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'object'
  );
}

interface DataTableProps {
  title?: string;
  headers: {
    label: string;
    title: string;
  }[];
  data: {
    id?: number | string;
    deletableRow?: boolean;
    isDefault?: boolean;
    isActive?: boolean;
  }[];
  deleteModalType?: DeleteModalType;
  lastColumnIsButtons?: boolean;
  buttonText?: string;
  onButtonClick?: (row: any) => void;
  onDelete?: (row: any) => Promise<void>;
  emptyStateText?: string;
}

function DataTable(props: DataTableProps) {
  const {
    headers,
    data,
    title,
    buttonText,
    onButtonClick,
    onDelete,
    deleteModalType,
    emptyStateText,
  } = props;
  const lastColumnIsButtons = props.lastColumnIsButtons ?? true;
  const [sortedData, setSortedData] = useState<
    { deletableRow?: boolean; isDefault?: boolean; isActive?: boolean }[]
  >([...data]);
  const [sortDirection, setSortDirection] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeData, setActiveData] = useState<any>();
  const [sortBy, setSortBy] = useState(headers[0].label || null);
  const [modalText, setModalText] = useState<{
    title: string;
    primaryText: string;
    secondaryText?: string;
    toastMessage: string;
  }>();
  const { setToast } = useAppToasts();
  const atLeastOneRowIsDeletable = data.some((row) => row.deletableRow);

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  useEffect(() => {
    if (!deleteModalType) return;
    createContext();
  }, [activeData]);

  const createContext = () => {
    if (!activeData) return;
    if (deleteModalType === 'paymentMethod') {
      setModalText({
        title: 'Remove payment method?',
        primaryText: `Are you sure you want to remove ${
          (activeData as ActivePaymentMethodData).name
        } as a payment method?`,
        secondaryText: 'This action cannot be undone.',
        toastMessage: `${(activeData as ActivePaymentMethodData).name} deleted!`,
      });
      return;
    }
    if (deleteModalType === 'admins') {
      setModalText({
        title: 'Remove admin',
        primaryText: `Are you sure you want to remove ${
          (activeData as MemberData).firstName + ' ' + (activeData as MemberData).lastName
        } as an admin?`,
        secondaryText:
          'This will remove any association between their account and your organization.',
        toastMessage: 'Admin removed',
      });
      return;
    }
    if (deleteModalType === 'member') {
      setModalText({
        title: 'Discontinue member',
        primaryText: `Are you sure you want to discontinue ${
          (activeData as MemberData).firstName + ' ' + (activeData as MemberData).lastName
        } as a member?`,
        secondaryText:
          'This will remove any association between their account and your organization.',
        toastMessage: 'Member removed',
      });
      return;
    }
  };

  const onDeleteClick = (row: any) => {
    if (deleteModalType !== undefined) {
      setActiveData(row);
      setModalOpen(true);
      return;
    }
    deleteRow(row);
  };
  const deleteRow = async (row: any) => {
    if (onDelete) {
      await onDelete(row);
    } else {
      sortedData.splice(sortedData.indexOf(row), 1);
      setSortedData([...sortedData]);
    }
  };

  const onHandleSave = async () => {
    await deleteRow(activeData);
    setToast(new Toast({ message: modalText?.toastMessage, severity: 'success', open: true }));
  };

  return (
    <>
      {deleteModalType && (
        <Modal
          title={modalText?.title ?? ''}
          handleSave={onHandleSave}
          data={activeData}
          open={modalOpen}
          setOpen={setModalOpen}>
          <Typography variant='p14' color='secondary.main' gutterBottom>
            {modalText?.primaryText ?? ''}
          </Typography>
          <Spacer height='xxs' />
          {modalText?.secondaryText ? (
            <Typography variant='p14' color='secondary.main' gutterBottom>
              {modalText?.secondaryText ?? ''}
            </Typography>
          ) : null}
          <Spacer height='sm' />
        </Modal>
      )}
      {title ? (
        <>
          <Typography variant='p20SemiBold' color='secondary.main'>
            {title}
          </Typography>
          <Spacer height='xs' />
        </>
      ) : null}
      {sortedData.length > 0 ? (
        <Card variant='ghost' color='primary'>
          <CardContent className={atLeastOneRowIsDeletable ? 'deletable' : 'dataTable'}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    {/* TODO need keys ; should get Ids on actual object */}
                    {atLeastOneRowIsDeletable && (
                      <TableCell sx={{ border: 'none', width: '50px' }}>&nbsp;</TableCell>
                    )}
                    {headers.map((header, index) => (
                      <TableCell
                        style={{
                          userSelect: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          sortData(
                            sortedData,
                            header.label,
                            sortDirection,
                            setSortedData,
                            setSortBy
                          );
                          setSortDirection(!sortDirection);
                        }}
                        key={index}>
                        <Row
                          style={{
                            alignItems: 'center',
                          }}>
                          <>{header.title}</>
                          {sortBy === header.label ? (
                            <Caret sortDirection={sortDirection} />
                          ) : (
                            <CaretPlaceholder />
                          )}
                        </Row>
                      </TableCell>
                    ))}
                    {lastColumnIsButtons ? <TableCell>&nbsp;</TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row: any, i: number) => (
                    <TableRow key={i}>
                      {row.deletableRow && !row.isDefault && !row.isActive ? (
                        <TableCell sx={{ width: '50px' }}>
                          <IconButton color='primary' onClick={() => onDeleteClick(row)}>
                            <Svgs.IconDelete />
                          </IconButton>
                        </TableCell>
                      ) : (
                        atLeastOneRowIsDeletable && <TableCell sx={{ width: '50px' }}></TableCell>
                      )}
                      {objectValues(row).map((entry, j) => {
                        if (j === 0 && (row.isDefault || row.isActive)) {
                          // if leftmost column and default or active
                          return typeof entry === 'boolean' ? null : (
                            <TableCell key={j} variant='dataBodyBold'>
                              {isPrimitive(entry) ? entry : 'Error Reading Table Entry'}
                              <Typography
                                variant='p18Bold'
                                color='primary'
                                sx={{ display: 'inline' }}>
                                &nbsp;&nbsp;{row.isDefault ? `Default` : `Active`}
                              </Typography>
                            </TableCell>
                          );
                        }
                        if (j === 0) {
                          return typeof entry === 'boolean' ? null : (
                            <TableCell key={j} variant='dataBodyBold'>
                              {isPrimitive(entry) ? entry : 'Error Reading Table Entry'}
                            </TableCell>
                          );
                        }
                        return typeof entry === 'boolean' ? null : (
                          <TableCell key={j}>
                            {isPrimitive(entry) ? entry : 'Error Reading Table Entry'}
                          </TableCell>
                        );
                      })}
                      {lastColumnIsButtons && onButtonClick && !row.isDefault ? (
                        <TableCell variant='hasButton'>
                          <Button
                            onClick={() => onButtonClick(row)}
                            style={{ alignSelf: 'flex-end' }}
                            sx={{ typography: { fontSize: 14 } }}
                            variant='outlined'
                            color='primary'>
                            {buttonText ?? `View`}
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      ) : (
        <Card variant='ghost' color='primary'>
          <CardContent sx={{ textAlign: 'center' }} className={'dataTable'}>
            <Typography variant='p20SemiBold' color='secondary.main'>
              {emptyStateText}
            </Typography>
          </CardContent>
        </Card>
      )}
      <Spacer height='lg' />
    </>
  );
}

export default DataTable;
