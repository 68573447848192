/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine } from 'victory';
import { Colors, Spacing, Chart } from '../themes';

interface ChartBarProps {
  height: number;
  width: number;
  formatY: (y: number) => string;
  data: ChartBarData[];
  yAxisLabel?: string;
  formatX?: (x: number | string) => string;
  suppressLabels?: boolean;
  xLines?: ChartXLinesData[];
  yTicksStep?: number;
  formatLabel?: (v: number) => string;
  barLabel?: JSX.Element;
  xTickLabelsStyle?: TickLabelsStyle;
}

export interface ChartBarData {
  x: number | string;
  y: number;
  color: string;
}

type XLineType = 'solid' | 'dashed';

export interface ChartXLinesData {
  y: number;
  color: string;
  type?: XLineType;
  label?: string;
}

export interface TickLabelsStyle {
  fill?: string | ((props: any) => string);
  fontSize?: number;
  fontWeight?: string;
}

export const ChartBar: React.FunctionComponent<ChartBarProps> = (props) => {
  const {
    height,
    width,
    formatY,
    data,
    yAxisLabel,
    formatX,
    suppressLabels,
    yTicksStep,
    xTickLabelsStyle,
  } = props;
  const { xLines, formatLabel } = props;

  const xPadding = width / (data.length + 2);

  const maxYValue = getMaxYValue(data);
  const formatMaxvalue = formatY(maxYValue);
  const yLabelsPadding = (Math.ceil(formatMaxvalue.length / 3) + 3) * 12;
  const tickValues = getTickValues(maxYValue, yTicksStep);
  const barLabelFontSize = Math.ceil(width / (formatMaxvalue.length * 2));
  const fontSizeClamped = Math.max(12, Math.min(barLabelFontSize, 18));

  const leftChartPadding = yAxisLabel ? yLabelsPadding + 10 : yLabelsPadding;

  return (
    // <FadeInView style={[props.style, { height: height }]} onLayout={onLayout}>
    <VictoryChart
      // animate={true}
      height={height}
      theme={Chart.theme}
      padding={{ top: Spacing.lg, bottom: Spacing.xl, left: leftChartPadding, right: Spacing.md }}
      domainPadding={{
        x: [xPadding, xPadding],
        y: [0, 0],
      }}>
      <VictoryAxis
        dependentAxis
        tickValues={tickValues}
        tickFormat={(y) => formatY(y)}
        style={{ axis: { stroke: Colors.tertiaryBase }, ticks: { size: 0 } }}
        tickLabelComponent={<VictoryLabel angle={0} textAnchor='end' />}
      />
      {yAxisLabel && (
        <VictoryAxis
          label={yAxisLabel}
          dependentAxis
          tickFormat={() => ``}
          offsetX={20}
          axisComponent={<></>}
          tickComponent={<></>}
          style={{ grid: { stroke: 0 } }}
        />
      )}
      <VictoryAxis
        tickFormat={(datum) => (formatX ? formatX(datum) : `${datum}`)}
        style={{
          grid: { stroke: 0 },
          ticks: { size: 0 },
          tickLabels: { ...Chart.theme.axis.style.tickLabels, ...xTickLabelsStyle },
        }}
      />
      <VictoryBar
        data={data}
        labels={({ datum }) =>
          suppressLabels ? '' : formatLabel ? formatLabel(datum.y) : formatY(datum.y)
        }
        x='x'
        y='y'
        barRatio={1}
        style={{
          data: {
            fill: ({ datum }) => datum.color,
          },
        }}
        labelComponent={
          props.barLabel ? (
            props.barLabel
          ) : (
            <VictoryLabel
              style={{
                ...Chart.theme.bar.style.labels,
                fontWeight: '900',
                fontSize: fontSizeClamped,
              }}
            />
          )
        }
      />
      {xLines?.map((s, i) => (
        <VictoryLine
          key={i.toString()}
          animate={false}
          y={() => s.y}
          samples={1}
          labels={['', s.label || '']}
          labelComponent={
            <VictoryLabel
              textAnchor='end'
              verticalAnchor='middle'
              style={{ ...Chart.theme.bar.style.labels, fill: s.color }}
            />
          }
          style={{
            data: { stroke: s.color, strokeDasharray: getXLinesType(s.type) },
          }}
        />
      ))}
    </VictoryChart>
    // </FadeInView>
  );
};

export function getXLinesType(type?: XLineType): string | number {
  if (type === 'solid') return 0;
  if (type === 'dashed') return '5 5';

  return 0;
}

function getMaxYValue(arr: ChartBarData[]): number {
  return Math.max(...arr.map((x) => x.y));
}

function getTickValues(maxYValue: number, range?: number): number[] {
  if (range && maxYValue > range) {
    const tickValues: number[] = [...Array(Math.ceil(maxYValue / range)).keys()].map(
      (x) => (x + 1) * range
    );
    return tickValues;
  }
  return [];
}
