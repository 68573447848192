/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import EmbeddedList from '../../../../../components/EmbeddedList';
import {
  BudgetSectionProps,
  calcHousingPayment,
  getAdjustedHousingPayment,
  getAdjustedRentPayment,
} from '../../../Types';
import { Svgs } from '../../../../../assets/svg';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { Box, Divider, Drawer, Grid } from '@mui/material';
import { Colors, Spacing } from '../../../../../themes';
import { LeaveBudgetDetailsModal } from '../../../BudgetDetailsLayout';
import { useNavigate } from 'react-router';
import { HomeRentEditViewWrapper } from './HomeRentEditView';
import { useMutation } from '@aesop-fables/scrinium';
import { useMessage } from '../../../../../hooks/useMessage';
import { SpendingProps } from '../../Types';

// The nested card displays should be its own component but it'll function similarly, I suppose
export const HomeSpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  spendingSummary,
}) => {
  const navigate = useNavigate();
  // TODO -- Split this up by spouse, etc.
  const houses = Hooks.useCombinedHousingData().filter((house) => calcHousingPayment(house) > 0);
  const { wizard } = Hooks.useRentWizard();
  const rentData = Hooks.useRentData();
  const deleteRent = useMutation(new Data.RealEstate.Mutations.DeleteRent());
  const { showMessage } = useMessage();

  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, spendingSummary.housing),
    [spendingSummary, mode]
  );

  const removeRentHandler = (id?: number) => {
    if (id) {
      deleteRent.action(id);
      showMessage('Rental removed!');
    }
  };

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };

  return (
    <CollapsiblePanel
      variant='compressed'
      header='Home'
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomHouse />}
      summary={formatWholeDollars(summary)}>
      <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
      <HomeRentalDrawer open={drawerOpen} closeDrawer={() => setDrawerOpen(false)} />
      <Grid className='embedded-content'>
        <EmbeddedList<Domain.HousingData>
          items={houses}
          keyFn={(x) => x.id?.toString() ?? ''}
          header='Home'
          emptyHeader='No Homes Added'
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) => formatWholeDollars(getAdjustedHousingPayment(x, mode))}
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen(true)}
        />
        <Divider color={Colors.primaryLight} sx={{ borderColor: 'transparent' }} />
        <EmbeddedList<Domain.RentData>
          items={rentData}
          keyFn={(x) => x.id?.toString() ?? ''}
          header='Homes I Rent'
          emptyHeader='Add a Home You Rent'
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) => formatWholeDollars(getAdjustedRentPayment(x, mode))}
          headingProps={{ endAdornment: () => <Svgs.IconAdd /> }}
          onClick={() => {
            wizard.start({});
            setDrawerOpen(true);
          }}
          onClickElement={(x) => {
            wizard.start({ id: x.id });
            setDrawerOpen(true);
          }}
          onDelete={(x) => removeRentHandler(x.id)}
        />
      </Grid>
    </CollapsiblePanel>
  );
};

interface HomeRentalDrawerProps {
  open: boolean;
  closeDrawer: () => void;
}

const HomeRentalDrawer: React.FC<HomeRentalDrawerProps> = ({ closeDrawer, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={closeDrawer}>
      <Box p={Spacing.xxs}>
        <HomeRentEditViewWrapper editing={true} onBack={closeDrawer} />
      </Box>
    </Drawer>
  );
};
