import React, { useMemo } from 'react';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import { Loading } from '../../hooks/useLoading';
import GroupPanels, { Group } from '../../components/GroupPanels';
import LifestyleCard from './LifestyleCard';

export const LifestylePanelsWrapper: React.FC = () => {
  const lifestyleProducts = Hooks.useLifestyleProducts();
  const allLogos = Hooks.useLifestyleProductLogos();

  if (!lifestyleProducts || allLogos.length === 0) {
    return <Loading />;
  }
  return <LifestylePanels lifestyleProducts={lifestyleProducts} allLogos={allLogos} />;
};

interface LifestylePanelsProps {
  lifestyleProducts: Domain.LifestyleProduct[];
  allLogos: Domain.ProductLogo[];
}

const LifestylePanels: React.FC<LifestylePanelsProps> = ({ lifestyleProducts, allLogos }) => {
  const groups: Group[] = useMemo(() => {
    const g: Group[] = [];
    lifestyleProducts?.forEach((lifestyleProduct) => {
      const targetLogo = allLogos.find((x) => x.logoUri === lifestyleProduct.logoUri);
      const logoData = targetLogo?.logoData ?? '';
      g.push({
        textKey: lifestyleProduct.productName,
        onClick: () => {
          window.open(lifestyleProduct.productUrl);
        },
        content: <LifestyleCard product={lifestyleProduct} logoData={logoData} />,
      });
    });

    return g;
  }, [lifestyleProducts, allLogos]);

  return (
    <Grid className='lifestyle-group-panels'>
      <GroupPanels variant='Popover' groups={groups} />
    </Grid>
  );
};

export default LifestylePanels;
