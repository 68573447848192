import { Grid, Typography } from '@mui/material';
import React from 'react';
import { qrCode, road } from '../../assets/png';

export const ToolsHeaderCard: React.FC = () => {
  return (
    <Grid container className='tools-split-card'>
      <Grid className='left-grid' width='100%' justifyContent='space-between' pl={5}>
        <Grid width='100%'>
          <Typography variant='p30Bold' color='secondary'>
            Tools Page Under Construction
          </Typography>
          <Typography mt={1} variant='p16' color='secondary'>
            Check out the mobile app to access tools.
          </Typography>
        </Grid>
        <Grid width='60%'>
          <Grid textAlign='center' alignContent='center'>
            <img
              src={qrCode}
              alt='qr code'
              style={{
                maxWidth: '70px',
                marginBottom: '-16px',
              }}
            />
            <Typography justifyContent='center' variant='p12' color='secondary'>
              Download the Mobile App
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div className='img-wrapper'>
        <img src={road} />
      </div>
    </Grid>
  );
};
