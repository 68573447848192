import React, { useEffect } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import _ from 'lodash';
import SpendingSummaryChart from '../../../spending-summary/SpendingSummaryChart';
import { Button } from '../../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Circle } from '@mui/icons-material';
import { TransactionsSummaryChartColors } from '../../../spending-summary/SpendingSummaryOverview';
import { Svgs } from '../../../../assets/svg';

const SpendingOverviewSection: React.FC = () => {
  const { t } = useTranslation();

  const linkedAccounts = Hooks.useLinkedAccounts();

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('SpendingOverview')}
      </Typography>
      {linkedAccounts.length > 0 && <LinkedAccountsSpendingOverview />}
      {linkedAccounts.length === 0 && <NoLinkedAccountsView />}
    </Grid>
  );
};

const LinkedAccountsSpendingOverview: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { transactionsSummary, loadingSummary, transactionsTimeframe, setTransactionsTimeframe } =
    Hooks.useTransactionsSummary();
  const transactionCategories = Hooks.useTopSpendingCategories(6);
  const spendingTotal = Hooks.useTransactionsSpendingTotal(transactionsSummary);

  useEffect(() => {
    if (
      transactionsTimeframe !==
      Data.Transactions.TransactionTimeFrameEnum['Average Over the Last 3 Months']
    ) {
      setTransactionsTimeframe(
        Data.Transactions.TransactionTimeFrameEnum['Average Over the Last 3 Months']
      );
    }
  }, [transactionsTimeframe]);

  if (
    loadingSummary ||
    transactionsTimeframe !==
      Data.Transactions.TransactionTimeFrameEnum['Average Over the Last 3 Months']
  ) {
    return <SkeletonSpendingOverview />;
  }

  if (transactionCategories.length === 0) {
    return <EmptySpendingOverview />;
  }

  return (
    <Grid className='advice-budget-section' justifyContent='space-between'>
      <Grid>
        <SpendingSummaryChart
          transactionsSummary={transactionCategories}
          colors={TransactionsSummaryChartColors}
        />
      </Grid>
      <Grid display='flex' flexDirection='column' justifyContent='space-between' rowGap='20px'>
        <Grid>
          <Typography variant='p16' color='primary'>
            {t('AvgSpendingLast3Months')}
          </Typography>
          <Typography
            className='no-underline-clickable'
            fontFamily='Montserrat'
            variant='p30'
            color='secondary'
            marginTop={'10px'}
            alignItems='flex-end'
            onClick={() => navigate('spending-summary')}>
            <span style={{ fontWeight: 'bold', fontSize: 40 }}>
              {formatWholeDollars(spendingTotal)}
            </span>
            /mo
          </Typography>
        </Grid>
        <Grid display='grid' columnGap='40px' rowGap='10px' gridTemplateColumns='auto auto'>
          {transactionCategories.map((transaction, index) => (
            <Grid
              key={index}
              className='spending-overview-categories-container'
              onClick={() => navigate('spending-summary')}>
              <Circle className='circle' htmlColor={TransactionsSummaryChartColors[index]} />
              <Typography className='category' variant='p12' color='secondary'>
                {_.startCase(_.toLower(transaction.primaryCategory?.toString()))}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Typography
        className='clickable'
        variant='p12'
        color='primary'
        alignSelf='baseline'
        onClick={() => navigate('spending-summary')}>
        {t('ViewDetails')}
      </Typography>
    </Grid>
  );
};

const EmptySpendingOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid className='advice-budget-section' justifyContent='space-between'>
      <Grid mx='40px'>
        <Svgs.ArtCoffee />
      </Grid>
      <Grid display='flex' flexDirection='column' justifyContent='space-between' rowGap='20px'>
        <Grid>
          <Typography variant='p16' color='primary'>
            {t('AvgSpendingLast3Months')}
          </Typography>
          <Typography
            fontFamily='Montserrat'
            variant='p40Bold'
            color='secondary'
            marginTop={'10px'}>
            $0
          </Typography>
        </Grid>
        <Typography variant='p16Bold' color='secondary'>
          We couldn't find any transactions.
        </Typography>
        <Typography variant='p16' color='secondary'>
          If you just linked an account, you probably have time to go grab a tea or coffee.
        </Typography>
      </Grid>
      <Typography
        className='clickable'
        variant='p12'
        color='primary'
        alignSelf='baseline'
        onClick={() => navigate('spending-summary')}>
        {t('ViewDetails')}
      </Typography>
    </Grid>
  );
};

const SkeletonSpendingOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid className='advice-budget-section' justifyContent='space-between'>
      <Grid>
        <SpendingSummaryChart transactionsSummary={[]} colors={TransactionsSummaryChartColors} />
      </Grid>
      <Grid
        display='flex'
        flexDirection='column'
        flexGrow={1}
        justifyContent='space-between'
        rowGap='20px'>
        <Grid>
          <Skeleton variant='rectangular' height='50px' width='150px' />
        </Grid>
        <Grid display='grid' columnGap='40px' rowGap='10px' gridTemplateColumns='auto auto'>
          <SkeletonSpendingOverviewRow />
          <SkeletonSpendingOverviewRow />
          <SkeletonSpendingOverviewRow />
          <SkeletonSpendingOverviewRow />
          <SkeletonSpendingOverviewRow />
          <SkeletonSpendingOverviewRow />
        </Grid>
      </Grid>
      <Typography
        className='clickable'
        variant='p12'
        color='primary'
        alignSelf='baseline'
        onClick={() => navigate('spending-summary')}>
        {t('ViewDetails')}
      </Typography>
    </Grid>
  );
};

const SkeletonSpendingOverviewRow: React.FC = () => {
  return (
    <Grid display='flex' columnGap='5px'>
      <Skeleton variant='circular' width={15} height={15} />
      <Skeleton variant='rectangular' width='75%' height={15} />
    </Grid>
  );
};

const NoLinkedAccountsView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const transactionsSummary = Data.Transactions.DefaultTransactionsChartValues;

  return (
    <Grid className='advice-budget-section'>
      <Grid>
        <SpendingSummaryChart transactionsSummary={transactionsSummary} />
      </Grid>
      <Grid display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='p18Bold' color='secondary'>
          {t('WannaKnowWhereYourMoneysGoing?')}
        </Typography>
        <Typography variant='p16' color='secondary' mt='10px' mb='20px'>
          {t('LinkAccountsToSeeYourOverview')}
        </Typography>
        <Button
          variant='outlined'
          label='LinkAccount'
          onClick={() => navigate('account-details/link-account')}
        />
      </Grid>
    </Grid>
  );
};

export default SpendingOverviewSection;
