import { Data, Hooks } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressStepProps } from '../../../components/ProgressIndicator';

function useHolisticAdviceProgress(): ProgressStepProps[] {
  const navigate = useNavigate();
  const location = useLocation();
  const overview = Hooks.useHolisticAdviceOverview();
  const holisticAdviceResults: ProgressStepProps[] = useMemo(() => {
    const firstGoalId = overview?.goalSummaries[0]?.goalPlan.id ?? '';
    const firstInvestmentId = overview?.investmentMixes[0]?.id;
    const firstInvestmentPath =
      firstInvestmentId === Data.HolisticAdvice.HolisticAdviceOpenAccountTypeEnum.Brokerage
        ? 'brokerage'
        : firstInvestmentId === Data.HolisticAdvice.HolisticAdviceOpenAccountTypeEnum.IRA
        ? 'ira'
        : firstInvestmentId;
    const steps: ProgressStepProps[] = [
      {
        title: 'MyBudget',
        status: location.pathname.includes('budget') ? 'active' : undefined,
        onClick: () => navigate('/holistic-advice/budget/monthly'),
      },
      {
        title: 'MyGoals',
        status: location.pathname.includes('goals') ? 'active' : undefined,
        onClick: () => navigate(`/holistic-advice/goals/${firstGoalId}`),
        subSteps: overview?.goalSummaries.reduce((goals, goal) => {
          if (goal.goalPlan.goalName) {
            goals.push({
              title: goal.goalPlan.goalName,
              status:
                goal.goalPlan.id && location.pathname.includes(goal.goalPlan.id.toString())
                  ? 'active'
                  : undefined,
              onClick: () => navigate(`/holistic-advice/goals/${goal.goalPlan.id}`),
            });
          }
          return goals;
        }, [] as ProgressStepProps[]),
      },
      {
        title: 'MyInvestments',
        status: location.pathname.includes('investments') ? 'active' : undefined,
        exclude: overview?.investmentMixes.length === 0,
        onClick: () => navigate(`/holistic-advice/investments/${firstInvestmentPath}`),
        subSteps: overview?.investmentMixes.reduce((mixes, invMix) => {
          if (invMix.id === 2) {
            mixes.push({
              title: 'Open a Brokerage Account',
              status: location.pathname.includes('brokerage') ? 'active' : undefined,
              onClick: () => navigate(`/holistic-advice/investments/brokerage`),
            });
          } else if (invMix.id === 4) {
            mixes.push({
              title: 'Open an IRA',
              status: location.pathname.includes('ira') ? 'active' : undefined,
              onClick: () => navigate(`/holistic-advice/investments/ira`),
            });
          } else if (invMix.name) {
            mixes.push({
              title: invMix.name,
              status: location.pathname.includes(invMix.id.toString()) ? 'active' : undefined,
              onClick: () => navigate(`/holistic-advice/investments/${invMix.id}`),
            });
          }
          return mixes;
        }, [] as ProgressStepProps[]),
      },
      {
        title: 'MyPlan',
        status: location.pathname.includes('plan') ? 'active' : undefined,
        onClick: () => navigate('/holistic-advice/plan/outline'),
        lastItem: true,
      },
    ];
    return steps;
  }, [navigate, location, overview]);

  return holisticAdviceResults;
}

export default useHolisticAdviceProgress;
