import { Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import SavingGoalCard from './cards/SavingGoalCard';
import VolunteeringGoalCard from './cards/VolunteeringGoalCard';
import CashGivingGoalCard from './cards/CashGivingGoalCard';
import MortgageGoalCard from './cards/MortgagePayOffCard';
import PayOffDebtGoalCard from './cards/DebtPayOffCard';
import RetirementGoalCard from './cards/RetirementGoalCard';

dayjs.extend(duration);

interface GoalsSectionProps {
  goals: Domain.GoalSummary[];
}

const GoalsSection: React.FC<GoalsSectionProps> = ({ goals }) => {
  const { t } = useTranslation();

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('Goals')}
      </Typography>
      {goals.map((goal) => {
        switch (goal.advisedGoalPlan.goalType) {
          case Domain.GoalTypeEnum.Cash_Giving:
            return <CashGivingGoalCard key={goal.goalPlan.id} goal={goal} />;
          case Domain.GoalTypeEnum.Mortgage:
            return <MortgageGoalCard key={goal.goalPlan.id} goal={goal} />;
          case Domain.GoalTypeEnum.Non_Mortgage_Debt:
            return <PayOffDebtGoalCard key={goal.goalPlan.id} goal={goal} />;
          case Domain.GoalTypeEnum.Retirement:
            return <RetirementGoalCard key={goal.goalPlan.id} goal={goal} />;
          case Domain.GoalTypeEnum.Volunteer:
            return <VolunteeringGoalCard key={goal.goalPlan.id} goal={goal} />;
          default:
            return <SavingGoalCard key={goal.goalPlan.id} goal={goal} />;
        }
      })}
    </Grid>
  );
};

export default GoalsSection;
