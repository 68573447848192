import React, { PropsWithChildren, createContext, useContext, useState } from 'react';
import { ToastDefaultValue, Toast } from './models/Toast';
import ToastBar from './components/ToastBar';

export const ToastbarContext = createContext<ToastDefaultValue>({
  toast: new Toast({ open: false }),
  setToast: () => {
    // no-op
  },
});

declare type ToastProviderProps = PropsWithChildren;

export const ToastbarProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState(new Toast({ open: false }));
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(new Toast({ open: false }));
  };

  return (
    <ToastbarContext.Provider value={{ toast, setToast }}>
      {children}
      <ToastBar
        open={toast.open}
        severity={toast.severity}
        message={toast.message}
        autoHideDuration={toast.autoHideDuration}
        handleClose={handleClose}
      />
    </ToastbarContext.Provider>
  );
};

declare type AppToasts = {
  toast: Toast;
  setToast: (toast: Toast) => void;
  show: (toast: Toast) => void;
  showError: (message: string) => void;
  notImplemented: () => void;
}

export function useAppToasts(): AppToasts {
  const context = useContext(ToastbarContext);
  if (!context) {
    throw new Error(`You must call useAppToasts() from within the context of a ToastbarProvider`);
  }

  const { toast, setToast } = context;
  return {
    toast,
    setToast,
    show: setToast,
    showError: (message: string) => {
      setToast(new Toast({ message, open: true, severity: 'error', autoHideDuration: 3000 }));
    },
    notImplemented: () => {
      setToast(new Toast({ message: 'Not implemented', open: true, severity: 'error', autoHideDuration: 3000 }));
    },
  }
}
