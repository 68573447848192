import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading } from '../../../../hooks/useLoading';
import { Spacing } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { PensionPlanBasicsEditViewWrapper as PensionPlanBasicsEditView } from '../basics/PensionPlanBasicsEditView';
import { PensionPlanDetailsEditViewWrapper as PensionPlanDetailsEditView } from '../details/PensionPlanDetailsEditView';
import PensionPlanBasicsCard from '../basics/PensionPlanBasicsCard';
import PensionPlanDetailsCard from '../details/PensionPlanDetailsCard';
import { SummaryView } from '../../../../components/SummaryHeader';
import { useMessage } from '../../../../hooks/useMessage';

declare type PensionPlanSummaryDrawerType = keyof Data.Pensions.PensionWizardRegistry | 'details';

const PensionPlanSummaryViewWrapper: React.FC = () => {
  const { wizard, params } = Hooks.usePensionWizard();
  const { id } = useParams();
  const isStarted = useObservable(wizard.isStarted$) ?? false;

  const pensionId = parseInt(id ?? '');
  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: pensionId });
    }
  }, [isStarted]);

  if (!isStarted || !params) {
    return <Loading />;
  }

  return <PensionPlanSummaryView wizard={wizard} params={params} />;
};

export interface InvestmentAccountSummaryViewProps {
  wizard: Data.Pensions.PensionWizard;
  params: Data.Pensions.PensionWizardParams;
}

// We need guards because the hooks are getting tripped up
const PensionPlanSummaryView: React.FC<InvestmentAccountSummaryViewProps> = ({
  wizard,
  params,
}) => {
  const data = Hooks.usePensionWizardData();
  const { showMessage } = useMessage();
  const includeSpouse = Hooks.useIncludeSpouse();
  const [drawerKey, setDrawerKey] = useState<PensionPlanSummaryDrawerType | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const currentBalance = data.basic.expectedMonthlyPayments ?? 0;
  const { retirementPlans, spouseRetirementPlans } = Hooks.useRetirementPlanData();
  const accounts = spouseRetirementPlans
    ? [...retirementPlans, ...spouseRetirementPlans]
    : retirementPlans;
  const deleteMutation = useMutation(new Data.InvestmentAccounts.Mutations.DeletePension());
  const account = useMemo(
    () => accounts.find((x) => x.id === params?.id) as Domain.PlanData,
    [accounts, params]
  );

  const openDrawer = (key?: PensionPlanSummaryDrawerType) => {
    wizard.selectStep('basic');
    setDrawerKey(key);
    setOpen(true);
  };

  const removeAccountHandler = useCallback(() => {
    deleteMutation.action(account.id ?? 0); // no need to actually wait since the datacache will still be updated
    navigate('/account-details');
    showMessage('Pension Plan removed!');
  }, [account]);

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={currentBalance}
      onRemoveAccount={removeAccountHandler}
      accountType={Domain.FinancialAccountTypeEnum.InvestmentAccount}
      accountSubType={Domain.planTypeToAccountLabel[Domain.PlanTypeEnum.Pension]}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <PensionPlanBasicsCard
            accountBasics={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <PensionPlanDetailsCard details={data.basic} onEdit={() => openDrawer('details')} />
        </Stack>
      </Grid>
      {/* <Grid item mt={2}>
        <Stack spacing={2}>
          <PensionPlanContributionsCard
            accountContributions={data.contributions}
            onEdit={() => openDrawer('contributions')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <PensionPlanEmployerContributionsCard
            employerContributions={data.employerContributions}
            onEdit={() => openDrawer('employerContributions')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <InvestmentsCard
            pensionId={params.id ?? 0}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid> */}
      <WizardDrawer
        drawerKey={drawerKey ?? 'basic'}
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep(
            undefined as unknown as keyof Data.InvestmentAccounts.InvestmentAccountWizardRegistry
          );
        }}
      />
      <WizardFooter nextLabel='Done' onDone={() => navigate('/account-details')} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  drawerKey: PensionPlanSummaryDrawerType;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ drawerKey, onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm drawerKey={drawerKey} onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  drawerKey: PensionPlanSummaryDrawerType;
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ drawerKey, onBack }) => {
  const { wizard, params } = Hooks.usePensionWizard();
  const initialized = useObservable(wizard.initialized$) ?? false;
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  if (!params) return null;

  if (drawerKey === 'basic') {
    return <PensionPlanBasicsEditView editing onBack={onBack} />;
  } else if (drawerKey === 'details') {
    return <PensionPlanDetailsEditView editing onBack={onBack} />;
  }

  return null;
};

export interface PensionPlanSummaryProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(PensionPlanSummaryViewWrapper, meta);
