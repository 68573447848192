import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { Modal } from '../../../components';
import { useMessage } from '../../../hooks/useMessage';
import { Colors } from '../../../themes';
import { Svgs } from '../../../assets/svg';
import { convertToTitleCase, formatPhoneNumber } from '../../../helpers/utilityFunctions';

interface RemoveMethodModalProps {
  selected: Api.AuthTwoFactorData | undefined;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveMethodModal: React.FC<RemoveMethodModalProps> = ({
  selected,
  modalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();

  const handleSave = async () => {
    if (selected) {
      if (selected.defaultSelection) {
        showMessage('You cannot delete your default two-factor authentication method.', 'info');
        return;
      }
      try {
        await commands.execute(Data.TwoFactor.Commands.DeleteTwoFactorMethod, {
          id: selected.id,
          type: selected.twoFactorType,
        });
        showMessage('Authentication method removed!');
      } catch (err) {
        showMessage('Error removing authentication method.', 'info');
      }
    }
  };

  return (
    <Modal
      title={t('RemoveAuthenticationMethod')}
      primaryButtonText='No, Keep Method'
      secondaryButtonText='Yes, Remove Method'
      swapButtonFunctionality
      open={modalOpen}
      setOpen={setModalOpen}
      handleSave={handleSave}>
      <Typography variant='p16' color='secondary'>
        {t('ConfirmRemoveAuthenticationMethod')}
      </Typography>
      <Typography mt={2} variant='p16Bold' color='secondary'>
        {selected?.info}
      </Typography>
      <Typography variant='p16Bold' color='secondary'>
        {convertToTitleCase(selected?.twoFactorType ?? '')}
      </Typography>
    </Modal>
  );
};

interface ChangeMethodModalProps {
  methods: Api.AuthTwoFactorData[];
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeMethodModal: React.FC<ChangeMethodModalProps> = ({
  methods,
  modalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [selected, setSelected] = useState<Api.AuthTwoFactorData | undefined>();

  useEffect(() => {
    setSelected(methods.find((method) => method.defaultSelection) ?? methods[0]);
  }, [modalOpen]);

  const handleSave = async () => {
    try {
      if (selected) {
        await commands.execute(Data.TwoFactor.Commands.SetDefaultTwoFactorMethod, {
          id: selected.id,
          type: selected.twoFactorType,
        });
        showMessage('Default authentication method updated!');
      }
    } catch (err) {
      showMessage('Error updating default authentication method.', 'info');
    }
  };

  return (
    <Modal
      title={t('ChangeDefaultAuthenticationMethod')}
      titleStyle={{
        textAlign: 'center',
        justifyContent: 'center',
      }}
      primaryButtonText='Submit'
      open={modalOpen}
      setOpen={setModalOpen}
      handleSave={handleSave}>
      <Grid container mt={-3} justifyContent='center'>
        <Grid item justifyContent='center' sm={11}>
          {methods.map((method) => {
            const checked = selected && selected.id === method.id;
            const email = method.twoFactorType?.toLowerCase() === Api.TwoFactorTypeEnum.Email;
            return (
              <Card
                className={checked ? 'check-select-checked' : 'check-select'}
                onClick={() => {
                  setSelected(checked ? undefined : method);
                }}
                key={method.id}
                elevation={0}
                style={{ maxWidth: '500px' }}>
                <CardHeader
                  disableTypography
                  title={
                    <Grid container flexDirection='row' alignItems='center'>
                      <Grid item mt='8px' pr={1}>
                        {email ? (
                          checked ? (
                            <Svgs.ActionSmallEnvelopeTertiaryDefault />
                          ) : (
                            <Svgs.DisplaySmallCustomEnvelope />
                          )
                        ) : checked ? (
                          <Svgs.ActionSmallPhone2TertiaryDefault />
                        ) : (
                          <Svgs.DisplaySmallCustomPhone2 />
                        )}
                      </Grid>
                      <Typography
                        color={checked ? Colors.tertiaryBase : Colors.secondaryBase}
                        variant='p18Bold'
                        mt='8px'
                        maxWidth='85%'
                        sx={{
                          display: 'block',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}>
                        {email ? method.info : formatPhoneNumber(method.info ?? '')}
                      </Typography>
                    </Grid>
                  }
                  action={
                    checked && (
                      <Grid item mt='11px' mr={1}>
                        <Check htmlColor={Colors.tertiaryBase} />
                      </Grid>
                    )
                  }
                />
              </Card>
            );
          })}
        </Grid>
      </Grid>
    </Modal>
  );
};

export { RemoveMethodModal, ChangeMethodModal };
