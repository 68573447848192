import { Typography } from '@mui/material';
import { SubscriptionDetails } from '../SubscriptionDetails';
import { FreeDefaultCarousel } from './FreeDefaultCarousel';
import { FreeDefaultFooterCard } from './FreeDefaultFooterCard';
import { FreeDefaultHeaderCard } from './FreeDefaultHeaderCard';

export const FreeDefaultView: React.FC = () => {
  return (
    <>
      <FreeDefaultHeaderCard />

      <Typography mt={5} mb={3} variant='p18Bold' color='primary'>
        Here’s What You Get!
      </Typography>

      <FreeDefaultCarousel />

      <SubscriptionDetails style={{ padding: '38px', paddingBottom: '45px' }} />

      <FreeDefaultFooterCard />
    </>
  );
};
