import { Domain, Hooks } from '@3nickels/data-modules';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  brokerageSummary,
  cashAccountSummary,
  debtSummary,
  homeSummary,
  hsaSummary,
  retirementSummary,
} from '../UrlRegistry';

function tryParseInt(val: string | undefined) {
  try {
    const value = parseInt(val as string);
    return isNaN(value) ? -1 : value;
  } catch {
    return -1;
  }
}

export function usePlaidManualLinkRedirect() {
  const navigate = useNavigate();
  const context = Hooks.usePlaidContext();

  return useCallback(
    (account: Domain.IAutoBindableAccount) => {
      console.log('redirecting to summary for', {
        type: account.financialAccountTypes,
        financialAccountId: account.financialAccountId,
        aggType: account.aggType,
        aggSubType: account.aggSubType,
        subType: account.subType,
      });

      if (account.financialAccountTypes === Domain.FinancialAccountTypeEnum.CashAccount) {
        return cashAccountSummary(context.account?.id ?? 0);
      }

      if (
        account.financialAccountTypes === Domain.FinancialAccountTypeEnum.Debt ||
        account.financialAccountTypes === Domain.FinancialAccountTypeEnum.CreditCard
      ) {
        if (account.subType?.toLowerCase() === 'mortgage') {
          return homeSummary(context.account?.id ?? 0);
        }

        const debt = context.account as Domain.IDebt;
        return debtSummary(debt.id ?? 0, debt.debtType as Domain.DebtTypeEnum);
      }

      if (account.financialAccountTypes === Domain.FinancialAccountTypeEnum.InvestmentAccount) {
        // first, let's try to grab the plan type
        const subType = tryParseInt(account.subType);
        if (subType !== -1) {
          const planData = context.account as Domain.PlanData;
          const planType = subType as Domain.PlanTypeEnum;
          if (planType === Domain.PlanTypeEnum.HSA) {
            return hsaSummary(planData.id ?? 0);
          }
          if (planType === Domain.PlanTypeEnum['Brokerage Account']) {
            return brokerageSummary(planData.id ?? 0);
          }
          return retirementSummary(planData.id ?? 0, planType);
        }
      }

      console.log('Missing redirect; falling back to /account-details');
      return `/account-details`;
    },
    [context, navigate]
  );
}
