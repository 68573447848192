import { Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import AddAccountHeader from '../headers/AddAccountHeader';
import React from 'react';
import { DebtView } from '../debt/DebtView';
import { Hooks } from '@3nickels/data-modules';
import { CashAccountView } from '../cash-account/CashAccountView';
import { useDeleteAccountMutations } from '../../../hooks/useMutations/useAccountMutations';
import { CarView } from '../car/CarView';
import { HomeView } from '../home/HomeView';
import { InvestmentAccountView } from '../investment-account/InvestmentAccountView';
import { RetirementPlanView } from '../retirement-plan/RetirementPlanView';
import { useInstitutionalAccountReviewStatus } from '../../../hooks/useInstitutionalAccountReviewStatus';

const AccountView: React.FC = () => {
  const { t } = useTranslation();

  const includeSpouse = Hooks.useIncludeSpouse();
  const { cashAccounts, spouseCashAccounts } = Hooks.useCashAccounts();
  const { retirementPlans, spouseRetirementPlans } = Hooks.useRetirementPlanData();
  const { investmentAccounts, spouseInvestmentAccounts } = Hooks.useInvestmentAccountData();
  const { cars, spouseCars } = Hooks.useCars();
  const { housing, spouseHousing } = Hooks.useHousingData();
  const { debts, spouseDebts } = Hooks.useDebts();

  const {
    deleteCashAccount,
    deleteRetirementPlan,
    deleteInvestmentAccount,
    deleteCar,
    deleteHome,
    deleteDebt,
  } = useDeleteAccountMutations();
  const hasAccounts =
    cashAccounts.length !== 0 ||
    retirementPlans.length !== 0 ||
    investmentAccounts.length !== 0 ||
    cars.length !== 0 ||
    housing.length !== 0 ||
    debts.length !== 0;
  const hasSpouseAccounts =
    spouseCashAccounts?.length !== 0 ||
    spouseRetirementPlans?.length !== 0 ||
    spouseInvestmentAccounts?.length !== 0 ||
    spouseCars?.length !== 0 ||
    spouseHousing?.length !== 0 ||
    spouseDebts?.length !== 0;

  useInstitutionalAccountReviewStatus();

  if (!hasAccounts && (!includeSpouse || !hasSpouseAccounts)) {
    return (
      <Card className='empty-account-card'>
        <Typography display='block' color='accent' fontWeight='bold'>
          {t('NoAccountsAddedYet')}
        </Typography>
      </Card>
    );
  }

  return (
    <Grid container mt={3}>
      {hasAccounts && (
        <Grid container className='account-view-container'>
          <Typography component='h2' mb={2} variant='p20Bold' color='secondary'>
            {t('My Accounts')}
          </Typography>
          <CashAccountView cashAccounts={cashAccounts} deleteCashAccount={deleteCashAccount} />
          <RetirementPlanView
            retirementPlans={retirementPlans}
            deleteRetirementPlan={deleteRetirementPlan}
          />
          <InvestmentAccountView
            accounts={investmentAccounts}
            deleteAccount={deleteInvestmentAccount}
          />
          <CarView cars={cars} deleteCar={deleteCar} />
          <HomeView homes={housing} deleteHome={deleteHome} />
          <DebtView debts={debts} deleteDebt={deleteDebt} />
        </Grid>
      )}
      {includeSpouse && hasSpouseAccounts && (
        <Grid container className='account-view-container' mt='30px'>
          <Typography component='h2' mb={2} variant='p20Bold' color='secondary'>
            {t("Spouse's Accounts")}
          </Typography>
          <CashAccountView
            cashAccounts={spouseCashAccounts ?? []}
            deleteCashAccount={deleteCashAccount}
          />
          <RetirementPlanView
            retirementPlans={spouseRetirementPlans ?? []}
            deleteRetirementPlan={deleteRetirementPlan}
          />
          <InvestmentAccountView
            accounts={spouseInvestmentAccounts ?? []}
            deleteAccount={deleteInvestmentAccount}
          />
          <CarView cars={spouseCars ?? []} deleteCar={deleteCar} />
          <HomeView homes={spouseHousing ?? []} deleteHome={deleteHome} />
          <DebtView debts={spouseDebts ?? []} deleteDebt={deleteDebt} />
        </Grid>
      )}
    </Grid>
  );
};

const meta = {
  showBack: true,
  showNext: false,
  header: <AddAccountHeader />,
} satisfies LayoutMeta;

export default withLayoutMeta(AccountView, meta);
