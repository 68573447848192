import { createTheme, ThemeProvider } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
// eslint-disable-next-line no-restricted-imports
import { Alignments, Borders, Colors, Margins, Mui, Percentages, Spacing } from '.';
import { BackgroundImage, Fullscreen, FullscreenView } from './mui';

declare module '@mui/material/Paper' {
  // These customizations are for cards, but the card variants come from paper
  interface PaperPropsVariantOverrides {
    selected: true;
    disabled: true;
    ghost: true;
    innerCard: true;
    embeddedCard: true;
    shadow: true;
    contained: true;
  }
}

declare module '@mui/material/TableCell' {
  interface TableCellPropsVariantOverrides {
    hasButton: true;
    dataHead: true;
    dataBodyKey: true;
    dataBodyValue: true;
    dataBodyBold: true;
  }
}

declare module '@mui/material/TableBody' {
  interface TableBodyPropsVariantOverrides {
    deletableRows: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    ghost: true;
    activeGhost: true;
    link: true;
    tertiary: true;
  }

  interface ButtonPropsColorOverrides {
    tertiary: true;
    accent: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p12: true;
    p12SemiBold: true;
    p13: true;
    p14: true;
    p14Bold: true;
    p16: true;
    p16Bold: true;
    p16Italic: true;
    p18: true;
    p18SemiBold: true;
    p18Bold: true;
    p18Italic: true;
    p20: true;
    p20Bold: true;
    p20SemiBold: true;
    p22Bold: true;
    p24: true;
    p24Bold: true;
    p24SemiBold: true;
    p25Bold: true;
    p26: true;
    p30: true;
    p30Bold: true;
    p32Light: true;
    p40: true;
    p40Bold: true;
    p45Bold: true;
    p50Light: true;
    p50Bold: true;
    p60: true;
    p70Bold: true;
    p12OpenSansSemiBold: true;
    p14OpenSans: true;
    p16OpenSans: true;
    p18OpenSans: true;
    p18OpenSansBold: true;
    p20OpenSansSemiBold: true;
    p30OpenSansLight: true;
  }
}
declare module '@mui/material/styles' {
  // These are properties that we added inside the primary{}, secondary{}, etc. objects
  interface PaletteColor {
    main: string;
    active?: string;
    hover?: string;
    disabled?: string;
    dark: string;
  }
  interface PaletteColorOptions {
    main?: string;
    active?: string;
    hover?: string;
    disabled?: string;
    dark?: string;
    light?: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends PaletteOptions {}
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    accent?: PaletteColorOptions;
    guidedChoice?: PaletteColorOptions;
  }

  interface TypographyVariants {
    p12: React.CSSProperties;
    p12SemiBold: React.CSSProperties;
    p13: React.CSSProperties;
    p14: React.CSSProperties;
    p14Bold: React.CSSProperties;
    p16: React.CSSProperties;
    p16Bold: React.CSSProperties;
    p16Italic: React.CSSProperties;
    p18: React.CSSProperties;
    p18SemiBold: React.CSSProperties;
    p18Bold: React.CSSProperties;
    p18Italic: React.CSSProperties;
    p20: React.CSSProperties;
    p20Bold: React.CSSProperties;
    p20SemiBold: React.CSSProperties;
    p22Bold: React.CSSProperties;
    p24: React.CSSProperties;
    p24Bold: React.CSSProperties;
    p24SemiBold: React.CSSProperties;
    p25Bold: React.CSSProperties;
    p26: React.CSSProperties;
    p30: React.CSSProperties;
    p30Bold: React.CSSProperties;
    p32Light: React.CSSProperties;
    p40: React.CSSProperties;
    p40Bold: React.CSSProperties;
    p45Bold: React.CSSProperties;
    p50Light: React.CSSProperties;
    p50Bold: React.CSSProperties;
    p60: React.CSSProperties;
    p70Bold: React.CSSProperties;
    p12OpenSansSemiBold: React.CSSProperties;
    p14OpenSans: React.CSSProperties;
    p16OpenSans: React.CSSProperties;
    p18OpenSans: React.CSSProperties;
    p18OpenSansBold: React.CSSProperties;
    p20OpenSansSemiBold: React.CSSProperties;
    p30OpenSansLight: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    p12?: React.CSSProperties;
    p12SemiBold?: React.CSSProperties;
    p13?: React.CSSProperties;
    p14?: React.CSSProperties;
    p14Bold?: React.CSSProperties;
    p16?: React.CSSProperties;
    p16Bold?: React.CSSProperties;
    p16Italic?: React.CSSProperties;
    p18: React.CSSProperties;
    p18SemiBold: React.CSSProperties;
    p18Bold?: React.CSSProperties;
    p18Italic?: React.CSSProperties;
    p20?: React.CSSProperties;
    p20Bold?: React.CSSProperties;
    p20SemiBold?: React.CSSProperties;
    p22Bold?: React.CSSProperties;
    p24?: React.CSSProperties;
    p24Bold?: React.CSSProperties;
    p24SemiBold?: React.CSSProperties;
    p25Bold: React.CSSProperties;
    p26: React.CSSProperties;
    p30: React.CSSProperties;
    p30Bold: React.CSSProperties;
    p32Light: React.CSSProperties;
    p40: React.CSSProperties;
    p40Bold: React.CSSProperties;
    p45Bold: React.CSSProperties;
    p50Light: React.CSSProperties;
    p50Bold: React.CSSProperties;
    p60?: React.CSSProperties;
    p70Bold?: React.CSSProperties;
    p12OpenSansSemiBold?: React.CSSProperties;
    p14OpenSans?: React.CSSProperties;
    p16OpenSans?: React.CSSProperties;
    p18OpenSans?: React.CSSProperties;
    p18OpenSansBold?: React.CSSProperties;
    p20OpenSansSemiBold?: React.CSSProperties;
    p30OpenSansLight?: React.CSSProperties;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primaryBase,
      active: '#125163',
      hover: '#165f74',
      disabled: '#d4ebf2',
      light: '#8cbac7',
    },
    secondary: {
      main: Colors.secondaryBase,
      active: '#161d4b',
      hover: '#1e2257',
      disabled: '#b2b6cc',
      dark: '#022a3a',
    },
    info: { main: '#7a8e96' },
    error: { main: Colors.accentBase, light: '#fceecf' },
    tertiary: {
      main: '#4d9927',
      active: '#4f793a',
      hover: '#3f7e20',
      disabled: '#b8d6a9',
    },
    accent: {
      main: Colors.accentBase,
      active: '#623424',
      hover: '#874831',
      disabled: '#e0cbc3',
    },
    guidedChoice: {
      main: Colors.cancelPolicy,
    },
  },
  typography: {
    fontFamily: 'Roboto, OpenSans',
    h1: {
      fontSize: 30,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 18,
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontStyle: 'italic',
    },
    subtitle1: {
      fontSize: 30,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
    },
    button: {
      fontSize: 18,
      fontWeight: 600,
    },
    // for custom variants, you need to re-specify the font family
    p12: {
      fontSize: 12,
      fontFamily: 'Roboto',
    },
    p12SemiBold: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: 'Roboto',
    },
    p13: {
      fontSize: 13,
      fontFamily: 'Roboto',
    },
    p14: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
    p14Bold: {
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p16: {
      fontSize: 16,
      fontFamily: 'Roboto',
    },
    p16Bold: {
      fontSize: 16,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    p16Italic: {
      fontSize: 16,
      fontStyle: 'italic',
      fontFamily: 'Roboto',
    },
    p18: {
      fontSize: 18,
      fontFamily: 'Roboto',
    },
    p18SemiBold: {
      fontSize: 18,
      fontWeight: '600',
      fontFamily: 'Roboto',
    },
    p18Bold: {
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p18Italic: {
      fontSize: 18,
      fontStyle: 'italic',
      fontFamily: 'Roboto',
    },
    p20: {
      fontSize: 20,
      fontFamily: 'Roboto',
    },
    p20Bold: {
      fontSize: 20,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    p20SemiBold: {
      fontSize: 20,
      fontFamily: 'Roboto',
      fontWeight: 600,
    },
    p22Bold: {
      fontSize: 22,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    p24: {
      fontSize: 24,
      fontFamily: 'Roboto',
    },
    p24SemiBold: {
      fontSize: 24,
      fontFamily: 'Roboto',
      fontWeight: 600,
    },
    p24Bold: {
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p25Bold: {
      fontSize: 25,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p26: {
      fontSize: 26,
      fontFamily: 'Roboto',
    },
    p30: {
      fontSize: 30,
      fontFamily: 'Roboto',
    },
    p30Bold: {
      fontSize: 30,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p32Light: {
      fontSize: 32,
      fontWeight: 300,
      fontFamily: 'Roboto',
    },
    p40: {
      fontSize: 40,
      fontWeight: 300,
      fontFamily: 'Roboto',
    },
    p40Bold: {
      fontSize: 40,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p45Bold: {
      fontSize: 45,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p50Light: {
      fontSize: 50,
      fontWeight: 300,
      fontFamily: 'Roboto',
    },
    p50Bold: {
      fontSize: 50,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p60: {
      fontSize: 60,
      fontFamily: 'Roboto',
    },
    p70Bold: {
      fontSize: 70,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    p12OpenSansSemiBold: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: 'OpenSans',
    },
    p14OpenSans: {
      fontSize: 14,
      fontFamily: 'OpenSans',
    },
    p16OpenSans: {
      fontSize: 16,
      fontFamily: 'OpenSans',
    },
    p18OpenSans: {
      fontSize: 18,
      fontFamily: 'OpenSans',
    },
    p18OpenSansBold: {
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'OpenSans',
    },
    p20OpenSansSemiBold: {
      fontSize: 20,
      fontWeight: 600,
      fontFamily: 'OpenSans',
    },
    p30OpenSansLight: {
      fontSize: 30,
      fontWeight: 300,
      fontFamily: 'OpenSans',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          wordWrap: 'break-word',
          lineHeight: 1.25,
          ...Alignments.verticalAlignCenter,
          ...(ownerState.color === 'accent' && {
            color: theme.palette.accent?.main,
          }),
          ...(ownerState.className === 'link-wrapper' && {
            display: 'inline',
          }),
          ...(ownerState.className === 'hex-header' && {
            ...Alignments.verticalAlignBottom,
            marginBottom: '5px',
          }),
          ...(ownerState.className === 'header-text' && {
            ...Alignments.verticalAlignBottom,
            marginBottom: '10px',
            marginRight: '20px',
          }),
          ...(ownerState.className === 'title' && {
            marginTop: '10px',
            fontSize: '30px',
          }),
          ...(ownerState.className === 'subtitle' && {
            marginTop: '16px',
          }),
          ...(ownerState.className === 'section-header' && {
            marginBottom: '10px',
          }),
          ...(ownerState.className === 'helper-text' && {
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: 1.66,
            marginTop: '4px',
          }),
          ...(ownerState.className === 'account-type-header' && {
            'marginBottom': '10px',
            '& svg': {
              marginRight: '10px',
            },
          }),
          ...(ownerState.className === 'clickable' && {
            'textDecoration': 'underline',
            '&:hover': {
              cursor: 'pointer',
            },
          }),
          ...(ownerState.className === 'hover-clickable' && {
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }),
          ...(ownerState.className === 'no-underline-clickable' && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
          ...(ownerState.className === 'start-screen-text' && {
            marginTop: '30px',
          }),
          ...(ownerState.className === 'bulleted-text' && {
            'color': 'green',
            'display': 'list-item',
            'listStyle': 'outside',
            'marginLeft': '40px',
            '&::marker': {
              content: '"•\t"',
            },
          }),
          ...(ownerState.className === 'one-line' && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }),
          ...(ownerState.className === 'two-lines' && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: '8px',
          ...(ownerState.color === 'primary' && {
            backgroundColor: Colors.tertiaryBase,
            border: `1px solid ${theme.palette.info.main}`,
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.color === 'secondary' && {
            backgroundColor: '#dfeef4',
            border: 'none',
          }),
          ...(ownerState.color === 'accent' && {
            backgroundColor: Colors.accentBase,
            border: 'none',
          }),
          ...(ownerState.variant === 'elevation' && {
            border: `5px solid ${theme.palette.info.main}`,
          }),
          ...(ownerState.variant === 'selected' && {
            border: `1px solid ${Colors.tertiaryBase}`,
            backgroundColor: Colors.primaryBase,
            color: `${Colors.tertiaryBase} !important`,
          }),
          ...(ownerState.variant === 'ghost' &&
            ownerState.color === 'primary' && {
              border: 'none',
              backgroundColor: 'rgba(3, 138, 183, 0.05)',
              color: theme.palette.secondary.dark,
            }),
          ...(ownerState.variant === 'ghost' &&
            ownerState.color === 'secondary' && {
              border: 'none',
              backgroundColor: Colors.tertiaryBase,
              color: theme.palette.secondary.main,
            }),
          ...(ownerState.variant === 'innerCard' && {
            border: 'none',
            backgroundColor: Colors.primaryLightest,
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.variant === 'embeddedCard' && {
            border: 'none',
            backgroundColor: Colors.primaryLighter,
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.className === 'content-card' && {
            position: 'relative',
            padding: '20px',
            boxShadow: 'none',
            border: `1px solid ${Colors.primaryLightest}`,
            display: 'flex',
            alignItems: 'center',
          }),
          ...(ownerState.className === 'content-title-card' && {
            'paddingLeft': '20px',
            'paddingRight': '20px',
            'boxShadow': 'none',
            'display': 'flex',
            'alignItems': 'center',
            'backgroundColor': 'transparent',
            '& > :not(:last-child)': {
              paddingRight: '10px',
            },
          }),
          ...(ownerState.className === 'panel' && {
            '& a': {
              textDecoration: 'none',
            },
            '& .MuiTypography-root': {
              cursor: 'pointer',
            },
            '& .MuiIconButton-root': {
              padding: '0 !important',
            },
            '& .group-panel-options': {
              '& > .MuiGrid-root': {
                cursor: 'pointer',
              },
              '& > div': {
                borderBottom: `1px solid ${Colors.primaryLight}`,
              },
              '& > div:last-child': {
                borderBottom: 'none',
              },
            },
          }),
          ...(ownerState.className === 'rectangle-panel' && {
            'border': 'inherit',
            ...Borders.borderRadius_10,
            'backgroundColor': Colors.tertiaryBase,
            '& .button-container': {
              ...Alignments.alignSelfCenter,
            },
            '& .MuiCardHeader-action': {
              alignSelf: 'center',
            },
          }),
          ...(ownerState.className === 'rectangle-panel' &&
            ownerState.raised && {
              border: 'none',
            }),
          ...(ownerState.className === 'collapsible-panel' && {
            'borderRadius': '0px',
            'marginBottom': '10px',
            'border':
              ownerState.variant === 'contained'
                ? `solid 1px ${Colors.primaryLightest}`
                : undefined,
            'boxShadow':
              ownerState.variant === 'shadow' ? '0 4px 7px 1px rgba(30, 41, 105, 0.2)' : 'none',
            'backgroundColor':
              ownerState.variant === 'shadow' || ownerState.variant === 'contained'
                ? undefined
                : 'transparent',
            ...(ownerState.variant === 'shadow' || ownerState.variant === 'contained'
              ? Borders.borderRadius_10
              : undefined),
            '& .collapsible-panel-header': {
              '&:hover': {
                'cursor': 'pointer',
                '& .icon': {
                  visibility: 'visible',
                },
              },
            },
            '& .MuiCardHeader-root': {
              ...Borders.borderRadius_10,
              'padding': '10px 20px',
              'backgroundColor': Colors.tertiaryBase,
              '& .icon': {
                visibility: 'hidden',
              },

              '& .MuiCardHeader-action': {
                alignSelf: 'center',
              },
            },
            '& .collapsible-panel:not(:last-of-type)': {
              borderBottom: `1px solid ${Colors.primaryLight}`,
              marginBottom: '0px',
            },
            '& .compressed-panel': {
              'borderRadius': '0px',
              'padding': '10px 20px',
              'backgroundColor': 'transparent',
              'border': 'none',
              '&:hover': {
                'cursor': 'pointer',
                '& .icon': {
                  visibility: 'visible',
                },
              },
            },
            '& .embedded-content': {
              paddingLeft: '60px',
              paddingRight: '20px',
            },
            '& .MuiCardContent-root': {
              padding: '0px',
            },
          }),
          ...(ownerState.className === 'card-display' && {
            '& .MuiCardHeader-root': {
              'backgroundColor': Colors.primaryLightest,
              'color': Colors.secondaryBase,
              'alignContent': 'center',
              '& .MuiCardHeader-action': {
                fontFamily: 'Roboto',
              },
            },
            '& .card-action': {
              color: Colors.primaryBase,
            },
            'boxShadow':
              '0 4px 7px 1px rgba(30, 41, 105, 0.2), 0 -4px 7px 1px rgba(30, 41, 105, 0.2);',
            'backdropFilter': 'blur(6px)',
          }),
          ...(ownerState.className === 'account-card' && {
            'backgroundColor': Colors.tertiaryBase,
            'border': `1px solid ${Colors.primaryLightest}`,
            'width': Percentages.Full,
            '& .MuiCardHeader-root': {
              'paddingTop': '12px',
              'paddingBottom': '12px',
              'paddingLeft': '12px',
              'paddingRight': '40px',
              '& :last-child': {
                marginTop: '5px',
              },
            },
          }),
          ...(ownerState.className === 'empty-account-card' && {
            backgroundColor: Colors.tertiaryBase,
            marginTop: theme.spacing(3),
            height: 62,
            border: `1px solid ${Colors.primaryLightest}`,
            boxShadow: 'none',
            ...Alignments.alignCenter,
            ...Borders.borderRadius_7,
          }),
          ...(ownerState.className === 'check-select' && {
            'backgroundColor': Colors.primaryLightest,
            'cursor': 'pointer',
            'minHeight': '51px',
            ...Margins.mt_sm,
            '& .MuiCardHeader-root': {
              padding: '6px',
              paddingLeft: '12px',
            },
            '&:hover': {
              backgroundColor: Colors.primaryLighter,
            },
          }),
          ...(ownerState.className === 'check-select-checked' && {
            'backgroundColor': Colors.primaryBase,
            'cursor': 'pointer',
            ...Margins.mt_sm,
            '& .MuiCardHeader-root': {
              padding: '6px',
              paddingLeft: '12px',
            },
            '& .MuiCardHeader-action': {
              fontSize: '30px',
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.hover,
            },
          }),
          ...(ownerState.className === 'investment-card' && {
            'width': Percentages.Full,
            '& .MuiCardHeader-root': {
              backgroundColor: Colors.primaryLightest,
              padding: '5px',
              paddingLeft: '35px',
            },
            '& .MuiCardHeader-action': {
              ...Alignments.alignSelfCenter,
            },
            '& .MuiSvgIcon-root': {
              fontSize: '30px',
            },
          }),
          ...(ownerState.className === 'security-details-card' && {
            'boxShadow': 'none',
            '& .MuiCardContent-root': {
              padding: '10px !important',
              backgroundColor: Colors.primaryLightest,
            },
          }),
          ...(ownerState.className &&
            ownerState.className.includes('institutional-account-card') && {
              'boxShadow':
                '0 4px 7px 1px rgba(30, 41, 105, 0.2), 0 -4px 7px 1px rgba(30, 41, 105, 0.2)',
              '&:hover, &.clicked': {
                '& .show-on-hover': { opacity: 1 },
              },
            }),
          ...(ownerState.className === 'dropdown-card' && {
            'backgroundColor': Colors.primaryLightest,
            'boxShadow': 'none',
            '& .card-action': {
              color: Colors.primaryBase,
            },
          }),
          ...(ownerState.className === 'leftover-card' && {
            backgroundColor: Colors.tertiaryBase,
            marginTop: theme.spacing(3),
            border: `1px solid ${Colors.primaryLightest}`,
            boxShadow: 'none',
            ...Borders.borderRadius_7,
          }),
          ...(ownerState.className &&
            ownerState.className.includes('goal-list-item') && {
              '&:hover, &.clicked': {
                '& .show-on-hover': { opacity: 1 },
                '& .hide-on-hover': { display: 'none' },
              },
            }),
          ...(ownerState.className === 'saved-guide' && {
            'backgroundColor': Colors.tertiaryBase,
            'border': `1px solid ${Colors.primaryLightest}`,
            'boxShadow': 'none',
            'padding': '10px 15px',
            'cursor': 'pointer',
            '&:hover': {
              backgroundColor: Colors.disabledLight,
            },
            ...Borders.borderRadius_7,
          }),
          ...(ownerState.className &&
            ownerState.className.includes('card-row') && {
              'border': `1px solid ${Colors.primaryLightest}`,
              'backdropFilter': 'blur(6px)',
              'boxShadow': 'none',
              '&:hover, &.clicked': {
                '& .show-on-hover': { opacity: 1 },
                '& .hide-on-hover': { display: 'none' },
              },
            }),
          ...(ownerState.className === 'panel-popover' && {
            minWidth: 'fit-content',
          }),
          ...(ownerState.className === 'plan-card' && {
            maxWidth: '461px',
            minWidth: '461px',
            maxHeight: '244px',
            minHeight: '244px',
            padding: '20px 0px 0px 20px',
            boxShadow: 'none',
            border: `1px solid ${Colors.primaryLightest}`,
            backdropFilter: 'blur(6px)',
          }),
          ...(ownerState.className === 'tools-carousel-card' && {
            'flexDirection': 'column',
            'display': 'flex',
            'padding': '10px',
            'boxShadow': 'none',
            'border': `1px solid ${Colors.primaryLighter}`,
            'alignItems': 'center',
            'justifyContent': 'center',
            'height': '135px',
            'width': '135px',
            '& .card-svg': {
              height: '75px',
              width: '75px',
            },
            '& img': {
              height: '60px',
              width: '60px',
            },
          }),
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'search-option' && {
            width: '100%',
            backgroundColor: Colors.primaryLightest,
            borderRadius: '7px',
          }),
          ...(ownerState.role === 'expanded' && {
            borderBottomLeftRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
          }),
          ...(ownerState.className === 'status-card' && {
            'height': '100%',
            '& .show-on-hover': {
              visibility: 'hidden',
            },
            '&:hover': {
              '& .show-on-hover': {
                visibility: 'visible',
              },
            },
          }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'basic' && {
            padding: '16px !important',
          }),
          ...(ownerState.className === 'deletable' && {
            padding: '16px 50px 16px 16px',
            backgroundColor: Colors.primaryLightest,
          }),
          ...(ownerState.className === 'dataTable' && {
            padding: '16px 50px',
            backgroundColor: Colors.primaryLightest,
          }),
          ...(ownerState.className === 'basicCard' && {
            padding: '30px 30px 35px 50px',
          }),
          ...(ownerState.className === 'pad30' && {
            padding: '30px !important',
          }),
          ...(ownerState.className === 'innerGlance' && {
            padding: '20px 60px',
          }),
          ...(ownerState.className === 'communications' && {
            padding: '30px 50px',
          }),
          ...(ownerState.className === 'innerContent' && {
            margin: '0px 30px',
          }),
          ...(ownerState.className === 'helpChoose' && {
            margin: '0px 10px',
            textAlign: 'center',
            height: '50%',
          }),
          ...(ownerState.className === 'innerContent' && {
            padding: '30px 30px 30px 50px',
          }),
          ...(ownerState.className === 'pad20' && {
            padding: '20px !important',
          }),
          ...(ownerState.className === 'centered-inner' && {
            padding: '16px !important',
            alignItems: 'center',
          }),
          ...(ownerState.className === 'reviewCost' && {
            padding: '30px',
            textAlign: 'center',
          }),
          ...(ownerState.className === 'productCard' && {
            padding: '50px !important',
          }),
          ...(ownerState.className === 'yearlyPrice' && {
            padding: '20px 30px',
          }),
          ...(ownerState.className === 'whoPays' && {
            padding: '10px 20px !important',
          }),
          ...(ownerState.className === 'packageCard' && {
            padding: '20px',
            paddingBottom: '20px !important',
          }),
          ...(ownerState.className === 'payment' && {
            '& .row': {
              justifyContent: 'space-between',
            },
          }),
          ...(ownerState.className === 'institution' && {
            '& .row': {
              justifyContent: 'space-between',
            },
          }),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          'borderRadius': '10px',
          'backdropFilter': 'blur(6px)',
          'boxShadow':
            '0 4px 7px 1px rgba(30, 41, 105, 0.2), 0 -4px 7px 1px rgba(30, 41, 105, 0.2)',
          'marginTop': '30px',
          'display': 'flex',
          'flexDirection': 'column',
          'justifyContent': 'center',
          '& .MuiButtonBase-root': {
            borderRadius: '7px',
            margin: '10px 16px',
            fontSize: '14px',
          },
          '& .no-options': {
            margin: '14px 16px',
            display: 'flex',
            justifyContent: 'center',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Roboto';
        }`,
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          fontSize: 14,
          fontWeight: 'semiBold',
          fontFamily: 'Montserrat',
          lineHeight: '2em',
          padding: '5px 35px !important',
          borderRadius: '27.5px',
          whiteSpace: 'nowrap',
          minWidth: 'fit-content',
          ...(ownerState.color === 'primary' && {
            'backgroundColor': theme.palette.primary.main,
            'color': Colors.tertiaryBase,
            '&:hover': {
              backgroundColor: theme.palette.primary.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.active,
            },
            '&:disabled': {
              backgroundColor: theme.palette.primary.disabled,
              color: Colors.tertiaryBase,
            },
          }),
          ...(ownerState.color === 'secondary' && {
            'backgroundColor': theme.palette.secondary.main,
            'color': Colors.tertiaryBase,
            '&:hover': {
              backgroundColor: theme.palette.secondary.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.secondary.active,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.disabled,
            },
          }),
          ...(ownerState.color === 'tertiary' && {
            'backgroundColor': theme.palette.tertiary?.main,
            'color': Colors.tertiaryBase,
            /* TODO update values for hover, active, and disabled once Sergey decides */
            '&:hover': {
              backgroundColor: theme.palette.tertiary?.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.tertiary?.active,
            },
            '&:disabled': {
              backgroundColor: theme.palette.tertiary?.disabled,
            },
          }),
          ...(ownerState.color === 'accent' && {
            'backgroundColor': theme.palette.accent?.main,
            'color': Colors.tertiaryBase,
            /* TODO update values for hover, active, and disabled once Sergey decides */
            '&:hover': {
              backgroundColor: theme.palette.accent?.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.accent?.active,
            },
            '&:disabled': {
              backgroundColor: theme.palette.accent?.disabled,
            },
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              'backgroundColor': Colors.tertiaryBase,
              'color': theme.palette.primary.main,
              '&:hover': {
                backgroundColor: '#e2e6e7',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
                color: theme.palette.primary.active,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              'backgroundColor': Colors.tertiaryBase,
              'color': theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: '#e2e6e7',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
                color: theme.palette.secondary.active,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'tertiary' && {
              'backgroundColor': Colors.tertiaryBase,
              'color': theme.palette.tertiary?.main,
              '&:hover': {
                backgroundColor: '#e2e6e7',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
                color: theme.palette.tertiary?.active,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'accent' && {
              'backgroundColor': Colors.tertiaryBase,
              'color': theme.palette.accent?.main,
              '&:hover': {
                backgroundColor: '#e2e6e7',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
                color: theme.palette.accent?.active,
              },
            }),
          ...(ownerState.variant === 'activeGhost' && {
            'border': 'none',
            'backgroundColor': Colors.tertiaryBase,
            'color': theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
            },
          }),
          ...(ownerState.variant === 'ghost' && {
            'border': 'none',
            'backgroundColor': Colors.tertiaryBase,
            'color': theme.palette.primary.main,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
            },
          }),
          ...(ownerState.variant === 'ghost' &&
            ownerState.color === 'secondary' && {
              'border': 'none',
              'backgroundColor': 'transparent',
              'color': theme.palette.secondary?.main,
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
              },
            }),
          ...(ownerState.variant === 'ghost' &&
            ownerState.color === 'accent' && {
              'border': 'none',
              'backgroundColor': 'transparent',
              'color': theme.palette.accent?.main,
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
              },
            }),
          ...(ownerState.className === 'ghostAlt' && {
            'border': 'none',
            'backgroundColor': 'transparent',
            'fontFamily': 'Montserrat',
            'fontSize': 14,
            'fontWeight': 600,
            'color': theme.palette.primary.main,
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'transparent',
            },
          }),
          ...(ownerState.variant === 'link' && {
            'border': 'none',
            'backgroundColor': Colors.tertiaryBase,
            'color': theme.palette.primary.main,
            'textDecoration': 'underline',
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
            },
          }),
          ...(ownerState.variant === 'link' &&
            ownerState.color === 'secondary' && {
              'border': 'none',
              'backgroundColor': 'transparent',
              'color': theme.palette.secondary?.main,
              'textDecoration': 'underline',
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
              },
            }),
          ...(ownerState.variant === 'link' &&
            ownerState.color === 'accent' && {
              'border': 'none',
              'backgroundColor': 'transparent',
              'color': theme.palette.accent?.main,
              'textDecoration': 'underline',
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
              },
            }),
          ...(ownerState.className === 'black' && {
            'border': 'none',
            'backgroundColor': 'black',
            'color': Colors.tertiaryBase,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
            },
          }),
          ...(ownerState.className === 'font16' && {
            'border': 'none',
            'backgroundColor': Colors.tertiaryBase,
            'color': theme.palette.secondary.main,
            'fontSize': 16,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
            },
          }),
          ...(ownerState.className === 'learnMore' && {
            'border': 'none',
            'backgroundColor': Colors.tertiaryBase,
            'color': theme.palette.primary.main,
            'textDecorationLine': 'underline',
            '&:hover': {
              backgroundColor: Colors.tertiaryBase,
            },
            '&:active': {
              backgroundColor: Colors.tertiaryBase,
            },
          }),
          ...(ownerState.className === 'activeLearnMore' && {
            'border': 'none',
            'backgroundColor': Colors.primaryBase,
            'color': Colors.tertiaryBase,
            'textDecorationLine': 'underline',
            '&:hover': {
              backgroundColor: Colors.primaryBase,
            },
            '&:active': {
              backgroundColor: Colors.primaryBase,
            },
          }),
          ...(ownerState.className === 'link' && {
            'border': 'none',
            'color': Colors.primaryBase,
            'fontSize': '12px',
            'backgroundColor': 'transparent',
            'textDecorationLine': 'underline',
            '&:hover': {
              backgroundColor: 'trasparent',
            },
            '&:active': {
              backgroundColor: 'trasparent',
            },
          }),
          ...(ownerState.className === 'gcCancel' && {
            'fontSize': 14,
            'fontFamily': 'OpenSans',
            'backgroundColor': Colors.tertiaryBase,
            'color': theme.palette.guidedChoice?.main,
            'border': '1px solid',
            'borderColor': theme.palette.guidedChoice?.main,
            'borderRadius': '4px',
            'padding': '5px 15px',
            'width': '11%',
            '&:hover': {
              backgroundColor: '#e2e6e7',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
              color: theme.palette.secondary.dark,
            },
          }) /* Some of these are unused and can change as we need them ; I'm making some guesses to what sort of widths we will want */,
          ...(ownerState.className === 'xs' && {
            width: '10%',
          }),
          ...(ownerState.className === 'sm' && {
            width: '15%',
          }),
          ...(ownerState.className === 'md' && {
            width: '20%',
          }),
          ...(ownerState.className === 'lg' && {
            width: '25%',
          }),
          ...(ownerState.className === 'xl' && {
            width: '40%',
          }),
          ...(ownerState.className === 'xxl' && {
            width: '50%',
          }),
          ...(ownerState.className === 'xxxl' && {
            width: '100%',
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& .MuiOutlinedInput-root': {
            'background': Colors.primaryLightest,
            '& fieldset': {
              borderColor: `${theme.palette.primary.main}`,
            },
            '&:hover fieldset': {
              borderColor: `${theme.palette.primary.main}`,
            },
          },
          ...(ownerState.className === 'ghost-input' && {
            '& fieldset': {
              borderWidth: '0px',
            },
            '&:hover fieldset': {
              borderWidth: '1px',
            },
          }),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: () => ({
          'color': theme.palette.secondary.main,
          '&.Mui-readOnly': {
            'backgroundColor': Colors.disabledLight,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.disabledDark,
            },
            '&:hover fieldset': {
              borderColor: Colors.disabledDarker,
            },
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: () => ({
          'textTransform': 'none',
          'border': 'none',
          'color': theme.palette.info.main,
          'fontSize': '14px',
          '&.Mui-selected': {
            backgroundColor: '#7a8396',
            color: Colors.tertiaryBase,
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#6a7d85', // TODO update this once we have designs
          },
          ':not(:first-of-type)': {
            outline: `1px solid ${theme.palette.info.main}`,
          },
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: () => ({
          backgroundColor: Colors.tertiaryBase,
          outline: `1px solid ${theme.palette.info.main}`,
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: () => ({
          '&.Mui-checked': {
            color: theme.palette.primary,
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          'display': 'inline',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: 'rgba(3, 138, 183, 0)',
          boxShadow: 'none',
          ...(ownerState.className === 'drawer-details' && {
            'width': '78%',
            '& .MuiTableCell-root': {
              textAlign: 'left',
            },
            '& .bold': {
              fontWeight: 'bold',
            },
          }),
          ...(ownerState.className === 'spending-overview-categories-container' && {
            'alignItems': 'baseline',
            'border': `solid 1px ${Colors.primaryLightest}`,
            'backgroundColor': Colors.tertiaryBase,
            'maxHeight': '50vh',
            'paddingLeft': Spacing.xl,
            'paddingRight': Spacing.xl,
            ...Borders.borderRadius_10,
            '& > .spending-overview-categories': {
              'display': 'flex',
              'flexDirection': 'column',
              '& .spending-overview-view': {
                'width': '100%',
                'paddingTop': '10px',
                'paddingBottom': '10px',
                '& .category-row': {
                  'display': 'flex',
                  'justifyContent': 'space-between',
                  'width': '100%',
                  'paddingTop': '10px',
                  'paddingBottom': '10px',
                  '& .category-section': {
                    display: 'flex',
                    width: '50%',
                    columnGap: '24px',
                    alignItems: 'center',
                  },
                  '& .amount-section': {
                    'display': 'flex',
                    'width': '50%',
                    '& > *': {
                      fontFamily: 'Montserrat',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '50%',
                    },
                  },
                  '& .circle': {
                    height: '20px',
                    transition: 'height 0.3s',
                  },
                  '&:hover': {
                    'cursor': 'pointer',
                    'paddingTop': '10px',
                    'paddingBottom': '10px',
                    '& .circle': {
                      height: '24px',
                    },
                    '& .category': {
                      textDecoration: 'underline',
                    },
                  },
                },
                '& > :not(:last-child)': {
                  borderBottom: `solid 1px ${Colors.primaryLighter}`,
                },
              },
            },
          }),
          ...(ownerState.className === 'spending-overview-transactions-container' && {
            'border': `solid 1px ${Colors.primaryLightest}`,
            'backgroundColor': Colors.tertiaryBase,
            'maxHeight': '50vh',
            'paddingLeft': Spacing.xl,
            'paddingRight': Spacing.xl,
            ...Borders.borderRadius_10,
            '& > .spending-overview-transactions': {
              'display': 'flex',
              'flexDirection': 'column',
              '& .spending-transactions-view': {
                'width': '100%',
                'paddingTop': '10px',
                'paddingBottom': '10px',
                '& .transaction-row': {
                  'display': 'flex',
                  'justifyContent': 'space-between',
                  'width': '100%',
                  'paddingTop': '10px',
                  'paddingBottom': '10px',
                  '& .description-section': {
                    'display': 'flex',
                    'width': '70%',
                    'columnGap': '24px',
                    'alignItems': 'center',
                    'overflow': 'hidden',
                    '& .date': {
                      width: '15%',
                    },
                    '& .description': {
                      display: 'inline-block',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '60%',
                    },
                  },
                  '& .amount-section': {
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'width': '40%',
                    '& .category': {
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '100%',
                    },
                    '& .amount': {
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%',
                    },
                  },
                },
                '& > :not(:last-child)': {
                  borderBottom: `solid 1px ${Colors.primaryLighter}`,
                },
              },
            },
          }),
        }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.secondary.dark,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: '16px 0px',
          ...(ownerState.variant === 'hasButton' && {
            ':last-of-type': {
              textAlign: 'right',
              borderBottom: 'none',
              fontSize: 16,
            },
          }),

          ...(ownerState.variant === 'head' && {
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            fontSize: 18,
            fontWeight: 600,
            color: theme.palette.primary.main,
          }),
          ...(ownerState.variant === 'body' && {
            borderBottom: 'none',
            fontSize: 18,
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.variant === 'dataHead' && {
            border: 'none',
            fontSize: 18,
            fontWeight: 700,
            color: theme.palette.secondary.main,
            padding: '5px 10px',
            textAlign: 'right',
          }),
          ...(ownerState.variant === 'dataBodyKey' && {
            border: 'none',
            fontSize: 16,
            fontWeight: 700,
            color: theme.palette.primary.main,
            padding: '5px 10px',
            textAlign: 'right',
          }),
          ...(ownerState.variant === 'dataBodyValue' && {
            border: 'none',
            fontSize: 16,
            color: theme.palette.secondary.main,
            padding: '5px 10px',
          }),
          ...(ownerState.variant === 'dataBodyBold' && {
            border: 'none',
            fontSize: 18,
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
          }),
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'toast-bar' &&
            ownerState.severity === 'success' && {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.main,
            }),
          ...(ownerState.className === 'toast-bar' &&
            ownerState.severity === 'error' && {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.main,
            }),
          ...(ownerState.severity === 'info' && {
            backgroundColor: Colors.alternateLighter,
            color: Colors.accentBase,
          }),
          ...(ownerState.severity === 'warning' &&
            {
              /* fill in if we ever use it */
            }),
        }),
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'toast' && {
            'position': 'absolute',
            'top': 0,
            'left': 0,
            'width': '100%',
            'height': '78px',
            'backgroundColor': theme.palette.primary.light,
            '& .MuiAlert-root': {
              marginTop: '10px',
              justifyContent: 'center !important',
              textAlign: 'center',
              backgroundColor: 'inherit !important',
              color: `${Colors.tertiaryBase} !important`,
              alignItems: 'center',
            },
            '& .MuiAlert-icon': {
              color: `${Colors.tertiaryBase} !important`,
              fontSize: 40,
              marginTop: '-4px',
            },
            '& .MuiAlert-action': {
              color: `${Colors.tertiaryBase} !important`,
              fontSize: 40,
              marginTop: '-8px',
              position: 'absolute',
              right: '16px',
            },
            ...(ownerState.role === 'error' && {
              'backgroundColor': theme.palette.error.light,
              '& .MuiAlert-icon': {
                color: `${theme.palette.error.main} !important`,
                fontSize: 40,
                marginTop: '-4px',
              },
              '& .MuiAlert-root': {
                marginTop: '10px',
                justifyContent: 'center !important',
                textAlign: 'center',
                backgroundColor: 'inherit !important',
                color: `${theme.palette.error.main} !important`,
                alignItems: 'center',
              },
              '& .MuiAlert-action': {
                color: `${theme.palette.error.main} !important`,
                fontSize: 40,
                marginTop: '-8px',
                position: 'absolute',
                right: '16px',
              },
            }),
            ...(ownerState.role === 'success' && {
              'backgroundColor': Colors.primaryLighter,
              '& .MuiAlert-icon': {
                color: `${Colors.primaryBase} !important`,
                fontSize: 40,
                marginTop: '-4px',
              },
              '& .MuiAlert-root': {
                marginTop: '10px',
                justifyContent: 'center !important',
                textAlign: 'center',
                backgroundColor: 'inherit !important',
                color: `${Colors.primaryBase} !important`,
                alignItems: 'center',
              },
              '& .MuiAlert-action': {
                color: `${Colors.primaryBase} !important`,
                fontSize: 40,
                marginTop: '-8px',
                position: 'absolute',
                right: '16px',
              },
            }),
            ...(ownerState.role === 'info' && {
              'backgroundColor': Colors.alternateLighter,
              '& .MuiAlert-icon': {
                color: `${theme.palette.error.main} !important`,
                fontSize: 40,
                marginTop: '-4px',
              },
              '& .MuiAlert-root': {
                marginTop: '10px',
                justifyContent: 'center !important',
                textAlign: 'center',
                backgroundColor: 'inherit !important',
                color: `${theme.palette.error.main} !important`,
                alignItems: 'center',
              },
              '& .MuiAlert-action': {
                color: `${theme.palette.error.main} !important`,
                fontSize: 40,
                marginTop: '-8px',
                position: 'absolute',
                right: '16px',
              },
            }),
          }),
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.anchor === 'right' && {
            '& .MuiDrawer-paper': { width: Mui.DrawerWidth },
          }),
          ...(ownerState.className === 'action-sheet' && {
            '& .MuiDrawer-paper': { borderRadius: '8px 8px 0 0' },
          }),
          ...(ownerState.className === 'mobile-drawer' && {
            'boxSizing': 'border-box',
            '& .MuiDrawer-paper': { width: '100%', display: 'block', padding: '5px' },
          }),
          ...(ownerState.className === 'browser-drawer' && {
            '& .MuiDrawer-paper': { width: '60%', padding: '5px' },
          }),
          ...(ownerState.role === 'past-invoice' && {
            '& .MuiBox-root': { maxWidth: '900px', padding: '20px' },
          }),
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: Colors.tertiaryBase,
          zIndex: theme.zIndex.drawer + 1,
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'top-nav' && {
            display: 'flex',
            flex: 1,
            zIndex: 3,
            position: 'fixed',
            backgroundColor: Colors.tertiaryBase,
            color: Colors.cancelPolicy,
            height: '64px',
          }),
          ...(ownerState.className === 'app-header' && {
            boxShadow: '0 2px 2px 0 rgba(182, 192, 196, 0.3)',
          }),
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          'overflow': 'visible',
          'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          'mt': 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '& .MuiMenuItem-root': {
            '&:hover': {
              bgcolor: theme.palette.primary.light,
            },
            'color': theme.palette.secondary.main,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.role === 'menu' &&
            ownerState.className === 'side-navigation' && {
              '& a': {
                textDecoration: 'unset !important',
                color: 'inherit !important',
              },
              '& .MuiDivider-root': {
                borderColor: `${theme.palette.secondary.main}`,
                marginRight: '12px',
                marginLeft: '12px',
                marginTop: '12px !important',
                marginBottom: '18px !important',
              },
              '& .MuiMenuItem-root': {
                marginTop: '10px',
                marginLeft: '10px !important',
              },
              '& .selected': {
                color: `${theme.palette.secondary.main} !important`,
                fontWeight: 'bold',
                borderRadius: '10px !important',
                backgroundColor: `${Colors.secondaryLighter} !important`,
                marginLeft: '15px !important',
                marginRight: '12px',
                height: '40px',
                justifyContent: 'left',
                paddingLeft: '12px !important',
              },
            }),
        }),
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '& .MuiFormControlLabel-root': {
            'border': `1px solid ${
              ownerState.className === 'radio-error'
                ? theme.palette.error.main
                : theme.palette.primary.main
            }`,
            'padding': '10px 30px',
            'flex': '1',
            'width': '100%',
            'textAlign': 'center',
            'marginRight': 0,
            'marginLeft': 0,
            'justifyContent': 'center',
            'backgroundColor': Colors.tertiaryBase,
            'fontSize': '18px',
            '&:first-of-type': {
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            },
            '&:last-child': {
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
              borderColor: Colors.disabledBase,
            },
            '&.lblContainerChecked': {
              'backgroundColor': theme.palette.primary.main,
              'color': theme.palette.primary.contrastText,
              '&.Mui-disabled': {
                backgroundColor: Colors.disabledBase,
                borderColor: Colors.disabledBase,
              },
            },
            '&.lblContainerUnchecked': {
              color: theme.palette.secondary.main,
            },
            '& .MuiRadio-root': {
              display: 'none',
            },
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.error && {
            '& .MuiInputBase-root': {
              borderColor: theme.palette.error.main,
            },
          }),
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'primary' && {
            color: theme.palette.primary.main,
          }),
          ...(ownerState.color === 'secondary' && {
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.color === 'tertiary' && {
            color: theme.palette.tertiary?.main,
          }),
        }),
        label: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          color: Colors.accentBase,
          marginLeft: 0,
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: () => ({
          /* fill in if we need */
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: () => ({
          /* fill in if we need */
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'institutional-modal-content' && {
            'paddingTop': '0px',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'marginBottom': '30px',
            'marginLeft': '18px',
            'marginRight': '18px',
            '& svg': {
              width: '90px',
            },
            '& span': {
              alignSelf: 'start',
              marginTop: '10px',
            },
          }),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'institutional-modal-actions' && {
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'marginBottom': '10px',
            '& button': {
              height: '40px',
              width: '35%',
            },
          }),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'primary' && {
            'color': theme.palette.primary.main,
            'backgroundColor': 'transparent',
            'borderColor': theme.palette.primary.main,
            '&::before': {
              borderColor: theme.palette.primary.main,
            },
            '&::after': {
              borderColor: theme.palette.primary.main,
            },
          }),
          ...(ownerState.color === 'primary.light' && {
            'color': theme.palette.primary.light,
            'backgroundColor': 'transparent',
            'borderColor': theme.palette.primary.light,
            '&::before': {
              borderColor: theme.palette.primary.light,
            },
            '&::after': {
              borderColor: theme.palette.primary.light,
            },
          }),
          ...(ownerState.color === 'secondary' && {
            'color': theme.palette.secondary.main,
            'backgroundColor': 'transparent',
            'borderColor': theme.palette.secondary.main,
            '&::before': {
              borderColor: theme.palette.secondary.main,
            },
            '&::after': {
              borderColor: theme.palette.secondary.main,
            },
          }),
          ...(ownerState.color === 'tertiary' && {
            'color': theme.palette.tertiary?.main,
            'backgroundColor': 'transparent',
            'borderColor': theme.palette.tertiary?.main,
            '&::before': {
              borderColor: theme.palette.tertiary?.main,
            },
            '&::after': {
              borderColor: theme.palette.tertiary?.main,
            },
          }),
          ...(ownerState.className === 'dependent-divider' && {
            marginTop: '16px',
            marginBottom: '16px',
          }),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'date-picker-input-label' && {
            fontSize: '14px !important',
          }),
        }),
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            borderRadius: '4px',
            border: `solid 1px ${Colors.primaryBase}`,
            backgroundColor: `${Colors.primaryLightest} !important`,
            padding: '0 !important',
            input: {
              height: 'inherit !important',
              padding: '10px !important',
            },
            color: Colors.secondaryBase,
          },
        },
      ],
      styleOverrides: {
        select: {
          paddingTop: '10px !important',
          paddingBottom: '10px !important',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: () => ({
          '&.Mui-readOnly': {
            backgroundColor: `${Colors.disabledLight} !important`,
            border: `solid 1px ${Colors.disabledDark} !important`,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          'color': Colors.cancelPolicy,
          '&$checked': {
            color: '#038ab7 !important',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& > svg': {
            '& .hover-clickable': {
              cursor: 'pointer',
            },
          },
          ...(ownerState.className === 'content-wrapper' && {
            '& > *': {},
            ...FullscreenView,
            ...BackgroundImage,
            'display': 'grid',
            'gridTemplateRows': '97px auto',
            'gridTemplateColumns': '250px auto',
            'gridTemplateAreas': `"header header"
                                "sidebar content"`,
          }),
          ...(ownerState.className === 'content-view' && {
            'zIndex': 1,
            '& .content': {
              'display': 'flex',
              'flexDirection': 'row',
              'justifyContent': 'center',
              '& > .children': {
                maxWidth: '1200px',
                width: '100%',
              },
            },
          }),
          ...(ownerState.className === 'page-content-with-background' && {
            ...FullscreenView,
            ...BackgroundImage,
          }),
          ...(ownerState.className === 'page-content' && {
            ...Fullscreen,
          }),
          ...(ownerState.className === 'form-content' && {
            width: Percentages.Full,
          }),
          ...(ownerState.className === 'side-nav' && {
            backgroundColor: `${Colors.tertiaryBase} !important`,
            color: `${Colors.primaryBase} !important`,
            height: '100%',
            borderTopRightRadius: '20px',
            boxShadow: '1px -1px 3px 1px rgba(30, 41, 105, 0.2)',
            zIndex: 2,
          }),
          ...(ownerState.className === 'mailing-info' && {
            'backgroundColor': Colors.primaryLightest,
            ...Borders.borderRadius_7,
            '& .MuiSvgIcon-root': {
              marginTop: '11px',
              color: '#e5aa2b',
            },
          }),
          ...(ownerState.className === 'account-view-container' && {
            '& .account-view ~ .account-view': {
              marginTop: '30px',
            },
            '& .account-list': {
              '& > *': { justifyContent: 'start' },
            },
          }),
          ...(ownerState.className === 'group-edit-item' && {
            border: `solid 1px ${Colors.primaryLightest}`,
            backgroundColor: Colors.tertiaryBase,
            maxWidth: '1200px !important',
            flexBasis: '100% !important',
            ...Borders.borderRadius_10,
            ...Alignments.verticalAlignCenter,
          }),
          ...(ownerState.className === 'group-edit-item-needs-attention' && {
            border: `solid 1px ${Colors.accentBase}`,
            backgroundColor: Colors.tertiaryBase,
            maxWidth: '1200px !important',
            flexBasis: '100% !important',
            ...Borders.borderRadius_10,
            ...Alignments.verticalAlignCenter,
          }),
          ...(ownerState.className === 'group-edit-count' && {
            maxWidth: '1200px !important',
            flexBasis: '100% !important',
            ...Alignments.verticalAlignCenter,
          }),
          ...(ownerState.className === 'dependent-item' && {
            backgroundColor: Colors.primaryLightest,
            ...Borders.borderRadius_10,
            ...Alignments.verticalAlignCenter,
          }),
          ...(ownerState.className === 'dependent-count' && {
            ...Borders.borderRadius_10,
            ...Alignments.verticalAlignCenter,
          }),
          ...(ownerState.className === 'help' && {
            borderColor: `${Colors.primaryLightest} !important`,
            ...Alignments.verticalAlignCenter,
          }),
          ...(ownerState.className === 'account-list' && {
            '& .group-edit-item': {
              width: '100%',
              backgroundColor: Colors.tertiaryBase,
              ...Borders.borderRadius_10,
              ...Alignments.verticalAlignCenter,
            },
          }),
          ...(ownerState.className === 'dependent-select' && {
            '& > .add-dependent': {
              padding: '10px !important',
              paddingRight: '0px !important',
            },
          }),
          ...(ownerState.className === 'add-investment' && {
            backgroundColor: Colors.primaryLightest,
            borderRadius: 'inherit',
          }),
          ...(ownerState.className === 'duplicate-account-content' && {
            'marginTop': '30px',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            '& .duplicate-account-info': {
              marginTop: '50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
          }),
          ...(ownerState.className === 'help-text' && {
            '& > .MuiTypography-root': {
              textDecoration: 'underline',
              marginRight: '5px',
            },
          }),
          ...(ownerState.className === 'collapsible-investment-selection' && {
            '& > .MuiGrid-root': {
              width: '100%',
            },
            '& .group-panels': {
              'flexDirection': 'row',
              'justifyContent': 'space-around',
              'width': '100%',
              '& > .panel': {
                'border': `${Colors.primaryLightest} 1px solid`,
                'boxShadow': 'none',
                '&:hover': {
                  backgroundColor: Colors.disabledLight,
                },
              },
            },
            '& .MuiTypography-root': {
              color: `${Colors.secondaryBase}`,
              fontSize: '16px',
            },
          }),
          ...(ownerState.className === 'college-giving-goal-panels' && {
            '& .panel-popover': {
              flexGrow: 1,
              maxWidth: 'none',
            },
            ...Alignments.alignCenter,
          }),
          ...(ownerState.className === 'plaid-status-card' && {
            '& .rectangle-panel h3.MuiTypography-h6': {
              ...theme.typography.p18Bold,
            },
          }),
          ...(ownerState.className === 'account-view-status-icon' && {
            '& svg': {
              height: '20px',
            },
          }),
          ...(ownerState.className === 'auto-save-indicator' && {
            'alignItems': 'center',
            '& > *': {
              marginRight: '10px !important',
            },
            '& .MuiCircularProgress-root, svg': {
              maxWidth: '20px',
              maxHeight: '20px',
            },
          }),
          ...(ownerState.className === 'embedded-list' && {
            'marginTop': '10px',
            'marginBottom': '16px',
            '& .embedded-list-header': {
              'width': '100%',
              '&:not(:hover)': {
                '& .show-on-hover': {
                  visibility: 'hidden',
                },
              },
              '& :hover': {
                'cursor': 'pointer',
                '& .show-on-hover': {
                  visibility: 'visible',
                },
              },
              '& :last-child': {
                flexGrow: 1,
              },
            },
            '& .embedded-list-content': {
              'paddingLeft': '0px',
              'borderColor': Colors.primaryLighter,
              'backgroundColor': Colors.secondaryLighter,
              '& > .MuiGrid-root': {
                padding: '0px',
              },
              '& > .MuiIconButton-root': {
                marginLeft: '2px',
                marginRight: '2px',
              },
              // '& :hover': {
              //   cursor: 'pointer',
              // },
              '&:not(:hover)': {
                '& .MuiIconButton-root': {
                  visibility: 'hidden',
                },
                '& .show-on-hover': {
                  visibility: 'hidden',
                },
              },
            },
          }),
          ...(ownerState.className === 'embedded-form' && {
            '& .embedded-form-input:not(:first-child)': {
              borderTop: `solid 1px ${Colors.primaryLight}`,
            },
          }),
          ...(ownerState.className === 'embedded-form-input' && {
            'justifyContent': 'space-between',
            'paddingBottom': '3px',
            'paddingTop': '3px',
            '&:not(:hover, :focus-within)': {
              '& .MuiOutlinedInput-root': {
                visibility: 'hidden',
              },
              '& .MuiInputBase-input': {
                textAlign: 'end',
                paddingRight: '0px',
                visibility: 'visible',
                fontWeight: 'bold',
              },
            },
            '&:hover, &:focus-within': {
              cursor: 'pointer',
            },
            '& .MuiTextField-root': {
              width: 'fit-content',
            },
            '& .Mui-error': {
              textAlign: 'end',
              marginRight: '0px',
            },
          }),
          ...(ownerState.className === 'budget-details-averages' && {
            '& > :not(:last-child)': {
              marginBottom: '10px',
            },
          }),
          ...(ownerState.className === 'budget-details-panel-ghost' && {
            'display': 'flex',
            'justifyContent': 'space-between',
            'maxWidth': '1200px !important',
            'flexBasis': '100% !important',
            'padding': '10px',
            ...Borders.borderRadius_10,
            ...Alignments.verticalAlignCenter,
            '& .budget-details-panel-start': {
              'display': 'flex',
              '& svg': {
                marginRight: '10px',
              },
            },
            '& .budget-details-panel-content': {
              'display': 'flex',
              'justifyContent': 'center',
              'minHeight': '40px',
              'borderRight': `2px solid ${Colors.primaryLight}`,
              'borderLeft': `2px solid ${Colors.primaryLight}`,
              '& > :not(:first-child)': {
                marginLeft: '5px',
              },
            },
            '& .budget-details-panel-end': {
              justifyContent: 'end',
            },
          }),
          ...(ownerState.className === 'budget-details-panel' && {
            'display': 'flex',
            'justifyContent': 'space-between',
            'border': `solid 1px ${Colors.primaryLightest}`,
            'backgroundColor': Colors.tertiaryBase,
            'maxWidth': '1200px !important',
            'flexBasis': '100% !important',
            'padding': '10px',
            ...Borders.borderRadius_10,
            ...Alignments.verticalAlignCenter,
            '& .budget-details-panel-start': {
              'display': 'flex',
              '& svg': {
                marginRight: '10px',
              },
            },
            '& .budget-details-panel-content': {
              'display': 'flex',
              'justifyContent': 'center',
              'minHeight': '40px',
              'borderRight': `2px solid ${Colors.primaryLight}`,
              'borderLeft': `2px solid ${Colors.primaryLight}`,
              '& > :not(:first-child)': {
                marginLeft: '5px',
              },
            },
            '& .budget-details-panel-end': {
              justifyContent: 'end',
            },
          }),
          ...(ownerState.className === 'content-card-container' && {
            'marginTop': '20px',
            '& > .content-card-header': {
              '& > svg': {
                marginRight: '10px',
              },
              '& > .column-header': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '20px',
              },
            },
            '& > .MuiCard-root': {
              marginTop: '15px',
            },
          }),
          ...(ownerState.className?.includes('info-card-border') && {
            '& > .MuiCard-root': {
              'padding': '20px',
              'boxShadow': 'none',
              'border': `1px solid ${Colors.primaryLight}`,
              'display': 'flex',
              'alignItems': 'center',
              '& > svg:first-child': {
                marginRight: '15px',
              },
            },
          }),
          ...(ownerState.className?.includes('info-card') && {
            '& :hover': {
              cursor: 'pointer',
              backgroundColor: Colors.disabledLight,
            },
          }),
          ...(ownerState.className === 'wizard-footer' && {
            '& .MuiButton-root': {
              whiteSpace: 'nowrap',
              minWidth: '150px',
            },
          }),
          ...(ownerState.className === 'mortgage-select' && {
            '& .embedded-list': {
              'margin': '18px 0',
              '& .embedded-list-content': {
                'backgroundColor': Colors.tertiaryBase,
                'border': `solid 1px ${Colors.primaryLightest}`,
                '& .embedded-list-content-title': {
                  color: Colors.secondaryBase,
                },
                '& .embedded-list-content-summary': {
                  color: Colors.accentBase,
                },
              },
            },
            '& .embedded-list-content:hover': {
              backgroundColor: Colors.disabledLight,
            },
            '& .empty': {
              '& .embedded-list-header': {
                'justifyContent': 'center',
                '& .start-header': {
                  flexGrow: 1,
                  justifyContent: 'end',
                },
              },
              'backgroundColor': Colors.tertiaryBase,
              'border': `solid 1px ${Colors.primaryLightest}`,
              ...Borders.borderRadius_7,
            },
            '& .not-empty': {
              maxWidth: '50%',
            },
          }),
          ...(ownerState.className === 'goal-summary' && {
            '& .MuiCard-root': {
              width: '100%',
              minHeight: '100px',
              marginTop: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
              backgroundColor: Colors.tertiaryBase,
              border: `solid 1px ${Colors.primaryLightest}`,
              ...Borders.borderRadius_7,
            },
          }),
          ...(ownerState.className === 'investment-account-results-header' && {
            'width': '50%',
            'justifyContent': 'end',
            'margin': '10px 0',
            'padding': '10px 20px',
            '& > *': {
              width: '50%',
              justifyContent: 'end',
            },
            '& > .compare': {
              cursor: 'pointer',
            },
          }),
          ...(ownerState.className === 'investment-results-asset-breakdown' && {
            'display': 'flex',
            'marginTop': '12px',
            'padding': '0 20px',
            '& > .MuiGrid-root': {
              width: '50%',
            },
            '& .amount-breakdown': {
              'display': 'flex',
              'justifyContent': 'end',
              'fontFamily': 'Montserrat',
              'fontWeight': 'normal',
              'fontSize': '16',
              '& > *': {
                width: '25%',
                justifyContent: 'end',
              },
            },
          }),
          ...(ownerState.className === 'investment-account-results' && {
            '& .MuiDivider-root': {
              margin: '20px 0',
            },
            '& .help-text': {
              margin: '20px 0',
            },
            '& .collapsible-panel': {
              '& .collapsible-panel-header': {
                'width': '100%',
                'display': 'flex',
                'justifyContent': 'flex-end',
                '& :last-child': {
                  flexGrow: '0',
                },
                '& .collapsible-panel-summary': {
                  fontSize: '22px',
                  fontFamily: 'Montserrat',
                  fontWeight: 'normal',
                },
                '& .MuiCardHeader-content': {
                  'width': '50%',
                  '& .embedded-list-content-title': {
                    'color': Colors.secondaryBase,
                    'fontSize': '18px',
                    'textTransform': 'uppercase',
                    '& svg': {
                      marginRight: '10px',
                    },
                  },
                  '& .embedded-list-content-summary': {
                    color: Colors.primaryBase,
                    fontSize: '22px',
                    fontFamily: 'Montserrat',
                    width: '50%',
                    justifyContent: 'end',
                  },
                },
                '& .MuiCardHeader-action': {
                  'width': '50%',
                  '& .embedded-list-content-title': {
                    'color': Colors.secondaryBase,
                    'fontSize': '18px',
                    'textTransform': 'uppercase',
                    '& svg': {
                      marginRight: '10px',
                    },
                  },
                },
              },
            },
          }),
          ...(ownerState.className === 'brokerage-account-info-cards' && {
            'marginTop': '20px',
            '& .info-card': {
              marginTop: '20px',
            },
            '& .info-card:not(:last-child)': {
              marginRight: '20px',
            },
          }),
          ...(ownerState.className?.includes('progress-indicator') && {
            // 'marginRight': '50px',
            'position': 'relative',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'end',
            '& .MuiDivider-root': {
              borderColor: Colors.primaryLight,
              marginRight: '9px',
              flexGrow: 1,
            },
            '& .progress-item': {
              'fontSize': '18px',
              'width': 'max-content',
              'display': 'flex',
              'justifyContent': 'end',
              'alignItems': 'start',
              '.progress-item-content': {
                'marginLeft': '10px',
                'marginRight': '10px',
                'marginBottom': '40px',
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'end',
                '& .progress-item-child': {
                  marginTop: '5px',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '150px',
                },
              },
              '& svg': {
                'zIndex': 1,
                'color': Colors.primaryLight,
                'fontSize': '15px',
                'margin': '-2px 2px',
                '&:hover': {
                  fontSize: '17px',
                  margin: '-2px 1px',
                  cursor: 'pointer',
                },
              },
              '& .active': {
                color: Colors.secondaryBase,
              },
              '& .progress-item-content:has(> :is(.progress-item-title:hover)) + *': {
                '& svg': {
                  fontSize: '17px',
                  margin: '-2px 1px',
                },
              },
              '& .progress-item-content > *': {
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              },
            },
          }),
          ...(ownerState.className === 'alert-switch-option' && {
            backgroundColor: Colors.tertiaryBase,
            border: `solid 1px ${Colors.primaryLightest}`,
            padding: '10px',
            borderRadius: '12px',
            width: '315px',
          }),
          ...(ownerState.className === '3NU-overview-content' && {
            '& .panel-popover': {
              'flexGrow': 1,
              'maxWidth': 'none',
              '& > .MuiCard-root': {
                padding: '16px 20px',
              },
            },
            '& .group-panels': {
              marginTop: '0px',
            },
            '& .panel': {
              '& .MuiTypography-root': {
                cursor: 'auto !important',
              },
            },
            '& .group-panel-container': {
              'display': 'flex',
              'flexDirection': 'column',
              'rowGap': '20px',
              '& .MuiCard-root': {
                boxShadow: 'none',
                border: `solid 1px ${Colors.primaryLightest}`,
              },
            },
          }),
          ...(ownerState.className === 'advice-section' && {
            '& .panel-popover': {
              'flexGrow': 1,
              'maxWidth': 'none',
              'marginTop': '10px',
              '& > .MuiCard-root': {
                padding: '16px 20px',
              },
            },
            '& .collapsible-panel': {
              '& .MuiCardContent-root': {
                padding: '10px 20px',
              },
            },
          }),
          ...(ownerState.className === 'advice-budget-section' && {
            border: `solid 1px ${Colors.primaryLightest}`,
            backgroundColor: Colors.tertiaryBase,
            maxWidth: '1200px !important',
            flexBasis: '100% !important',
            padding: Spacing.md,
            ...Borders.borderRadius_10,
            ...Alignments.verticalAlignCenter,
          }),
          ...(ownerState.className === '3NU-section' && {
            'border': `solid 1px ${Colors.primaryLightest}`,
            'backgroundColor': Colors.tertiaryBase,
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'maxWidth': '1200px !important',
            'padding': Spacing.md,
            'paddingBottom': '0px',
            ...Borders.borderRadius_10,
            '& .content-section': {
              // 'flexBasis': '100% !important',
              'justifyContent': 'space-between',
              ...Alignments.verticalAlignCenter,
              '& .course-details': {
                maxWidth: '30%',
              },
            },
          }),
          ...(ownerState.className === 'walk-through-advice-card' && {
            'border': `solid 1px ${Colors.primaryLightest}`,
            'backgroundImage': 'linear-gradient(to bottom, rgba(231, 247, 250, 0.27), #89d2e2)',
            'display': 'flex',
            'flexWrap': 'nowrap',
            'justifyContent': 'space-between',
            'alignItems': 'end',
            '& > img': {
              width: '50%',
              borderRadius: 'inherit',
            },
            ...Borders.borderRadius_10,
          }),
          ...(ownerState.className === '3nu-next-lesson-card' && {
            'border': `solid 1px ${Colors.primaryLightest}`,
            'backgroundImage': 'linear-gradient(to bottom, #ededed -61%, #cfe1f2)',
            'display': 'flex',
            'flexWrap': 'nowrap',
            'justifyContent': 'space-between',
            'alignItems': 'stretch',
            '& > .MuiGrid-root': {
              display: 'flex',
              alignItems: 'center',
              background: '#f7d27e',
              flexGrow: 1,
            },
            '& > .left-grid': {
              borderTopLeftRadius: 'inherit',
              borderBottomLeftRadius: 'inherit',
            },
            '& > .course-details': {
              alignItems: 'start',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            '& > .gradient': {
              background:
                'linear-gradient(to bottom right, #f7d27e, #f7d27e 50%, transparent 50%, transparent)',
              height: '150px',
              width: '150px',
              flexGrow: 0,
            },
            '& > img': {
              maxWidth: '230px',
              borderRadius: 'inherit',
            },
            ...Borders.borderRadius_10,
          }),
          ...(ownerState.className === 'unit-section' && {
            '& .collapsible-panel': {
              '& .MuiCardContent-root': {
                padding: '10px 20px',
              },
            },
          }),
          ...(ownerState.className === 'unit-progress-card-lesson' && {
            ...Borders.borderRadius_10,
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'space-between',
            'border': `1px solid ${Colors.primaryLightest}`,
            'backgroundColor': Colors.secondaryLighter,
            'padding': '10px',
            '& .unit-progress-card-details': {
              'alignItems': 'center',
              'columnGap': '10px',
              'display': 'flex',
              '& > svg': {
                width: '30px',
                height: '30px',
              },
            },
            '& .show-on-hover': {
              display: 'none',
            },
            '&:hover': {
              'cursor': 'pointer',
              '& .lesson-title': {
                textDecoration: 'underline',
              },
              '& .show-on-hover': {
                display: 'inherit',
              },
              '& .hide-on-hover': {
                display: 'none',
              },
            },
            '& .hide-on-hover': {
              display: 'inherit',
            },
          }),
          ...(ownerState.className === 'course-progress-side-view' && {
            ...Borders.borderRadius_10,
            'border': `1px solid ${Colors.primaryLightest}`,
            'backgroundColor': '#fff',
            'height': 'fit-content',
            'maxHeight': '70vh',
            '& .MuiTableContainer-root': {
              maxHeight: '70vh',
            },
            '& .MuiCardContent-root': {
              paddingBottom: '0px !important',
            },
            '& .collapsible-panel': {
              'marginBottom': '0px',
              '&:not(:last-child)': {
                borderBottom: `solid 1px ${Colors.primaryLightest}`,
              },
            },
            '& .unit-progress-card-lesson': {
              'borderRadius': '0px',
              'border': 'none',
              '&:first-child': {
                borderTop: `solid 1px ${Colors.primaryLightest}`,
              },
            },
          }),
          ...(ownerState.className === 'tools-split-card' && {
            'backgroundImage': 'linear-gradient(to bottom, #cfe1f2 -61%, #accae8)',
            'display': 'flex',
            'flexWrap': 'nowrap',
            '& > .MuiGrid-root': {
              display: 'flex',
              alignItems: 'center',
              background: Colors.disabledLight,
            },
            '& > .left-grid': {
              borderTopLeftRadius: 'inherit',
              borderBottomLeftRadius: 'inherit',
            },
            '& > .img-wrapper': {
              'position': 'relative',
              '&::before': {
                content: '""',
                background: Colors.disabledLight,
                position: 'absolute',
                clipPath: 'polygon(-1% 0, 25% 0, 0 100%)',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 2,
              },
              '& > img': {
                maxWidth: '100%',
                height: '100%',
                borderRadius: 'inherit',
              },
            },
            ...Borders.borderRadius_10,
          }),
          ...(ownerState.className === 'forward-slash-split-card' && {
            'width': '100%',
            'overflow': 'hidden',
            'display': 'flex',
            'flexWrap': 'nowrap',
            'background': 'var(--background)',
            '& > .MuiGrid-root': {
              display: 'flex',
              alignItems: 'center',
              background: 'var(--background)',
            },
            '& .left-grid': {
              'display': 'flex',
              'position': 'relative',
              'alignContent': 'center',
              'justifyContent': 'center',
              'borderTopLeftRadius': 'inherit',
              'borderBottomLeftRadius': 'inherit',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: -150,
                width: '150px',
                height: '100%',
                background: 'var(--background)',
                clipPath: 'polygon(0 0, 100% 0, 0% 100%)',
                zIndex: 2,
              },
            },
            '& > .img-wrapper': {
              'display': 'flex',
              'position': 'relative',
              'borderBottomRightRadius': 'inherit',
              'borderTopRightRadius': 'inherit',
              'overflow': 'hidden',
              '& img': {
                display: 'flex',
                overflow: 'hidden',
                maxHeight: '100%',
                borderRadius: 'inherit',
              },
            },
            ...Borders.borderRadius_10,
          }),
          ...(ownerState.className === 'back-slash-split-card' && {
            'width': '100%',
            'height': '100%',
            'display': 'flex',
            'flexWrap': 'nowrap',
            'overflow': 'hidden',
            'background': 'var(--background)',
            '& > .MuiGrid-root': {
              display: 'flex',
              alignItems: 'center',
              background: 'var(--background)',
            },
            '& > .img-wrapper': {
              'display': 'flex',
              'position': 'relative',
              'borderBottomLeftRadius': 'inherit',
              'borderTopLeftRadius': 'inherit',
              'overflow': 'hidden',
              '& > img': {
                width: '100%',
                maxHeight: '100%',
                borderRadius: 'inherit',
              },
            },
            '& .right-grid': {
              'display': 'flex',
              'position': 'relative',
              'width': '100%',
              'alignContent': 'center',
              'justifyContent': 'center',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: -50,
                width: '50px',
                height: '100%',
                background: 'var(--background)',
                clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
                zIndex: 2,
              },
            },
            ...Borders.borderRadius_10,
          }),
          ...(ownerState.className === 'holistic-advice-view-content' && {
            '& .advice-section': {
              marginTop: Spacing.lg,
            },
            '& .left-container': {
              marginRight: Spacing.xxl,
              // flexGrow: 1,
              width: '33%',
            },
            '& .right-container': {
              // flexGrow: 2,
              width: '67%',
            },
          }),
          ...(ownerState.className === 'goal-card-details-summary' && {
            ...Borders.borderRadius_10,
            border: `1px solid ${Colors.primaryLightest}`,
            backgroundColor: Colors.secondaryLighter,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '10px',
            paddingBottom: '10px',
            alignItems: 'center',
          }),
          ...(ownerState.className === 'transactions-selector-panel' && {
            'color': Colors.secondaryBase,
            'fontSize': '12px',
            'background': Colors.primaryLightest,
            'borderRadius': '7px',
            'paddingLeft': '5px',
            'paddingRight': '5px',
            'alignItems': 'center',
            'display': 'flex',
            'columnGap': '10px',
            '&:hover': {
              cursor: 'pointer',
              background: Colors.primaryLighter,
            },
          }),
          ...(ownerState.className === 'transactions-selectors' && {
            'marginTop': '20px',
            'marginBottom': '20px',
            'display': 'flex',
            'columnGap': '10px',
            '& > *': {
              flexGrow: 1,
            },
          }),
          ...(ownerState.className === 'category-select-container' && {
            display: 'flex',
            justifyContent: 'flex-start',
            columnGap: '4%',
            rowGap: '10px',
          }),
          ...(ownerState.className === 'category-select-all' && {
            'borderRadius': '10px',
            'border': `solid 1px transparent`,
            'padding': '10px',
            'display': 'flex',
            'justifyContent': 'space-between',
            'width': '48%',
            '&:hover': {
              background: Colors.primaryLightest,
              cursor: 'pointer',
            },
            '& .MuiCheckbox-root': {
              padding: '0px',
              color: Colors.secondaryBase,
            },
            '& .MuiCheckbox-root.Mui-checked': {
              color: Colors.secondaryBase,
            },
            '& .MuiCheckbox-root.MuiCheckbox-indeterminate': {
              color: Colors.secondaryBase,
            },
            '& .MuiTypography-root': {
              color: Colors.secondaryBase,
            },
          }),
          ...(ownerState.className === 'category-select' && {
            'borderRadius': '10px',
            'border': `solid 1px ${Colors.primaryLightest}`,
            'padding': '10px',
            'display': 'flex',
            'justifyContent': 'space-between',
            'width': '48%',
            '&:hover': {
              background: Colors.primaryLightest,
              cursor: 'pointer',
            },
            '& .MuiCheckbox-root': {
              padding: '0px',
              color: Colors.primaryLight,
            },
            '& .MuiTypography-root': {
              color: Colors.primaryBase,
            },
          }),
          ...(ownerState.className === 'category-select-selected' && {
            'borderRadius': '10px',
            'border': `solid 1px ${Colors.primaryLightest}`,
            'padding': '10px',
            'display': 'flex',
            'justifyContent': 'space-between',
            'width': '48%',
            'background': Colors.primaryBase,
            '&:hover': {
              background: Colors.primaryLight,
              cursor: 'pointer',
            },
            '& .MuiCheckbox-root': {
              padding: '0px',
              color: Colors.tertiaryBase,
            },
            '& .MuiTypography-root': {
              color: Colors.tertiaryBase,
            },
          }),
          ...(ownerState.className === 'spending-overview-categories-container' && {
            'display': 'flex',
            'flexBasis': '50%',
            'height': '15px',
            'alignItems': 'center',
            'wrap': 'nowrap',
            '& .circle': {
              height: '15px',
              transition: 'height 0.3s',
            },
            '& .category': {
              marginLeft: '5px',
              whiteSpace: 'noWrap',
            },
            '&:hover': {
              'cursor': 'pointer',
              '& .circle': {
                height: '20px',
              },
              '& .category': {
                textDecoration: 'underline',
                height: '20px',
              },
            },
          }),
          ...(ownerState.className === 'lifestyle-banner' && {
            'width': '973px',
            'position': 'relative',
            'border': `solid 1px ${Colors.primaryLightest}`,
            'backgroundImage': 'linear-gradient(to bottom, #cfe1f2 -61%, #accae8)',
            'display': 'flex',
            'flexWrap': 'nowrap',
            'justifyContent': 'space-between',
            'alignItems': 'stretch',
            '& .MuiTypography-root': {
              zIndex: 3,
            },
            '& > .MuiGrid-root': {
              display: 'flex',
              justifyContent: 'center',
              background: '#8e94b4',
              flexGrow: 1,
            },
            '& > .left-grid': {
              borderTopLeftRadius: 'inherit',
              borderBottomLeftRadius: 'inherit',
            },
            '& > .gradient': {
              position: 'absolute',
              top: 0,
              right: 0,
              background:
                'linear-gradient(to bottom right, #8e94b4, #8e94b4 50%, transparent 50%, transparent)',
              height: '100%',
              width: '210px',
              flexGrow: 0,
              zIndex: 2,
              marginRight: 170,
            },
            '& > .gap': {
              position: 'absolute',
              top: 0,
              right: 0,
              background: 'linear-gradient(to right, #8e94b4, #8e94b4 50%)',
              height: '100%',
              width: '150px',
              flexGrow: 0,
              zIndex: 2,
              marginRight: 380,
            },
            '& > img': {
              position: 'relative',
              zIndex: 1,
              maxWidth: '450px',
              height: 'auto',
              borderRadius: 'inherit',
            },
            ...Borders.borderRadius_10,
          }),
          ...(ownerState.className === 'lifestyle-group-panels' && {
            ...Alignments.alignCenter,
            '& .group-panels': {
              'display': 'grid',
              'gridTemplateColumns': 'repeat(3, 1fr)',
              'columnGap': '20px',
              'rowGap': '20px',
              '@media (max-width: 1300px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
              '@media (max-width: 900px)': {
                gridTemplateColumns: '1fr',
              },
            },
            '& .MuiCard-root': {
              height: '122px',
              width: '311px',
              backdropFilter: 'blur(6px)',
              boxShadow:
                '0 4px 7px 1px rgba(30, 41, 105, 0.2), 0 -4px 7px 1px rgba(30, 41, 105, 0.2)',
            },
          }),
        }),
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          '& .MuiSlider-root': {
            marginRight: Spacing.xxs,
          },
          '& .slider-input': {
            'width': '90px',
            '& .MuiInputBase-input': {
              textAlign: 'end',
            },
          },
          '& .menu-box': {
            'display': 'inline-flex',
            '& .MuiIconButton-root': {
              padding: 0,
              marginLeft: '7px',
            },
          },
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: () => ({
          '& .MuiPickersYear-yearButton': {
            fontSize: '18px',
          },
        }),
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: () => ({
          '& .MuiPickersMonth-monthButton': {
            fontSize: '18px',
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& .MuiTabs-scroller': {
            'display': 'flex',
            'justifyContent': 'center',
            '& .MuiTab-root:not(:last-child)': {
              marginRight: ownerState.variant === 'standard' ? '0px' : '40px',
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              color: Colors.primaryBase,
              minWidth: '130px',
            },
            '& .Mui-selected': {
              color: Colors.secondaryBase,
              borderColor: Colors.secondaryBase,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: Colors.secondaryBase,
              height: '3px',
            },
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: () => ({
          '& .MuiBreadcrumbs-separator': {
            color: Colors.primaryBase,
          },
        }),
      },
    },
    MuiSkeleton: {
      variants: [
        {
          props: { variant: 'rectangular' },
          style: {
            borderRadius: '5px',
          },
        },
      ],
    },
  },
});

const ThemeWrapper: React.FC<React.PropsWithChildren> = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeWrapper;
