import { Box, DialogContent, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountView from './accounts/AccountView';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from './AccountDetailsLayout';
import { Hooks } from '@3nickels/data-modules';
import { ManualAccountTypes, ManualAccountIcon } from './accounts/ManualAccountTypes';
import { HelpOutline } from '@mui/icons-material';
import { BootstrapDialog, BootstrapHeader } from '../../components/Modal';
import { Spacer } from '../../components';

const AccountDetailsView: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const netWorth = Hooks.useNetWorth();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <WhatTypeOfAccountsDialog modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Box display='inline-flex' width='100%' justifyContent='space-between'>
        <Box width='480px' mr={2}>
          <Typography className='title' variant='h1' component='h1' color='primary'>
            {t('AccountDetails')}
          </Typography>
          <Typography className='subtitle' color='secondary'>
            {t('AccountDetailsDescription')}
          </Typography>
          <Grid className='help-text' item mt={2} alignItems='center' display='flex'>
            <Link variant='p16Bold' color='primary' onClick={() => setModalOpen(true)}>
              {t('WhatTypesOfAccounts')}
            </Link>
            <Box
              mt={'5px'}
              onClick={() => setModalOpen(true)}
              style={{
                cursor: 'pointer',
              }}>
              <HelpOutline
                style={{
                  color: theme.palette.primary.main,
                }}
              />
            </Box>
          </Grid>
        </Box>
        <Box
          display='inline-flex'
          width='390px'
          alignItems='center'
          justifyContent='flex-end'
          pt={3}>
          <Typography display='inline-block' mr={2} color='primary'>
            {t('NetWorth')}
          </Typography>
          <Typography
            display='inline-block'
            fontFamily='Montserrat'
            variant='p40Bold'
            color={(netWorth?.totalNetWorth ?? 0) < 0 ? 'accent' : 'secondary'}>
            {netWorth?.totalNetWorth?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }) ?? '$0'}
          </Typography>
        </Box>
      </Box>
      <AccountView />
    </Box>
  );
};

const meta = {
  showBack: true,
  showNext: false,
  onBack: (navigate) => navigate('/onboarding'),
  headerVariant: 'addAccount',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(AccountDetailsView, meta);

interface AccountsDialogProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WhatTypeOfAccountsDialog: React.FC<AccountsDialogProps> = (props) => {
  const { t } = useTranslation();
  const { modalOpen, setModalOpen } = props;

  const accounts = [
    {
      iconType: 'CashAccount',
      boldText: 'Cash accounts',
      description: 'such as your checking and savings accounts.',
    },
    {
      iconType: 'RetirementPlan',
      boldText: 'Retirement plans',
      description: 'such as your 401(k) or IRA.',
    },
    {
      iconType: 'Investment',
      boldText: 'Investments',
      description: 'such as your brokerage account.',
    },
    { iconType: 'Car', boldText: 'Cars', description: 'even if you’re not paying off a car loan.' },
    {
      iconType: 'Home',
      boldText: 'Homes',
      description: 'even if you’re not paying off a mortgage.',
    },
    {
      iconType: 'Debt',
      boldText: 'Debts',
      description: 'such as your credit cards or student loans.',
    },
  ];

  return (
    <BootstrapDialog open={modalOpen}>
      <Grid container flexDirection='row' style={{ backgroundColor: 'lavender' }}>
        <BootstrapHeader id='customized-dialog-title' onClose={() => setModalOpen(false)} />
        <Spacer width='sm' />
      </Grid>
      <DialogContent>
        <Grid container padding={3}>
          <Grid item>
            <Typography variant='p18Bold' color='secondary'>
              {t('WhatTypesOfAccounts')}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <Typography color='secondary'>
              You can link or manually add any financial account, such as these:
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Stack spacing={'12px'}>
              {accounts.map((a, i) => (
                <AccountTypeItem
                  key={i}
                  iconType={a.iconType as ManualAccountTypes}
                  boldText={a.boldText}
                  description={a.description}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
};

interface AccountTypeItemProps {
  iconType: ManualAccountTypes;
  boldText: string;
  description: string;
}

const AccountTypeItem: React.FC<AccountTypeItemProps> = ({ iconType, boldText, description }) => {
  return (
    <Grid container alignItems='center' spacing={2}>
      {ManualAccountIcon(iconType)}
      <Spacer width='xxs' />
      <Typography display='inline-block' color='secondary'>
        <span style={{ fontWeight: 'bold' }}>{boldText}</span>, {description}
      </Typography>
    </Grid>
  );
};
