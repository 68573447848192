/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { GoalsLayoutMeta } from '../../GoalsLayout';
import { Svgs } from '../../../../assets/svg';
import { Button } from '../../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Spacing } from '../../../../themes';
import InfoCard from '../../../../components/InfoCard';

const GivingAssetsView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container flexWrap='nowrap' justifyContent='space-between' mt='10px'>
        <Grid item width='50%'>
          <Typography color='primary' component='h1' variant='h1'>
            {t('DonatingAssets')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('DonateWhatYouNoLongerUse')}
          </Typography>
        </Grid>
        <InfoCard onClick={() => navigate('/tools')}>
          <Typography color='secondary' variant='p16'>
            {t('RelatedTaxBenefitsOfDonations')}
          </Typography>
        </InfoCard>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplaySmallCustomCar />
          <Typography color='secondary' variant='p18Bold'>
            {t('DonatingACar')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <Typography color='secondary' variant='p16'>
            {t('YouCanDonateYourCar')}
          </Typography>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplaySmallCustomInvestment />
          <Typography color='secondary' variant='p18Bold'>
            {t('AppreciatedAssets')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <Typography color='secondary' variant='p16' display='inline-block'>
            {t('ForAssetsThatHaveIncreasedInValue')}
            <strong>{t('NoTaxOnTheIncreaseValue')}</strong>
          </Typography>
        </Card>
      </Grid>
      <Grid container className='content-card-container' flexDirection='column'>
        <Grid container className='content-card-header'>
          <Svgs.DisplaySmallCustomRetirement />
          <Typography color='secondary' variant='p18Bold'>
            {t('GivingFromYourIra')}
          </Typography>
        </Grid>
        <Card className='content-card'>
          <Grid container justifyContent='space-between'>
            <Box ml='20px'>
              <Typography color='secondary' variant='p16'>
                {t('At701/2')}
              </Typography>
              <br />
              <Typography color='secondary' variant='p16'>
                {t('IfYouAreSubectToRmds')}
              </Typography>
            </Box>
            <Grid item flexGrow='1' display='flex' justifyContent='center'>
              <Svgs.ArtRetirement />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item sm={2} mt={Spacing.xxs}>
        <Button
          fullWidth
          label='Back'
          color='secondary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />
      </Grid>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  title: 'Giving',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(GivingAssetsView, meta);
