import { useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { Button, FormGroup, Typography } from '@mui/material';
import { useService } from '@aesop-fables/containr-react';
import { Column, PasswordRequirements, Row, Spacer, VerticalFormInput } from '../components';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { logo3NSmall } from '../assets/png';
import { useAppToasts } from '../AppToasts';
import { Toast } from '../models/Toast';
import { ProfileData } from '../models/ProfileData';
import {
  AccountSettingsWizard,
  accountSettingsWizardKey,
  ForgotPasswordData,
} from '../services/accountSettings';
import { useDeviceType } from '../hooks/useDeviceType';
import { mobileMargin } from '../App';

export interface PasswordConfirmData {
  password?: string;
  rePassword?: string;
}

const ChangePassword = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const { setToast } = useAppToasts();
  const accountSettingsWizard = useService<AccountSettingsWizard>(accountSettingsWizardKey);
  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasANumber, setHasANumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&:()._-])([A-Za-z\d@$!%*#?&:()._-]){8,32}$/;

  const onSubmit = async (values: ForgotPasswordData) => {
    try {
      const data: ForgotPasswordData = { password: values.password };
      await accountSettingsWizard.resetPassword(data);
      setToast(
        new Toast({
          message: 'Password reset!',
          open: true,
          severity: 'success',
        })
      );
      navigate('/');
    } catch (err) {
      setToast(
        new Toast({
          message: 'We weren’t banking on that happening...please try again later.',
          open: true,
          severity: 'error',
        })
      );
    }
  };

  const onCancel = () => {
    navigate('/');
  };
  const onValidatePasswords = (values: PasswordConfirmData) => {
    const errors: PasswordConfirmData = {};
    setHasMinChar(false);
    setHasANumber(false);
    setHasUpperCase(false);
    setHasSpecialChar(false);

    if (values.password) {
      if (values.password.length > 7 && values.password.length < 33) {
        setHasMinChar(true);
      }

      const numResult = values.password.match(/[0-9]/g);
      if (numResult !== null) Array.from(numResult);
      if (Array.isArray(numResult) && numResult.length > 0) {
        setHasANumber(true);
      }

      const upperCaseResult = values.password.match(/[A-Z]/g);
      if (upperCaseResult !== null) Array.from(upperCaseResult);
      if (Array.isArray(upperCaseResult) && upperCaseResult.length > 0) {
        setHasUpperCase(true);
      }

      const specialCharResult = values.password.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g);
      if (specialCharResult !== null) Array.from(specialCharResult);
      if (Array.isArray(specialCharResult) && specialCharResult.length > 0) {
        setHasSpecialChar(true);
      }
    }

    if (values.password && !passwordRegex.test(values.password)) {
      errors.password = 'Please follow the password requirements below';
    } else if (values.password && passwordRegex.test(values.password)) {
      errors.password = undefined;
    }
    if (values.password !== values.rePassword) {
      errors.rePassword = 'Password does not match';
    } else if (values.password === values.rePassword) {
      errors.rePassword = undefined;
    }
    return errors;
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit} validate={(values) => onValidatePasswords(values)}>
        {({ handleSubmit }) => (
          <>
            <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
              <img src={logo3NSmall} alt='logo' style={{ height: '55px', alignSelf: 'flex-end' }} />
              <Spacer height='xs' />
              <Column style={{ width: isMobile || isTablet ? '100%' : '60%' }}>
                <Typography variant={isMobile || isTablet ? 'p22Bold' : 'p50Bold'} color='primary'>
                  Change Password
                </Typography>
                <Spacer height={isMobile || isTablet ? 'xxs' : 'xs'} />
                <Typography variant={isMobile || isTablet ? 'p16' : 'p20'} color='secondary'>
                  Please follow the password requirements below.
                </Typography>
              </Column>
              <Spacer height='sm' />

              <FormGroup
                style={{ alignSelf: 'center', width: isMobile || isTablet ? '90%' : '35%' }}>
                <VerticalFormInput<ProfileData>
                  autoFocus
                  fieldName='password'
                  label='Enter New Password'
                  placeholder='Enter New Password'
                  inputStyle={{ width: '100%' }}
                  required
                />
                <Spacer height='sm' />
                <PasswordRequirements
                  hasMinChar={hasMinChar}
                  hasSpecialChar={hasSpecialChar}
                  hasANumber={hasANumber}
                  hasUpperCase={hasUpperCase}
                />
                <Spacer height='sm' />
                <VerticalFormInput<ProfileData>
                  fieldName='rePassword'
                  label='Re-enter New Password'
                  placeholder='Re-enter Password'
                  inputStyle={{ width: '100%' }}
                  required
                />
              </FormGroup>
              <Spacer height='lg' />

              {isMobile || isTablet ? (
                <Column style={{ justifyContent: 'space-between' }}>
                  <Button onClick={handleSubmit} color='secondary' type='submit'>
                    Submit
                  </Button>
                  <Spacer height='xs' />
                  <Button onClick={onCancel} variant='outlined' color='secondary'>
                    Cancel
                  </Button>
                </Column>
              ) : (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Button onClick={onCancel} className='xs' variant='outlined' color='secondary'>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    className='md'
                    color='secondary'
                    type='submit'
                    style={{ alignSelf: 'flex-end' }}>
                    Submit
                  </Button>
                </Row>
              )}
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default ChangePassword;
