import { Authentication, Data } from '@3nickels/data-modules';
import { inject } from '@aesop-fables/containr';
import { DataCache, fromAppStorage, PredicateListener } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { AuthTokenApi } from '../../api/apis/AuthTokenApi';

export class TwoFactorDataListener extends PredicateListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    context: Authentication.IAuthenticationContext,
    @inject(ApiKeys.AuthToken) private readonly api: AuthTokenApi,
    @fromAppStorage(Data.TwoFactor.twoFactorStorageKey)
    private readonly cache: DataCache<Data.TwoFactor.TwoFactorCompartments>
  ) {
    super(context.isAuthenticated$);
  }

  async onNext(): Promise<void> {
    const keys: (keyof Data.TwoFactor.TwoFactorCompartments)[] = ['twoFactorMethods'];
    await this.api.generateCookie();
    Promise.all([keys.map((key) => this.cache.reload(key))]);
  }
}
