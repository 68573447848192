import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { diffState } from '@aesop-fables/scrinium';
import { useAutoSaveStatus } from './useAutoSaveStatus';

export interface AutoSaveOptions<T> {
  mode?: 'full' | 'partial';
  defaultValues?: T;
  onSave: (data: T) => Promise<void>;
  transformValues?: (data: T) => T;
  schema?: Yup.Schema<T>;
}

export type AutoSaveStatus = 'saving' | 'completed';

export interface AutoSaveResponse {
  onChange: () => Promise<void>;
}

export function useAutoSaveHandler<T extends object>(
  options: AutoSaveOptions<T>
): AutoSaveResponse {
  const { defaultValues, mode = 'full', onSave, schema, transformValues } = options;
  const { setSaving, setComplete } = useAutoSaveStatus();
  const { clearErrors, getValues, getFieldState, setError, reset } = useFormContext();
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const validateAndTransform = async (data: T) => {
    if (schema) {
      data = await schema.validate(data);

      if (transformValues) {
        data = transformValues(data);
      }
    }

    return data;
  };

  const generatePatchModel = (data: T) => {
    const changes = diffState<T>(defaultValues ?? ({} as T), data);
    const patchModel: T = {} as T;
    changes.forEach((change) => {
      const fieldState = getFieldState(change.property);
      if (fieldState.isDirty) {
        patchModel[change.property as keyof T] = change.value;
      }
    });

    return patchModel;
  };

  const onChange = useCallback(async () => {
    clearTimeout(timer);

    let data = getValues() as T;

    let patch: T;
    const patchKeys: string[] = [];
    try {
      data = await validateAndTransform(data);

      patch = generatePatchModel(data);
      patchKeys.push(...Object.keys(patch));
      setSaving(patchKeys);

      if (mode === 'partial') {
        data = generatePatchModel(data);
      }
    } catch (e) {
      const validationError = e as Yup.ValidationError;
      if (validationError?.path) {
        setError(validationError.path, { message: validationError.message });
      }

      return;
    }
    setTimer(
      setTimeout(async () => {
        console.log(JSON.stringify(patch));

        clearErrors();
        await onSave(data);
        setComplete(patchKeys);
        reset(data);
      }, 1000)
    );
  }, [mode, defaultValues, timer]);

  return {
    onChange,
  };
}
