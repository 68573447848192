import React, { useState } from 'react';
import LinkAccountHeader from '../../headers/LinkAccountHeader';
import { Grid, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { getWizardFooterProps } from '../../../EditViewUtils';
import { Spacer } from '../../../../components';
import { FlatSelector, FlatSelectorItem } from '../../../../components/Flat';
import { PlaidAccountType } from '../Types';
import { useLoading } from '../../../../hooks/useLoading';
import { Hooks } from '@3nickels/data-modules';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AccountTypeViewProps {}

const selectorItems: FlatSelectorItem<PlaidAccountType>[] = [
  { title: 'Cash Account', description: 'Checking, Savings, etc.', value: PlaidAccountType.CashAccount },
  { title: 'Investment', description: '401(k), IRA, Brokerage, 529, HSA, etc.', value: PlaidAccountType.Investment },
  { title: 'Debt', description: 'Credit Card, Mortgage, Student Loan, etc.', value: PlaidAccountType.Debt },
];

// A guard here wouldn't really be deterministic off the "owner" property alone
const AccountTypeView: React.FC<AccountTypeViewProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedAccountTypes, setSelectedAccountTypes] = useState<PlaidAccountType[]>([]);
  const { setLoading } = useLoading();
  const wizard = Hooks.usePlaidWizard();

  const continueHandler = async () => {
    setLoading(true);
    try {
      wizard.selectAccountTypes(selectedAccountTypes);
      wizard.generateLinkToken(); // this can happen async because the choose institution screen has a guard condition
      navigate('/account-details/link-account/choose-institution');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Grid container flexDirection='column'>
      <Typography className='title' variant='h1' component='h1' color='primary'>
        {t('ChooseAccountType')}
      </Typography>
      <Spacer height='xxxs' />
      <Typography color='primary' variant='p12'>
        {t('ChooseAccountTypeNote')}
      </Typography>
      <Typography color='primary' variant='p16Bold' mt={1}>
        {t('ChooseAccountTypeAccountsLinking')}
      </Typography>
      <Spacer height='xxs' />
      <Grid container justifyContent='center'>
        <Grid item justifyContent='center' sm={6}>
          <FlatSelector<PlaidAccountType>
            items={selectorItems}
            onSelectedChanged={setSelectedAccountTypes}
          />
        </Grid>
      </Grid>
      <WizardFooter
        disableNext={selectedAccountTypes.length === 0}
        {...getWizardFooterProps(meta.nextLocaleKey)}
        onDone={() => continueHandler()}
      />
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'Continue',
  // nextUrl: 'link-account/institutions', // TODO: Set when next page is done
  // disableNextButton: false,
  header: <LinkAccountHeader />,
} satisfies LayoutMeta;

export default withLayoutMeta(AccountTypeView, meta);
