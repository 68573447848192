import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Spacer } from '../../../components';
import { Api } from '@3nickels/data-modules';
import { useTranslation } from 'react-i18next';

export interface RetirementAgeGoalCardProps {
  includeSpouse: boolean;
  retirementGoal: Api.RetirementGoalRest;
  originalRetirementGoal: Api.RetirementGoalRest;
}

export const RetirementAgeGoalCard: React.FC<RetirementAgeGoalCardProps> = ({
  includeSpouse,
  retirementGoal,
  originalRetirementGoal,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Spacer height='xs' />
      {includeSpouse ? (
        <Grid container>
          <Grid xs={5.75} container direction='column' alignItems='center' justifyContent='center'>
            <Card>
              <Typography variant='p16' color='secondary'>
                {t('YourRetirementAge')}
              </Typography>
              {originalRetirementGoal.primary?.retirementAge !==
              retirementGoal.primary?.retirementAge ? (
                <Grid container xs={8} alignItems='center'>
                  <Grid
                    xs={6}
                    container
                    direction='column'
                    alignItems='center'
                    justifyContent='center'>
                    <Typography mt='3px' fontFamily='Montserrat' variant='p30' color='tertiaryDark'>
                      {originalRetirementGoal.primary?.retirementAge ?? 0}
                    </Typography>
                    <Typography variant='p12' color='tertiaryDark'>
                      current goal
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    container
                    direction='column'
                    alignItems='center'
                    justifyContent='center'>
                    <Typography
                      mt='3px'
                      fontFamily='Montserrat'
                      variant='p30Bold'
                      color='secondary'>
                      {retirementGoal.primary?.retirementAge ?? 0}
                    </Typography>
                    <Typography variant='p12' color='secondary'>
                      recommended
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container xs={8} alignItems='center'>
                  <Grid container direction='column' alignItems='center' justifyContent='center'>
                    <Typography
                      mt='3px'
                      fontFamily='Montserrat'
                      variant='p30Bold'
                      color='secondary'>
                      {retirementGoal.primary?.retirementAge ?? 0}
                    </Typography>
                    <Typography variant='p12' color='secondary'>
                      current goal
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
          <Grid item xs={0.5} />
          <Grid xs={5.75} container direction='column' alignItems='center' justifyContent='center'>
            <Card>
              <Typography variant='p16' color='secondary'>
                {t('SpousesRetirementAge')}
              </Typography>
              <Grid container xs={8} alignItems='center'>
                <Grid container direction='column' alignItems='center' justifyContent='center'>
                  <Typography mt='3px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
                    {originalRetirementGoal.spouse?.retirementAge ?? 0}
                  </Typography>
                  <Typography variant='p12' color='secondary'>
                    current goal
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Card>
          <Typography variant='p16' color='secondary'>
            {t('YoureShootingToRetireAtThisAge')}
          </Typography>
          {originalRetirementGoal.primary?.retirementAge !==
          retirementGoal.primary?.retirementAge ? (
            <Grid container xs={8} alignItems='center'>
              <Grid xs={6} container direction='column' alignItems='center' justifyContent='center'>
                <Typography mt='10px' fontFamily='Montserrat' variant='p30' color='tertiaryDark'>
                  {originalRetirementGoal.primary?.retirementAge ?? 0}
                </Typography>
                <Typography variant='p12' color='tertiaryDark'>
                  current goal
                </Typography>
              </Grid>
              <Grid xs={6} container direction='column' alignItems='center' justifyContent='center'>
                <Typography mt='10px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
                  {retirementGoal.primary?.retirementAge ?? 0}
                </Typography>
                <Typography variant='p12' color='secondary'>
                  recommended
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography mt='10px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
              {retirementGoal.primary?.retirementAge ?? 0}
            </Typography>
          )}
        </Card>
      )}
    </>
  );
};
