import { Typography } from '@mui/material';
import { Colors } from '../themes';
import { Domain } from '@3nickels/data-modules';

export declare type FormTitleProps = {
  title: string;
  emphasizedText?: string;
};

export const FormTitle: React.FC<FormTitleProps> = ({ emphasizedText, title }) => {
  return (
    <Typography component='h1' variant='h1' color={Colors.primaryBase} display='inline-block'>
      {title}&nbsp;
      <span style={{ fontWeight: '100' }}>{emphasizedText}</span>
    </Typography>
  );
};

export const GoalFormTitle: React.FC<{ editing?: boolean; type: Domain.GoalTypeEnum }> = ({
  editing,
  type,
}) => {
  return (
    <FormTitle
      title={editing ? `Edit Goal:` : `Add a Goal:`}
      emphasizedText={Domain.GoalTypeLabels[type]}
    />
  );
};
