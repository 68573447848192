interface Props extends React.PropsWithChildren {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style?: any;
}

const Column: React.FC<Props> = props => {
 return (
    <div {...props} style={{ display: 'flex', flexDirection: 'column', ...props.style}}>{props.children}</div>
 );
}

export default Column;