/* eslint-disable @typescript-eslint/no-empty-function */
import { LayoutHeader } from '../Types';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useIsFreeUser, useNeedsOnboarding } from '../../hooks';
import { NickelsMenuItem } from '../../components/toc/SideNavigation';
import { Hooks } from '@3nickels/data-modules';

export interface IContentViewContext {
  sideNav: (titleSelected?: string) => NickelsMenuItem[];
  header: LayoutHeader;
  setSelectedTab: (selected?: string) => void;
}

const ContentViewContext = createContext<IContentViewContext>({
  sideNav: () => [],
  header: {},
  setSelectedTab: () => {},
});

export const ContentViewProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const needsOnboarding = useNeedsOnboarding();
  // const user = Hooks.usePrincipalUser();
  const isFreeUser = useIsFreeUser();
  // const isFreeUser = false;
  const universityEnabled = Hooks.useUniversityEnabled();

  const [sideNav, setSideNav] = useState<(titleSelected?: string) => NickelsMenuItem[]>(() => []);
  const [header, setHeader] = useState<LayoutHeader>({});

  useEffect(() => {
    setSideNav(() => (titleSelected?: string) => {
      const data = [
        {
          title: 'Home',
          icon: 'HomeSvg',
          selected: false,
          href: '/',
        },
        {
          isDivider: true,
        },
        {
          title: 'Accounts',
          icon: 'AccountsSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/account-details',
        },
        {
          title: 'Budget',
          icon: 'BudgetSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/budget-details',
        },
        {
          title: 'Goals',
          icon: 'GoalsSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/goals',
        },
        {
          title: 'Tools',
          icon: 'ToolsSvg',
          selected: false,
          exclude: isFreeUser || needsOnboarding,
          href: '/tools',
        },
        {
          isDivider: true,
          exclude: isFreeUser || needsOnboarding,
        },
        {
          title: '3NickelsU',
          icon: 'UniversitySvg',
          selected: false,
          exclude: !universityEnabled || (universityEnabled && isFreeUser),
          href: '/university',
        },
        {
          title: 'Settings',
          icon: 'SettingsSvg',
          selected: false,
          href: '/settings',
        },
        {
          title: 'ContactUs',
          icon: 'EarPhoneSvg',
          selected: false,
          href: '/contact',
        },
        {
          title: 'AboutUs',
          icon: 'MenuExclamationSvg',
          selected: false,
          href: '/about',
        },
        {
          isDivider: true,
        },
        {
          title: 'SignOut',
          icon: 'SignOutSvg',
          selected: false,
          href: '/logout',
        },
      ];

      return data.map((p) =>
        Object.assign(p, {
          selected: p.title === titleSelected,
        })
      );
    });
  }, [needsOnboarding, isFreeUser, universityEnabled]);

  useEffect(() => {
    setHeader((previous) => ({ ...previous, hideProfile: needsOnboarding }));
  }, [needsOnboarding]);

  const setSelectedTab = (selected?: string) => {
    setHeader((previous) => ({ ...previous, selectedTab: selected }));
  };

  return (
    <ContentViewContext.Provider value={{ sideNav: sideNav, header, setSelectedTab }}>
      {children}
    </ContentViewContext.Provider>
  );
};

export function useContentView() {
  const context = useContext(ContentViewContext);
  if (!context) {
    throw new Error('useContentView must be used within ContentViewProvider');
  }

  return context;
}
