import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ContentView from '../layout/Content';
import { Grid } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { useContentView } from '../layout/ContentViewContext';
import { Hooks } from '@3nickels/data-modules';
import Breadcrumbs from '../../components/Breadcrumbs';
import CourseProgressSideView from './components/CourseProgressSideView';

export type UniversityLayout = Record<string, unknown>;

export declare type UniversityLayoutMeta = object;

const UniversityLayout: React.FC<UniversityLayout> = () => {
  const { meta, setMeta } = useLayoutMeta<UniversityLayout>();
  const navigate = useNavigate();
  const location = useLocation();
  const { sideNav } = useContentView();
  const units = Hooks.useUniversityUnits();
  const pathnameSegments = location.pathname.split('/');
  const breadcrumbNameMap: { [key: string]: string } = useMemo(
    () =>
      units.reduce(
        (breadcrumbs, unit) => ({
          ...breadcrumbs,
          ...unit.lessons.reduce(
            (unitBreadcrumbs, lesson) => ({
              ...unitBreadcrumbs,
              [`/university/${encodeURIComponent(lesson.title)}`]: lesson.title,
            }),
            {}
          ),
        }),
        { ['/university']: '3NickelsU' }
      ),
    [units]
  );

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  return (
    <ContentView
      sideNavMenuItems={sideNav('3NickelsU')}
      progressIndicator={<CourseProgressSideView />}
      progressIndicatorPosition='end'
      leftChildren={
        <Breadcrumbs pathnameSegments={pathnameSegments} breadcrumbNameMap={breadcrumbNameMap} />
      }>
      <Outlet context={{ setMeta }} />
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default UniversityLayout;
