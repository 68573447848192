import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormGroup, Typography } from '@mui/material';
import { useService } from '@aesop-fables/containr-react';
import { Column, VerticalFormInput, Row, Spacer, DatePicker } from '../components';
import { logo3NSmall } from '../assets/png';
import { useAppToasts } from '../AppToasts';
import {
  AccountSettingsWizard,
  accountSettingsWizardKey,
  ForgotPasswordData,
} from '../services/accountSettings';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { useObservable } from '../hooks/useObservable';
import { useDeviceType } from '../hooks/useDeviceType';
import { Toast } from '../models/Toast';
import { mobileMargin } from '../App';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const { setToast } = useAppToasts();
  const accountSettingsWizard = useService<AccountSettingsWizard>(accountSettingsWizardKey);
  const forgotPasswordData = useObservable(accountSettingsWizard.forgotPasswordData$);

  const onSubmit = async (values: ForgotPasswordData) => {
    try {
      await accountSettingsWizard.initiateResetPassword(values);
      accountSettingsWizard.setForgotPasswordData(values);
      navigate('/enter-code');
    } catch (err) {
      setToast(
        new Toast({
          message: 'We weren’t banking on that happening...please try again later.',
          open: true,
          severity: 'error',
        })
      );
    }
  };

  const navBack = () => {
    navigate(-1);
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit} initialValues={forgotPasswordData}>
        {({ handleSubmit }) => (
          <>
            <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
              <img src={logo3NSmall} alt='logo' style={{ height: '55px', alignSelf: 'flex-end' }} />
              <Spacer height='xs' />
              <Column style={{ width: isMobile || isTablet ? '100%' : '60%' }}>
                <Typography variant={isMobile || isTablet ? 'p22Bold' : 'p50Bold'} color='primary'>
                  Forgot Password?
                </Typography>
                <Spacer height={isMobile || isTablet ? 'xxs' : 'xs'} />
                <Typography variant={isMobile || isTablet ? 'p16' : 'p20'} color='secondary'>
                  Don’t worry, it happens to all of us.
                </Typography>
                <Spacer height={isMobile || isTablet ? 'xxs' : 'xs'} />
                <Typography variant={isMobile || isTablet ? 'p16' : 'p20'} color='secondary'>
                  Just enter your date of birth and the email address associated with this account,
                  and we’ll send instructions for resetting your password.
                </Typography>
              </Column>
              <Spacer height='sm' />

              <FormGroup
                style={{ alignSelf: 'center', width: isMobile || isTablet ? '90%' : '35%' }}>
                <DatePicker<ForgotPasswordData>
                  fieldName='birthDate'
                  label='Date of Birth'
                  autoFocus
                  required
                />
                <Spacer height='sm' />
                <VerticalFormInput<ForgotPasswordData>
                  fieldName='username'
                  label='Email'
                  required
                />
              </FormGroup>
              <Spacer height='lg' />

              {isMobile || isTablet ? (
                <Column style={{ justifyContent: 'space-between' }}>
                  <Button onClick={handleSubmit} color='secondary' type='submit'>
                    Send Code
                  </Button>
                  <Spacer height='xs' />
                  <Button onClick={navBack} variant='outlined' color='secondary'>
                    Cancel
                  </Button>
                </Column>
              ) : (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Button onClick={navBack} className='xs' variant='outlined' color='secondary'>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    className='md'
                    color='secondary'
                    type='submit'
                    style={{ alignSelf: 'flex-end' }}>
                    Send Code
                  </Button>
                </Row>
              )}
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default ForgotPassword;
