import { Data, Hooks } from '@3nickels/data-modules';
import { useEffect, useState } from 'react';
import { useLoading } from './useLoading';
import { usePlaidLink } from 'react-plaid-link';

export function usePlaidCredentialRefresh(accessId: number) {
  const [token, setToken] = useState<string | null>(null);
  const commands = Hooks.useCommands();
  const wizard = Hooks.usePlaidWizard();
  const { setLoading } = useLoading();

  const { open } = usePlaidLink({
    token,
    async onSuccess() {
      setLoading(true);
      try {
        await commands.execute(Data.Plaid.Commands.RefreshPlaidInstitution, {
          accessId,
        });
      } finally {
        setLoading(false);
      }

      setToken(null);
    },
    onExit() {
      setToken(null);
    }
  });

  const updateLoginHandler = async () => {
    const newToken = await wizard.getExpiredCredentialsToken(accessId);
    setToken(newToken);
  };

  useEffect(() => {
    if (token) {
      open();
    }
  }, [token, open])

  return updateLoginHandler;
}