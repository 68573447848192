import { Card, Collapse, Grid, Typography } from '@mui/material';
import Spacer from '../../../../components/Spacer';
import TextInput from '../../../../components/form/TextInput';
import { Data, Hooks } from '@3nickels/data-modules';
import { useForm, useFormContext } from 'react-hook-form';
import FormContent from '../../../../components/form/FormContent';
import { useLoading } from '../../../../hooks/useLoading';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useEffect } from 'react';
import { WizardStep } from '@aesop-fables/scrinium/lib/Wizards';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMessage } from '../../../../hooks/useMessage';
import { useTranslation } from 'react-i18next';
import { BootstrapHeader } from '../../../../components/Modal';
import { Button } from '../../../../components/buttons/Button';

interface SpouseIncomeDrawerWrapperProps {
  dropdownOpen: boolean;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SpouseIncomeDropdownWrapper: React.FC<SpouseIncomeDrawerWrapperProps> = ({
  dropdownOpen,
  setDropdownOpen,
}) => {
  const { currentStep, wizard } =
    Hooks.usePersonalDetailsWizard<Data.People.BasicFinancialFormData>();
  useEffect(() => {
    wizard.selectStep('financialInfo');
  }, []);

  return (
    <SpouseIncomeDropDown
      currentStep={currentStep}
      wizard={wizard}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
    />
  );
};

interface SpouseIncomeDrawerProps extends SpouseIncomeDrawerWrapperProps {
  currentStep: WizardStep<Data.People.BasicFinancialFormData, Data.People.NoOpParams>;
  wizard: Data.People.IPersonWizard;
}

const SpouseIncomeDropDown: React.FC<SpouseIncomeDrawerProps> = ({
  currentStep,
  wizard,
  dropdownOpen,
  setDropdownOpen,
}) => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const spouseData = Hooks.useSpouseData();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const personData = Hooks.usePersonData();
  const methods = useForm<Data.People.BasicFinancialFormData>({
    defaultValues: {
      ...currentStep?.model,
    },
    resolver: yupResolver(
      Yup.object({
        spouseAnnualIncome: Yup.string()
          .required('Spouse Annual Income is required')
          .test('spouseAnnualIncome', 'Spouse Annual Income must be greater than $0', (income) => {
            const cleanNumber = cleanWholeNumberStr(income);
            return cleanNumber > 0;
          }),
      })
    ),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    setLoading(true);
    const spouseAnnualIncome = cleanWholeNumberStr(values.spouseAnnualIncome);
    currentStep.save({
      ...personData,
      spouseAnnualIncome,
      spouseAnnualOtherIncome: spouseData?.otherAnnualIncome,
      spouseCreditScore: spouseData?.creditScore,
      spouseEligibleForHsa: spouseData?.eligibleForHsa,
      spouseSelfEmployed: spouseData?.selfEmployed,
    });
    await wizard.commitStep('financialInfo');
    setLoading(false);
    setDropdownOpen(false);
    showMessage(`Spouse's income saved!`);
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card className='dropdown-card'>
          <Collapse in={dropdownOpen}>
            <div style={{ float: 'right' }}>
              <BootstrapHeader id='header' onClose={() => setDropdownOpen(false)} />
            </div>

            <Grid item p={3}>
              <Typography variant='p16Bold' color='secondary'>
                {t('AddSpousesIncome')}
              </Typography>
              <Spacer height='xxs' />
              <Typography variant='p16' color='secondary'>
                {t('ToMakeContributions')}
              </Typography>
              <Spacer height='xs' />
              <Grid item width='100%'>
                <TextInput<Data.People.BasicFinancialFormData>
                  error={formState.errors.spouseAnnualIncome !== undefined}
                  helperText={formState.errors.spouseAnnualIncome?.message?.toString()}
                  defaultValue={spouseData?.annualIncome}
                  inputProps={{ maxLength: 11 }}
                  name='spouseAnnualIncome'
                  label='SpouseAnnualIncome'
                  type='dollar'
                  placeholder='$0'
                />
              </Grid>

              <Spacer height='md' />
              <Grid container justifyContent='center'>
                <Button onClick={methods.handleSubmit(onSubmit)} style={{ width: '150px' }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </form>
    </FormContent>
  );
};

export default SpouseIncomeDropDown;
