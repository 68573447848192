import { Authentication, Data, Domain } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { AppStorage, ISubjectResolver, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';
import { firstValueFrom } from 'rxjs';

export async function goalsLoader(container: IServiceContainer) {
  const authContext = container.get<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const appStorage = container.get<AppStorage>(ScriniumServices.AppStorage);
  const goalsDataCache = appStorage.retrieve<Data.Goals.GoalCompartments>(
    Data.Goals.goalStorageKey
  );
  const goalsListener = new Data.Goals.GoalDataListener(authContext, goalsDataCache);
  goalsListener.start();
  return json('goals listener started');
}

export async function charityCategoriesLoader(container: IServiceContainer) {
  const authContext = container.get<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const appStorage = container.get<AppStorage>(ScriniumServices.AppStorage);
  const charityDataCache = appStorage.retrieve<Data.Charity.CharityCompartments>(
    Data.Charity.charityStorageKey
  );
  const charityCategoriesListener = new Data.Charity.CharityCategoryListener(
    authContext,
    charityDataCache
  );
  charityCategoriesListener.start();
  return json('charity category listener started');
}

export async function cashGivingGoalLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const cashGoal$ = resolver.resolveSubject(Data.Goals.CashGoalSubject);
  const cashGoal = await firstValueFrom(cashGoal$);

  return json(cashGoal?.id ?? null);
}

export async function payoffDebtGoalLoader(container: IServiceContainer) {
  const resolver = container.get<ISubjectResolver>(ScriniumServices.SubjectResolver);
  const goal$ = resolver.resolveSubject(Data.Goals.OrderedGoalsSubject);
  const goal = await firstValueFrom(goal$);
  const payoffDebtGoal = goal.find((g) => g.type === Domain.GoalTypeEnum.Non_Mortgage_Debt);

  return json(payoffDebtGoal?.id ?? null);
}
