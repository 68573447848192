import { Authentication, Data } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { AppStorage, ScriniumServices } from '@aesop-fables/scrinium';
import { json } from 'react-router-dom';

export async function budgetLoader(container: IServiceContainer) {
  const authContext = container.get<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const appStorage = container.get<AppStorage>(ScriniumServices.AppStorage);
  const budgetDataCache = appStorage.retrieve<Data.Budget.BudgetCompartments>(
    Data.Budget.budgetStorageKey
  );
  const plaidListener = new Data.Budget.BudgetDataListener(authContext, budgetDataCache);
  plaidListener.start();
  return json('budget listener started');
}
