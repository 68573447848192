import { Grid, Typography } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import GroupPanels from '../../../components/GroupPanels';
import { universityAdvisor } from '../../../assets/png';

const CoachingSessionPanel: React.FC = () => {
  return (
    <GroupPanels
      variant='Popover'
      groups={[
        {
          textKey: '3NickelsUCoachingSession',

          content: (
            <Grid container display='flex' alignItems='center' justifyContent='space-between'>
              <img src={universityAdvisor} width='75px' />
              <Typography variant='p16Bold' color='secondary' maxWidth='50%'>
                Book a one-on-one coaching session
              </Typography>
              <Svgs.IconArrowRight />
            </Grid>
          ),
          onClick: () => {
            window.open(
              'https://outlook.office365.com/owa/calendar/beta@guidedchoice.com/bookings/s/Z1nJ_7aGRkCVW6T7GOhN0A2'
            );
          },
        },
      ]}
    />
  );
};

export default CoachingSessionPanel;
