import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export function EarPhoneSvg(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h25v25H0z" />
        <path
          d="M12.72 2.5C7.078 2.506 2.506 7.078 2.5 12.72v8.03c0 1.21.98 2.19 2.19 2.19h2.92a.73.73 0 0 0 .73-.73v-7.3a.73.73 0 0 0-.73-.73H4.69c-.25.003-.496.048-.73.135V12.72a8.76 8.76 0 1 1 17.52 0v1.595a2.165 2.165 0 0 0-.73-.135h-2.92a.73.73 0 0 0-.73.73v7.3c0 .403.327.73.73.73h2.92c1.21 0 2.19-.98 2.19-2.19v-8.03C22.934 7.078 18.362 2.506 12.72 2.5z"
          fill={props.fill}
          fillRule="nonzero"
        />
      </g>
    </SvgIcon>
  );
}

EarPhoneSvg.defaultProps = {
  fill: '#fff',
};
