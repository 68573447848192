// Add more types here if we need more spacer type options
interface SpacerVariantOptions {
  variant:
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'xxxl'
    | 's12'
    | 's15'
    | 's25';
}

const SpacerVariants = {
  xxxs: '5px',
  xxs: '10px',
  xs: '20px',
  sm: '30px',
  md: '40px',
  lg: '50px',
  xl: '60px',
  xxl: '70px',
  xxxl: '100px',
  s12: '12px',
  s15: '15px',
  s25: '25px',
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  width?: SpacerVariantOptions['variant'];
  height?: SpacerVariantOptions['variant'];
}

const Spacer: React.FC<Props> = props => {
  const { width, height, ...rest } = props;

  const resolveVariant = (variant?: SpacerVariantOptions['variant']) => {
    if (!variant) {
      return undefined;
    }
    return SpacerVariants[`${variant}`];
  };

  return (
    <div
      {...rest}
      style={{
        height: resolveVariant(height),
        width: resolveVariant(width),
        display: 'flex',
        flexDirection: 'row',
        ...props.style,
      }}
    />
  );
};
export default Spacer;
