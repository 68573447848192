import React from 'react';
import { IconButton } from '@mui/material';
import { Hooks } from '@3nickels/data-modules';
import TextInput from '../../components/form/TextInput';
import { useFormContext } from 'react-hook-form';
import { Close, Search } from '@mui/icons-material';
import { useAutoSaveHandler } from '../../hooks/useAutoSaveHandler';

const SpendingSummaryTransactionsSearch: React.FC = () => {
  const { transactionsSearchString, setTransactionsSearchString } = Hooks.useTransactions();
  const methods = useFormContext();

  const { onChange } = useAutoSaveHandler({
    mode: 'full',
    onSave: async (data: { searchString: string }) => {
      setTransactionsSearchString(data.searchString);
    },
  });

  return (
    <form onChange={onChange}>
      <TextInput
        className='ghost-input'
        name='searchString'
        placeholder='Search for a transaction'
        defaultValue={transactionsSearchString}
        InputProps={{
          startAdornment: <Search color='primary' style={{ marginRight: '14px' }} />,
          endAdornment: (
            <IconButton
              style={{
                padding: '0px',
                marginLeft: '14px',
                visibility: methods.formState.isDirty ? undefined : 'hidden',
              }}
              onClick={() => {
                methods.resetField('searchString');
                setTransactionsSearchString(undefined);
              }}>
              <Close color='primary' />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};

export default SpendingSummaryTransactionsSearch;
