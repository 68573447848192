import { Backdrop } from "@mui/material";
import { Loading } from "../hooks/useLoading";

export interface FormLoaderProps {
  loading: boolean;
  children: React.ReactNode;
}

export const FormLoader: React.FC<FormLoaderProps> = ({ loading, children }) => {
  return (
    <>
    <Backdrop open={loading}>
        <Loading />
      </Backdrop>
      {!loading && (
        <>{children}</>
      )}
    </>
  )
};
