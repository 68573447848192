import { Box, MenuItem, Typography, Stack } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import TextInput from '../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import SelectInput from '../../../components/form/SelectInput';
import { Data, Domain } from '@3nickels/data-modules';
import { Margins, Spacing } from '../../../themes';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import { useFormContext } from 'react-hook-form';
import BulletedList from '../../../components/BulletedList';
import { Spacer } from '../../../components';

export type FinancialInfoProps = {
  financialInfo?: Data.People.BasicFinancialFormData;
  includeSpouse?: boolean;
};

const FinancialInfoForm: React.FC<FinancialInfoProps> = (props) => {
  const { formState } = useFormContext();

  return (
    <>
      <Typography variant='h2' fontSize='18px' fontWeight='bold' color='secondary'>
        {t('IncomeTaxInfo')}
      </Typography>
      <Spacer height='xs' />
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.People.BasicFinancialFormData>
          error={formState.errors.annualIncome !== undefined}
          helperText={formState.errors.annualIncome?.message?.toString()}
          defaultValue={props.financialInfo?.annualIncome}
          inputProps={{ maxLength: 11 }}
          name='annualIncome'
          label='GrossAnnualIncome'
          type='dollar'
          placeholder='$0'
          autoFocus
          helpContext={AnnualIncomeHelp()}
        />
        <TextInput<Data.People.BasicFinancialFormData>
          defaultValue={props.financialInfo?.otherAnnualIncome}
          inputProps={{ maxLength: 11 }}
          name='otherAnnualIncome'
          label='AnnualOtherIncome'
          type='dollar'
          placeholder='$0'
          helpContext={AnnualOtherIncomeHelp()}
        />

        <SelectInput<Data.People.BasicFinancialFormData>
          error={formState.errors.taxFilingStatus !== undefined}
          helperText={formState.errors.taxFilingStatus?.message?.toString()}
          defaultValue={props.financialInfo?.taxFilingStatus}
          name='taxFilingStatus'
          label='TaxFilingStatus'
          type='number'
          helpContext={
            <HelpPopover title='Tax Filing Status'>
              <BulletedList
                children={[
                  'Used for simulated projections',
                  'As earnings go in, taxes come out (where and when applicable).',
                ]}
              />
            </HelpPopover>
          }>
          {Domain.TaxFilingStatusOptions.map((x) => (
            <MenuItem value={x.key} key={x.key}>
              {x.value}
            </MenuItem>
          ))}
        </SelectInput>
        <SelectInput<Data.People.BasicFinancialFormData>
          error={formState.errors.taxFilingState !== undefined}
          helperText={formState.errors.taxFilingState?.message?.toString()}
          defaultValue={props.financialInfo?.taxFilingState}
          name='taxFilingState'
          label='StateIFileIn'
          helpContext={
            <HelpPopover title='State in Which You File Taxes'>
              <BulletedList
                children={[
                  'Used for simulated projections',
                  'Federal tax is included; state tax depends on the state in which you earn money.',
                ]}
              />
            </HelpPopover>
          }>
          {Domain.UsStatesListItems.map((x) => (
            <MenuItem value={x.key} key={x.key}>
              {x.value}
            </MenuItem>
          ))}
        </SelectInput>
      </Stack>

      <Spacer height='lg' />
      <Typography variant='h2' fontSize='18px' fontWeight='bold' color='secondary'>
        {t('OtherInfo')}
      </Typography>
      <Spacer height='xs' />
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.People.BasicFinancialFormData>
          error={formState.errors.creditScore !== undefined}
          helperText={formState.errors.creditScore?.message?.toString()}
          defaultValue={props.financialInfo?.creditScore}
          name='creditScore'
          label='MyCreditScore'
          type='integer'
          placeholder='XXX'
          max={900}
          helpContext={CreditScoreHelp()}
        />
        <SelectInput<Data.People.BasicFinancialFormData>
          error={formState.errors.investmentPreference !== undefined}
          helperText={formState.errors.investmentPreference?.message?.toString()}
          defaultValue={formState.defaultValues}
          name='investmentPreference'
          label='InvestmentPref'
          helpContext={
            <HelpPopover title='Investing Preference'>
              <BulletedList
                style={Margins.mt_xxs}
                children={[
                  'We use your investing preference to create a personalized portfolio based on your values.',
                  'If we cannot find a particular asset class with your preference, we will pick an investment that is the next best fit.',
                ]}
              />
            </HelpPopover>
          }>
          {Domain.InvestmentPreferenceTypeListItems.map((x) => (
            <MenuItem value={x.key} key={x.key}>
              {x.value}
            </MenuItem>
          ))}
        </SelectInput>
        <Box>
          <ToggleRadioButtonGroup<Data.People.BasicFinancialFormData>
            error={formState.errors.eligibleForHsa !== undefined}
            helperText={formState.errors.eligibleForHsa?.message?.toString()}
            name='eligibleForHsa'
            label='EligibleHSA'
            row
            defaultValue={props.financialInfo?.eligibleForHsa ? 'true' : 'false'}
            items={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
          />
        </Box>
        <Box>
          <ToggleRadioButtonGroup<Data.People.BasicFinancialFormData>
            error={formState.errors.selfEmployed !== undefined}
            helperText={formState.errors.selfEmployed?.message?.toString()}
            name='selfEmployed'
            label='AreSelfEmployed'
            row
            defaultValue={props.financialInfo?.selfEmployed ? 'true' : 'false'}
            items={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
          />
        </Box>
      </Stack>

      {/* spouse */}
      {props.includeSpouse && (
        <>
          <Spacer height='lg' />
          <Typography variant='h2' fontSize='18px' fontWeight='bold' color='secondary'>
            {t('SpouseIncomeTaxInfo')}
          </Typography>
          <Spacer height='xs' />
          <Stack spacing={Spacing.xxs}>
            <TextInput<Data.People.BasicFinancialFormData>
              error={formState.errors.spouseAnnualIncome !== undefined}
              helperText={formState.errors.spouseAnnualIncome?.message?.toString()}
              defaultValue={props.financialInfo?.spouseAnnualIncome}
              inputProps={{ maxLength: 11 }}
              name='spouseAnnualIncome'
              label='SpouseAnnualIncome'
              type='dollar'
              placeholder='$0'
              helpContext={AnnualIncomeHelp()}
            />
            <TextInput<Data.People.BasicFinancialFormData>
              defaultValue={props.financialInfo?.spouseAnnualOtherIncome}
              inputProps={{ maxLength: 11 }}
              name='spouseAnnualOtherIncome'
              label='SpouseOtherIncome'
              type='dollar'
              placeholder='$0'
              helpContext={AnnualOtherIncomeHelp()}
            />
          </Stack>

          <Spacer height='lg' />
          <Typography variant='h2' fontSize='18px' fontWeight='bold' color='secondary'>
            {t('SpouseOtherInfo')}
          </Typography>
          <Spacer height='xs' />
          <Stack spacing={Spacing.xxs}>
            <TextInput<Data.People.BasicFinancialFormData>
              error={formState.errors.spouseCreditScore !== undefined}
              helperText={formState.errors.spouseCreditScore?.message?.toString()}
              defaultValue={props.financialInfo?.spouseCreditScore}
              name='spouseCreditScore'
              label='SpouseCreditScore'
              type='integer'
              placeholder='XXX'
              max={900}
              helpContext={CreditScoreHelp()}
            />

            <Box>
              <ToggleRadioButtonGroup<Data.People.BasicFinancialFormData>
                error={formState.errors.spouseEligibleForHsa !== undefined}
                helperText={formState.errors.spouseEligibleForHsa?.message?.toString()}
                name='spouseEligibleForHsa'
                label='SpouseEligibleHSA'
                row
                defaultValue={props.financialInfo?.spouseEligibleForHsa ? 'true' : 'false'}
                items={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
              />
            </Box>
            <Box>
              <ToggleRadioButtonGroup<Data.People.BasicFinancialFormData>
                error={formState.errors.spouseSelfEmployed !== undefined}
                helperText={formState.errors.spouseSelfEmployed?.message?.toString()}
                name='spouseSelfEmployed'
                label='IsSpouseSelfEmployed'
                row
                defaultValue={props.financialInfo?.spouseSelfEmployed ? 'true' : 'false'}
                items={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
              />
            </Box>
          </Stack>
        </>
      )}
    </>
  );
};

export default FinancialInfoForm;

const AnnualIncomeHelp = () => {
  return (
    <HelpPopover title={'Annual Income (Before Tax)'}>
      <HelpPopoverText>Include:</HelpPopoverText>
      <BulletedList
        children={[
          'Wages',
          'Salaries',
          'Tips',
          'Social Security',
          'Annuities',
          'Pension Income',
          'Retirement Plan Distributions',
          'Tax-exempt Interest',
          'Capital Gains',
          'Qualified Dividends',
        ]}
      />
    </HelpPopover>
  );
};

const AnnualOtherIncomeHelp = () => {
  return (
    <HelpPopover title='Annual Other Earned Income'>
      <HelpPopoverText>
        Enter the annual pay received from any secondary job (or a part-time job taken in
        retirement).
      </HelpPopoverText>
      <Spacer height='xxs' />
      <HelpPopoverText>
        Note: unearned income from, for instance, a pension plan, alimony, or investment income from
        a rental property should NOT be included here.
      </HelpPopoverText>
    </HelpPopover>
  );
};

const CreditScoreHelp = () => {
  return (
    <HelpPopover title='Credit Score'>
      <HelpPopoverText children={'Credit score is used in lots of planning, including:'} />
      <BulletedList children={['Loans', 'Credit cards', 'Auto insurance', 'Etc.']} />
      <HelpPopoverText style={Margins.mt_sm}>
        If you don’t know your exact credit score, feel free to use these ranges as a rule of thumb.
      </HelpPopoverText>
      <Spacer height='xxxs' />
      <BulletedList
        children={['720-900 (Excellent)', '680-719 (Good)', '640-679 (Average)', '250-639 (Poor)']}
      />
    </HelpPopover>
  );
};
