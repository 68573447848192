import { Box, Drawer, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactInfoCard from '../contactInfo/ContactInfoCard';
import DependentInfoCard from '../dependent/DependentInfoCard';
import FinancialInfoCard from '../financialInfo/FinancialInfoCard';
import MailingInfoCard from '../mailingInfo/MailingInfoCard';
import PersonalInfoCard from '../personalInfo/PersonalInfoCard';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useObservable, useSubject } from '@aesop-fables/scrinium';
import { combineLatest } from 'rxjs';
import { PersonalInfoEditViewWrapper as PersonalInfoEditView } from '../personalInfo/PersonalInfoEditView';
import { ContactInfoEditViewWrapper as ContactInfoEditView } from '../contactInfo/ContactInfoEditView';
import { MailingAddressInfoEditViewWrapper as MailingInfoEditView } from '../mailingInfo/MailingInfoEditView';
import { FinancialInfoEditViewWrapper as FinancialInfoEditView } from '../financialInfo/FinancialInfoEditView';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { useNavigate } from 'react-router-dom';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { DependentForm } from '../dependent/DependentForm';
import { Modal } from '../../../components';
import { useMessage } from '../../../hooks/useMessage';
import {
  useDeleteDependent,
  useSaveDependent,
} from '../../../hooks/useMutations/useDependentMutations';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { PersonalDetailsLayoutMeta } from '../PersonalDetailsLayout';
import SocialSecurityCard from '../social-security/SocialSecurityCard';
import { SocialSecurityEditWrapper as SocialSecurityEditView } from '../social-security/SocialSecurityEditView';
import { Spacing } from '../../../themes';

declare type PersonalDetailsSummaryDrawerType = keyof Data.People.PersonWizard;

const PersonalDetailsSummaryView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <Typography variant='h1' component='h1' color='primary'>
        {t('Summary')}
      </Typography>
      <Typography fontSize={16} color='secondary'>
        {t('SummaryDescription')}
      </Typography>
      <PersonalDetailsSummaryContent />
      <WizardFooter nextLabel='Looks Good!' onDone={() => navigate('/onboarding')} />
    </Box>
  );
};

export interface PersonalDetailsSummaryContentProps {
  showSsi?: boolean;
}

export const PersonalDetailsSummaryContent: React.FC<PersonalDetailsSummaryContentProps> = ({
  showSsi = false,
}) => {
  const { contact, person } = Hooks.useCombinedSelfData();
  const financialInfo = useSubject<Data.People.BasicFinancialFormData>(
    Data.People.PersonServices.FinancialInfo
  );
  const spouse = Hooks.useSpouseData();
  const dependents =
    useSubject<Domain.DependentData[]>(Data.People.PersonServices.DependentData) ?? [];
  const [open, setOpen] = useState(false);
  const [dependent, setDependent] = useState<Domain.DependentData | undefined>(undefined);
  const { showMessage, hideMessage } = useMessage();
  const [needsAttention, setNeedsAttention] = React.useState<string | undefined>();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeDependent, setActiveDependent] = useState<number>();
  const { wizard } = Hooks.usePersonalDetailsWizard();
  const { setLoading } = useLoading();
  const [editing, setEditing] = useState(false);
  const saveDependent = useSaveDependent(editing, () => {
    setOpen(false);
    setEditing(false);
  });
  const deleteDependent = useDeleteDependent(editing);

  useEffect(() => {
    if (person?.includeSpouse && !spouse?.creditScore) {
      showMessage(
        "We're missing some info for your spouse. That's ok – fill it out when you can.",
        'info'
      );
      setNeedsAttention('financialInfo');
    }
  }, [person?.includeSpouse, spouse?.creditScore]);

  useEffect(() => {
    if ((!person?.includeSpouse || spouse?.creditScore) && needsAttention === 'financialInfo') {
      hideMessage();
      setNeedsAttention(undefined);
    }
  }, [person?.includeSpouse, spouse?.creditScore, needsAttention]);

  const handleSaveDependent = (values: Domain.DependentData, editing?: boolean) => {
    setLoading(true);
    editing && setEditing(editing);
    saveDependent.action(values);
    setDependent(undefined);
  };

  const handleDeleteDependent = (id: number) => {
    setLoading(true);
    deleteDependent.action(id);
  };

  const openDrawer = (key?: PersonalDetailsSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };

  return (
    <Box>
      <Grid display='none'>
        <Modal
          title='Remove Dependent?'
          primaryButtonText='No, Keep Dependent'
          secondaryButtonText='Yes, Remove Dependent'
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={() => {
            handleDeleteDependent(activeDependent ?? 0);
          }}>
          <Typography variant='p16' color='secondary'>
            This will remove all of the information for this dependent. Are you sure you want to do
            that?
          </Typography>
        </Modal>
      </Grid>

      <Grid item mt={2}>
        <Stack spacing={2}>
          <PersonalInfoCard
            personalInfo={person}
            spouseInfo={spouse}
            onEdit={() => openDrawer('personalInfo')}
          />
          <ContactInfoCard contactInfo={contact} onEdit={() => openDrawer('contactInfo')} />
          <MailingInfoCard mailingAddress={contact} onEdit={() => openDrawer('addressInfo')} />
          <FinancialInfoCard
            financialInfo={financialInfo}
            onEdit={() => openDrawer('financialInfo')}
            includeSpouse={person?.includeSpouse}
          />
          {showSsi && (
            <SocialSecurityCard person={person} spouse={spouse} onEdit={() => openDrawer()} />
          )}
          <DependentInfoCard
            dependents={dependents}
            onAdd={() => {
              setDependent({});
              openDrawer();
            }}
            onEdit={(d) => {
              openDrawer();
              setDependent(d);
            }}
            onDelete={(d) => {
              setActiveDependent(d.id ?? 0);
              setModalOpen(true);
            }}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        dependent={dependent}
        open={open}
        onClose={() => {
          setOpen(false);
          setDependent(undefined);
          wizard.selectStep(undefined as unknown as keyof Data.People.PersonWizard);
        }}
        saveDependent={handleSaveDependent}
      />
    </Box>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
  dependent?: Domain.DependentData;
  saveDependent?: (d: Domain.DependentData) => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ dependent, onClose, open, saveDependent }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm dependent={dependent} onBack={onClose} saveDependent={saveDependent} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
  dependent?: Domain.DependentData;
  saveDependent?: (d: Domain.DependentData) => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ dependent, onBack, saveDependent }) => {
  const { wizard } = Hooks.usePersonalDetailsWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key;
  if (typeof dependent !== 'undefined' && saveDependent) {
    return (
      <DependentForm
        dependent={dependent}
        edit={typeof dependent.id !== 'undefined'}
        onCancel={() => {
          onBack();
        }}
        onSave={saveDependent}
      />
    );
  }

  if (key === 'personalInfo') {
    return <PersonalInfoEditView editing onBack={onBack} />;
  }

  if (key === 'contactInfo') {
    return <ContactInfoEditView editing onBack={onBack} />;
  }

  if (key === 'addressInfo') {
    return <MailingInfoEditView editing onBack={onBack} />;
  }

  if (key === 'financialInfo') {
    return <FinancialInfoEditView editing onBack={onBack} />;
  }

  return <SocialSecurityEditView editing onBack={onBack} />;
};

const meta = {
  step: 'summary',
} satisfies LayoutMeta<PersonalDetailsLayoutMeta>;

export default withLayoutMeta(PersonalDetailsSummaryView, meta);
