import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DebtSvg: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h30v30H0z" />
          <path
            d="m26.476 23.15-3.71-13.955a.71.71 0 0 0-.68-.52h-4.05C19.82 6.387 18.195 3 15.267 3c-2.927 0-4.551 3.388-2.767 5.674h-4.33c-.321 0-.602.22-.682.542L4.02 23.21c-.04.16-.02.34.06.48L5.524 26.6c.12.24.36.401.621.401h18.266c.28 0 .521-.16.642-.401l1.383-2.947c.06-.16.08-.341.04-.502zM15.288 4.404a2.105 2.105 0 1 1-2.105 2.105c-.02-1.163.942-2.105 2.105-2.105zm.662 17.243v.36a.71.71 0 0 1-.702.702.71.71 0 0 1-.702-.701v-.301a3.484 3.484 0 0 1-1.403-.542.708.708 0 1 1 .782-1.183c.541.341 1.363.462 1.824.241.341-.16.582-.481.622-.822.08-.702-.722-1.023-1.323-1.263-.642-.26-1.364-.542-1.785-1.283-.601-1.083-.02-2.567 1.303-2.988v-.3a.71.71 0 0 1 .702-.702.71.71 0 0 1 .702.701v.241c.421.1.822.28 1.123.561.28.261.3.702.04 1.003a.72.72 0 0 1-1.003.04c-.28-.26-1.002-.36-1.363-.1-.3.2-.421.601-.28.862.32.581 1.463.642 2.345 1.323 1.664 1.303.922 3.65-.882 4.15z"
            fill="#9A5238"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
