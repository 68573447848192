import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { Stream } from '@cloudflare/stream-react';
import { Backdrop, Box, Stack, Typography } from '@mui/material';
import { Loading } from '../../hooks/useLoading';
import { aboutUs } from '../../assets/jpg';
import ContentView from '../layout/Content';
import { useContentView } from '../layout/ContentViewContext';

export const AboutUsWrapper: React.FC = () => {
  const [posterUrl, setPosterUrl] = useState('');
  const videos = Hooks.useVideos();
  const aboutUsVideo = useMemo(
    () => videos?.find((video) => video.name === Domain.VideoTypeEnum.About_3Nickels),
    [videos]
  );

  useEffect(() => {
    setPosterUrl(`${window.location.origin}${aboutUs}`);
  }, [aboutUs]);

  if (!aboutUsVideo) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  return <AboutUsView aboutUsVideo={aboutUsVideo} posterUrl={posterUrl} />;
};

interface AboutUsViewProps {
  aboutUsVideo: Api.MediaRest;
  posterUrl: string;
}

const AboutUsView: React.FC<AboutUsViewProps> = ({ aboutUsVideo, posterUrl }) => {
  const { t } = useTranslation();
  const { sideNav } = useContentView();

  return (
    <ContentView sideNavMenuItems={sideNav('AboutUs')}>
      <Box>
        <Stack spacing={2}>
          <Typography variant='h1' color='primary'>
            {t('AboutUs')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {t('At3NickelsWeHaveALongHistory')}
          </Typography>
          {typeof aboutUsVideo?.uid !== 'undefined' && (
            <div
              style={{
                aspectRatio: '16/9',
                backgroundColor: 'black',
                backgroundImage: `url('${posterUrl}')`,
                backgroundSize: 'cover',
              }}>
              <Stream controls src={aboutUsVideo.uid} poster={posterUrl} />
            </div>
          )}
        </Stack>
      </Box>
    </ContentView>
  );
};

export default AboutUsView;
