import { Box, Drawer } from "@mui/material";
import { Domain } from "@3nickels/data-modules";
import { Spacing } from "../../themes";
import React, { PropsWithChildren, createContext, useCallback, useContext, useState } from "react";

interface WizardFormProps {
  onBack: () => void;
}

export declare type DrawerRoute = {
  onSelect: (goal: Domain.HolisticGoalDisplay) => Promise<void>;
  renderComponent?: React.FC<WizardFormProps>; 
  type: Domain.GoalTypeEnum;
};

export declare type GoalDrawerProps = {
  routes: DrawerRoute[];
};

export declare type GoalDrawerContextProps = {
  selectGoal: (goal: Domain.HolisticGoalDisplay) => Promise<void>;
};

const GoalDrawerContext = createContext<GoalDrawerContextProps | undefined>(undefined);

export const GoalManager: React.FC<GoalDrawerProps & PropsWithChildren> = ({ children, routes }) => {  
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<DrawerRoute | undefined>();
  const onClose = () => setOpen(false);

  const selectGoal = useCallback(async (goal: Domain.HolisticGoalDisplay) => {
    const route = routes.find((x) => x.type === goal.type);
    if (!route) {
      return;
    }

    await route.onSelect(goal);
    setSelected(route);
    setOpen(true);
  }, [routes]);

  const providerProps = {
    close: () => setOpen(false),
    open: () => setOpen(true),
    selectGoal,
  };

  return (
    <GoalDrawerContext.Provider value={providerProps}>
      <Drawer anchor='right' open={open} onClose={onClose}>
        <Box p={Spacing.xxs}>
          {selected && selected.renderComponent && selected.renderComponent({ onBack: onClose })}
        </Box>
      </Drawer>
      {children}
    </GoalDrawerContext.Provider>
  );
};

export function useGoalManager() {
  const context = useContext(GoalDrawerContext);
  if (!context) {
    throw new Error('Must call useGoalManager within the GoalManager provider');
  }

  return context;
}