/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import EmbeddedList from '../../../components/EmbeddedList';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { BudgetSectionProps, OtherSavingsFormData, calcContributions } from '../Types';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { OtherSavingsForm, OtherSavingsFormProps } from './OtherSavingsForm';
import { useSubject } from '@aesop-fables/scrinium';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Svgs } from '../../../assets/svg';
import { Divider, Typography } from '@mui/material';
import { Colors } from '../../../themes';
import { useNavigate } from 'react-router-dom';
import { LeaveBudgetDetailsModal } from '../BudgetDetailsLayout';
import Grid from '@mui/system/Unstable_Grid/Grid';

const SavingsSectionWrapper: React.FC<BudgetSectionProps> = ({ mode, budget }) => {
  const saving = useSubject<Domain.SavingData>(Data.Savings.SavingsServices.SavingsData);
  const defaultValues = useMemo(() => {
    return {
      otherSavings: Data.Budget.adjustAnnualAmount(mode, saving?.otherSavings),
    };
  }, [mode, saving]);

  return <SavingsSection defaultValues={defaultValues} mode={mode} budget={budget} />;
};

export const SavingsSection: React.FC<OtherSavingsFormProps> = ({
  budget,
  defaultValues,
  mode,
}) => {
  const navigate = useNavigate();
  const hsas = Hooks.useCombinedHsaData();
  const { retirementPlans, spouseRetirementPlans } = Hooks.useRetirementPlanData();
  const { person, includeSpouse } = Hooks.useCombinedSelfData();
  const spouseData = Hooks.useSpouseData();
  const adjustedIncome = useMemo(() => {
    return person?.annualIncome ?? 0;
  }, [mode, person]);
  const adjustedSpouseIncome = useMemo(() => {
    return spouseData?.annualIncome ?? 0;
  }, [mode, spouseData]);
  // need to filter by active contributions
  const accounts = useMemo(
    () =>
      retirementPlans.filter(
        (x) =>
          x.planType !== Domain.PlanTypeEnum['IRA | Rollover'] &&
          x.planType !== Domain.PlanTypeEnum.Pension &&
          (x as Domain.PlanData).contributionEligibility &&
          (x as Domain.PlanData).employeeContrib &&
          calcContributions(x, adjustedIncome) > 0
      ),
    [retirementPlans, adjustedIncome]
  );
  const spouseAccounts = useMemo(
    () =>
      spouseRetirementPlans?.filter(
        (x) =>
          x.planType !== Domain.PlanTypeEnum['IRA | Rollover'] &&
          x.planType !== Domain.PlanTypeEnum.Pension &&
          (x as Domain.PlanData).contributionEligibility &&
          (x as Domain.PlanData).employeeContrib &&
          calcContributions(x, adjustedSpouseIncome) > 0
      ),
    [spouseRetirementPlans, adjustedSpouseIncome]
  );
  const investments = useMemo(
    () =>
      hsas.filter(
        (x) => x.planType === Domain.PlanTypeEnum.HSA && (x.annualContribPreTaxDollar ?? 0) > 0
      ),
    [hsas]
  );
  const methods = useForm<OtherSavingsFormData>({ defaultValues });
  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, budget?.totalSaving), // total saving is monthly
    [budget?.totalSaving, mode]
  );

  const [open, setOpen] = useState(false);

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };
  // No goals yet

  return (
    <CollapsiblePanel
      header='SAVINGS'
      icon={<Svgs.PiggyBank />}
      summary={formatWholeDollars(summary)}>
      <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
      <Grid className='embedded-content'>
        <Typography variant='p18Bold' color='secondary' marginY='20px'>
          Current Retirement Contributions
        </Typography>
        <EmbeddedList<Domain.PlanData>
          items={accounts}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={'My Contributions'}
          emptyHeader='No Contributions Added (me)'
          titleFn={(x) => x.nickname ?? ''}
          summaryFn={(x) =>
            mode === 'monthly'
              ? formatWholeDollars(calcContributions(x, adjustedIncome) / 12)
              : formatWholeDollars(calcContributions(x, adjustedIncome))
          }
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen(true)}
        />
        {includeSpouse && (
          <>
            <Divider color={Colors.primaryLight} sx={{ borderColor: 'transparent' }} />
            <EmbeddedList<Domain.PlanData>
              items={spouseAccounts}
              keyFn={(x) => x.id?.toString() ?? ''}
              header="Spouse's Contributions"
              emptyHeader='No Contributions Added (Spouse)'
              titleFn={(x) => x.nickname ?? ''}
              summaryFn={(x) =>
                mode === 'monthly'
                  ? formatWholeDollars(calcContributions(x, adjustedSpouseIncome) / 12)
                  : formatWholeDollars(calcContributions(x, adjustedSpouseIncome))
              }
              headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
              onClick={() => setOpen(true)}
            />
          </>
        )}
        <Divider color={Colors.primaryLight} sx={{ borderColor: 'transparent' }} />
        <Typography variant='p18Bold' color='secondary' marginY='20px'>
          Investments
        </Typography>
        <EmbeddedList<Domain.PlanData>
          items={investments}
          keyFn={(x) => x.id?.toString() ?? ''}
          header='HSA'
          emptyHeader='No HSA added'
          titleFn={(x) => x.nickname ?? ''}
          summaryFn={(x) =>
            mode === 'monthly'
              ? formatWholeDollars((x.annualContribPreTaxDollar ?? 0) / 12)
              : formatWholeDollars(x.annualContribPreTaxDollar ?? 0)
          }
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen(true)}
        />
        {/* Wait for goals */}
        {/* <Divider color={Colors.primaryLight} sx={{ borderColor: 'transparent' }} />
      <Typography variant='p18Bold' color='secondary' marginY='20px'>
        Other Saving
      </Typography>
      <EmbeddedList<Domain.PlanData>
        items={investments}
        keyFn={(x) => x.id?.toString() ?? ''}
        header='Other Savings Goals'
        titleFn={(x) => x.nickname ?? ''}
        summaryFn={(x) => formatWholeDollars(x.balance ?? 0)}
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
      /> */}
        <Divider color={Colors.primaryLight} sx={{ borderColor: 'transparent' }} />
        <FormContent formProviderProps={methods}>
          <OtherSavingsForm defaultValues={defaultValues} mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};

export default SavingsSectionWrapper;
