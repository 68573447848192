/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Api,
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { GoalsLayoutMeta } from '../GoalsLayout';
import RetirementGoalForm from './RetirementGoalForm';
import { GoalFormTitle } from '../../../components/FormTitle';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';

const formSchema = Yup.object({
  // name: Yup.string().required('Required'),
  retirementAge: Yup.string().required('Required'),
  incomeReplacementPercent: Yup.string().required('Required'),
});

export const RetirementGoalEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } = Hooks.useRetirementGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const retirementGoal = Hooks.useRetirementGoal();
  const ageBoundary = Hooks.useRetirementAges() as Api.RetirementAgeBoundRest;
  const includeSpouse = Hooks.useIncludeSpouse();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted && retirementGoal.isResolved) {
      wizard.start({ id: retirementGoal.value?.id });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [retirementGoal.isResolved, isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <RetirementGoalEditView
              ageBoundary={ageBoundary}
              includeSpouse={includeSpouse}
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface RetirementGoalEditViewProps extends EditViewProps {
  ageBoundary: Api.RetirementAgeBoundRest;
  includeSpouse: boolean;
  currentStep: WizardStep<Data.Goals.RetirementGoalFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.RetirementGoalWizard;
  params: Data.Goals.WizardParamsWithId;
}

const RetirementGoalEditView: React.FC<RetirementGoalEditViewProps> = ({
  ageBoundary,
  includeSpouse,
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.Goals.RetirementGoalFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema),
  });
  const { setLoading } = useLoading();

  const formatInputs = (values: any) => {
    const retirementAge = cleanWholeNumberStr(values.retirementAge);
    const spouseRetirementAge =
      (values.spouseRetirementAge && cleanWholeNumberStr(values.spouseRetirementAge)) || undefined;
    const incomeReplacementPercent = cleanWholeNumberStr(values.incomeReplacementPercent);

    return { retirementAge, spouseRetirementAge, incomeReplacementPercent };
  };

  const onSubmit = async (values: Data.Goals.RetirementGoalFormData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({
        ...values,
        ...cleanedValues,
      });

      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/goals');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <GoalFormTitle editing={true} type={Domain.GoalTypeEnum.Retirement} />

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 12 : 6}>
                  <RetirementGoalForm
                    ageBoundary={ageBoundary}
                    includeSpouse={includeSpouse}
                    model={currentStep.model}
                    onSubmit={onSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Save & Continue',
  title: 'Retirement',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(RetirementGoalEditViewWrapper, meta);
