import { createServiceModule, Scopes } from '@aesop-fables/containr';
import { ApiKeys } from './apis/ApiKeys';
import { AuthTokenApi } from './apis/AuthTokenApi';
import { PaymentApi } from './apis/PaymentApi';
import { PricebookApi } from './apis/PricebookApi';
import axios, { AxiosInstance } from 'axios';
import { domain } from '../configuration/Environment';
import { PlatformSwitchApi } from './apis/PlatformSwitchApi';

export const nickelsApisKey = 'api/nickels';

export const useNickelsApis = createServiceModule(nickelsApisKey, (services) => {
  services.autoResolve<PaymentApi>(ApiKeys.Payment, PaymentApi, Scopes.Transient);
  services.autoResolve<PricebookApi>(ApiKeys.Pricebook, PricebookApi, Scopes.Transient);
  services.autoResolve<AuthTokenApi>(ApiKeys.AuthToken, AuthTokenApi, Scopes.Transient);
  services.autoResolve<PlatformSwitchApi>(
    ApiKeys.PlatformSwitch,
    PlatformSwitchApi,
    Scopes.Transient
  );
  services.factory<AxiosInstance>(
    ApiKeys.MobileAuthAxios,
    () => {
      return axios.create({ baseURL: `https://3n-auth.${domain}/api/`, withCredentials: true });
    },
    Scopes.Transient
  );
});
