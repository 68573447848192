import { Box, Grid, Stack, Switch, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import DatePicker from '../../../components/form/DatePicker';
import dayjs from 'dayjs';
import { Spacing } from '../../../themes';
import { Data } from '@3nickels/data-modules';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../components';

export type PersonalInfoFormProps = {
  personalInfo?: Data.People.PersonalInfoFormData;
  onSubmit: (values: Data.People.PersonalInfoFormData) => Promise<void>;
};

type ConfirmationDialogProps = {
  open: boolean;
  onAffirmPress?: () => void;
  onDeclinePress?: () => void;
};

type MarriageAndSpouse = {
  includeSpouse: boolean;
  married: boolean;
};

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = (props) => {
  const { t } = useTranslation();
  const { control, setValue, formState } = useFormContext();
  // TODO -- This pattern of "copying" data from the wizard state into the component state
  // isn't necessarily a "smell" but it's certainly worth noting as a candidate for refactoring
  // Just spitballing but maybe something like:
  //    const [formState, setFormState] = useFormState<Data.People.PersonalInfoFormData>();
  // We'd probably want/need to pass in react-hook-form stuff so that any changes are also propagated to the form
  // so that it's just additive rather than having to track that AND the form values
  const [maritalStatus, setMaritalStatus] = useState<MarriageAndSpouse>({
    includeSpouse: false,
    married: false,
  });
  const [confirmDialog, setConfirmDialog] = useState<ConfirmationDialogProps>({ open: false });
  // const marriedRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setMaritalStatus({
      includeSpouse: props.personalInfo?.includeSpouse ?? false,
      married: props.personalInfo?.maritalStatus ?? false,
    });
  }, [props.personalInfo?.includeSpouse, props.personalInfo?.maritalStatus]);

  const changeMaritalStatus = useCallback((value: boolean) => {
    if (!value) {
      setValue('includeSpouse', value, { shouldTouch: true });
      setMaritalStatus({ married: value, includeSpouse: false });
    }

    setMaritalStatus((previous) => {
      return { ...previous, married: value };
    });
  }, []);
  const changeIncludeSpouse = useCallback((value: boolean) => {
    setValue('includeSpouse', value, { shouldTouch: true });
    setMaritalStatus((previous) => {
      return { ...previous, includeSpouse: value };
    });
  }, []);

  const maritalStatusChangeHandler = (
    target: HTMLInputElement,
    maritalStatus: MarriageAndSpouse
  ) => {
    const value = target.value === 'true';
    // personalInfo.spouseId
    if (maritalStatus.married && maritalStatus.includeSpouse && !value) {
      setConfirmDialog({
        open: true,
        onAffirmPress: () => changeMaritalStatus(false),
        onDeclinePress: () => {
          changeMaritalStatus(true);
          setValue('maritalStatus', 'true', { shouldTouch: true });
        },
      });
    } else {
      changeMaritalStatus(value);
    }
  };

  const includeSpouseChangeHandler = (value: boolean, maritalStatus: MarriageAndSpouse) => {
    // peronalInfo.spouseId
    if (maritalStatus.married && !value) {
      setConfirmDialog({
        open: true,
        onAffirmPress: () => changeIncludeSpouse(false),
        onDeclinePress: () => changeIncludeSpouse(true),
      });
    } else {
      changeIncludeSpouse(value);
    }
  };

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.People.PersonalInfoFormData>
          error={formState.errors.firstName !== undefined}
          helperText={formState.errors.firstName?.message?.toString()}
          defaultValue={props.personalInfo?.firstName}
          inputProps={{ maxLength: 32 }}
          label='FirstName'
          name='firstName'
          autoFocus
        />
        <TextInput<Data.People.PersonalInfoFormData>
          error={formState.errors.lastName !== undefined}
          helperText={formState.errors.lastName?.message?.toString()}
          defaultValue={props.personalInfo?.lastName}
          inputProps={{ maxLength: 32 }}
          label='LastName'
          name='lastName'
        />
        <TextInput<Data.People.PersonalInfoFormData>
          defaultValue={props.personalInfo?.preferredName}
          inputProps={{ maxLength: 32 }}
          label='PreferredName'
          name='preferredName'
        />
        <DatePicker<Data.People.PersonalInfoFormData>
          error={formState.errors.birthDate !== undefined}
          helperText={formState.errors.birthDate?.message?.toString()}
          value={props.personalInfo?.birthDate ? dayjs(props.personalInfo.birthDate) : undefined}
          label='DateOfBirth'
          name='birthDate'
        />

        <ToggleRadioButtonGroup<Data.People.PersonalInfoFormData>
          error={formState.errors.gender !== undefined}
          helperText={formState.errors.gender?.message?.toString()}
          name='gender'
          label='Gender'
          row
          defaultValue={props.personalInfo?.gender}
          items={[
            { label: 'Female', value: 'F' },
            { label: 'Male', value: 'M' },
          ]}
        />

        <ToggleRadioButtonGroup<Data.People.PersonalInfoFormData>
          error={formState.errors.maritalStatus !== undefined}
          helperText={formState.errors.maritalStatus?.message?.toString()}
          name='maritalStatus'
          label='MaritalStatus'
          // ref={marriedRef}
          row
          onChange={(target) => {
            // const status = target.value === 'true';
            maritalStatusChangeHandler(target, maritalStatus);
          }}
          defaultValue={props.personalInfo?.maritalStatus?.toString()}
          items={[
            { label: 'Single', value: 'false' },
            { label: 'Married', value: 'true' },
          ]}
        />

        {maritalStatus.married && (
          <Grid justifyContent='space-between' container>
            {/* { TODO -- We REALLY need to fix this styling and make sure it's a proper form header (maybe make a component for it)  } */}
            {/* <Label color='primary' textKey='SpouseInfo' htmlFor='includeSpouse' /> */}
            {/* Should be about 22px */}
            <Typography color='primary' component='h2' variant='p18Bold'>
              {t('SpouseInfo')}
            </Typography>
            <Controller
              name='includeSpouse'
              control={control}
              render={() => (
                <Switch
                  color='primary'
                  onChange={(e) => {
                    includeSpouseChangeHandler(e.target.checked, maritalStatus);
                  }}
                  checked={maritalStatus.includeSpouse}
                />
              )}
            />
          </Grid>
        )}
        {maritalStatus.includeSpouse && (
          <>
            <TextInput<Data.People.PersonalInfoFormData>
              error={formState.errors.spouseFirstName !== undefined}
              helperText={formState.errors.spouseFirstName?.message?.toString()}
              defaultValue={props.personalInfo?.spouseFirstName}
              inputProps={{ maxLength: 32 }}
              label='SpouseFirstName'
              name='spouseFirstName'
            />
            <TextInput<Data.People.PersonalInfoFormData>
              error={formState.errors.spouseLastName !== undefined}
              helperText={formState.errors.spouseLastName?.message?.toString()}
              defaultValue={props.personalInfo?.spouseLastName}
              inputProps={{ maxLength: 32 }}
              label='SpouseLastName'
              name='spouseLastName'
            />
            <TextInput<Data.People.PersonalInfoFormData>
              defaultValue={props.personalInfo?.spousePreferredName}
              inputProps={{ maxLength: 32 }}
              label='SpousePreferredName'
              name='spousePreferredName'
            />
            <DatePicker<Data.People.PersonalInfoFormData>
              error={formState.errors.spouseBirthDate !== undefined}
              helperText={formState.errors.spouseBirthDate?.message?.toString()}
              value={
                props.personalInfo?.spouseBirthDate
                  ? dayjs(props.personalInfo.spouseBirthDate)
                  : undefined
              }
              label='SpouseDateOfBirth'
              name='spouseBirthDate'
            />
            <ToggleRadioButtonGroup<Data.People.PersonalInfoFormData>
              error={formState.errors.spouseGender !== undefined}
              helperText={formState.errors.spouseGender?.message?.toString()}
              name='spouseGender'
              label='SpouseGender'
              row
              defaultValue={props.personalInfo?.spouseGender}
              items={[
                { label: 'Female', value: 'F' },
                { label: 'Male', value: 'M' },
              ]}
            />
          </>
        )}
      </Stack>
      <Modal
        title='Delete Spouse From Plan?'
        primaryButtonText='No, Keep Spouse In Plan'
        secondaryButtonText='Yes, Delete Spouse From Plan'
        swapButtonFunctionality
        open={confirmDialog.open}
        setOpen={() => setConfirmDialog({ open: false })}
        handleSave={() => {
          if (confirmDialog.onAffirmPress) confirmDialog.onAffirmPress();
          setConfirmDialog({ open: false });
        }}>
        <Typography variant='p16' color='secondary'>
          This will remove all of the information for your spouse. Are you sure you want to do that?
        </Typography>
      </Modal>
    </Box>
  );
};

export default PersonalInfoForm;
