import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import { Spacing } from '../../../themes';
import { Api, Data, Domain, formatWholeDollars } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import DatePicker from '../../../components/form/DatePicker';
import dayjs from 'dayjs';
import SelectInput from '../../../components/form/SelectInput';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import Spacer from '../../../components/Spacer';
import BulletedList from '../../../components/BulletedList';
import { Margins } from '../../../themes';
import InfoCard from '../../../components/InfoCard';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';

export type CollegeGoalDetailsFormProps = {
  model?: Data.Goals.CollegeGoalFormData;
  averageCosts: Api.CollegeFundSelfAverageCostsResultRest;
  onSubmit: (values: Data.Goals.CollegeGoalFormData) => Promise<void>;
};

const options = [
  { label: 'Custom', value: Domain.CollegeTuitionTypeEnum.Custom },
  { label: 'Average Private University', value: Domain.CollegeTuitionTypeEnum.Private },
  {
    label: 'Average Public University (In-State)',
    value: Domain.CollegeTuitionTypeEnum.PublicInState,
  },
  {
    label: 'Average Public University (Out-of-State)',
    value: Domain.CollegeTuitionTypeEnum.PublicOutState,
  },
];

const CollegeGoalDetailsForm: React.FC<CollegeGoalDetailsFormProps> = (props) => {
  const { formState, setValue, reset, watch } = useFormContext();
  const { averageCosts, model } = props;
  const [annualCost, setAnnualCost] = useState(0);
  const [annualCostDisabled, setAnnualCostDisabled] = useState(false);
  const [beneficiaryType, setBeneficiaryType] = useState<Domain.BeneficiaryTypeEnum | undefined>(
    model?.beneficiaryType
  );

  const onTuitionTypeChanged = useCallback(
    (type: Domain.CollegeTuitionTypeEnum) => {
      switch (type) {
        case Domain.CollegeTuitionTypeEnum.Custom:
          setAnnualCostDisabled(false);
          break;
        case Domain.CollegeTuitionTypeEnum.Private:
          setValue('annualTuitionCost', averageCosts.averagePrivateSchoolAnnualCost);
          setAnnualCostDisabled(true);
          break;
        case Domain.CollegeTuitionTypeEnum.PublicInState:
          setValue('annualTuitionCost', averageCosts.averagePublicSchoolInStateAnnualCost);
          setAnnualCostDisabled(true);
          break;
        case Domain.CollegeTuitionTypeEnum.PublicOutState:
          setValue('annualTuitionCost', averageCosts.averagePublicSchoolOutStateAnnualCost);
          setAnnualCostDisabled(true);
          break;
        default:
          break;
      }
    },
    [averageCosts]
  );

  React.useEffect(() => {
    setBeneficiaryType(model?.beneficiaryType);
  }, [model?.beneficiaryType]);

  React.useEffect(() => {
    reset(model);

    setAnnualCost(
      model?.annualTuitionCostCoveragePercent
        ? (model?.annualTuitionCost ?? 0) * 4 * (model?.annualTuitionCostCoveragePercent / 100)
        : (model?.annualTuitionCost ?? 0) * 4
    );
  }, [model, reset, setAnnualCost]);

  React.useEffect(() => {
    const subscription = watch((values, bag) => {
      const { name } = bag;
      if (name === 'annualTuitionCost' || name === 'annualTuitionCostCoveragePercent') {
        let coverage = 0;
        if (model?.beneficiaryType !== Domain.BeneficiaryTypeEnum.DEPENDENT) {
          coverage = 1;
        } else {
          coverage = Math.min(
            (cleanWholeNumberStr(values.annualTuitionCostCoveragePercent) ?? 0) / 100,
            1
          );
        }

        setAnnualCost(cleanWholeNumberStr(values.annualTuitionCost) * coverage * 4);
      } else if (name === 'tuitionType') {
        onTuitionTypeChanged(values.tuitionType);
      } else if (name === 'beneficiaryType') {
        setBeneficiaryType(values.beneficiaryType);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setAnnualCost, model]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        {beneficiaryType !== Domain.BeneficiaryTypeEnum.DEPENDENT && (
          <DatePicker<Data.Goals.CollegeGoalFormData>
            error={formState.errors.targetDate !== undefined}
            helperText={formState.errors.targetDate?.message?.toString()}
            value={props.model?.targetDate ? dayjs(props.model?.targetDate) : undefined}
            label={`When would ${
              beneficiaryType === Domain.BeneficiaryTypeEnum.SPOUSE ? 'your spouse' : 'you'
            } like to attend college?`}
            name='targetDate'
          />
        )}

        {beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT && (
          <TextInput<Data.Goals.CollegeGoalFormData>
            error={formState.errors.annualTuitionCostCoveragePercent !== undefined}
            helperText={formState.errors.annualTuitionCostCoveragePercent?.message?.toString()}
            helpContext={<PercentageCoveredHelpContent />}
            defaultValue={props.model?.annualTuitionCostCoveragePercent ?? 50}
            label='Annual Tuition Cost Coverage Percent'
            name='annualTuitionCostCoveragePercent'
            type='percent'
          />
        )}

        <SelectInput<Data.Goals.CollegeGoalFormData>
          error={formState.errors.tuitionType !== undefined}
          helperText={formState.errors.tuitionType?.message?.toString()}
          defaultValue={model?.tuitionType}
          name='tuitionType'
          autoFocus
          label='Type of College Expenses'>
          {options.map((x) => (
            <MenuItem value={x.value}>{x.label}</MenuItem>
          ))}
        </SelectInput>

        <Grid container alignItems='flex-end' justifyContent={'space-between'}>
          <Grid item sm={6}>
            <TextInput<Data.Goals.CollegeGoalFormData>
              error={formState.errors.annualTuitionCost !== undefined}
              helperText={formState.errors.annualTuitionCost?.message?.toString()}
              defaultValue={props.model?.annualTuitionCost}
              helpContext={<AnnualCostHelpContent />}
              label='Annual Cost of College'
              name='annualTuitionCost'
              disabled={annualCostDisabled}
              type='dollar'
              autoFocus
            />
          </Grid>
          <Grid item sm={5} ml={2}>
            <InfoCard renderIcon={false}>
              <Stack spacing={Spacing.xxxs}>
                <Typography color='secondary' variant='p14Bold'>
                  Total 4-Year Cost
                </Typography>
                <Typography color='secondary' variant='p14'>
                  {formatWholeDollars(annualCost)}
                </Typography>
              </Stack>
            </InfoCard>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export const PercentageCoveredHelpContent = () => {
  return (
    <HelpPopover title='Percentage of Cost'>
      <HelpPopoverText>Most families expect to cover 50% of the cost.</HelpPopoverText>
    </HelpPopover>
  );
};

export const AnnualCostHelpContent = () => {
  return (
    <HelpPopover title='Percentage of Cost'>
      <HelpPopoverText>
        The following expenses are included in the annual cost college:
      </HelpPopoverText>
      <Spacer height='xxs' />
      <BulletedList style={Margins.mt_xxs}>
        <>Tuition and fees</>
        <>Room and board</>
        <>Books and supplies</>
        <>Transportation</>
        <>Other expenses</>
      </BulletedList>
      <Spacer height='xxs' />
      <HelpPopoverText>Note: For our averages, we have assumed on-campus housing.</HelpPopoverText>
    </HelpPopover>
  );
};

export default CollegeGoalDetailsForm;
