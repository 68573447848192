import Grid, { type GridProps } from '@mui/material/Grid';
import React from 'react';

export interface PageContentWithBackgroundProps extends GridProps {
  useContainer?: boolean;
}

export const PageContentWithBackground: React.FC<PageContentWithBackgroundProps> = ({
  useContainer = true,
  ...props
}) => (
  <Grid
    className='page-content-with-background'
    {...(useContainer && { container: true })}
    {...props}>
    {props.children}
  </Grid>
);
