/* tslint:disable */
import OnboardingView from '../pages/onboarding/OnboardingView';
import AccountDetailsLayout from '../pages/account-details/AccountDetailsLayout';
import PersonalDetailsLayout from '../pages/personal-details/PersonalDetailsLayout';
import { RouteObject } from 'react-router';
import BudgetDetailsLayout from '../pages/budget-details/BudgetDetailsLayout';
import HolisticFinancialAdviceLayout from '../pages/holistic-financial-advice/HolisticFinancialAdviceLayout';
import { PersonalDetailsRoutes } from './PersonalDetailsRoutes';
import { AccountDetailsRoutes } from './AccountDetailsRoutes';
import { BudgetDetailsRoutes } from './BudgetDetailsRoutes';
import { HolisticFinancialAdviceRoutes } from './HolisticFinancialAdviceRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { LoaderFunction } from 'react-router-dom';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { loadAdviceOverview, loadAdviceResults } from './loaders/HolisticAdviceLoaders';
import { wireUpLoaders } from './utils';
import { incomeReadyLoader } from './loaders/IncomeReadyLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { commonRoutes } from './CommonRoutes';
import {
  accountDetailsLoader,
  accountsLoader,
  carsLoader,
  creditCardsLoader,
  debtLoader,
  investmentAccountsLoader,
  linkAccountLoader,
  realEstateLoader,
} from './loaders/AccountDetailsLoader';
import { netWorthLoader } from './loaders/NetWorthLoader';
import { plaidLoader } from './loaders/PlaidLoader';
import { budgetLoader } from './loaders/BudgetLoader';
import { incomeLoader } from './loaders/IncomeLoader';
import { twoFactorLoader } from './loaders/TwoFactorLoader';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';
import UniversityLayout from '../pages/university/UniversityLayout';
import UniversityOverview from '../pages/university/UniversityOverview';
import UniversityLessonView from '../pages/university/UniversityLessonView';
import { universityLoader } from './loaders/UniversityLoader';

const adviceOnboardingRoutes: RouteObject[] = [
  {
    path: '/',
    element: <OnboardingView />,
  },
  {
    path: 'personal-details',
    element: <PersonalDetailsLayout />,
    children: PersonalDetailsRoutes,
  },
  {
    path: 'account-details',
    element: <AccountDetailsLayout headerVariant='addAccount' />,
    children: AccountDetailsRoutes,
  },
  {
    path: 'budget-details',
    element: <BudgetDetailsLayout />,
    children: BudgetDetailsRoutes,
  },
  {
    path: 'holistic-advice',
    element: <HolisticFinancialAdviceLayout />,
    children: HolisticFinancialAdviceRoutes,
  },
  {
    path: 'university',
    element: <UniversityLayout />,
    children: [
      { index: true, element: <UniversityOverview /> },
      {
        path: ':title',
        element: <UniversityLessonView />,
      },
    ],
  },
  ...commonRoutes,
];

export function createAdviceOnboardingRoutes(container: IServiceContainer) {
  const loaders = new Map<string, LoaderFunction>();
  // load advice on homescreen
  loaders.set('/', async () => {
    await Promise.all([appReadyLoader(container), realEstateLoader(container)]);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });
  loaders.set('personal-details', () =>
    Promise.all([incomeReadyLoader(container), incomeLoader(container)])
  );
  // reload advice in holistic-advice
  loaders.set('account-details', () =>
    Promise.all([
      incomeReadyLoader(container),
      accountDetailsLoader(container),
      incomeLoader(container),
      netWorthLoader(container),
      accountsLoader(container),
      plaidLoader(container),
    ])
  );
  loaders.set('account-details/link-account', ({ request }) =>
    plaidLoader(container).then(() => linkAccountLoader(request, container))
  );
  loaders.set('budget-details', () =>
    Promise.all([
      incomeReadyLoader(container),
      budgetLoader(container),
      incomeLoader(container),
      realEstateLoader(container),
      investmentAccountsLoader(container),
      carsLoader(container),
      creditCardsLoader(container),
      debtLoader(container),
    ])
  );
  loaders.set('holistic-advice', () => loadAdviceOverview(container));
  loaders.set('holistic-advice/investments', () => investmentAccountsLoader(container));
  loaders.set('holistic-advice/plan', () => loadAdviceResults(container));
  loaders.set('university', () => universityLoader(container));
  loaders.set('settings/update-password', () => authTokenCookieLoader(container));
  loaders.set('settings/two-factor-authentication', () => twoFactorLoader(container));

  return wireUpLoaders(adviceOnboardingRoutes, loaders);
}
