import { redirect } from 'react-router-dom';
import { RedirectHandler } from './RedirectHandler';
import { injectSubject } from '@aesop-fables/scrinium';
import { Data, Domain } from '@3nickels/data-modules';
import { Observable, firstValueFrom } from 'rxjs';

export class AcceptTermsHandler implements RedirectHandler {
  constructor(
    @injectSubject(Data.Users.UserServices.PrincipalUser)
    private readonly user: Observable<Domain.PrincipalUser>
  ) {}
  async matches() {
    const { userConfig } = await firstValueFrom(this.user);
    if (!userConfig) {
      return false;
    }

    return userConfig?.requireToAcceptTerms === true;
  }

  handle(): Response {
    return redirect('/accept-terms');
  }
}
