import { Api, Domain } from '@3nickels/data-modules';
import { SummaryCardProps } from '../account-details/Types';
import CardDisplay from '../../components/panels/CardDisplay';
import { Card, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useService } from '@aesop-fables/containr-react';
import { useLoading } from '../../hooks/useLoading';

export interface SavedGuidesCardProps extends SummaryCardProps {
  savedGuides: Domain.SavedGuidesData[];
}

const SavedGuidesCard: React.FC<SavedGuidesCardProps> = ({ savedGuides }) => {
  const { setLoading } = useLoading();
  const api = useService<Api.IHolisticAdviceApi>(Api.ApiKeys.HolisticAdvice);

  const downloadGuide = async (guide: Domain.SavedGuidesData) => {
    try {
      setLoading(true);
      const response = await api.getSelectedGuidePdf(guide);
      console.log(response);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'guide.pdf');
      document.body.appendChild(link);
      window.open(url);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardDisplay headerKey='PreviouslySavedPlans'>
      <Grid container spacing={2}>
        {savedGuides.map((guide) => (
          <Grid key={guide.uri} item xs onClick={() => downloadGuide(guide)}>
            <Card className='saved-guide'>
              <Typography variant='h3' component='h3' color='primary'>
                {dayjs(guide.dateCreated).format('MMMM D, YYYY')}
              </Typography>
              <Typography variant='p12' color='secondary'>
                Saved at {dayjs(guide.dateCreated).format('h:mm A')}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </CardDisplay>
  );
};

export default SavedGuidesCard;
