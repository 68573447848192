/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading } from '../../../../hooks/useLoading';
import { getWizardFooterProps } from '../../../EditViewUtils';
import { Spacer } from '../../../../components';
import LinkAccountHeader from '../../headers/LinkAccountHeader';
// import ImportAccountsCard from './ImportAccountCard';
import PlaidAccountCard from './PlaidAccountCard';
import { usePlaidAccountTypes } from '../PlaidHooks';
import { useObservable } from '@aesop-fables/scrinium';
import { PlaidInstitutionContext } from '../PlaidWizard';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useMessage } from '../../../../hooks/useMessage';
import NoAccountsFoundCard from './NoAccountsFoundCard';

interface ImportAccountViewProps {
  // currentStep: WizardStep<InvestmentAccountBasicFormData, InvestmentAccountWizardParams>;
  // wizard: InvestmentAccountWizard;
  institution: PlaidInstitutionContext;
}

const ImportAccountViewWrapper: React.FC = () => {
  const wizard = Hooks.usePlaidWizard();
  const navigate = useNavigate();
  const context = useObservable(wizard.context$);
  const loading = useObservable(wizard.loading$);
  const current = useObservable(wizard.current$);

  useEffect(() => {
    if (context && current !== Data.Plaid.PlaidStepEnum.Loading && !context.selectedInstitution) {
      navigate(`/account-details/link-account`);
    }
  }, [context]);

  if (loading === true || typeof context?.selectedInstitution === 'undefined') {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  return <ImportAccountView institution={context.selectedInstitution as PlaidInstitutionContext} />;
};

const ImportAccountView: React.FC<ImportAccountViewProps> = ({ institution }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const plaidAccountTypes = usePlaidAccountTypes();
  const plaidWizard = Hooks.usePlaidWizard();
  const incompleteAccounts = useMemo(() => {
    return (
      typeof plaidAccountTypes.find(
        (x) => x.status === Domain.PlaidAccountStatusEnum.Incomplete
      ) !== 'undefined'
    );
  }, [plaidAccountTypes]);

  const nonImportedAccounts = useMemo(() => {
    return (
      typeof plaidAccountTypes.find(
        (x) => x.status === Domain.PlaidAccountStatusEnum.NotImported
      ) !== 'undefined'
    );
  }, [plaidAccountTypes]);

  useEffect(() => {
    if (incompleteAccounts || nonImportedAccounts) {
      showMessage(
        'Whoops! Your details seem to be playing hide and seek. Mind helping us out?',
        'error'
      );
    }
  }, [incompleteAccounts, nonImportedAccounts]);

  return (
    <Grid container flexDirection='column'>
      <Typography className='title' variant='h1' component='h1' color='primary'>
        {t('ImportAccounts')}
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p18Bold' color='secondary'>
        {institution.institutionName}
      </Typography>
      <Typography className='subtitle' color='secondary'>
        {t('ImportAccountsDescription')}
      </Typography>
      <Spacer height='xxs' />
      <Typography color='primary' variant='p12'>
        {t('ImportAccountsNote')}
      </Typography>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item mt={3} sm={9}>
          <Stack spacing={3}>
            {plaidAccountTypes.length > 0 ? (
              <PlaidAccountCard institution={institution} accounts={plaidAccountTypes} />
            ) : (
              <NoAccountsFoundCard />
            )}
          </Stack>
        </Grid>
      </Grid>
      <WizardFooter
        {...getWizardFooterProps(meta.nextLocaleKey)}
        hideBack={incompleteAccounts}
        disableNext={incompleteAccounts}
        onBack={() => {
          plaidWizard.resetSelectedInstitution();
          navigate(-1);
        }}
        onDone={() => {
          plaidWizard.resetSelectedInstitution();
          navigate('/account-details');
        }}
      />
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'Done',
  header: <LinkAccountHeader />,
} satisfies LayoutMeta;

export default withLayoutMeta(ImportAccountViewWrapper, meta);
