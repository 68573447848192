import { HelpOutline } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import FormInput, { FormInputProps } from './FormInput';
import 'tippy.js/themes/light.css'; // must import any theme you wish to use with Tippy
import 'tippy.js/dist/tippy.css'; // optional
import Tippy from '@tippyjs/react';
import React from 'react';
import Column from './Column';
import Row from './Row';
import Spacer from './Spacer';

interface VerticalFormInputProps<T> extends FormInputProps<T> {
  label?: string;
  helpText?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputStyle?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const VerticalFormInput = <T extends unknown>(props: VerticalFormInputProps<T>) => {
  const theme = useTheme();
  const { label, helpText } = props;
  return (
    <Column>
      <Row style={{ justifyContent: 'space-between', marginBottom: '6px' }}>
        {label ? (
          <>
            <Typography variant='p14Bold' color='primary'>
              {label}
            </Typography>
            <Spacer width='xs' />
          </>
        ) : null}
        {helpText ? (
          <>
            <Column style={{ alignSelf: 'flex-end' }}>
              <Tippy placement='right' content={helpText} theme='light'>
                <HelpOutline style={{ color: theme.palette.primary.main }} />
              </Tippy>
              <Spacer width='xs' />
            </Column>
          </>
        ) : null}
      </Row>
      <FormInput {...props} />
    </Column>
  );
};

export default VerticalFormInput;
