import { withSignUpLoginWizard } from '../services/signUpLogin';
import { withPricebookData } from '../data/pricebook';
import { withPaymentData } from '../data/payment';
import { withLicenseWizard } from '../services/license';
import { withSignUpProgressWizard } from '../services/signUpProgress';
import { withAccountSettingsWizard } from '../services/accountSettings';
import { withTermsData } from '../data/terms';
import { useNickelsApis } from '../api/useNickelsApis';
import { IServiceContainer, IServiceModule } from '@aesop-fables/containr';
import { IAppStorageModule } from '@aesop-fables/scrinium';
import { Authentication, Configuration } from '@3nickels/data-modules';
import { withLicenseData } from '../data/license';
import { useRedirectHandlers } from '../redirects';
import { getApiEnvFromDomain } from './Environment';
import { usePaymentServices } from '../services/payment';
import { usePricebookServices } from '../services/pricebook';

export const bootstrap = (overrides: IServiceModule[] = []): IServiceContainer => {
  // Configuration
  const options = { environment: getApiEnvFromDomain() };
  const modules: IServiceModule[] = [
    Authentication.useWebSessionAuth,
    useNickelsApis,
    usePaymentServices,
    usePricebookServices,
    withLicenseWizard,
    withSignUpProgressWizard,
    withSignUpLoginWizard,
    withAccountSettingsWizard,
    useRedirectHandlers,
    ...overrides,
  ];

  const storageModules: IAppStorageModule[] = [
    withLicenseData,
    withTermsData,
    withPricebookData,
    withPaymentData,
  ];

  return Configuration.bootstrap(options, modules, storageModules);
};
