import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ContentView from '../layout/Content';
import AddAccountHeader from './headers/AddAccountHeader';
import HexHeader from '../layout/header-variants/HexHeader';
import { Grid, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { SummaryHeader } from './headers/SummaryHeader';
import { useAccountHeaderContent } from '../../hooks/useAccountHeaderContent';
import { DuplicateAccountHeader } from './headers/DuplicateAccountHeader';
import { useContentView } from '../layout/ContentViewContext';

export type AccountDetailsLayout = Record<string, unknown>;

export declare type AccountDetailsLayoutMeta = {
  headerVariant: 'addAccount' | 'hex' | 'summary' | 'duplicate';
  planTypeLabel?: string;
};

const AccountDetailsLayout: React.FC<AccountDetailsLayout> = () => {
  const { meta, setMeta } = useLayoutMeta<AccountDetailsLayoutMeta>();
  const navigate = useNavigate();
  const content = useAccountHeaderContent();
  const { sideNav } = useContentView();

  const headerVariableControl = {
    addAccount: <AddAccountHeader />,
    hex: (
      <HexHeader
        stepProps={meta.hexSteps}
        startAdornment={
          content && (
            <Typography className='header-text' variant='p18Bold' color='primary'>
              {content}
            </Typography>
          )
        }
      />
    ),
    summary: <SummaryHeader content={content} />,
    duplicate: <DuplicateAccountHeader content={content} />,
  };

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  return (
    <ContentView
      sideNavMenuItems={sideNav('Accounts')}
      headerChildren={meta.header ?? headerVariableControl[meta.headerVariant]}>
      <Outlet context={{ setMeta }} />
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default AccountDetailsLayout;
