import { LoaderFunction, RouteObject } from 'react-router-dom';
import { Observable, filter } from 'rxjs';

export function wireUpLoaders(
  routes: RouteObject[],
  loaders: Map<string, LoaderFunction>,
  parentPath = ''
) {
  return routes.map((route) => {
    const path = parentPath.concat(route.path ?? '');
    if (route.path) {
      const loader = loaders.get(path);
      if (loader) {
        route.loader = loader;
      }
    }

    if (route.children) {
      route.children = wireUpLoaders(route.children, loaders, path?.concat('/'));
    }

    return route;
  });
}

export async function waitFor(predicate$: Observable<boolean>) {
  await new Promise<void>((resolve, reject) => {
    const subscription = predicate$.pipe(filter((val) => val === true)).subscribe({
      next() {
        try {
          subscription.unsubscribe();
        } catch (e) {
          reject(e);
          return;
        }

        resolve();
      },
    });
  });
}
