import { Grid, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const BetaMessage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack flexDirection='row' justifyContent='flex-end' marginLeft='auto' minHeight='50px'>
      <Grid container alignItems='center'>
        <Typography className='link-wrapper' variant='p16' color='secondary'>
          The web app is currently in beta.&nbsp;
          <Link onClick={() => navigate('/contact')}>Send us your feedback!</Link>
        </Typography>
      </Grid>
    </Stack>
  );
};
