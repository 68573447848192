import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Helpers from '../../utils/Helpers';
import { theme } from '../../themes/ThemeWrapper';
import {
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  ListItemIcon,
  MenuItem,
  MenuList,
  SvgIcon,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { API_URL } from '../../configuration/Environment';

type Props = Record<string, unknown>;

export type NickelsMenuItem = {
  title: string;
  icon?: string;
  selected?: boolean;
  exclude?: boolean;
  href: string;
  isDivider?: boolean;
};

type NickelsMenuItems = NickelsMenuItem[];

export type SideNavigationProps = {
  menuItems: NickelsMenuItems;
} & Props;

const Item: React.FC<{ menu: NickelsMenuItem }> = ({ menu }) => {
  const { t } = useTranslation();
  const [disableSignout, setDisableSignout] = React.useState(false);

  if (menu.exclude) {
    return null;
  }

  if (menu.isDivider) {
    return <Divider />;
  }

  if (menu.href === '/logout') {
    const formRef = React.useRef<HTMLFormElement>(null);
    return (
      <>
        <form ref={formRef} method='post' action={API_URL.replace('/api', '') + 'logout'}>
          <MenuItem
            className={menu.selected ? 'selected' : ''}
            disableRipple
            disabled={disableSignout}
            disableTouchRipple
            onClick={() => {
              formRef.current?.submit();
              setDisableSignout(true);
            }}
            selected={menu.selected}>
            <ListItemIcon>
              <SvgIcon
                component={Helpers.IconSolver(menu.icon)}
                fill={menu.selected ? theme.palette.secondary.main : theme.palette.primary.main}
                inheritViewBox
              />
            </ListItemIcon>
            <Typography variant='inherit'>{t(menu.title)}</Typography>
          </MenuItem>
          <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
        </form>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={disableSignout}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    );
  }

  return (
    <Link to={menu.href}>
      <MenuItem
        className={menu.selected ? 'selected' : ''}
        disableRipple
        disableTouchRipple
        selected={menu.selected}>
        <ListItemIcon>
          <SvgIcon
            component={Helpers.IconSolver(menu.icon)}
            fill={menu.selected ? theme.palette.secondary.main : theme.palette.primary.main}
            inheritViewBox
          />
        </ListItemIcon>
        <Typography variant='inherit'>{t(menu.title)}</Typography>
      </MenuItem>
    </Link>
  );
};

const SideNavigation: React.FC<SideNavigationProps> = (props) => {
  const renderMenuItems = () =>
    props.menuItems.map((menu, index) => <Item key={index} menu={menu} />);

  return (
    <Grid className='side-nav' item>
      <MenuList className='side-navigation'>{renderMenuItems()}</MenuList>
    </Grid>
  );
};

export default SideNavigation;
