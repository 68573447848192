import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GroupPanels, { Group } from '../../../../components/GroupPanels';
import { Svgs } from '../../../../assets/svg';
import { financialFitnessLogo } from '../../../../assets/png';
import { Hooks } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { Colors } from '../../../../themes';
import UniversityCompleteCard from './UniversityCompleteCard';

const ExploreSection: React.FC = () => {
  const { t } = useTranslation();
  const urls = Hooks.useFinancialFitnessUrls();
  const { featureMap } = Hooks.usePrincipalUser();
  const course = Hooks.useUniversityCourse();
  const lifestyleProducts = Hooks.useLifestyleProducts();
  const groups: Group[] = useMemo(() => {
    const g: Group[] = [];
    if (featureMap.threeNickelsU && course?.complete) {
      g.push({
        textKey: '3NickelsU',
        url: '/university',
        style: {
          background: `linear-gradient(${Colors.secondaryLight} 0 0) bottom left / 100% 8px no-repeat, white`,
        },
        content: <UniversityCompleteCard />,
      });
    }
    g.push({
      textKey: 'ToolsAndChecklists',
      subTextKey: 'Explore12ModulesOfTools',
      icon: <Svgs.DisplaySmallCustomCalculator />,
      url: '/tools',
    });
    g.push({
      textKey: 'FinancialFitness',
      subTextKey: 'CheckOutASuiteOfBonusContent',
      icon: <img height='29px' src={financialFitnessLogo} />,
      onClick: () => {
        window.open(urls?.AllCourses);
      },
    });
    if ((lifestyleProducts?.length ?? 0) > 0) {
      g.push({
        textKey: 'Lifestyle',
        subTextKey: "Here'sAnIdeaForYourLifeNeeds",
        icon: <Svgs.DisplaySmallCustomLegacy />,
        url: '/lifestyle',
      });
    }

    return g;
  }, [urls, featureMap, course, lifestyleProducts]);

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('Explore')}
      </Typography>
      <GroupPanels variant='Popover' groups={groups} groupsPerRow={2} />
    </Grid>
  );
};

export default ExploreSection;
