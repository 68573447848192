import { Grid, Typography } from '@mui/material';
import { universitySchool } from '../../../../assets/png';
import { Svgs } from '../../../../assets/svg';
import { SubscriptionDetails } from '../SubscriptionDetails';
import { Colors } from '../../../../themes';

export const Free3nuHeaderCard: React.FC = () => {
  return (
    <Grid
      container
      className='forward-slash-split-card'
      style={{ '--background': '#f7d27e' } as React.CSSProperties}>
      <Grid className='left-grid' pl={3} pt={3} pb={2} flexDirection='column' textAlign='center'>
        <Typography variant='p30Bold' color='secondary'>
          It’s never too late to start learning
        </Typography>
        <Typography mt={1} variant='p18Bold' color='primary'>
          The first step to financial freedom is financial literacy.
        </Typography>
        <SubscriptionDetails
          style={{ paddingTop: '16px', paddingBottom: '10px' }}
          linkColor={Colors.secondaryBase}
        />
      </Grid>

      <Grid
        container
        flexDirection='column'
        className='img-wrapper'
        alignContent='flex-end'
        justifyContent='center'
        maxWidth='35%'
        pt={1}
        style={{
          backgroundImage: `linear-gradient(to bottom, ${Colors.disabledLight} -61%, #cfe1f2)`,
        }}>
        <Grid container width='80%' pl={5} justifyContent='center'>
          <Svgs.NickelsULogoSecondary style={{ paddingLeft: '8px', paddingRight: '8px' }} />
          <img src={universitySchool} style={{ maxHeight: '130px', maxWidth: '230px' }} />
        </Grid>
      </Grid>
    </Grid>
  );
};
