import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, InputLabel, Typography, Stack, Slider } from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import '../../themes/tippy-light-nickels.css';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../../themes/ThemeWrapper';
import TextInput, { TextInputProps } from './TextInput';
import { useFormContext } from 'react-hook-form';
import { cleanWholeNumberStr } from '../../helpers/utilityFunctions';

export default function SliderPercent<T>(props: TextInputProps<T>) {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext();
  const [percent, setPercent] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    const fieldValue = getValues(props.name.toString());
    if (fieldValue) {
      setPercent(cleanWholeNumberStr(fieldValue.toString(), { float: true }));
    } else if (props.defaultValue) {
      setPercent(props.defaultValue as number);
    } else {
      setPercent(0);
    }
  }, []);

  React.useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === props.name) {
        const newVal = values[name];
        setPercent(cleanWholeNumberStr(newVal, { float: true }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, props.name]);

  const { label, ...textProps } = props;
  const text = label ? t(label) : '';

  if (typeof percent === typeof undefined) return null;

  return (
    <Grid display='flex' flexDirection='column'>
      <Grid
        item
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        display='flex'
        mb='6px'>
        <InputLabel
          shrink={false}
          disableAnimation
          required={props.required}
          htmlFor={props.name as string}
          error={props.error}>
          <Typography variant='p14Bold' color='primary'>
            {text}
          </Typography>
        </InputLabel>
        {props.helpContext && (
          <Grid item display='flex' justifyContent='flex-end'>
            <Tippy placement='right' content={props.helpContext} theme='light-nickels-theme'>
              <HelpOutline style={{ color: theme.palette.primary.main }} />
            </Tippy>
          </Grid>
        )}
      </Grid>
      <Stack spacing={2} direction='row' alignItems='center'>
        <Slider
          color='primary'
          value={percent}
          min={textProps.min}
          max={textProps.max}
          onChange={(event: Event, newValue: number | number[]) => {
            setValue(textProps.name.toString(), newValue.toString(), {
              shouldDirty: true,
            });
          }}
        />
        <TextInput
          {...textProps}
          id={textProps.name?.toString()}
          // defaultValue={percent}
          // value={percent?.toString() + '%'}
          className='slider-input'
          type={textProps.type ?? 'percent'}
        />
      </Stack>
    </Grid>
  );
}
