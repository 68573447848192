import { AlertColor } from '@mui/material';

export type ToastDefaultValue = {
  toast: Toast;
  setToast: React.Dispatch<React.SetStateAction<Toast>>;
};

// TODO update these once we have designs for them
const defaultMessages = {
  success: 'Success',
  error: 'Error',
  warning: 'Warning',
  info: 'Info',
  empty: undefined,
};

export class Toast {
  open: boolean;
  severity?: AlertColor;
  message?: string;
  autoHideDuration?: number;

  constructor(data: Toast) {
    this.open = data.open;
    this.severity = data.severity;
    this.message = data.message ?? defaultMessages[this.severity ?? 'empty'];
    this.autoHideDuration = data.autoHideDuration;
  }
}
