import styled from '@emotion/styled';

type MainContentProps = {
  isMobile: boolean;
};

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
  height: 100vh;

  background-repeat: no-repeat;
  background-size: ${(props: MainContentProps) => (props.isMobile ? '100%' : '80%')};
`;
