import { Data } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { json } from 'react-router-dom';

export async function loadAdviceOverview(container: IServiceContainer) {
  const holisticAdviceListener = container.resolve(Data.HolisticAdvice.HolisticAdviceListener);
  const socialSecurityListener = container.resolve(Data.SocialSecurity.SocialSecurityListener);
  holisticAdviceListener.start();
  socialSecurityListener.start();
  return json('holistic-advice and social security listener started');
}

export async function loadAdviceResults(container: IServiceContainer) {
  const adviceResultsListener = container.resolve(Data.HolisticAdvice.AdviceResultsListener);
  adviceResultsListener.start();
  return json('advice results listener started');
}

export async function savedGuidesLoader(container: IServiceContainer) {
  const savedGuidesListener = container.resolve(Data.HolisticAdvice.SavedGuidesListener);
  savedGuidesListener.start();
  return json('saved guides listener started');
}
