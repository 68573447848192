import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { SpendingSummaryLayoutMeta } from './SpendingSummaryLayout';
import SpendingSummaryTimeframeSelector from './SpendingSummaryTimeframeSelector';
import SpendingSummaryTransactionsCategoriesSelector from './SpendingSummaryTransactionsCategoriesSelector';
import SpendingSummaryTransactionsAccountsSelector from './SpendingSummaryTransactionsAccountsSelector';
import { Data, Hooks } from '@3nickels/data-modules';
import SpendingSummaryTransactionsTable from './SpendingSummaryTransactionsTable';
import FormContent from '../../components/form/FormContent';
import { useForm } from 'react-hook-form';
import SpendingSummaryTransactionsSearch from './SpendingSummaryTransactionsSearch';

const SpendingSummaryTransactionsView: React.FC = () => {
  const { t } = useTranslation();

  const { transactions, loadingTransactions, loadingMoreTransactions, transactionsSearchString } =
    Hooks.useTransactions();
  const methods = useForm<{ searchString: string }>({
    defaultValues: { searchString: transactionsSearchString ?? '' },
  });

  const disableSelectors = useMemo(() => {
    return loadingTransactions || loadingMoreTransactions;
  }, [loadingTransactions, loadingMoreTransactions]);

  return (
    <Grid>
      <Typography variant='p30Bold' color='primary'>
        {t('Transactions')}
      </Typography>
      <FormContent formProviderProps={methods}>
        <Grid
          className='transactions-selectors'
          container
          display='flex'
          justifyContent={'space-between'}>
          <SpendingSummaryTransactionsSearch />
          <SpendingSummaryTransactionsCategoriesSelector disabled={disableSelectors} />
          <SpendingSummaryTimeframeSelector
            variant='panel'
            showStartAdornment
            hideArrow
            cutAverages
            disabled={disableSelectors}
          />
          <SpendingSummaryTransactionsAccountsSelector disabled={disableSelectors} />
        </Grid>
      </FormContent>
      <SpendingSummaryTransactionsTable transactions={transactions} />
    </Grid>
  );
};

const SpendingSummaryTransactionsViewWithTransactionsContext: React.FC = () => {
  return (
    <Data.Transactions.TransactionsProvider>
      <SpendingSummaryTransactionsView />
    </Data.Transactions.TransactionsProvider>
  );
};

const meta = {
  showBack: true,
  onBack: (navigate) => navigate('/spending-summary'),
} satisfies LayoutMeta<SpendingSummaryLayoutMeta>;

export default withLayoutMeta(SpendingSummaryTransactionsViewWithTransactionsContext, meta);
