import { Authentication } from '@3nickels/data-modules';
import { inject } from '@aesop-fables/containr';
import { PredicateListener } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { AuthTokenApi } from '../../api/apis/AuthTokenApi';

export class AuthTokenCookieDataListener extends PredicateListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    context: Authentication.IAuthenticationContext,
    @inject(ApiKeys.AuthToken) private readonly api: AuthTokenApi
  ) {
    super(context.isAuthenticated$);
  }

  async onNext(): Promise<void> {
    await this.api.generateCookie();
  }
}
