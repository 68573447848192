export const transparent = 'rgba(0, 0, 0, 0)';
export const semiTransparent = 'rgba(0, 0, 0, .5)';
export const black = '#000';

export const primaryLightest = '#e8f1f3';
export const primaryLighter = '#c6dde3';
export const primaryLight = '#8cbac7';
export const primaryBase = '#1b7690';
export const primaryDark = '#0d3b48';
export const primaryDarker = '#08232b';
export const secondaryLighter = '#f3f9fa';
export const secondaryLighterTransparent = 'rgba(243, 249, 250, .85)';
export const secondaryLight = '#8e94b4';
export const secondaryBase = '#1e2969';
export const secondaryDark = '#0f1434';
export const secondaryDarker = '#0a0b1f';
export const tertiaryBase = '#ffffff';
export const tertiaryDark = '#7f7f7f';
export const tertiaryDarker = '#4c4c4c';
export const accentLightest = '#f4edeb';
export const accentLighter = '#e0cbc3';
export const accentLight = '#cca89b';
export const accentBase = '#9a5238';
export const accentDark = '#623424';
export const accentDarker = '#2e1810';
export const alternateLightest = '#fef9ef';
export const alternateLighter = '#fceecf';
export const alternateDarker = '#493b1c';
export const alternateBase = '#f6c760';
export const alternateDark = '#f2ae18';
export const alternateLight = '#fae3af';
export const disabledLight = '#ededed';
export const disabledBase = '#bfbfbf';
export const disabledDark = '#5f5f5f';
export const disabledDarker = '#393939';

export const cancelPolicy = '#4a606a';
