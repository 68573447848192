/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useService } from '@aesop-fables/containr-react';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { AccountSettingsWizard, accountSettingsWizardKey } from '../../../services/accountSettings';
import { useObservable } from '../../../hooks/useObservable';
import { useMessage } from '../../../hooks/useMessage';
import { emailRegx, phoneNumberRegex } from '../../../helpers/utilityFunctions';
import FormContent from '../../../components/form/FormContent';
import TextInput from '../../../components/form/TextInput';
import { WizardFooter } from '../../../components/form/WizardFooter';
import SelectInput from '../../../components/form/SelectInput';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';

const authMethodSchema = (twoFactorType: string, methods: Api.AuthTwoFactorData[] | undefined) =>
  Yup.object({
    authenticationMethod: Yup.string().required('Method is required'),
    email:
      twoFactorType === Api.TwoFactorTypeEnum.Email
        ? Yup.string()
            .required('Email is required')
            .matches(emailRegx, 'Invalid email address')
            .test(
              'email',
              'Authentication method is already in use',
              (value) => !methods?.some((method) => method.info === value)
            )
        : Yup.string().notRequired(),
    mobilePhone:
      twoFactorType === Api.TwoFactorTypeEnum['Mobile Phone']
        ? Yup.string()
            .required('Mobile phone is required')
            .matches(phoneNumberRegex, 'Invalid phone number')
            .test(
              'mobilePhone',
              'Authentication method is already in use',
              (value) => !methods?.some((method) => method.info === value.replace(/\D/g, ''))
            )
        : Yup.string().notRequired(),
  });

const AddAuthMethodView: React.FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const twoFactorMethods = Hooks.useTwoFactorMethods();
  const accountSettingsWizard = useService<AccountSettingsWizard>(accountSettingsWizardKey);
  const addTwoFactorMethodData = useObservable(accountSettingsWizard.addTwoFactorMethodData$);
  const [twoFactorType, setTwoFactorType] = useState<Api.TwoFactorTypeEnum>(
    (addTwoFactorMethodData?.authenticationMethod as Api.TwoFactorTypeEnum) ??
      Api.TwoFactorTypeEnum.Email
  );
  const methods = useForm<Data.TwoFactor.AuthTwoFactorFormData>({
    defaultValues: addTwoFactorMethodData,
    resolver: yupResolver(authMethodSchema(twoFactorType, twoFactorMethods)),
  });

  const formatInputs = (values: any) => {
    const phoneMethod =
      values.authenticationMethod === Api.TwoFactorTypeEnum['Mobile Phone'] && values.mobilePhone;
    if (phoneMethod) {
      const cleanedMobilePhone = values.mobilePhone.replace(/\D/g, '');
      return { ...values, mobilePhone: cleanedMobilePhone };
    }
    return values;
  };

  const addTwoFactorMethod = async (values: Data.TwoFactor.AuthTwoFactorFormData) => {
    try {
      const cleanedValues = formatInputs(values);
      accountSettingsWizard.setAddTwoFactorMethodData(cleanedValues);
      await accountSettingsWizard.saveTwoFactorMethod(cleanedValues);
      navigate('/settings/two-factor-authentication/verify');
    } catch (err) {
      showMessage('We weren’t banking on that happening...please try again later.', 'info');
    }
  };

  const onSubmit = methods.handleSubmit((data) => {
    addTwoFactorMethod(data);
  });

  useEffect(() => {
    if (addTwoFactorMethodData) {
      methods.setValue('authenticationMethod', addTwoFactorMethodData.authenticationMethod);
      setTwoFactorType(addTwoFactorMethodData.authenticationMethod as Api.TwoFactorTypeEnum);
      if (addTwoFactorMethodData.authenticationMethod === Api.TwoFactorTypeEnum.Email) {
        methods.setValue('email', addTwoFactorMethodData.email);
      } else {
        methods.setValue('mobilePhone', addTwoFactorMethodData.mobilePhone);
      }
    }
  }, [addTwoFactorMethodData]);

  useEffect(() => {
    if (twoFactorType === Api.TwoFactorTypeEnum.Email) {
      methods.setValue('mobilePhone', undefined);
    } else {
      methods.setValue('email', undefined);
    }
  }, [twoFactorType]);

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography variant='p30Bold' component='h1' color='primary'>
          {t('AddAnAuthenticationMethod')}
        </Typography>
        <Typography mt={2} mb={3} variant='p16' color='secondary'>
          {t('AnotherLayerOfSecurity')}
        </Typography>
        <FormContent formProviderProps={methods}>
          <Box component='form' onSubmit={onSubmit}>
            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={6}>
                <Stack spacing={3}>
                  <SelectInput<Data.TwoFactor.AuthTwoFactorFormData>
                    error={methods.formState.errors.authenticationMethod !== undefined}
                    helperText={methods.formState.errors.authenticationMethod?.message?.toString()}
                    name='authenticationMethod'
                    label='TypeOfAuthenticationMethod'
                    defaultValue={
                      addTwoFactorMethodData?.authenticationMethod ?? Api.TwoFactorTypeEnum.Email
                    }>
                    {Data.TwoFactor.TwoFactorTypeEnumList.map((x) => (
                      <MenuItem
                        onClick={() => {
                          setTwoFactorType(x.key as Api.TwoFactorTypeEnum);
                        }}
                        value={x.key}>
                        {x.value}
                      </MenuItem>
                    ))}
                  </SelectInput>
                  {twoFactorType === Api.TwoFactorTypeEnum.Email ? (
                    <TextInput<Data.TwoFactor.AuthTwoFactorFormData>
                      error={methods.formState.errors.email !== undefined}
                      helperText={methods.formState.errors.email?.message?.toString()}
                      label='EnterEmailAddress'
                      name='email'
                      placeholder='name@email.com'
                      defaultValue={addTwoFactorMethodData?.email}
                    />
                  ) : (
                    <TextInput<Data.TwoFactor.AuthTwoFactorFormData>
                      error={methods.formState.errors.mobilePhone !== undefined}
                      helperText={methods.formState.errors.mobilePhone?.message?.toString()}
                      label='EnterMobilePhoneNumber'
                      name='mobilePhone'
                      type='tel'
                      placeholder='(###) ###-####'
                      defaultValue={addTwoFactorMethodData?.mobilePhone}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
            <WizardFooter onDone={onSubmit} nextLabel='Submit' backLabel='Cancel' />
          </Box>
        </FormContent>
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: false,
  hexHeader: true,
  hexSteps: {
    steps: 2,
    currentStep: 1,
  },
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(AddAuthMethodView, meta);
