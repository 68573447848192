import { BehaviorSubject, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';
import { LicenseWizardState } from '../../models/LicenseData';
import { createServiceModule } from '@aesop-fables/containr';

export const licenseWizardKey = 'services/licenseWizard';

class LicenseWizard implements Disposable {
  private readonly state: BehaviorSubject<LicenseWizardState> = new BehaviorSubject({});

  constructor() {
    console.log('creating license wizard');
    this.init();
  }

  async init() {
    console.log('initializing');
    try {
      const licenseWizardStateJson = localStorage.getItem('license-wizard-state');
      if (licenseWizardStateJson) {
        const licenseWizardState = JSON.parse(licenseWizardStateJson) as LicenseWizardState;
        this.state.next(licenseWizardState);
      }
    } catch (err) {
      console.error(err);
    }
  }

  get state$(): Observable<LicenseWizardState> {
    return this.state;
  }

  setState(state: LicenseWizardState) {
    localStorage.setItem('license-wizard-state', JSON.stringify(state));
    this.state.next(state);
  }

  clearCache() {
    localStorage.removeItem('license-wizard-state');
    this.state.next({});
  }

  dispose(): void {
    console.log('disposing LicenseWizard');
  }
}

export const withLicenseWizard = createServiceModule(licenseWizardKey, services => {
  services.use<LicenseWizard>(licenseWizardKey, LicenseWizard);
});

export { LicenseWizard };
