import { Grid, Typography } from '@mui/material';
import React from 'react';

interface ChartLegendProps {
  items: ChartLegendItemProps[];
  orientation?: Orientation;
}

interface ChartLegendItemProps {
  text: string;
  color: string;
  dashed?: boolean;
}
type Orientation = 'horizontal' | 'vertical';

export const ChartLegend: React.FC<ChartLegendProps> = (props) => {
  const renderItems = () =>
    props.items.map((item, i) => <ChartLegendItem {...item} key={i.toString()} />);

  // if (props.orientation && props.orientation === 'horizontal') {
  //   return <Row style={[{ justifyContent: 'space-evenly' }]}>{renderItems()}</Row>;
  // }

  return (
    <Grid display='flex' alignItems='flex-end' justifyContent='space-around'>
      {renderItems()}
    </Grid>
  );
};

export const ChartLegendItem: React.FC<ChartLegendItemProps> = (props) => {
  const dashedStyle = { height: 2, width: 7, backgroundColor: props.color, borderRadius: 2.5 };

  return (
    <Grid display='flex' justifyContent='center' alignItems='center'>
      <Typography color='secondary' variant='p16'>
        {props.text}
      </Typography>
      {props.dashed ? (
        <Grid justifyContent='space-between'>
          <Grid style={dashedStyle} />
          <Grid style={dashedStyle} />
        </Grid>
      ) : (
        <Grid
          ml='5px'
          style={{ height: 5, width: 30, backgroundColor: props.color, borderRadius: 2.5 }}
        />
      )}
    </Grid>
  );
};
