/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Backdrop, Grid, Typography } from '@mui/material';
import { Loading } from '../../hooks/useLoading';
import { IncomeReadySubject } from '../../subjects/AppReadySubject';
import { useSubject } from '@aesop-fables/scrinium';
import Box from '@mui/material/Box';
import { BudgetDetailsLayoutMeta } from './BudgetDetailsLayout';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { TabPanel, Tabs } from '../../components/Tabs';
import { formatPercent } from '../../helpers/utilityFunctions';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Svgs } from '../../assets/svg';

const BudgetDetailsAveragesWrapper: React.FC = () => {
  const incomeReady = useSubject<boolean>(IncomeReadySubject);
  const result = Hooks.useBudgetProjectionResult();

  if (typeof incomeReady === 'undefined' || incomeReady === false || result.loading) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }
  return <BudgetDetailsAverages result={result} />;
};

const IncomePanel: React.FC<{ income: number }> = ({ income }) => {
  return (
    <Grid className='budget-details-panel-ghost'>
      <Grid item xs={2}>
        <Typography className='budget-details-panel-start' variant='p18Bold' color='secondary'>
          <Svgs.DisplaySmallCustomWallet />
          INCOME
          <Typography variant='p12' fontWeight='regular' color='primary' marginLeft='10px'>
            (After tax)
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          className='budget-details-panel-end'
          variant='p22Bold'
          color='primary'
          fontFamily='Montserrat'>
          {formatWholeDollars(income)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const LeftoverPanel: React.FC<{ leftover: number }> = ({ leftover }) => {
  return (
    <Grid className='budget-details-panel-ghost'>
      <Grid item />
      <Grid item display='flex'>
        <Typography
          className='budget-details-panel-end'
          variant='p16Bold'
          color='secondary'
          marginRight='20px'>
          Amount Left Over
        </Typography>
        <Typography
          className='budget-details-panel-end'
          variant='p22Bold'
          color={leftover > 0 ? 'secondary' : 'error'}
          fontFamily='Montserrat'>
          {formatWholeDollars(leftover)}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface BudgetDetailsPanelProps {
  category: string;
  icon: React.ReactNode;
  percentDifference: number;
  amount: number;
}

const BudgetDetailsPanel: React.FC<BudgetDetailsPanelProps> = ({
  category,
  icon,
  percentDifference,
  amount,
}) => {
  const memoizedPercent = useMemo(
    () => formatPercent(percentDifference, true),
    [percentDifference, formatPercent]
  );
  return (
    <Grid className='budget-details-panel'>
      <Grid item xs={2}>
        <Typography className='budget-details-panel-start' variant='p18Bold' color='secondary'>
          {icon}
          {category.toLocaleUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={7} className='budget-details-panel-content'>
        <Typography variant='p18Bold' color='secondary'>
          {memoizedPercent}
          <Grid item marginLeft='5px'>
            {percentDifference > 0 && <ArrowUpward color='primary' />}
            {percentDifference < 0 && <ArrowDownward color='primary' />}
            {/* === 0 ? */}
          </Grid>
        </Typography>
        <Typography variant='p14' color='primary'>
          Your {category.toLocaleLowerCase()} is {memoizedPercent}{' '}
          {percentDifference > 0 ? 'above' : 'below'} average for your income level.
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          className='budget-details-panel-end'
          variant='p22Bold'
          color='primary'
          fontFamily='Montserrat'>
          {formatWholeDollars(amount)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BudgetDetailsAveragesContent: React.FC<{
  result: Hooks.BudgetProjectionResult;
  value: number;
  index: number;
  mode: Data.Budget.BudgetDetailsMode;
}> = ({ result, value, index, mode }) => {
  return (
    <TabPanel className='budget-details-averages' value={value} index={index}>
      <IncomePanel
        income={Data.Budget.adjustAnnualAmount(
          mode,
          result.projection?.summary?.afterTaxHouseholdIncome ?? 0
        )}
      />
      <BudgetDetailsPanel
        category='saving'
        icon={<Svgs.PiggyBank />}
        percentDifference={result.projection?.differences?.averageSavingsDifference ?? 0}
        amount={Data.Budget.adjustMonthlyAmount(mode, result.projection?.summary?.totalSaving ?? 0)}
      />
      <BudgetDetailsPanel
        category='spending'
        icon={<Svgs.DisplaySmallCustomBudget />}
        percentDifference={result.projection?.differences?.averageSpendingDifference ?? 0}
        amount={Data.Budget.adjustMonthlyAmount(
          mode,
          result.projection?.summary?.totalSpending ?? 0
        )}
      />
      <BudgetDetailsPanel
        category='giving'
        icon={<Svgs.DisplaySmallCustomGifts />}
        percentDifference={result.projection?.differences?.averageGivingDifference ?? 0}
        amount={Data.Budget.adjustMonthlyAmount(mode, result.projection?.summary?.totalGiving ?? 0)}
      />
      <LeftoverPanel
        leftover={Data.Budget.adjustMonthlyAmount(
          mode,
          result.projection?.summary?.remainingFunds ?? 0
        )}
      />
    </TabPanel>
  );
};

export const BudgetDetailsAverages: React.FC<{ result: Hooks.BudgetProjectionResult }> = ({
  result,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs labels={['Monthly', 'Annual']} value={value} onChange={handleChange} />
      <BudgetDetailsAveragesContent result={result} value={value} index={0} mode='monthly' />
      <BudgetDetailsAveragesContent result={result} value={value} index={1} mode='annual' />
      <Typography variant='p12' color='primary'>
        Budget averages info c/o Pew Research Center analysis of 2016 American Community Survey
        (IPUMS).
      </Typography>
    </Box>
  );
};

const meta = {
  showBack: true,
  showNext: false,
  headerVariant: 'averages',
} satisfies LayoutMeta<BudgetDetailsLayoutMeta>;

export default withLayoutMeta(BudgetDetailsAveragesWrapper, meta);
