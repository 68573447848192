import React from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { cleanWholeNumberStr, formatWholeDollars } from '../../helpers/utilityFunctions';
import { TextInputProps } from './TextInput';

const maxDollarInput = 99999999;

export default function DollarInput<T>(props: TextInputProps<T>) {
  const [formattedValue, setFormattedValue] = React.useState<string>('');
  const methods = useFormContext();

  const dollarHandler = (value: string) => {
    const number = cleanWholeNumberStr(value);
    if (number <= (props.max ?? maxDollarInput)) {
      const dollar = formatWholeDollars(number);
      setFormattedValue(dollar);
      return dollar;
    }
    return formattedValue;
  };

  React.useEffect(() => {
    const dollar = dollarHandler(props.defaultValue?.toString() ?? '');
    methods.setValue(props.name as string, dollar);
  }, [props.defaultValue]);

  React.useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === props.name) {
        // allow custom function to handle inputs with unconventional names
        // i.e. fieldArrays where convention is fieldArrayName.index.property
        if (props.resolver) {
          const newVal = props.resolver(values);
          dollarHandler(newVal);
          return;
        }
        const newVal = values[name];
        dollarHandler(newVal);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, props.name, dollarHandler]);

  const textProps = { ...props };
  if (textProps.helpContext) {
    delete textProps.helpContext;
  }

  return (
    <TextField
      {...(textProps as TextFieldProps)}
      fullWidth
      size='small'
      variant='outlined'
      inputProps={{
        ...props.inputProps,
        ...{ color: 'secondary' },
        ...(methods && methods.register ? { ...methods.register(props.name as string) } : {}),
      }}
      defaultValue={undefined}
      value={formattedValue}
    />
  );
}
